@use "vars" as *;

/* ====================================================
PAGE nyushi danshi
==================================================== */
.sec-danshi {
	padding-bottom: 177px;
	overflow: hidden;

	@include mq {
		padding-bottom: 198px;
	}
}

.danshi-heading {
	line-height: 1.875;
	margin-bottom: 63px;

	@include mq {
		line-height: 2.083;
		margin-bottom: 55px;
	}

	&__img {
		margin-bottom: 53px;

		@include mq {
			margin-bottom: 33px;
		}

		img {
			border-radius: 20px;
		}
	}

	&__dl {
		&:not(:last-child) {
			margin-bottom: 58px;

			@include mq {

				margin-bottom: 32px;
			}
		}

		&-dt {
			margin-bottom: 10px;

			@include mq {
				margin-bottom: 5px;
			}
		}
	}
}

.danshi-app {
	position: relative;

	@include p-before(274px, 265px) {
		background: url(../img/nyushi/danshi/bg-before.png) no-repeat center/contain;
		left: -23.5%;
		bottom: -175px;
		z-index: -1;

		@include mq {
			width: 187px;
			height: 151px;
			left: -47px;
			bottom: -173px;
		}
	}

	.c-ttl__03 {
		margin-bottom: 15px;

		@include mq {
			margin-bottom: 4px;
		}
	}

	&__advise {
		@include fts(20, 35);
		font-weight: 700;
		color: #E0346E;

		@include mq {
			@include fts(16, 25);
		}

		&:not(:last-child) {
			margin-bottom: 22px;

			@include mq {
				margin-bottom: 11px;
			}
		}

		a {
			color: inherit;
		}

		small {
			@include font-size(16);
		}
	}

	p {
		@include mq {
			line-height: 2.083;
		}

		&:not(:last-child) {
			margin-bottom: 33px;

			@include mq {
				margin-bottom: 23px;
			}
		}
	}

	.c-btn__01 {
		margin-left: 0;
	}
}
