@use "vars" as *;

#page-campus_life_qa{
    .mv-page__ttl{
        @include mq(md-min){
            padding-bottom: 27px;
        }
    }
}
.sec-int{
    padding-bottom: 66px;
    @include mq(){
        padding-bottom: 39px;
    }
}
.int-block{
    &__item{
        @include flex(0,0,wrap);
        margin-bottom: 31px;
        @include mq(){
            margin-bottom: 20px;
        }
        &--02{
            @include flex(between,0,wrap);
            .int-block{
                &__ct{
                    width: calc(61.5% - 47px);
                    @include mq(xl-min){
                        .c-ttl__03{
                            margin-right: -15px;
                        }
                    }
                    @include mq(sm){
                        width: 100%;
                        img{
                            padding-top: 6px;
                            margin-bottom: 17px;
                        }
                    }
                }
                &__img{
                    width: 38.5%;
                    padding-top: 11px;
                    @include mq(sm){
                        width: 100%;
                    }
                }
            }
        }
        &--03{
            display: block;
            margin-bottom: 55px;
            @include mq(){
                margin-bottom: 43px;
            }
            .c-ttl{
                &__03{
                    @include mq(sm){
                        margin-bottom: 20px;
                    }
                }
            }
            .int-block{
                &__img{
                    width: 38.5%;
                    padding-top: 11px;
                    display: inline-block;
                    float: right;
                    margin-left: 30px;
                    @include mq(sm){
                        width: 100%;
                    }
                }
                &__ct{
                    width: auto;
                    p{
                        margin-bottom: 18px;
                    }
                    img{
                        @include mq(sm){
                            margin-bottom: 17px;
                        }
                    }
                }
            }
        }
        &--04{
            display: block;
            .int-block{
                &__img{
                    width: 38.5%;
                    display: inline-block;
                    float: left;
                    margin-right: 30px;
                    margin-bottom: 10px;
                    @include mq(sm){
                        width: 100%;
                    }
                }
                &__ct{
                    width: auto;
                    @include mq(xl-min){
                        margin-right: -8%;
                    }
                    .c-ttl__03{
                        display: grid;
                        margin-bottom: 40px;
                    }
                    img{
                        @include mq(sm){
                            margin-bottom: 17px;
                        }
                    }
                }
            }
        }
        &--05{
            .int-block{
                &__ct{
                    &-img{
                        width: 38.5%;
                        float: right;
                        margin-left: 30px;
                        @include mq(sm){
                            width: 100%;
                            margin-bottom: 17px;
                        }
                    }
                }
            }
        }
    }
    p{
        font-weight: 700;
        font-family: $font-Yugothic;
        margin-bottom: 30px;
        &:last-child{
            margin-bottom: 12px;
        }
    }
    &--02{
        p{
            margin-bottom: 13px;
            @include mq(){
                margin-bottom: 18px;
            }
        }
    }
}