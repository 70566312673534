@use "vars" as *;

/* ====================================================
Control
==================================================== */
.c-fc {

	//font-color
	&--ff {
		color: #fff
	}

	&--base {
		color: #1D3E62;
	}

	&--pink {
		color: #E0346E;
	}

	&--blue {
		color: #277AE2 !important;
	}

	&--purple {
		color: #B838C1;
	}

	&--green {
		color: #5BBE7F;
	}
}


.c-ft {

	//font-family
	&--jp {
		font-family: $font-jp;
	}

	&--base {
		font-family: $font-Yugothic;
	}
}


.c-fw {

	//font-weight
	&--lt {
		font-weight: 300 !important
	}

	&--rg {
		font-weight: 400 !important
	}

	&--md {
		font-weight: 500 !important
	}

	&--sbd {
		font-weight: 600 !important
	}

	&--bd {
		font-weight: 700 !important
	}

	&--bk {
		font-weight: 900 !important
	}
}


.c-tx--v {
	//Vertical writing
	//display: inline-block;
	-ms-writing-mode: tb-rl;
	writing-mode: vertical-rl;

	&-pc {
		@include mq(md-min) {
			-ms-writing-mode: tb-rl;
			writing-mode: vertical-rl;
		}
	}
}


.c-al {

	//text-align
	&--c {
		text-align: center !important
	}

	&--r {
		text-align: right !important
	}

	&--l {
		text-align: left !important
	}
}


.c-val {

	//vertical-align
	&--md {
		vertical-align: middle
	}

	&--top {
		vertical-align: top
	}
}

/* ====================================================
Component
==================================================== */
// Common Text style
.c-txt {
	&__basic {
		@include fts(16, 35);

		@include mq() {
			@include fts(12, 20);
		}

		&--lh30 {
			line-height: calc(30/16);
		}
	}
	&__under {
		text-decoration: underline;
	}
	&__hightline{
		background-image: linear-gradient(to right, #FFF8A1 50%, transparent 50%);
		background-size: 200% 23px;
		background-repeat: no-repeat;
		background-position: 0 33px;
		@supports (-webkit-touch-callout: none) {
			background-position: 0 17px;
		}  
		@include mq{
			background-size: 200% 16px;
			background-position: 0 22px;
			@supports (-webkit-touch-callout: none) {
				background-position: 0 12px;
			} 
		}
	}
}



//ttl
.c-ttl {
	&__01 {}

	&__02 {
		@include fts(40, 70);
		font-weight: 700;
		text-align: center;

		@include mq {
			@include fts(20, 30);
		}
		@media screen and (max-width: 375px){
			@include font-size(18);
		}
		@include mq(xs) {
			@include font-size(16);
		}
	}

	&__03 {
		@include fts(30, 50);
		font-weight: 700;
		position: relative;

		@include mq() {
			@include fts(16, 20);
		}

		&--pink {
			color: #E0346E;
			position: relative;
			padding-left: 34px;
			margin-bottom: 7px;

			@include mq() {
				padding-left: 18px;
				margin-bottom: 15px;
			}

			@include p-before(15px, 32px) {
				background: #E0346E;
				left: 0;
				top: 12px;

				@include mq() {
					width: 8px;
					height: 17px;
					top: 3px;
				}
			}
		}

		&--line {
			color: #E0346E;
			padding-bottom: 26px;
			margin-bottom: 44px;

			@include mq {
				padding-bottom: 15px;
				margin-bottom: 19px;
			}

			small {
				@include font-size(20);

				@include mq {
					@include font-size(12);
				}
			}

			@include p-after(100%, 10px) {
				background: linear-gradient(105deg, #FFDBE7, #FFEB9A);
				border-radius: 5px;
				bottom: 0;
				left: 0;

				@include mq {
					height: 5px;
				}
			}
		}

		&--sp {
			@include mq {
				@include font-size(20);
			}
		}
	}

	&__04 {
		@include font-size(20);
		line-height: 1.4;
		font-weight: 700;
		color: #E0346E;

		@include mq {
			@include font-size(16);
		}

		&--lhb {
			line-height: 1.75;
			@include mq {
				line-height: 1.5625;
			}
		}
		a {
			color: #E0346E;
		}
		small {
			@include font-size(16);
		}
	}
	&__gradient{
		text-align: center;
		@include fts(19, 29);
		font-weight: 700;
	
		@include mq {
			@include fts(16, 29);
		}
		@include mq{
			@include font-size(15);
		}

		span {
			background: linear-gradient(121deg, #ff82ac, #fbc900);
			display: inline-block;
			border-radius: 35px;
			color: white;
			padding: 8px 20px;
			position: relative;

			@include mq {
				max-width: 313px;
				width: 100%;
			}

			@include p-after(13px, 10px) {
				// background: linear-gradient(121deg, #ff82ac, #fbc900);
				background: linear-gradient(153deg, #fda558, #fcac45);
				clip-path: polygon(0 0, 50% 100%, 100% 0);
				top: 99%;
				left: 50%;
				transform: translateX(-50%);
			}
		}
		&--blue{
			span{
				background: linear-gradient(139deg, #277ae2 0%, #fbc900 100%);
				&:after{
					background: linear-gradient(153deg, #a0a761, #adac53);
				}
			}
		}
		&--large{
			span{
				@include font-size(30);
				padding: 14px 44px;
				letter-spacing: 3px;
				@include mq{
					@include font-size(20);
					letter-spacing: 0;
					padding: 10px 20px;
					max-width: none;
					width: auto;
				}
				&:after{
					width: 22px;
					height: 18px;
					@include mq{
						width: 16px;
						height: 11px;
					}
				}
			}
		}
		&--green{
			span{
				background: linear-gradient(139deg, #277AE2 0%, #93D142 100%);
				&:after{
					background: linear-gradient(153deg, #62aa89, #66ad84);
				}
				@include mq(sm){
					background: linear-gradient(139deg, #277AE2 0%, #FBC900 100%);
					&:after{
						background: linear-gradient(153deg, #94A36D, #9CA665);
					}
				}
			}
		}
	}
}

//btn
.c-btn {
	&__01 {
		width: 100%;
		max-width: 251px;
		height: 50px;
		border-radius: 40px;
		background: white;
		position: relative;
		@include flex(center, center);
		color: #E0346E;
		line-height: 1;
		@include font-size(13);
		font-weight: bold;
		margin: 0 auto;
		border-bottom: 5px solid #DEDEDE;

		@include mq() {
			border-radius: 30px;
		}

		&:active {
			transform: translate(0px, 5px);
			-webkit-transform: translate(0px, 5px);
			border-bottom: 1px solid;
		}

		span {
			position: relative;
			padding: 0 14px 0 0;

			&:after {
				content: "";
				width: 0;
				height: 0;
				border-style: solid;
				border-width: 4px 0 4px 8px;
				border-color: transparent transparent transparent #E0346E;
				position: absolute;
				top: 4px;
				right: 0;
			}
		}

		&--pink {
			max-width: 383px;
			height: 76px;
			background: #E0346E;
			color: white;
			@include font-size(20);
			border-bottom: 7px solid #9C0F3F;

			@include mq() {
				@include font-size(16);
				max-width: 314px;
				height: 63px;
			}

			&:active {
				transform: translate(0px, 7px);
				-webkit-transform: translate(0px, 7px);
				border-bottom: 1px solid;
			}

			span {
				padding: 0 19px 0 0;

				&:after {
					border-width: 7px 0 7px 12px;
					border-color: transparent transparent transparent white;

					@include mq() {
						border-width: 5px 0 5px 10px;
					}
				}
			}
		}
		&--blue{
			max-width: 383px;
			height: 76px;
			background: #277AE2;
			color: white;
			@include font-size(20);
			border-bottom: 7px solid #0F478D;

			@include mq() {
				@include font-size(16);
				max-width: 314px;
				height: 63px;
			}

			&:active {
				transform: translate(0px, 7px);
				-webkit-transform: translate(0px, 7px);
				border-bottom: 1px solid;
			}

			span {
				padding: 0 19px 0 0;

				&:after {
					border-width: 7px 0 7px 12px;
					border-color: transparent transparent transparent white;

					@include mq() {
						border-width: 5px 0 5px 10px;
					}
				}
			}
		}
		&--pastel {
			background: #FFF2F6;
			border-bottom: 5px solid #FFC5D9;
		}
		&--yellow{
			max-width: 143px;
			margin: 0;
			background: #FF9100;
			border-color: #B16500;
			font-weight: bold;
			color: #fff;
			height: 39px;
			@include font-size(16);
			@include mq(){
				margin: 0 auto;
			}
			span{
				&::after{
					border-color: transparent transparent transparent #fff;
				}
			}
		}
		&--blue{
			background: #277AE2;
			border-bottom-color: #0F478D;
		}
	}

	&__02 {
		text-align: right;
		@include fts(16, 35);
		font-weight: 700;
		color: #E0346E;
		padding-right: 31px;
		position: relative;

		@include mq {
			@include font-size(13);
			padding-right: 23px;
		}

		&::after {
			content: "";
			position: absolute;
			right: 11px;
			top: 50%;
			-webkit-transform: translateY(-50%);
			-ms-transform: translateY(-50%);
			transform: translateY(-50%);
			width: 0;
			height: 0;
			border-top: 7px solid transparent;
			border-left: 11px solid #E0346E;
			border-bottom: 7px solid transparent;

			@include mq {
				top: calc(50% - 1px);
				@include font-size(14);
				right: 8px;
				border-width: 6px 0 6px 11px;
			}
		}
		&--normal {
			@include font-size(20);
			padding-right: 20px;
			@include mq {
				@include font-size(15);
			}
			a {
				color: #1D3E62;
			}
			&:after {
				@include font-size(15);
				border-top: 7px solid transparent;
				border-left: 12px solid #1D3E62;
				border-bottom: 7px solid transparent;
				right: 3px;
				top: calc(50% + 1px);
			}
		}
		&--blue{
			color: #277AE2;
			padding-left: 18px;
			padding-right: 0;
			@include mq{
				padding-left: 13px;
			}
			&::after{
				border-left-color: currentColor;
				right: auto;
				left: 1px;
				top: 50%;
				border-width: 7px 0 7px 10px;
				@include mq{
					border-width: 5px 0 5px 9px;
				}
			}
		}
	}
}


//fade up
.js-scrollin {
	opacity: 0;
	transform: translate(0, 70px);

	&.active {
		opacity: 1;
		transform: translate(0, 0);
		transition: 0.7s cubic-bezier(0.25, 0.46, 0.45, 0.94);
	}
}

