@use "vars" as *;

#page-event {
	@include mq(xs) {
		.mv-page .c-txt__basic {
			margin: 0 -10px;
		}
	}
}

.sec-benefits {
	padding: 193px 0 120px;
	@include mq(lp-min) {
		margin-top: 50px;
	}
	@include mq(sm) {
		padding: 26.1vw 0 92px;
	}

	.dental-department {
		&:after {
			bottom: -3.1vw;
		}

		.inner {
			max-width: 1240px;
			@include mq(md-min) {
				top: -123px;
			}
			@include mq {
				padding-bottom: 146px;
			}
		}

		&__deco-01 {
			top: -17.7vw;
			@include mq {
				top: -28.6vw;
				left: -6px;
			}
		}

		&__deco-02 {
			right: -63px;
			top: 28.3%;
			@include mq {
				right: -88px;
				top: 27.6%;
			}
		}

		&__deco-03 {
			bottom: -1.6vw;
			right: 43px;
			@include mq {
				bottom: -7.8vw;
				right: 20px;
				width: 96px;
			}
		}

		&--night {
			background: linear-gradient(124deg, #9fcaff 0%, #ffeb9a 100%);
			@include mq(sm) {
				background: linear-gradient(166deg, #9fcaff 0%, #ffeb9a 100%);
			}

			&:after {
				bottom: -10vw;
				@include mq {
					display: none;
				}
			}

			.inner {
				top: -43px;
				@include mq {
					top: -67px;
				}
			}

			.dental-department {
				&__deco-01 {
					top: -13.4vw;
					left: -38px;
					@include mq {
						top: -45vw;
						left: -35px;
					}
				}

				&__deco-02 {
					@include mq {
						top: 31.8%;
					}
				}

				&__deco-03 {
					bottom: 1.4vw;
					@include mq {
						bottom: 205px;
						width: 149px;
						left: 0;
					}
					@include mq(sm) {
						bottom: 192px;
					}
				}
			}
		}

		&--gray {
			background: #F5F5F5;
			margin-top: -17vw;
			padding: 22.8vw 0 124px;
			@include mq {
				margin-top: -112px;
				padding: 160px 0 52px;
				.inner {
					padding-bottom: 0;
				}
			}
			@include mq(sm) {
				padding-top: 134px;
			}

			&:before {
				display: none;
				@include mq {
					content: "";
					position: absolute;
					display: block;
					background: url("../img/common/wave-gray-sp.png") top center no-repeat;
					background-size: 100% 100%;
					width: 100%;
					height: 36px;
					top: -35px;
					@include h-align;
					pointer-events: none;
				}
			}

			&:after {
				content: "";
				position: absolute;
				background: url("../img/common/wave-white.png") top center no-repeat;
				background-size: 100% 100%;
				width: 100%;
				height: 78px;
				bottom: -1px;
				@include h-align;
				pointer-events: none;
				@include mq {
					display: block;
					background: url("../img/common/wave-white-sp.png") top center no-repeat;
					background-size: 100% 100%;
					height: 36px;
				}
			}
		}
	}

	.c-ttl__gradient--large span {
		min-width: 382px;
		@include mq {
			min-width: 190px;
			padding: 7px 20px;
		}
	}
}

.benefits-list {
	position: relative;
	z-index: 3;

	&__item {
		@include flex(between, 0, wrap);
		background: white;
		border-radius: 20px;
		padding: 44px 45px 36px 48px;
		margin-bottom: 50px;

		&:last-child {
			margin-bottom: 0;
		}

		@include mq {
			padding: 21px 20px 29px;
			margin-bottom: 28px;
			.c-txt__basic {
				line-height: 2.08;
			}
		}
	}

	&__ct {
		width: 50%;
		@include mq {
			width: 100%;
			margin-bottom: 13px;
		}

		&-ttl {
			color: #E0346E;
			font-weight: bold;
			@include font-size(30);
			margin-bottom: 12px;
			@include mq {
				@include font-size(21);
				line-height: 1.5;
				margin-bottom: 11px;
			}

			img {
				@include mq(md-min) {
					margin-left: 18px;
				}
			}
		}
	}

	&__timeline {
		font-weight: bold;
		margin-top: 28px;
		@include mq {
			line-height: 2.08;
			margin-top: 19px;
		}

		&-item {
			@include flex(between);
			margin-bottom: 9px;
			@include mq {
				margin-bottom: 11px;
			}
		}

		&-ttl {
			text-align: center;
			color: #E0346E;
			width: 110px;
			letter-spacing: 3px;
			@include mq {
				width: 95px;
				letter-spacing: 2px;
			}

			span {
				display: block;
				width: 100%;
				background: #FFF2F6;
				border-radius: 20px;
				padding: 0 5px 1px;
				margin: 3px 0 2px;
				letter-spacing: 0;
				@include mq {
					padding: 3px 5px 2px;
					margin: 0 0 3px;
				}
			}

			img {
				margin-left: 18px;
			}
		}

		&-txt {
			width: calc(100% - 124px);
			@include mq {
				width: calc(100% - 110px);
				line-height: 2.08;
			}
		}
	}

	&__img {
		width: 45.4%;
		@include mq {
			width: 100%;
		}

		&-thumb {
			position: relative;
			border-radius: 20px;
			overflow: hidden;
			margin-bottom: 32px;
			@include mq {
				margin: 18px 0 0;
			}

			&:before {
				content: "";
				display: block;
				padding-bottom: calc(335 / 502 * 100%);
			}

			img {
				position: absolute;
				width: 100%;
				height: 100%;
				top: 0;
				left: 0;
				object-fit: cover;
			}
		}

		.c-btn__01 {
			@include mq(md-min) {
				margin-right: 0;
			}

			span {
				padding: 0 34px 0 14px;
				@include mq {
					padding: 0 23px;
				}
			}
		}
	}

	&--blue {
		.benefits-list {
			&__ct-ttl {
				color: #277AE2;
			}

			&__timeline {
				&-ttl {
					color: #277AE2;

					span {
						background: #DFEEFF;
					}
				}
			}

			&__img {
				.c-btn__01 {
					background: #277AE2;
					border-bottom-color: #0E3E7A;
				}
			}
		}
	}

	&--gradient {
		.benefits-list {
			&__timeline {
				&-ttl {
					color: white;

					span {
						background: linear-gradient(90deg, #FF5890, #FBB800);
					}
				}
			}

			&__img {
				.c-btn__01 {
					height: 69px;
					border-bottom: 0;
					z-index: 1;
					@include mq {
						height: 56px;
					}

					&:before {
						content: "";
						position: absolute;
						background: linear-gradient(90deg, #FF5890, #FBB800);
						width: 100%;
						height: 100%;
						top: 0;
						left: 0;
						border-radius: 40px;
						z-index: -1;
						@include mq {
							border-radius: 30px;
						}
					}

					&:after {
						content: "";
						position: absolute;
						background: linear-gradient(90deg, #BB003F, #AF8100);
						width: 100%;
						height: 100%;
						bottom: -7px;
						left: 0;
						border-radius: 40px;
						transition: 0.3s ease;
						z-index: -2;
						@include mq {
							border-radius: 30px;
						}
					}

					&:active {
						transform: translate(0px, 7px);

						&:after {
							transform: translate(0px, -7px);
						}
					}
				}
			}
		}
	}
}

.benefits-btn {
	position: relative;
	display: block;
	width: 100%;
	max-width: 378px;
	height: 58px;
	background: #FFF2F6;
	color: #E0346E;
	border-radius: 20px;
	font-weight: bold;
	@include font-size(20);
	padding: 8px 5px 5px 59px;
	@include mq {
		max-width: 286px;
		height: 44px;
		@include font-size(14);
		padding: 6px 5px 5px 45px;
	}

	&:before {
		content: "";
		position: absolute;
		background: url("../img/event/icon-tel.svg") center no-repeat;
		background-size: contain;
		width: 29px;
		height: 38px;
		left: 23px;
		@include v-align;
		@include mq {
			width: 22px;
			height: 29px;
			left: 18px;
		}
	}

	span {
		@include font-size(30);
		@include mq {
			@include font-size(21);
		}
	}

	&--mail {
		letter-spacing: -1px;
		padding: 15px 5px 5px 69px;
		@include mq {
			letter-spacing: -0.7px;
			padding: 11px 5px 5px 53px;
		}

		&:before {
			background: url("../img/event/icon-mail.svg") center no-repeat;
			background-size: contain;
			width: 37px;
			height: 29px;
			@include mq {
				width: 28px;
				height: 22px;
			}
		}
	}
}