@use "vars" as *;

/* ====================================================
PAGE about_eiseishi
==================================================== */
#page-about_eiseishi {
	.mvhd-block__ttl {
		margin-top: -77px;
		margin-left: -29px;

		@include mq {
			margin-top: -42px;
			margin-left: -29px;
		}

		img {
			height: auto;
		}
	}
}

.sec-heading {
	text-align: center;
	margin-bottom: 121px;
	font-weight: 700;

	@include mq {
		padding-top: 180px;
		margin-bottom: 48px;

		.c-txt__basic {
			line-height: 2.083;
		}
	}

	&__ttl {
		margin-bottom: 22px;

		@include mq {
			margin-bottom: 13px;
		}
	}

	@include p-before(238px, 257px) {
		background: url(../img/about_eiseishi/bg-before.png) no-repeat center/contain;
		top: 41.7%;
		left: 11.7%;
		z-index: -1;

		@include mq {
			left: 26.2%;
			width: 149px;
			top: -2px;
			height: 159px;
		}
	}

	@include p-after(108px, 141px) {
		background: url(../img/about_eiseishi/bg-after.png) no-repeat center bottom/contain;
		top: -2%;
		right: 17.6%;
		z-index: -1;

		@include mq {
			height: 88px;
			width: 69px;
			right: 26.1%;
			top: 2px;
		}
	}
}

.sec-about {
	background: url(../img/about_eiseishi/bg-gray.png) no-repeat bottom center/100% 93%;
	padding-bottom: 15%;

	@include mq {
		background: url(../img/about_eiseishi/bg-graysp.png) no-repeat bottom center/100% 80%;
		padding-bottom: 30%;
	}
}

.about-photo {
	@include flex(center, 0, wrap);
	margin: 0 -14px 81px;
	grid-gap: 11px 0;

	@include mq {
		margin: 0 -6px 30px;
	}

	&__item {
		width: 33.33%;
		padding: 0 14px;

		@include mq {
			width: 50%;
			padding: 0 6px;

			&:nth-child(3) {
				width: 48.6%;
			}
		}

		img {
			width: 100%;
			height: 100%;
			object-fit: cover;
			border-radius: 20px;
		}
	}
}

.about-heading {
	text-align: center;
	margin-bottom: 122px;
	font-weight: 700;
	@include mq {
		line-height: 2.083;
		margin-bottom: 79px;
	}

	&__ttl {
		margin-bottom: 28px;

		@include mq {
			margin-bottom: 23px;
		}
	}
}

.about-question {
	background: #FFFFFF;
	border-radius: 20px;
	position: relative;
	padding: 0 35px 51px;
	margin: 0 auto;
	max-width: 1036px;
	font-weight: 700;

	@include mq(md-min) {
		text-align: center;

	}

	@include mq {
		padding: 0 19px 38px;
		line-height: 2.083;
	}

	@include p-before(98px, 88px) {
		background: url(../img/about_eiseishi/ico-05.png) no-repeat center/contain;
		right: 35px;
		top: -44px;

		@include mq {
			width: 64px;
			height: 57px;
			right: 20px;
			top: calc(100% - 28px);
		}
	}

	&__ttl {
		position: relative;
		transform: translateY(-58%);
		@include mq{
			transform: translateY(-66%);
		}
		
	}

	p {
		margin-top: -2px;
	}
}

.sec-info {
	background: url(../img/about_eiseishi/bg-02.png) no-repeat center/100% 100%;
	margin-top: -8.9%;
	padding: 280px 0 216px;

	@include mq {
		background: url(../img/about_eiseishi/bg-02sp.png) no-repeat center/100% 100%;
		margin-top: -9.9%;
		padding: 161px 0 25%;
	}

	@include p-before(136px, 182px) {
		background: url(../img/about_eiseishi/ico-04.png) no-repeat top center/contain;
		right: 3.5%;
		top: 0;

		@include mq {
			right: 1.5%;
			width: 90px;
			height: 112px;
		}
	}

	@include p-after(154px, 177px) {
		background: url(../img/about_eiseishi/ico-01.png) no-repeat top center/contain;
		left: 2.1%;
		top: 14.8%;

		@include mq {
			
			width: 92px;
			height: 120px;
		
			left: -25px;
			top: 11.2%;
		}
	}

	&__ttl {
		text-align: center;
		position: relative;
		margin-bottom: 109px;
		z-index: 10;
		@include mq{
			margin-bottom: 126px;
		}
		@include p-before(64px, 98px) {
			background: url(../img/about_eiseishi/ico-03.png) no-repeat center top/contain;
			top: -127px;
			left: 50%;
			transform: translateX(-50%);

			@include mq {
				top: -93px;
				width: 56px;
				height: 71px;
			}
		}
	}
}

.info-list {
	@include flex(0, 0, wrap);
	grid-gap: 55px 18px;
	font-weight: 700;

	&__item {
		width: calc(33.33% - 12px);
		background: white;
		border-radius: 20px;
		padding: 0 29px 58px 37px;
		position: relative;

		@include mq {
			width: 100%;
			padding: 0 20px 34px;
		}
	}

	&__number {
		position: relative;
		text-align: center;
		transform: translateY(-50%);
		display: block;
		margin-bottom: 3px;
		@include mq{
			margin-bottom: 1px;
		}
	}

	&__ttl {
		text-align: center;
		margin-bottom: 34px;
		@include mq{
			margin-top: -5px;
			margin-bottom: 20px;
		}
	}

	&__img {
		text-align: center;
		@include flex(center, center);
		margin-bottom: 25px;
		@include mq{
			margin-bottom: 16px;
		}
	}
}

.sec-look--about {
	padding-top: 112px;
	@include mq{
		background: url(../img/about_eiseishi/bg-03.png) no-repeat top center/ 100% 100%;
		margin-top: -9%;
		padding-top: 109px;
	}
	@include p-before(150px, 176px) {
		background: url(../img/about_eiseishi/ico-02.png) no-repeat top center/contain;
		right: 3.4%;
		top: -163px;
		margin-top: -35px;
		@include mq{
			right: 0.4%;
			top: -26px;
			width: 102px;
			height: 115px;
		}
	}
}
