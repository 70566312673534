@use "vars" as *;

.sec-setsubi{
	padding: 50px 0 0;
	@include mq(){
		padding: 12px 0 0;
	}
}

.setsubi-note{
	position: relative;
	font-weight: bold;
	margin-bottom: 40px;
	@include mq(){
		line-height: 2.08333333333;
		margin-bottom: 18px;
	}
	p{
		margin-bottom: 21px;
		&:last-child{
			margin-bottom: 0;
		}
	}
	.c-ttl__03{
		@include mq(){
			line-height: 1.5;
		}
	}
	&__list{
		@include flex(center,0,wrap);
		margin: 0 -8px;
		text-align: center;
		position: relative;

		@include mq(){
			margin: 0 -4px;
			&:after{
				content: "";
				width: 80px;
				height: 100px;
				background: url(../img/campus_life/setsubi/item-04.png) no-repeat;
				background-size: 100%;
				background-position: bottom;
				position: absolute;
				bottom: -7px;
				right: -17px;
			}
		}
		&-item{
			width: 33.333%;
			padding: 0 8px;
			position: relative;
			z-index: 2;
			@include mq(){
				padding: 0 4px;
				width: 50%;
				margin-bottom: 6px;
			}
		}
		img{
			width: 100%;
			border-radius: 20px;
			margin-bottom: 8px;
			@include mq(){
				margin-bottom: 0;
			}
		}
		span{
			display: block;
			@include font-size(20);
			font-weight: bold;
			@include mq(){
				@include font-size(12);
			}
		}
	}
	&--item{
		padding: 0 0 84px;
		margin-bottom: 22px;
		@include mq(){
			padding: 0 0 96px;
			margin-bottom: 0;
		}
		>*{
			position: relative;
			z-index: 2;
		}
		&:after{
			content: "";
			width: 130px;
			height: 152px;
			background: url(../img/campus_life/setsubi/item-05.png) no-repeat;
			background-size: 100%;
			background-position: bottom;
			position: absolute;
			bottom: 0;
			left: -131px;
			@include mq(){
				width: 82px;
				height: 103px;
				left: -36px;
			}
		}
	}
}
.setsubi-list{
	@include flex(0,0,wrap);
	margin: 0 -16px 37px;
	@include mq(){
		margin: 0 -4px 33px;
	}
	&__item{
		width: 50%;
		padding: 0 16px;
		position: relative;
		margin-bottom: 56px;
		&:nth-child(1){
			width: 100%;
			&:before{
				content: "";
				width: 385px;
				height: 443px;
				background: url(../img/campus_life/setsubi/item-03.png) no-repeat;
				background-size: 100%;
				position: absolute;
				top: 160px;
				left: -186px;
				@include mq(){
					width: 246px;
					height: 283px;
					top: 116px;
					left: -39px;
				}
			}
		}
		&:nth-child(5){
			&:after{
				content: "";
				width: 190px;
				height: 237px;
				background: url(../img/campus_life/setsubi/item-04.png) no-repeat;
				background-size: 100%;
				background-position: bottom;
				position: absolute;
				bottom: -119px;
				right: -125px;
				@include mq(){
					display: none;
				}
			}
		}
		@include mq(){
			padding: 0 4px;
			margin-bottom: 18px;
			&:nth-child(2),&:nth-child(3){
				width: 100%;
			}
		}
	}
	&__ttl{
		position: relative;
		z-index: 1;
		@include flex(center,center);
		text-align: center;
		@include font-size(20);
		font-weight: bold;
		color: white;
		margin-bottom: 8px;
		@include mq(){
			@include font-size(16);
		}
		@include mq(xs){
			@include font-size(14);
		}
		span{
			display: block;
			width: 100%;
			max-width: 255px;
			background: url(../img/campus_life/setsubi/item-ttl.png) no-repeat;
			background-size: 100%;
			padding: 9px 5px 20px;
			@include mq(){
				max-width: 180px;
				padding: 3px 5px 15px;
			}
			@include mq(xs){
				max-width: 140px;
			}
		}
	}
	&__img{
		position: relative;
		z-index: 1;
		overflow: hidden;
		border-radius: 20px;
		img{
			width: 100%;
		}
	}
}