@use "vars" as *;

/* ====================================================
Header
==================================================== */
#header {
	position: fixed;
	width: 100%;
	background: white;
	padding: 32px 0 24px;
	top: 0;
	left: 0;
	z-index: 999;
	@include mq(tb) {
		height: 60px;
		padding: 22px 4px 20px;
	}
	.header-logo {
		display: block;
		width: 100%;
		max-width: 412px;
		margin: 0 auto 23px;
		@include mq(tb) {
			max-width: 250px;
			margin: 0;
		}
		@include mq(xs) {
			max-width: 200px;
		}
	}

	.header-nav {
		@include mq(tb) {
			display: none;
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			height: 100vh;
			background: linear-gradient(rgba(255, 88, 144, 0.9), rgba(251, 184, 0, 0.9));
			padding: 141px 20px;
			overflow-y: scroll;
			&.active {
				display: block;
			}
		}

		&__menu {
			@include flex(center);
			@include mq(tb) {
				display: block;
			}

			&-item {
				margin: 0 22px;
				@include mq(tb) {
					margin: 0 0 43px;
					&:last-child {
						margin-bottom: 0;
					}
				}
				> a {
					display: block;
					color: black;
					font-weight: bold;
					@include font-size(20);
					word-break: keep-all;
					@include mq(tb) {
						text-align: center;
						color: white;
					}
				}
				@include mq(tb-min) {
					&--red {
						> a {
							color: #E0346E;
						}
					}
					&--blue {
						> a {
							color: #277AE2;
						}
					}
				}
			}
		}
	}

	.js-mobile {
		position: absolute;
		width: 40px;
		height: 40px;
		background: linear-gradient(#FF5890, #FBB800);
		border-radius: 50%;
		top: 11px;
		right: 20px;
		cursor: pointer;
		z-index: 12;
		transition: 0.3s ease;
		display: none;
		@include mq(tb) {
			display: block;
		}

		span {
			position: absolute;
			display: block;
			width: 22px;
			height: 2px;
			background: white;
			border-radius: 3px;
			@include v-h-align;

			&:before, &:after {
				content: "";
				position: absolute;
				width: 100%;
				height: 100%;
				background: white;
				border-radius: 3px;
				left: 0;
				transition: transform 0.3s;
			}

			&:before {
				top: -7px;
			}

			&:after {
				bottom: -7px;
			}
		}

		&--close {
			background: white;
			span {
				background-color: transparent;

				&:before, &:after {
					background: #E0346E;
				}

				&:before {
					transform: translateY(0) rotate(45deg);
					top: 0;
				}

				&:after {
					transform: translateY(0) rotate(-45deg);
					bottom: 0;
				}
			}
		}
	}
}


.js-locked {
	overflow: hidden;
	-ms-touch-action: none;
	touch-action: none;

	body {
		-webkit-overflow-scrolling: auto;
	}
}