@use "vars" as *;


.sec-reason{
	padding: 0 0 100px 0;
	@include mq(){
		padding: 0 0 82px 0;
	}
	.inner{
		max-width: 1076px;
	}
}

.reason-list{
	font-weight: bold;
	&__item{
		border-radius: 20px;
		position: relative;
		background: #FFF2F6;
		text-align: center;
		padding: 45px 70px;
		margin-bottom: 52px;
		@include mq(){
			padding: 30px 21px 22px;
			margin-bottom: 22px;
			text-align: left;
			line-height: 2.08333333333;
			&:nth-child(4){
				.reason-list__ttl{
					text-align: left;
					.reason-list__ttl--small{
						@include font-size(24);
						@include mq(xs){
							@include font-size(22);
						}
					}
				}
			}
			&:nth-child(5){
				.reason-list__ttl{
					.reason-list__ttl--big{
						@include font-size(30);
					}
				}
			}
			&:nth-child(6){
				.reason-list__ttl{
					@include font-size(26);
					.reason-list__ttl--big{
						@include font-size(33);
					}
					>span{
						background-size: 200% 27px;
						background-repeat: no-repeat;
						background-position: 0 20px;
					}
				}
			}
		}
		@include mq(xs){
			padding-left: 15px;
			padding-right: 15px;
		}
		&:nth-child(even){
			background: #FFFCDB;
		}
		>*{
			position: relative;
			z-index: 2;
		}
	}
	&__ttl{
		display: block;
		line-height: 1.3;
		font-weight: bold;
		@include font-size(40);
		color: #E0346E;
		margin-bottom: 27px;
		@include mq(){
			@include font-size(32);
			text-align: center;
			margin-bottom: 19px;
		}
		@include mq(xs){
			@include font-size(28);
		}
		small{
			display: block;
			@include font-size(20);
			font-weight: bold;
			margin-bottom: 5px;
			@include mq(){
				@include font-size(16);
				margin-bottom: -2px;
			}
		}
		&--small{
			@include font-size(30);
			@include mq(){
				@include font-size(29);
			}
			@include mq(xs){
				@include font-size(26);
			}
		}
		&--big{
			@include font-size(50);
			@include mq(){
				@include font-size(48);

			}
			@include mq(xs){
				@include font-size(42);
			}
			&-other{
				@include mq(){
					@include font-size(35);
				}
				@include mq(xs){
					@include font-size(30);
				}
			}
		}
		>span{
			background-image: -o-linear-gradient(left, #FFF8A1 50%, transparent 50%);
			background-image: -webkit-gradient(linear, left top, right top, color-stop(50%, #FFF8A1), color-stop(50%, transparent));
			background-image: linear-gradient(to right, #FFF8A1 50%, transparent 50%);
			background-size: 200% 23px;
			background-repeat: no-repeat;
			background-position: 0 33px;
			@supports (-webkit-touch-callout: none) {
				background-position: 0 17px;
			}
			@include mq(){
				background-size: 200% 26px;
				background-repeat: no-repeat;
				background-position: 0 26px;
				@supports (-webkit-touch-callout: none) {
					background-position: 0 12px;
				} 
			}
		}
	}
	&__img{
		position: absolute;
		z-index: 1;
		&--01{
			top: -19px;
			right: -83px;
			width: 263px;
			@include mq(){
				top: -59px;
				right: -32px;
				width: 120px;
			}
		}
		&--02{

		}
		&--03{
			left: -19px;
			top: -43px;
			width: 158px;
			@include mq(){
				top: -57px;
				width: 86px;
				left: -8px;
			}
			@include mq(xs){
				top: -44px;
				width: 65px;
			}
		}
		&--04{
			top: -87px;
			right: -82px;
			width: 255px;
			@include mq(){
				top: -27px;
				right: -61px;
				width: 140px;
			}
		}
		&--05{

		}
		&--06{
			left: -81px;
			bottom: -78px;
			width: 137px;
			@include mq(){
				left: -21px;
				bottom: -102px;
				width: 70px;
			}
			@include mq(xs){
				width: 55px;
			}
		}
	}
	&__sub{
		@include flex(center,center,wrap);
		.c-btn__01{
			margin: 0 10px;
			@include mq(){
				margin-bottom: 10px;
				&:last-child{
					margin-bottom: 0;
				}
			}
		}
	}
	p{
		margin-bottom: 34px;
		@include mq(){
			margin-bottom: 20px;
		}
	}
}