@use "vars" as *;


@include mq(tb-min){
	#page-nyushi{
		.mvhd-block__ttl img{
			height: 158px;
			margin-left: -9px;
			margin-top: -10px;
			margin-bottom: -4px;
		}
	}
	
}

.sec-education--02{
	@include mq(){
		padding-top: 180px;
		margin-bottom: 16px;
	}
	.mv-page__info{
		&::before{
			content: "";
			bottom: -27%;
			left: 15%;
			width: 125px;
			height: 150px;
			background: url('../img/nyushi/icon-before.png') center no-repeat;
			background-size: contain;
			position: absolute;
			@include mq(xl){
				left: 0;
			}
			@include mq(){
				background: url('../img/nyushi/icon-before-sp.png') center no-repeat;
				background-size: contain;
				width: 218px;
				height: 161px;
				left: 50%;
				transform: translateX(-50%);
				top: -181px;
			}
		}
		&::after{
			content: '';
			top: -11px;
			width: 216px;
			height: 162px;
			background: url('../img/nyushi/icon-after.png') center no-repeat;
			background-size: contain;
			position: absolute;
			bottom: auto;
			right: 11%;
			@include mq(xl){
				right: 0;
				width: 180px;
				height: 150px;
			}
			@include mq(){
				display: none;
			}
		}
	}
	.c-txt__basic{
		&::before,&::after{
			display: none;
		}
		@include mq(xl){
			padding: 0;
		}
		@include mq(){
			line-height: 2.08;
		}
	}
}
.sec-education--03{
	@include mq(md-min){
		margin-bottom: 141px;
	}
	@include mq(){
		padding-top: 180px;
		margin-bottom: 16px;
	}
	.mv-page__info{
		&::before{
			content: "";
			bottom: 3px;
			left: 9%;
			width: 150px;
			height: 244px;
			background: url('../img/campus_life/icon-before.png') center no-repeat;
			background-size: contain;
			position: absolute;
			@include mq(tb){
				left: -20px;
				width: 110px;
				height: 180px;
			}
			@include mq(){
				background: url('../img/campus_life/icon-before-sp.png') center no-repeat;
				background-size: contain;
				width: 182px;
				height: 140px;
				left: 50%;
				transform: translateX(-50%);
				top: -173px;
			}
		}
		&::after{
			content: '';
			top: -5px;
			width: 177px;
			height: 176px;
			background: url('../img/campus_life/icon-after.png') center no-repeat;
			background-size: contain;
			position: absolute;
			bottom: auto;
			right: 12.2%;
			@include mq(tb){
				right: 0;
				width: 140px;
				height: 130px;
			}
			@include mq(){
				display: none;
			}
		}
	}
	.c-txt__basic{
		&::before,&::after{
			display: none;
		}
		@include mq(xl){
			padding: 0;
		}
		@include mq(){
			line-height: 2.08;
		}
	}
}

.sec-nyushi{
	padding: 26px 0 50px;
	@include mq(){
		padding: 26px 0 63px;
	}
	.inner{
		position: relative;
		z-index: 2;
		&::before,&::after{
			content: '';
			width: 232px;
			height: 257px;
			background: url('../img/nyushi/icon-top.png') center no-repeat;
			background-size: contain;
			position: absolute;
			z-index: -1;
			top: 7.8%;
			left: -16.5%;
			@include mq(sm){
				width: 125px;
				height: 138px;
				top: 11.1%;
				left: -27px;
			}
		}
		&::after{
			bottom: -2.7%;
			top: auto;
			background: url('../img/nyushi/icon-bottom.png') center no-repeat;
			background-size: contain;
			width: 246px;
			height: 209px;
			left: -15%;
			@include mq(sm){
				width: 119px;
				height: 140px;
				top: 47.2%;
				right: -8%;
			}
		}
	}
}

.nyushi-list{
	margin: 0 -18px;
	@include flex(0,0,wrap);
	position: relative;
	z-index: 2;
	@include mq(sm){
		margin: 0;
	}
	&::after{
		content: '';
		width: 122px;
		height: 122px;
		background: url('../img/nyushi/icon-center.png') center no-repeat;
		background-size: contain;
		position: absolute;
		z-index: -1;
		top: 62%;
		right: -9.8%;
		transform: translateY(-50%);
		@include mq(sm){
			width: 65px;
			height: 65px;
			top: 46.6%;
			right: -9%;
		}
	}
	&__item{
		padding: 0 18px;
		width: 50%;
		margin-bottom: 68px;
		@include mq(sm){
			width: 100%;
			padding: 0;
			margin-bottom: 31px;
		}
		a{
			background: #fff;
			display: block;
			border-radius: 20px;
			overflow: hidden;
			box-shadow: 0 3px 6px rgba(#000,0.16);
		}
	}
	&__img{
		position: relative;
		overflow: hidden;
		&:before {
			content: "";
			display: block;
			padding-bottom: calc(267/500*100%);
			@include mq(){
				padding-bottom: 41.1%;
			}
		}
		&-thumb{
			position: absolute;
			width: 100%;
			height: 100%;
			top: 0;
			left: 0;
			img {
				width: 100%;
				height: 100%;
				-o-object-fit: cover;
				object-fit: cover;
			}
		}
	}
	&__ct{
		padding: 20px 20px;
		@include mq(){
			padding: 14px 20px 21px;
		}
		p{
			color: #1D3E62;
			min-height: 62px;
			margin-bottom: 10px;
			font-weight: bold;
			@include mq(){
				min-height: fit-content;
				margin-bottom: 7px;
			}
		}
	}
	&__ttl{
		color: #E0346E;
		font-weight: bold;
		line-height: 1.3;
		margin-bottom: 6px;
		@include font-size(30);
		@include mq(){
			@include font-size(20);
		}
	}
}