@charset "utf-8";
@use "vars";//setting variable style and mixin
@use "reset";//Basically uneditable document
@use "base";//html,body (and common element) setting
@use "module";//whole content layout, Common element and block style(layout component)
@use "component";//Common button, title, text setting(small component)
@use "utility";//PC, SP switching, Common Animation, hover action, change appearance by JS except for Header and Footer



/* ====================================================
Module
==================================================== */
@use "header";//header style Only
@use "footer";//footer style Only



/* ====================================================
Pages
==================================================== */
@use "index";
@use "campus_life_int01";
@use "campus_file_setsubi";
@use "gakko_annai";
@use "about_eiseishi_field";
@use "reason";
@use "column";
@use "about_eiseishi";
@use "gakko_annai_kokka975";
@use "nyushi_index";
@use "nyushi_boshu";
@use "nyushi_nyushi";
@use "yakan";
@use "nyushi_danshi";
@use "nyushi_shogakukin";
@use "nyushi_shogakukin2";
@use "news";
@use "gakko_annai_shushoku";
@use "campus_life";
@use "question";
@use "event";
@use "campus_life_interview";
@use "hiruma";
@use "himawari";
@use "taishousha_zaikou";
@use "sitemap";

