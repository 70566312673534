@use "vars" as *;
#page-nyushi_nyushi{
	.sec-support-system{
		padding-top: 32px;
		padding-bottom: 32px;
		@include mq{
			padding-bottom: 50px;
			padding-top: 0;
		}
	}
	.mv-page{
		overflow: visible;
	}
}
.mv-page{
	&__info{
		&--nyushi{
			padding-bottom: 0;
			@include mq{
				margin-top: 60px;
				padding-bottom: 33px;
			}
			&::before{
				bottom: auto;
				top: -50px;
				z-index: 10;
				@include mq(tb){
					left: 0;
					transform: translateX(-70%);
				}
				@include mq{
					bottom: auto;
					top: -105px;
					z-index: 10;
					left: 35px;
				}
			}

			&:after{
				width: 122px;
				height: 122px;
				background: url(../img/common/icon-pen.png) no-repeat center/cover;
				right: -40px;
				bottom: -28px;
				z-index: 10;
				@include mq{
					width: 62px;
					height: 62px;
					right: -18px;
				}
			}
			.c-ttl__02{
				@include mq(sm){
					margin: 0 -22px;
					position: relative;
					left: 4px;
					margin-bottom: 8px;
				}
			}
		}
	}
}