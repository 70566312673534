@use "vars" as *;

.sec-campus{
	padding: 80px 0 250px;
	background: url('../img/campus_life/bg-01.png') bottom center no-repeat;
	background-size: 101% 96.8%;
	@include mq(){
		background: url('../img/campus_life/bg-01-sp.png') bottom center no-repeat;
		background-size: 101% 100%;
		background-position: top 54.7vw center;
		padding: 31px 0 167px;
	}
	// @include mq(sm){
	// 	background-position: top 23.7vh center;
	// }
	&::before{
		content: '';
		width: 190px;
		height: 213px;
		background: url('../img/campus_life/icon-01.png') center no-repeat;
		background-size: contain;
		position: absolute;
		bottom: -16px;
		left: 63px;
		@include mq(){
			bottom: 47px;
			left: 8px;
			width: 92px;
			height: 98px;
			z-index: 3;
		}
	}
}

.campus-img{
	@include flex();
	margin: -100px -14px 91px;
	@include mq(){
		justify-content: center;
		margin: 0 -6px 53px;
		flex-wrap: wrap;
	}
	&__item{
		width: 33.33%;
		padding: 0 14px;
		overflow: hidden;
		@include mq(){
			padding: 0 6px;
			width: 50%;
			margin-bottom: 8px;
		}
	}
	&__img{
		position: relative;
		overflow: hidden;
		border-radius: 20px;
		&:before {
			content: "";
			display: block;
			padding-bottom: calc(282/381*100%);
		}
		&-thumb{
			position: absolute;
			width: 100%;
			height: 100%;
			top: 0;
			left: 0;
			img {
				width: 100%;
				height: 100%;
				-o-object-fit: cover;
				object-fit: cover;
			}
		}
	}
}

.campus-list{
	&__item{
		margin-bottom: 70px;
		@include mq(){
			margin-bottom: 37px;
		}
		&:last-child{
			margin-bottom: 0;
		}
		&:nth-child(2n){
			.campus-list__heading{
				text-align: left;
				@include mq(){
					padding-left: 20px;
					padding-right: 0;
				}
			}
		}
	}
	&__heading{
		text-align: right;
		margin-bottom: -7px;
		img{
			max-width: 100%;
			display: inline-block;
			height: 100%;
			max-height: 50px;
			@include mq(){
				max-height: 30px;
				padding-right: 19px;
				margin-bottom: 2px;
			}
		}
	}
	&__flex{
		background: #fff;
		border-radius: 20px;
		padding: 47px 46px 55px;
		@include flex(0,center);
		@include mq(xl){
			padding: 40px 25px;
			flex-wrap: wrap-reverse;
		}
		@include mq(){
			padding: 18px 20px 21px;
		}
	}
	&__img{
		width: 45.75%;
		border-radius: 20px;
		overflow: hidden;
		@include mq(){
			width: 100%;
			margin-bottom: 15px;
		}
		img{
			width: 100%;
		}
	}
	&__ct{
	    width: 54.25%;
		padding-left: 47px;
		padding-bottom: 4px;
		@include mq(xl){
			padding-left: 25px;
		}
		@include mq(){
			padding-left: 0;
			width: 100%;
		}
		p{
			margin-bottom: 15px;
			letter-spacing: 0.1px;
			font-weight: bold;
			@include mq(){
				margin-bottom: 16px;
				letter-spacing: 0;
				line-height: 2.05;
			}
		}
	}
	&__ttl{
		font-weight: bold;
		font-feature-settings: "palt" 1;
		color: #E0346E;
	    line-height: 1.667;
		letter-spacing: 0;
		margin-bottom: 4px;
		@include font-size(30);
		@include mq(xl){
			@include font-size(24);
		}
		@include mq(){
			margin-bottom: 19px;
			line-height: 1.5;
			letter-spacing: 1.25px;
			@include font-size(20);
		}
		span{
		    letter-spacing: 2px;
			display: inline-block;
			margin-bottom: 11px;
			border-radius: 32px;
			background: #E0346E;
			padding: 8px 15px;
			text-align: center;
			min-width: 128px;
			line-height: 1.2;
			color: #fff;
			font-weight: bold;
			@include font-size(16);
			@include mq(){
				padding: 6px 13px;
				min-width: 92px;
				margin-bottom: 20px;
				@include font-size(12);
			}
		}
	}
}

.sec-look--02{
	position: relative;
	padding: 151px 0 182px;
	@include mq(){
		padding: 113px 0 87px;
		background: url('../img/campus_life/bg-02.png') top center no-repeat;
		background-size: 100%;
		margin-top: -76px;
		.inner{
			z-index: 2;
			position: relative;
		}
	}
	&::before,&::after{
		content: '';
		width: 386px;
		height: 444px;
		background: url('../img/campus_life/icon-03.png') center no-repeat;
		background-size: contain;
		position: absolute;
		bottom: -9%;
		left: -2.5%;
		z-index: -1;
		@include mq(){
			width: 246px;
			height: 283px;
			bottom: 0.2%;
			left: -6.5%;
			z-index: 1;
		}
	}
	&::after{
		bottom: auto;
		top: 3.8%;
		right: 6%;
		left: auto;
		width: 197px;
		height: 131px;
		background: url('../img/campus_life/icon-02.png') center no-repeat;
		background-size: contain;
		@include mq(){
			width: 91px;
			height: 61px;
			top: 4.3%;
			right: 5%;
			z-index: 2;
		}
	}
}