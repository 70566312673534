@use "vars" as *;

.sec-detail {
	padding-bottom: 61px;
	@include mq() {
		padding-bottom: 30px;
	}
}

.detail-block {
	&__wrap {
		background: #FFF2F6;
		border-radius: 20px;
		padding: 13px 25px;
		margin-bottom: 13px;
		@include mq() {
			padding: 18px 19px 25px;
			margin-bottom: 16px;
		}
	}

	&__date {
		font-weight: 700;
		@include mq() {
			display: block;
			margin-bottom: 9px;
		}
	}

	&__ttl {
		line-height: 1.35;
	}

	&__area {
		font-weight: 700;

		h2 {
			@include fts(30, 50);
			font-weight: 700;
			color: #FF9100;
			position: relative;
			padding-left: 34px;
			margin-bottom: 15px;
			margin-top: 72px;
			@include mq() {
				@include fts(16, 20);
				padding-left: 18px;
				margin-bottom: 17px;
				margin-top: 56px;
			}
			@include p-before(15px, 32px) {
				background: #FF9100;
				left: 0;
				top: 12px;
				@include mq() {
					width: 8px;
					height: 95%;
					top: 3px;
				}
			}
		}

		h3 {
			@include fts(20, 50);
			font-weight: 700;
			color: #E0346E;
			border-bottom: 2px #FF9100 solid;
			padding-bottom: 3px;
			margin-bottom: 13px;
			margin-top: 67px;
			@include mq() {
				@include fts(16, 20);
				padding-bottom: 9px;
				margin-bottom: 15px;
				margin-top: 58px;
			}
		}

		p {
			font-family: $font-Yugothic;
			margin-bottom: 34px;
			@include mq() {
				margin-bottom: 20px;
			}
		}
	}
}


.sec-column {
	position: relative;
	padding-bottom: 33px;
	z-index: 1;
	@include mq {
		padding: 25px 0 14px;
	}
	@include mq(sm) {
		.newspage-list__item {
			p {
				-webkit-line-clamp: 4;
				max-height: 80px;
			}
		}
	}

	&:before {
		content: "";
		position: absolute;
		background: url("../img/column/bg-column.png") top center no-repeat;
		background-size: 100%;
		width: 100vw;
		height: 102%;
		top: -37px;
		@include h-align;
		z-index: -1;
		pointer-events: none;
		@include mq(sm) {
			background: url("../img/column/bg-column-sp.png") top center no-repeat;
			background-size: 100%;
			top: -17px;
		}
	}
}