@use "vars" as *;

/* ====================================================
Utility
==================================================== */
.pc {
	@include mq() {
		display: none !important;
	}

	&-tb {
		@include mq(tb) {
			display: none !important;
		}
	}

	&-lg {
		@include mq(lg) {
			display: none !important;
		}
	}

	&-sm {
		@include mq(sm) {
			display: none !important;
		}
	}

	&-xs {
		@media screen and (max-width: 392px) {
			display: none !important;
		}
	}
}

.sp {
	@include mq(md-min) {
		display: none !important;
	}

	&-tb {
		@include mq(tb-min) {
			display: none !important;
		}
	}

	&-lg {
		@include mq(lg-min) {
			display: none !important;
		}
	}

	&-sm {
		@include mq(sm-min) {
			display: none !important;
		}
	}

	&-xs {
		@include mq(xs-min) {
			display: none !important;
		}
	}
}

// Hover style
.u-fn-hover {
	display: inline-block;
	@include fn-hover
}

// Position style
.u-pull {
	&--left {
		margin-left: calc(((100vw - 100%) / 2) * -1)
	}

	&--right {
		margin-right: calc(((100vw - 100%) / 2) * -1)
	}
}

// Image Objec Fit
.u-img-of {
	width: 100%;
	height: 100%;
	@include object-fit(cover, center)
}

// Scroll Box
.c-scroll-box {
	padding-bottom: 20px;
	overflow-x: scroll;
	overflow-y: hidden;

	&-y {
		padding-bottom: 20px;
		overflow-x: hidden;
		overflow-y: scroll;
	}

	&-sp {
		@include mq() {
			padding-bottom: 20px;
			overflow-x: scroll;
			overflow-y: hidden;
		}
	}
}
