@use "vars" as *;

#page-yakan{
	.mvhd-block__ttl{
		margin-top: -53px;
		margin-left: 17px;
		@include mq{
			margin-left: 0px;
			margin-top: -25px;
		}
		img{
			height: 103px;
			@include mq{
				height: 49px;
			}
		}
	}
	.sec-heading{
		margin-bottom: 62px;
		@include mq{
			padding-top: 175px;
			margin-bottom: 35px;
		}
		&::before{
			width: 213px;
			height: 244px;
			background: url('../img/yakan/icon-before.png') no-repeat center/cover;
			top: 17%;
			left: 11.5%;
			@include mq(xl){
				width: 140px;
				height: 170px;
				top: 40%;
				left: 0%;
			}
			@include mq{
				width: 98px;
				height: 134px;
				top: 5.8%;
				left: 27%;
			}
		}
		&::after{
			width: 137px;
			height: 135px;
			background: url('../img/yakan/icon-after.png') no-repeat center/contain;
			top: -12.5%;
			right: 19.5%;
			@include mq(xl){
				width: 110px;
				height: 100px;
				top: -12.5%;
				right: 4%;
			}
			@include mq{
				width: 79px;
				height: 83px;
				right: 24.1%;
				top: 43px;
			}
		}
	}
	.sec-introduction{
		@include mq(md-min){
			&::before{
				top:13%;
			}
			&::after{
				bottom: -10%;
			}
		}
		@include mq(){
			padding-bottom: 116px;
			margin-bottom: -40px;
		}
	}
	.bg-introduction{
		@include mq(){
			background: url('../img/yakan/bg-02-sp.png') center no-repeat;
			background-size: 100% 100%;
			&::after{
				bottom: -10px;
			}
		}
	}
}

.bg-introduction{
	@include mq(){
		background: url('../img/yakan/bg-01-sp.png') center no-repeat;
		background-size: 100% 111%;
		position: relative;
		&::after{
			content: '';
			width: 51px;
			height: 107px;
			bottom: 0;
			right: 5%;
			background: url('../img/yakan/icon-04.png') center no-repeat;
			background-size: contain;
			position: absolute;
			z-index: 2;
		}
	}
}

.sec-introduction{
	padding: 2px 0 172px;
	margin-bottom: -80px;
	background: url('../img/yakan/bg-01.png') center no-repeat;
	background-size: 100% 100%;
	z-index: 2;
	position: relative;
	@include mq(){
		// padding-bottom: 30px;
		// padding-bottom: 76px;
		// background: none;
		// margin-bottom: 0;
		padding-bottom: 116px;
		background: none;
		margin-bottom: -40px;
	}
	&::before{
		content: '';
		width: 144px;
		height: 134px;
		background: url('../img/yakan/icon-03.png') center no-repeat;
		background-size: contain;
		position: absolute;
		top: 7%;
		left: 5.2%;
		@include mq(){
			width: 84px;
			height: 75px;
			top: 0.5%;
			left: 5%;
		}
	}
	&::after{
		content: '';
		width: 110px;
		height: 270px;
		background: url('../img/yakan/icon-04.png') center no-repeat;
		background-size: contain;
		position: absolute;
		bottom: -4%;
		right: 6%;
		@include mq(xl){
			width: 90px;
			height: 230px;
			bottom: -7%;
		}
		@include mq(){
			display: none;
		}
	}
	.curriculum-page__intro{
		&::before,&::after{
			display: none;
		}
	}
	.curriculum-page__ttl{
		top: 0;
		margin-bottom: 63px;
		@include mq{
			top: -13px;
			margin-bottom: 15px;
		}
	}
}

.introduction-list{
	margin: 0 auto;
	max-width: 92%;
	@include flex();
	@include mq(xl){
		max-width: initial;
		padding: 0;
		margin: 0 -20px;
	}
	@include mq(){
		margin: 0;
		flex-wrap: wrap;
	}
	&--night{
		max-width: initial;
		margin: 0 -40px;
		@include mq(xl){
			margin: 0 -20px;
		}
		@include mq(){
			margin: 0;
			flex-wrap: wrap;
		}
	}
	.c-ttl__gradient--large{
		span{
			padding: 12px 44px;
			@include mq(){
				padding: 6px 20px;
			}
			min-width: 383px;
			@include mq(){
				max-width: 320px;
				width: 100%;
				min-width:max-content;
			}
		}
	}
	&__item{
		width: 50%;
		padding: 0 40px;
		font-weight: bold;
		@include mq(xl){
			padding: 0 20px;
		}
		@include mq(){
			width: 100%;
			padding: 0;
			margin-bottom: 42px;
			&:last-child{
				margin-bottom: 0;
			}
			p{
				line-height: 2.08;
			}
		}
		.c-ttl__gradient{
			margin-bottom: 27px;
			@include mq(){
				margin-bottom: 21px;
			}
		}
	}
	&__img{
		@include flex(center,center);
		margin-bottom: 17px;
		@include mq(){
			margin-bottom: 19px;
		}
		img{
			max-width: 100%;
		}
	}
	&__ttl{
		font-feature-settings: "palt";
		color: #1D3E62;
		font-weight: bold;
		line-height: 1.5;
		text-align: center;
		margin-bottom: 10px;
		letter-spacing: 2px;
		@include font-size(30);
		@include mq(xl){
			letter-spacing: 0;
			@include font-size(24);
		}
		@include mq(){
			margin-bottom: 14px;
			@include font-size(20);
		}
	}
}

.sec-session{
	padding: 165px 0 166px;
	background: #F9F9F9;
	margin: 0 0 -82px;
	@include mq(){
		padding: 47px 0 99px;
		background: none;
		margin: 0 0 -30px;	
	}
}

.session-flex{
	max-width: 839px;
	width: 100%;
	margin: 0 auto;
	@include flex(0,center);
	@include mq(){
		flex-wrap: wrap;
	}
	&__ct{
		width: 47.5%;
		padding-right: 10px;
		font-weight: bold;
		padding-bottom: 18px;
		@include mq(xl){
			padding-right: 10px;
			width: 50%;
		}
		@include mq(){
			width: 100%;
			padding-right: 0;
			text-align: center;
			padding-bottom: 15px;
			p{
				line-height: 2.08;
			}
		}
	}
	&__ttl{
		color: #277AE2;
		margin-bottom: 9px;
		font-weight: bold;
		@include font-size(30);
		@include mq(){
			margin-bottom: 12px;
			@include font-size(20);
		}
		span{
			display: inline-block;
			padding: 4px 6px;
			background: #FFF471;
			@include mq(){
				padding: 2px 5px;
			}
		}
	}
	&__img{
		border-radius: 20px;
		overflow: hidden;
		width: 52.5%;
		transform: translateX(10px);
		@include mq(xl){
			width: 50%;
			transform: translateX(0);
		}
		@include mq(){
			width: calc(100% + 14px);
			margin: 0 -7px;
		}
		img{
			width: 100%;
		}
	}
}

.sec-tuition{
	padding: 163px 0 176px;
	background: url('../img/yakan/bg-02.png') center no-repeat;
	background-size: 100% 100%;
	@include mq(){
		padding: 100px 0 80px;
		background: url('../img/yakan/bg-03-sp.png') center no-repeat;
		background-size: 100% 100%;
	}
}

.tuition-box{
	background: #fff;
	border-radius: 20px;
	padding: 30px 58px 55px;
	text-align: center;
	max-width: 1036px;
	margin: 0 auto 71px;
	@include mq(tb){
		padding: 30px 30px 55px;
	}
	@include mq(){
		padding: 20px 12px 35px;
		// margin: 0 auto 54px;
	}
	&:last-child{
		margin-bottom: 0;
		padding-bottom: 73px;
		@include mq(){
			padding-bottom: 39px;
		}
		.c-txt__basic{
			@include mq{
				padding: 0 6px;
				letter-spacing: 1.5px;
			}
		}
	}
	&__ttl{
		margin-bottom: 24px;
		text-align: center;
		margin-top: -53px;
		position: relative;
		z-index: 2;
		@include mq(){
			margin-top: -33px;
			margin-bottom: 14px;
		}
		img{
			max-width: 100%;
			max-height: 43.5px;
			height: 100%;
			@include mq(){
				height: auto;
				max-height: max-content;
			}
		}
		&--02{
			@include mq(){
				margin-top: -32px;
				margin-bottom: 34px;
			}
		}
	}
	&__desc{
		margin-bottom: 14px;
		font-weight: bold;
		line-height: 2;
		color: #1D3E62;
		@include font-size(20);
		@include mq(){
			margin-bottom: 17px;
			// letter-spacing: 1.25px;
			line-height: 1.5625;
			text-align: center;
			@include font-size(16);
			margin-left: -10px;
			margin-right: -10px;
		}
		@include mq(xs){
			@include font-size(14.5);
		}
		span{
			background-image: -webkit-gradient(linear, left top, right top, color-stop(50%, #FFF8A1), color-stop(50%, transparent));
			background-image: -o-linear-gradient(left, #FFF8A1 50%, transparent 50%);
			background-image: linear-gradient(to right, #FFF8A1 50%, transparent 50%);
			background-size: 200% 20px;
			background-repeat: no-repeat;
			background-position: 0% 15px;
			padding-bottom: 10px;
			@include mq{
				background-position: 0% 6px;
				padding-bottom: 0px;
			}
		}
	}
	&__tags{
		margin-bottom: 45px;
		@include mq(){
			margin-bottom: 40px;
		}
		&-item{
			color: #1D3E62;
			font-weight: bold;
			margin-bottom: 7px;
			@include font-size(20);
			@include mq(){
				margin-bottom: 0;
				@include font-size(16);
			}
			&:last-child{
				margin-bottom: 0;
			}
			span{
				@include font-size(13);
				@include mq(){
					@include font-size(12);
				}
			}
		}
	}
	.c-txt__basic{
		margin-bottom: 32px;
		font-weight: bold;
		@include mq(){
			margin-bottom: 25px;
			line-height: 2.13;
			font-feature-settings: "palt";
		}
	}
}

.bg-wave-blue{
	background: #DFEEFF;
	position: relative;
	@include p-before(100%, 6.369vw){
		background: url('../img/yakan/wave-before.png') no-repeat top center/cover;
		left: 0;
		@include mq{
			height: 41px;
			background: url('../img/yakan/wave-before-sp.png') no-repeat top center/cover;
		}
	}
}