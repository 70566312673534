@use "vars" as *;

.sec-interview {
	position: relative;
	margin-top: -82px;
	padding-bottom: 67px;
	@include mq {
		margin-top: -39px;
		padding-bottom: 62px;
	}
	z-index: 1;

	&:before {
		content: "";
		position: absolute;
		background: url("../img/campus_life/interview/bg-interview-pc.png") center top no-repeat;
		background-size: 100%;
		width: 100%;
		height: calc(100% + 498px);
		top: -498px;
		left: 0;
		pointer-events: none;
		@include mq(sm) {
			background: url("../img/campus_life/interview/bg-interview-sp.png") center top no-repeat;
			background-size: 100%;
			height: calc(100% + 369px);
			top: -369px;
		}
	}
}

.interview-list {
	&__item {
		position: relative;
		@include flex(between, start, wrap);
		padding: 78px 0 75px;
		@include mq {
			flex-direction: column-reverse;
			padding: 23px 0 28px;
		}

		&:before {
			content: "";
			position: absolute;
			background: linear-gradient(90deg, #FFDBE7, #FFEB9A);
			width: 100%;
			height: 10px;
			border-radius: 5px;
			top: 0;
			left: 0;
			@include mq {
				height: 5px;
			}
		}
	}

	&__ct {
		width: 48%;
		@include mq {
			width: 100%;
		}

		.c-txt__basic {
			display: block;
			color: #E0346E;
			font-weight: bold;
			margin-bottom: -3px;
			@include mq {
				margin-bottom: 5px;
			}
		}

		.c-ttl__03 {
			margin-bottom: 33px;
			@include mq {
				line-height: 1.5625;
				margin-bottom: 18px;
			}
		}

		.c-btn__01 {
			max-width: 251px;
			height: 50px;
			@include font-size(13);
			border-bottom-width: 5px;
			@include mq(md-min) {
				margin-left: 0;
			}
			@include mq {
				max-width: 214px;
			}

			span {
				padding: 0 40px;
				@include mq {
					padding: 0 52px;
				}

				&:after {
					border-width: 4px 0 4px 8px;
					top: 4px;
				}
			}
		}
	}

	&__img {
		width: 48.5%;
		position: relative;
		border-radius: 20px;
		overflow: hidden;
		margin-top: 10px;
		@include mq {
			width: 100%;
			margin-bottom: 20px;
		}

		&:before {
			content: "";
			display: block;
			padding-bottom: calc(335 / 502 * 100%);
		}

		img {
			position: absolute;
			width: 100%;
			height: 100%;
			top: 0;
			left: 0;
			object-fit: cover;
		}
	}
}

#page-campus_life_movie {
	.mv-page__setsubi {
		padding-bottom: 28px;
		@include mq {
			padding: 36px 0 55px;
		}

		&::before {
			top: -2px;
		}
	}
}

.sec-movie {
	padding: 26px 0 132px;
	@include mq {
		padding: 0 0 45px;
		margin-top: -3px;
	}
}

.movie-wrap {
	position: relative;
	margin-bottom: 42px;
	@include mq {
		margin-bottom: 20px;
	}

	&:before {
		content: "";
		display: block;
		padding-bottom: calc(576 / 1036 * 100%);
	}

	&--shape1 {
		&:after {
			content: "";
			position: absolute;
			background: url("../img/common/icon-start-rbga.png") center no-repeat;
			background-size: contain;
			width: 385px;
			height: 443px;
			top: 31%;
			left: -202px;
			z-index: -1;
			@include mq {
				top: -8%;
				left: -81px;
				width: 197px;
				height: 226px;
			}
		}
	}

	&--shape2 {
		&:after {
			content: "";
			position: absolute;
			background: url("../img/common/icon-cup.png") center no-repeat;
			background-size: contain;
			width: 194px;
			height: 258px;
			top: 44.2%;
			right: -143px;
			z-index: -1;
			@include mq {
				top: 40.2%;
				right: -35px;
				width: 80px;
				height: 112px;
			}
		}
	}

	iframe {
		position: absolute;
		width: 100%;
		height: 100%;
		border: 0;
		top: 0;
		left: 0;
	}
}