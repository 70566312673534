@use "vars" as *;

/* ====================================================
Layout
==================================================== */
.wrapper {
	padding-top: 136px;
	@include mq(tb){
		padding-top: 60px;
	}
}

.inner {
	display: block;
	max-width: 1240px;
	padding: 0 20px;
	margin: 0 auto;

	&--1076 {
		max-width: 1076px;
	}
	&--1000{
		max-width: 1040px;
	}
}
// page mv
.mv-page {
	overflow: hidden;
	z-index: 2;

	&__ttl {
		text-align: center;
		position: relative;
		margin-bottom: 44px;
		padding-bottom: 34px;

		@include mq {
			padding-bottom: 27px;
			margin-bottom: 42px;
		}

		@include p-after(150px, 10px) {
			background: linear-gradient(105deg, #FF82AC, #FBC900);
			border-radius: 5px;
			bottom: 0;
			left: 50%;
			transform: translateX(-50%);

			@include mq {
				height: 8px;
				width: 111px;
			}
		}
	}

	&__img {
		img {
			border-radius: 20px;
			width: 100%;
			object-fit: cover;
		}
		//Modifier page mv character
		&--character{
			margin: 0 auto;
			width: 480px;
			@include mq{
				width: 282px;
			}
		}
	}

	.c-txt__basic {
		position: relative;
		text-align: center;
		font-weight: 700;

		@include mq {
			line-height: 2.083;
		}
		a {
			color: #1D3E62;
			text-decoration: underline;
		}
	}
	.mx-w860{
		max-width: 860px;
		margin: 0 auto;
	}
	&__info {
		text-align: center;
		position: relative;
		font-weight: 700;
		margin-top: 50px;
		padding-bottom: 184px;

		@include mq {
			margin-top: 41px;
			padding-bottom: 78px;
		}

		@include p-before(232px, 257px) {
			background: url(../img/about_eiseishi/field/bg-before.png) no-repeat center/contain;
			bottom: 33%;
			left: -9.8%;
			z-index: -1;

			@include mq {
				bottom: 74%;
				// left: -9.8%;
				left: -34px;
				width: 89px;
				height: 99px;
			}
		}

		@include p-after(212px, 277px) {
			background: url(../img/about_eiseishi/field/bg-after.png) no-repeat center bottom/contain;
			bottom: -16px;
			right: -10.8%;
			z-index: -1;

			@include mq {
				height: 107px;
				width: 82px;
				bottom: -6px;
				// right: -8.9%;
				right: -31px;
			}
		}

		&-ttl {
			margin-bottom: 27px;

			@include mq {
				margin-bottom: 13px;
			}
		}

		&--ct {
			@include mq{
				margin-top: 8px;
			}
			&::after,
			&::before {
				content: none;
			}

			.c-txt__basic {
				position: relative;
				display: inline-block;
				padding: 0 151px 0 151px;
				@include mq(xl){
					padding: 0 50px;
				}
				@include mq{
					padding: 0 0;
					line-height: 2.083;
				}
				@include p-before(92px, 288px) {
					background: url(../img/gakko_annai/img-l.png) no-repeat center/contain;
					bottom: -21px;
					left: 19px;
					@include mq(xl){
						left: -8%;
					}
					@include mq {
						content: none;
					}
				}

				@include p-after(119px, 284px) {
					background: url(../img/gakko_annai/img-r.png) no-repeat center bottom/contain;
					bottom: -19px;
					right: -1px;
					@include mq(xl){
						right: -10%;
					}
					@include mq {
						content: none;
					}
				}
			}

		}
		&--danshi{
			padding-bottom: 54px;
			@include mq{
				padding-bottom: 39px;
				.mv-page__info-ttl{
					margin-bottom: 8px;
				}
			}
			&::before{
				bottom: auto;
				top: -49px;
				@include mq{
					top: -41px;
					width: 100px;
					height: 104px;
				}
			}
			&::after{
				background: url(../img/nyushi/danshi/bg-after.png) no-repeat center/ contain;
				width: 122px;
				height: 122px;
				bottom: 25px;
				right: -3.4%;
				@include mq{
					width: 62px;
					height: 62px;
					right: -4.4%;
					bottom: 9px;
				}
			}
		}
		&--nopen {
			margin-top: 39px;
			padding-bottom: 221px;
			@include mq {
				margin-top: 33px;
				padding-bottom: 0;
				z-index: 1;
				&:before {
					bottom: 61%;
					left: -32px;
					width: 96px;
					height: 106px;
					z-index: -1;
				}
			}
			&:after {
				display: none;
			}
		}
		&--noimg {
			&:before, &:after {
				display: none;
			}
		}
		&--shushoku{
			padding-bottom: 68px;
			margin-bottom: 41px;
			@include mq{
				padding-bottom: 106px;
				margin-bottom: 0;
			}
			&::before{
				width: 337px;
				height: 238px;
				background: url(../img/gakko_annai/shushoku/bg-after.png) no-repeat center/contain;
				bottom: -11px;
				left: -6.8%;
				@include mq{
					width: 138px;
					height: 98px;
					bottom: -5px;
					left: -69PX;
				}
			}
			&::after{
				width: 263px;
				height: 196px;
				background: url(../img/gakko_annai/shushoku/bg-before.png) no-repeat center/contain;
				bottom: auto;
				top: -9px;
				right: -4.6%;
				@include mq{
					width: 98px;
					height: 74px;
					right: -32px;
					top: -36px;
				}
			}
		}
	}

	&__campus-01 {
		text-align: left;
		max-width: 1036px;
		margin: 0 auto;
		font-weight: 700;
		padding-bottom: 30px;

		&-ttl {
			text-align: left;
			@include font-size(30);
			font-weight: 700;

			@include mq() {
				@include font-size(16);
				margin-bottom: 11px;
			}
		}

		p {
			text-align: left;

			@include mq() {
				line-height: 1.6666666667;
			}
		}
	}

	&__reason {
		padding: 42px 0 48px;

		@include mq() {
			padding: 37px 0 96px;
		}
	}
	
	&__setsubi{
		padding: 50px 0 55px;
		>*{
			position: relative;
			z-index: 2;
		}
		@include mq(){
			padding: 46px 0 34px;
		}
		@include mq(xs){
			padding-bottom: 55px;
		}
		&:before,&:after{
			content: "";
			position: absolute;
		}
		&:before{
			width: 192px;
			height: 219px;
			background: url(../img/campus_life/setsubi/item-01.png) no-repeat;
			background-size: 100%;
			top: 45px;
			left: -22px;
			@include mq(){
				width: 87px;
				height: 99px;
				top: 13px;
				left: -30px;
			}
		}
		&:after{
			width: 197px;
			height: 131px;
			background: url("../img/campus_life/setsubi/item-02.png")no-repeat;
			background-size: 100%;
			right: 0;
			bottom: 0;
			@include mq(){
				width: 88px;
				height: 63px;
				right: -32px;
			}
		}
	}

	
}
.sec-mvhd{
	margin-top: -22px;
	@include mq{
		margin-top: -19px;
	}
}
.wave-pink-top {
	position: relative;
	background: linear-gradient(90deg, #FFDBE7, #FFEB9A);
	@include mq(sm) {
		background: linear-gradient(135deg, #FFDBE7, #FFEB9A);
	}
	&:before {
		content: "";
		position: absolute;
		background: url("../img/common/wave-pink.png") top center no-repeat;
		background-size: 100% 100%;
		width: 100%;
		height: 77px;
		top: -76px;
		@include h-align;
		pointer-events: none;
		@include mq(sm) {
			background: url("../img/common/wave-pink-sp.png") top center no-repeat;
			background-size: 100% 100%;
			height: 36px;
			top: -35px;
		}
	}
}

.wave-white-bottom {
	position: relative;
	&:after {
		content: "";
		position: absolute;
		background: url("../img/common/wave-white.png") top center no-repeat;
		background-size: 100% 100%;
		width: 100%;
		height: 78px;
		bottom: -1px;
		@include h-align;
		pointer-events: none;
		@include mq(sm) {
			background: url("../img/common/wave-white-sp.png") top center no-repeat;
			background-size: 100% 100%;
			height: 36px;
		}
	}
}

// breadcrumb
.breadcrumb {
	background: linear-gradient(105deg, #FFDBE7, #FFEB9A);
	font-family: $font-Yugothic;
	font-weight: 700;
	@include fts(16, 26);
	letter-spacing: .07rem;
	margin-bottom: 52px;
	padding: 3px 0 1px;

	@include mq {
		@include fts(9, 15);
		padding: 3px 0 2px;
		margin-bottom: 39px;
	}

	.inner {
		max-width: 1210px;
	}

	a {
		color: inherit;
		display: inline-block;
		position: relative;
		padding-right: 22px;

		@include mq {
			padding-right: 11px;
		}

		&::after {
			content: ">";
			position: absolute;
			right: 4px;
			top: 0;

			@include mq {
				right: 2px;
			}
		}
	}
	&--blue{
		background: linear-gradient(105deg, #9FCAFF, #E3FFBE);
	}
}

// cm-list
.cm-list {
	@include flex(center, 0, wrap);
	margin: 0 -9px;
	grid-gap: 49px 0;

	@include mq {
		line-height: 2.083;
		grid-gap: 33px 0;
		// grid-gap: 37px 0;
	}

	&__item {
		width: 33.33%;
		padding: 0 9px;

		@include mq {
			width: 50%;
		}

		@include mq(sm) {
			width: 100%;
		}
	}

	&__wrap {
		display: block;
		border-radius: 20px;
		overflow: hidden;
		box-shadow: 0 3px 6px rgba($color: #000000, $alpha: .16);
		background: white;
		p{
			color: #1D3E62;
		}
	}

	&__img {
		position: relative;

		&::before {
			content: "";
			padding-top: calc((267/388) * 100%);
			display: block;

			@include mq {
				padding-top: calc((144/ 353) * 100%);
			}
		}

		&-thumb {
			position: absolute;
			top: 0;
			left: 0;
			bottom: 0;
			right: 0;

			img {
				width: 100%;
				height: 100%;
				object-fit: cover;
			}
		}
	}

	&__ttl {
		color: #E0346E;
		margin-bottom: 1px;

		@include mq {
			@include fts(20, 30);
		}
	}

	&__ct {
		padding: 13px 21px 21px;
		@include flex(0, 0, 0, 0, 1);

		@include mq(md-min) {
			min-height: 335px;
		}

		@include mq {
			padding: 14px 19px 24px;
			// padding: 14px 19px 18px;
		}

		p {
			font-weight: 700;

			&:not(:last-child) {
				// margin-bottom: 35px;
				margin-bottom: 5px;

				@include mq {
					margin-bottom: 2px;
				}
			}
		}

		.c-btn__02 {
			margin-top: auto;
		}
	}
}
//form input
.form-control {
	display: block;
	width: 100%;
	height: 45px;
	background: white;
	border: 1px solid #ccc;
	font-weight: 500;
	padding: 5px 10px;
	@include font-size(15);

	&:focus {
		border-color: #66afe9;
		outline: 0;
		-webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, .075), 0 0 8px rgba(102, 175, 233, .6);
		box-shadow: inset 0 1px 1px rgba(0, 0, 0, .075), 0 0 8px rgba(102, 175, 233, .6);
	}
}

//HTML CSS radio end check box

//<ul class="pure-list">
//	<li>
//		<div class="pure-list__input">
//			<input name="radio" type="radio" class="radio" checked="checked">
//			<span>Option 1</span>
//		</div>
//	</li>
//
//	<li>
//		<div class="pure-list__input">
//			<input name="radio" type="radio" class="radio">
//			<span>Option 1</span>
//		</div>
//	</li>
//
//	<li>
//		<div class="pure-list__input">
//			<input name="checkbox" type="checkbox" checked="checked">
//			<span>Choice A</span>
//		</div>
//	</li>
//
//	<li>
//		<div class="pure-list__input">
//			<input name="checkbox" type="checkbox">
//			<span>Choice B</span>
//		</div>
//	</li>
//</ul>

//radio end check box
.pure-list {
	&__input {
		position: relative;

		input {
			position: absolute;
			top: 0;
			left: 0;
			right: 0;
			bottom: 0;
			z-index: 1;
			opacity: 0;
			cursor: pointer;

			&[type="checkbox"] {
				&+span {
					&:after {
						background-color: transparent;
						top: 50%;
						left: 4px;
						width: 8px;
						height: 3px;
						margin-top: -4px;
						border-style: solid;
						border-color: #ffffff;
						border-width: 0 0 3px 3px;
						border-image: none;
						transform: rotate(-45deg) scale(0);
					}
				}

				&:checked {
					&+span {
						&:after {
							content: '';
							transform: rotate(-45deg) scale(1);
							transition: transform 200ms ease-out;
						}

						&:before {
							animation: borderscale 200ms ease-in;
							background: #4F8196;
						}
					}
				}
			}

			&[type="radio"] {
				+span {

					&:after,
					&:before {
						border-radius: 50%;
					}
				}

				&:checked {
					&+span {
						&:before {
							animation: borderscale 300ms ease-in;
							background-color: white;
						}

						&:after {
							transform: scale(1);
						}
					}
				}
			}
		}

		span {
			display: block;
			position: relative;
			padding: 10px 0 10px 40px;
			vertical-align: middle;
			user-select: none;
			cursor: pointer;

			&:before {
				box-sizing: content-box;
				content: '';
				color: #4F8196;
				position: absolute;
				top: 50%;
				left: 0;
				width: 14px;
				height: 14px;
				margin-top: -9px;
				border: 2px solid #4F8196;
				text-align: center;
				transition: all 0.4s ease;
			}

			&:after {
				box-sizing: content-box;
				content: '';
				background-color: #4F8196;
				position: absolute;
				top: 50%;
				left: 4px;
				width: 10px;
				height: 10px;
				margin-top: -5px;
				transform: scale(0);
				transform-origin: 50%;
				transition: transform 200ms ease-out;
			}
		}
	}
}


//navigation
//<div class="nums-page">
//	<div class='wp-pagenavi' role='navigation'>
//		<a class="prevtpostslink" rel="next" aria-label="Prev Page" href="">Prev</a>
//		<span aria-current='page' class='current'>1</span>
//		<a class="page larger" title="Page 2" href="">2</a>
//		<a class="page larger" title="Page 3" href="">3</a>
//		<a class="nextpostslink" rel="next" aria-label="Next Page" href="">Next</a>
//	</div>
//</div>



.table-cm {
	padding: 100px 48px 13px;
	border-radius: 20px;
	max-width: 1036px;
	margin: 0 auto;
	background: #fff;
	position: relative;
	z-index: 10;
	@include mq{
		padding: 65px 20px 17px;
	}
	&__list {
		&-item {
			&--th {
				@include fts(20, 35);
				font-weight: 700;
				@include flex(0, 0);
				padding-bottom: 15px;
				border-bottom: 2px solid #1D3E62;
				@include mq{
					@include fts(16, 35);
					padding-bottom: 13px;
					justify-content: space-between;
				}
			}

			&th01 {
				width: calc(243/940*100%);
				@include mq{
					width: auto;
				}
			}

			&th02 {
				width: calc(452/940*100%);
				@include mq{
					display: none;
				}
			}

			&th03 {
				width: calc(128/940*100%);
				text-align: center;
				@include mq{
					width: auto;
				}
			}

			&th04 {
				width: calc(115/940*100%);
				text-align: center;
				padding-left: 14px;
				@include mq{
					display: none;
				}
			}
			&--td {
				font-family: $font-Yugothic;
				font-weight: 700;
				padding: 21px 0;
				border-bottom: 2px solid #1D3E62;
				@include mq{
					padding: 19px 0 28px;
				}
			}
			&--bt{
				.table-cm__listsub{
					color: #277AE2;
					font-weight: 700;
					&--pink{
						color: #E0346E;
					}
					&-item{
						padding: 19px 0;
						@include mq{
							padding: 19px 0;
							align-items: flex-end;
							.table-cm__listsub-item01{
								@include fts(16, 30);
							}
						}
					}
				}
				
			}
		   
	
		   
		}
		&-hd {
			@include flex(between, 0);
			@include mq{
				@include fts(16, 30);
				align-items: center;
			}
			&l {
				width: calc(825/940*100%);
				@include mq{
					width: calc(100% - 79px);
				}
			}

			&r {
				width: calc(115/940*100%);
				text-align: center;
				padding-right: 24px;
				position: relative;
				@include mq{
					width: 79px;
				}
			}
		}

		&-ct {
			padding-top: 40px;
			line-height: 1.875;
			margin-bottom: 3px;
			@include mq{
				padding-top: 19px;
				 line-height: calc(30/12);
				 margin-bottom: -6px;
			}
		}
		&sub {
			color: #1D3E62;
			font-family: $font-Yugothic;
			&-item {
				@include flex(end, 0);
				@include mq{
					gap: 0 3px;
				}
				&01 {
					width: calc(452/940*100%);
					@include mq{
						width: calc(216/313*100%);
					}
				}

				&02 {
					width: calc(128/940*100%);
					text-align: center;
					@include mq{
						width: calc(60/313*100%);
						text-align: right;
					}
				}

				&03 {
					width: calc(115/940*100%);
					text-align: center;
					padding-left: 10px;
					@include mq{
						width: calc(30/313*100%);
						padding-left: 0;
						text-align: right;
					}
				}
			}
		}
	}
}

.btn-accordion {
	padding: 1px 5px;
	border-radius: 19px;
	background: #1D3E62;
	color: #fff;
	font-size: 14px;
	font-size: 1.4rem;
	line-height: 1.7857142857;
	font-weight: 700;
	position: relative;
	min-width: 79px;
	display: inline-flex;
	align-items: center;
	justify-content: center;
	cursor: pointer;
	@include smooth-transition;
	font-family: $font-jp;
	&:hover{
		opacity: .7;
		@include smooth-transition;
	}
	&:after {
		content: '';
		width: 18px;
		height: 18px;
		background: url('../img/common/img-add.png') no-repeat center center;
		background-size: 100%;
		margin-left: 5px;
		display: inline-block;
		@include mq{
			width: 15px;
			height: 15px;
		}
	}
	&.on{
		&:after{
			background: url('../img/common/img-minus.png') no-repeat center center;
			background-size: 100%;
		}
	}
}
