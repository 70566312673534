@use "vars" as *;



.sec-sitemap{
	padding: 49px 0 87px;
	@include mq(){
		padding: 40px 0 44px;
	}
}
.sitemap{
	@include flex(between,0,wrap);
	line-height: 1.4;
	font-weight: bold;
	a{
		color: #1D3E62;
	}
	&-list{
		width: 50%;
		padding-right: 15px;
		@include mq(){
			width: 100%;
			padding-right: 0;
			margin-bottom: 43px;
			&:last-child{
				margin-bottom: 0;
			}
		}
		&__item{
			margin-bottom: 42px;
			&:last-child{
				margin-bottom: 0;
			}
			.c-ttl__04{
				margin-bottom: 16px;
				@include mq(){
					margin-bottom: 10px;
				}
			}
		}
		ul{
			padding: 0 0 0 20px;
			margin-bottom: 36px;
			&:last-child{
				margin-bottom: 0;
			}
			li{
				margin-bottom: 8px;
				&:last-child{
					margin-bottom: 0;
				}
			}
			ul{
				padding: 6px 0 0 16px;
				@include mq(){
					padding: 8px 0 0 12px;
				}
			}
			&.first{
				padding-left: 0;
			}
		}
	}
}