@use "vars" as *;

/* ====================================================
Footer
==================================================== */

#footer {
	border-top: 1px solid #1D3E62;
	padding: 86px 0 20px;
	background: white;
	@include mq {
		border-top: 0;
		padding: 0 0 20px;
	}

	&.nobd {
		border-top: 0;
	}

	.footer-menu {
		@include mq(md-min) {
			@include flex(center);
			border-bottom: 1px solid #1D3E62;
			padding-bottom: 52px;
			margin-bottom: 30px;
		}

		&__item {
			font-family: $font-Yugothic;
			font-weight: 500;
			@include font-size(16);
			letter-spacing: -0.6px;
			margin: 0 20px;
			@include mq(lg) {
				margin: 0 11px;
			}
			@include mq {
				@include font-size(13);
				line-height: 1.41;
				letter-spacing: -0.3px;
				margin: 0 -20px;
			}

			a {
				color: #1D3E62;
			}
		}

		&__sub {
			margin-bottom: 50px;
			@include mq {
				border-top: 1px solid #1D3E62;
				padding: 7px 10px;
				margin: 0;
				&.js-accorfooter {
					.footer-menu__list {
						display: none;
						padding: 12px 10px 7px;

						&-item {
							border-bottom: 0;
						}
					}
				}
			}

			&:last-child {
				margin-bottom: 0;
			}
		}

		&__ttl {
			@include mq(md-min) {
				position: relative;
				padding-left: 24px;
				&:before {
					content: "●";
					position: absolute;
					@include font-size(10);
					top: 5px;
					left: -2px;
				}
			}
		}

		&__list {
			@include mq(md-min) {
				position: relative;
				margin-top: 3px;
				padding: 4px 0 4px 25px;
				&:before {
					content: "";
					position: absolute;
					background: #1D3E62;
					width: 1px;
					height: 100%;
					top: 0;
					left: 3px;
				}
				&:first-child {
					margin-top: -4px;
				}
			}
			@include mq {
				margin: 0 -10px;
			}

			&-item {
				margin-bottom: 8px;
				@include mq {
					border-bottom: 1px solid #1D3E62;
					padding: 0 10px 7px;
				}

				&:last-child {
					margin-bottom: 0;
					border-bottom: 0;
					@include mq {
						margin-bottom: -8px;
					}
				}
			}
		}
	}

	.footer-visitor {
		@include flex();
		max-width: 829px;
		margin: 0 auto 74px;
		@include mq {
			display: block;
			background: #213260;
			color: white;
			margin: 0 -20px;
			padding: 33px 25px 36px;
		}

		&__ttl {
			font-weight: bold;
			@include font-size(16);
			@include mq(md-min) {
				position: relative;
				width: 173px;
				padding-left: 15px;
				&:before {
					content: "●";
					position: absolute;
					@include font-size(10);
					top: 5px;
					left: 0;
				}
			}
			@include mq {
				@include font-size(17);
			}
		}

		&__ct {
			width: calc(100% - 173px);
			@include mq {
				width: 100%;
				margin-top: 13px;
			}

			a {
				position: relative;
				display: inline-block;
				color: #1D3E62;
				font-family: $font-Yugothic;
				font-weight: 500;
				@include font-size(16);
				line-height: 1.6;
				margin-right: 41px;
				@include mq(md-min) {
					&:after {
						content: "";
						position: absolute;
						background: #1D3E62;
						width: 1px;
						height: 12px;
						top: 6px;
						right: -23px;
					}
				}
				@include mq {
					display: block;
					color: white;
					@include font-size(13.5);
					margin-bottom: 6px;
					&:last-child {
						margin-bottom: 0;
					}
				}
			}
		}
	}

	.footer-contact {
		position: relative;
		text-align: center;
		padding: 55px 0 62px;
		margin-bottom: 14px;
		z-index: 1;
		@include mq {
			padding: 38px 0 43px;
			margin-bottom: 12px;
		}

		&:before {
			content: "";
			position: absolute;
			background: linear-gradient(90deg, #FFDBE7, #FFEB9A);
			width: 100vw;
			height: 100%;
			@include v-h-align;
			z-index: -1;
			pointer-events: none;
			@include mq {
				background: linear-gradient(135deg, #FFDBE7, #FFEB9A);
			}
		}

		&__txt {
			font-weight: bold;
			@include font-size(20);
			margin-bottom: 45px;
			@include mq {
				margin-bottom: 34px;
				span {
					@include font-size(12);
				}
			}
		}

		&__tel {
			position: relative;
			display: block;
			max-width: 440px;
			color: #1D3E62;
			font-weight: bold;
			@include font-size(50);
			padding-left: 58px;
			margin: -36px auto 25px;
			@include mq {
				max-width: 324px;
				@include font-size(36);
				padding-left: 38px;
				margin: -27px auto 5px;
			}
			@include mq(xs) {
				max-width: 275px;
				@include font-size(30);
			}

			&:before {
				content: "";
				position: absolute;
				background: url("../img/common/icon-tel.svg") center no-repeat;
				background-size: contain;
				width: 43px;
				height: 72px;
				top: -1px;
				left: 6px;
				@include mq {
					width: 31px;
					height: 52px;
				}
				@include mq(xs) {
					top: -6px;
				}
			}
		}
	}

	.footer-txt {
		text-align: center;
		font-weight: bold;
		@include fts(10, 20);
		@include mq {
			@include fts(9, 20);
			margin: 0 -10px;
		}

		a {
			color: #1D3E62;
		}
	}
}