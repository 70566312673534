@use "vars" as *;

/* ====================================================
PAGE about_eiseishi_field
==================================================== */
.sec-field {
	margin-top: -55px;
	margin-bottom: 160px;

	@include mq {
		margin-top: -15px;
		margin-bottom: 74px;
	}

	&__inner {
		position: relative;

		@include p-after(36%, 35%) {
			background: url(../img/about_eiseishi/field/bg-01.png) no-repeat top left/contain;
			bottom: -21%;
			left: -16.5%;
			z-index: -1;

			@include mq {
				bottom: -21.8%;
				left: -4.5%;
				width: 39%;
				height: 32%;
			}
		}
	}
}

.article-list {
	font-weight: 700;

	&__item {
		@include flex(between, center, wrap);

		@include mq {
			align-items: start;
		}

		&:not(:last-child) {
			margin-bottom: 50px;

			@include mq {
				margin-bottom: 20px;
			}
		}
	}

	&__img {
		width: 250px;
		align-self: baseline;
		border-radius: 20px;
		overflow: hidden;
		position: relative;

		@include mq {
			width: 160px;
		}

		@include mq(xs) {
			width: 110px;
		}

		&::before {
			content: "";
			display: block;
			padding-top: 100%;
		}

		img {
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
			object-fit: cover;
		}
	}

	&__ct {
		width: calc(100% - 289px);

		@include mq {
			width: calc(100% - 177px);
		}

		@include mq(xs) {
			width: calc(100% - 125px);
		}

		&-ttl {
			color: #E0346E;
			margin-bottom: 5px;

			@include mq {
				margin-left: -5px;
			}

			span {
				display: inline-block;
				background: #FFFCDB;
				border-radius: 31px;
				padding: 6px 28px;

				@include mq {
					padding: 6px 16px;

				}
			}
		}
	}
}

.sec-look {
	padding-bottom: 139px;
	position: relative;
    // overflow: hidden;
	@include mq {
		padding-bottom: 95px;
	}

	&--field {
		@include mq {
			overflow: hidden;
		}

		@include p-after(21%, 31%) {
			background: url(../img/about_eiseishi/field/bg-02.png) no-repeat top left/contain;
			bottom: 1%;
			right: 0;
			z-index: -1;
			max-width: 280px;
			max-height: 256px;



			@include mq(sm) {
				bottom: 0%;
				right: -22px;
				width: 33%;
				height: 16%;
			}

			@media screen and (min-width: 394px) {
				@include mq {
					bottom: 3px;
					width: 99px;
					height: 100px;
					right: 0;
				}
			}
		}
	}

	&--haspen {
		@include mq {
			overflow: hidden;
		}

		@include p-after(21%, 31%) {
			background: url(../img/gakko_annai/img-pen.png) no-repeat top left/contain;
			bottom: 2.2%;
			left: 2.9%;
			z-index: -1;
			max-width: 172px;
			max-height: 172px;

			@include mq {
				bottom: 0%;
				left: 0px;
				width: 28%;
				height: 4.8%;
			}
		}
	}
	&--icobefore{
		@include p-before(385px, 443px){
			background: url(../img/gakko_annai/img-bg.png) no-repeat center/contain;
			top: 15%;
			right: -175px;
			z-index: -1;
			@include mq{
				width: 193px;
				height: 222px;
				right: -32px;
				top: 13.7%;
			}
		}
	}
}
// cm-list
.cm-list {
	@include flex(center, 0, wrap);
	margin: 0 -9px;
	grid-gap: 49px 0;

	@include mq {
		line-height: 2.083;
		grid-gap: 33px 0;
		// grid-gap: 37px 0;
	}

	&__item {
		width: 33.33%;
		padding: 0 9px;

		@include mq {
			width: 50%;
		}

		@include mq(sm) {
			width: 100%;
		}
	}

	&__wrap {
		display: block;
		border-radius: 20px;
		overflow: hidden;
		box-shadow: 0 3px 6px rgba($color: #000000, $alpha: .16);
		background: white;
	}

	&__img {
		position: relative;

		&::before {
			content: "";
			padding-top: calc((267/388) * 100%);
			display: block;

			@include mq {
				padding-top: calc((144/ 353) * 100%);
			}
		}

		&-thumb {
			position: absolute;
			top: 0;
			left: 0;
			bottom: 0;
			right: 0;

			img {
				width: 100%;
				height: 100%;
				object-fit: cover;
			}
		}
	}

	&__ttl {
		color: #E0346E;
		margin-bottom: 1px;

		@include mq {
			@include fts(20, 30);
		}
	}

	&__ct {
		padding: 13px 21px 21px;
		@include flex(0, 0, 0, 0, 1);

		@include mq(md-min) {
			min-height: 335px;
		}

		@include mq {
			padding: 14px 19px 24px;
			// padding: 14px 19px 18px;
		}

		p {
			font-weight: 700;

			&:not(:last-child) {
				// margin-bottom: 35px;
				margin-bottom: 5px;

				@include mq {
					margin-bottom: 2px;
				}
			}
		}

		.c-btn__02 {
			margin-top: auto;
		}
	}
}

.look-ttl {
	text-align: center;
	margin-bottom: 48px;

	@include mq {
		margin-bottom: 21px;
	}
}



/* ====================================================
PAGE page-kitai
==================================================== */
#page-about_eiseishi_kitai {
	@include mq {
		.sec-look--field {
			&::after {
				bottom: -2%;
				right: -20px;
			}
		}
	}
}

.mv-page__info--kitai {
	padding-bottom: 140px;

	@include mq {
		padding-bottom: 69px;
	}

	&::before {
		bottom: 54%;

		@include mq {
			bottom: 78.2%;
		}
	}

	&::after {
		bottom: -13px;
		right: -10.8%;

		@include mq {
			bottom: 9px;
			right: -8.8%;
		}
	}
}

.sec-kitai {
	margin-top: -62px;
	margin-bottom: 179px;

	@include mq {
		margin-top: -26px;
		margin-bottom: 74px;
	}

	&__inner {
		position: relative;

		@include p-after(385px, 436px) {
			background: url(../img/about_eiseishi/field/bg-01.png) no-repeat top left/contain;
			// bottom: -55%;
			bottom: -301px;
			left: -16.5%;
			z-index: -1;

			@include mq {
				// bottom: -30.8%;
				bottom: -151px;
				left: -5.5%;
				width: 161px;
				height: 185px;
			}
		}
	}
}

.kitai-box {
	background: white;
	line-height: 1.875;

	@include mq {
		line-height: 2.083;
	}

	.c-ttl__03 {
		margin-bottom: 33px;

		@include mq {
			margin-bottom: 22px;
		}
	}

	&__dl {
		&:not(:last-child) {
			margin-bottom: 32px;

			@include mq {
				margin-bottom: 38px;
			}
		}

		&-dt {
			margin-bottom: 13px;

			@include mq {
				margin-bottom: 3px;
			}
		}

		&-dd {
			font-weight: 700;
			font-family: $font-Yugothic;
		}
	} iiy

	&__note {
		padding-top: 46px;
		font-family: $font-Yugothic;
		font-weight: 700;
		@include mq {
			padding-top: 14px;
		}
	}
	
	&--style2 {
		padding-top: 44px;
		position: relative;

		@include p-before(232px, 257px) {
			background: url(../img/about_eiseishi/field/bg-before.png) no-repeat center/contain;
			top: -159px;
			right: 96.7%;
			z-index: -1;

			@include mq {
				top: -62px;
				right: 84%;
				width: 89px;
				height: 99px;
			}
		}

		@include p-after(212px, 277px) {
			background: url(../img/about_eiseishi/field/bg-after.png) no-repeat center bottom/contain;
			bottom: -79px;
			left: 100%;
			z-index: -1;

			@include mq {
				height: 107px;
				width: 82px;
				bottom: 50%;
				left: 86%;
			}
		}

		@include mq {
			padding-top: 23px;
			padding-bottom: 12px;
		}

		.kitai-box__dl {
			&:not(:last-child) {
				margin-bottom: 53px;

				@include mq {
					margin-bottom: 38px;
				}
			}
		}
	}
	&--number{
		counter-reset: index;
		margin-bottom: 84px;
		@include mq{
			margin-bottom: 55px;
		}
		.kitai-box__dl{
			&:not(:last-child){
				margin-bottom: 47px;
				@include mq{
					margin-bottom: 30px;
				}
			}
			&-dt{
				position: relative;
				padding-left: 40px;
				@include mq{
					padding-left: 32px;
				}
				&::before{
					counter-increment: index;
					content: counter(index) " . ";
					position: absolute;
					top: 0;
					left: 4px;
					@include mq{
						left: 3px;
					}
				}
			}
		}
	}
}

/* ====================================================
PAGE page-shigoto
==================================================== */
.sec-shigoto {
	padding-top: 83px;
	margin-bottom: 141px;

	@include mq {
		padding-top: 43px;
		margin-bottom: 68px;
	}

	.inner {
		&::after {
			@include mq(md-min) {
				bottom: -548px;
			}

			@include mq {
				bottom: -126px;
			}
		}
	}
}
