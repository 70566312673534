@charset "UTF-8";
@media screen and (min-width: 835px) {
  .mbpc-0 {
    margin-bottom: 0px !important;
  }
}
@media screen and (max-width: 834px) {
  .mbsp-0 {
    margin-bottom: 0px !important;
  }
}
@media screen and (min-width: 835px) {
  .mbpc-1 {
    margin-bottom: 1px !important;
  }
}
@media screen and (max-width: 834px) {
  .mbsp-1 {
    margin-bottom: 1px !important;
  }
}
@media screen and (min-width: 835px) {
  .mbpc-2 {
    margin-bottom: 2px !important;
  }
}
@media screen and (max-width: 834px) {
  .mbsp-2 {
    margin-bottom: 2px !important;
  }
}
@media screen and (min-width: 835px) {
  .mbpc-3 {
    margin-bottom: 3px !important;
  }
}
@media screen and (max-width: 834px) {
  .mbsp-3 {
    margin-bottom: 3px !important;
  }
}
@media screen and (min-width: 835px) {
  .mbpc-4 {
    margin-bottom: 4px !important;
  }
}
@media screen and (max-width: 834px) {
  .mbsp-4 {
    margin-bottom: 4px !important;
  }
}
@media screen and (min-width: 835px) {
  .mbpc-5 {
    margin-bottom: 5px !important;
  }
}
@media screen and (max-width: 834px) {
  .mbsp-5 {
    margin-bottom: 5px !important;
  }
}
@media screen and (min-width: 835px) {
  .mbpc-6 {
    margin-bottom: 6px !important;
  }
}
@media screen and (max-width: 834px) {
  .mbsp-6 {
    margin-bottom: 6px !important;
  }
}
@media screen and (min-width: 835px) {
  .mbpc-7 {
    margin-bottom: 7px !important;
  }
}
@media screen and (max-width: 834px) {
  .mbsp-7 {
    margin-bottom: 7px !important;
  }
}
@media screen and (min-width: 835px) {
  .mbpc-8 {
    margin-bottom: 8px !important;
  }
}
@media screen and (max-width: 834px) {
  .mbsp-8 {
    margin-bottom: 8px !important;
  }
}
@media screen and (min-width: 835px) {
  .mbpc-9 {
    margin-bottom: 9px !important;
  }
}
@media screen and (max-width: 834px) {
  .mbsp-9 {
    margin-bottom: 9px !important;
  }
}
@media screen and (min-width: 835px) {
  .mbpc-10 {
    margin-bottom: 10px !important;
  }
}
@media screen and (max-width: 834px) {
  .mbsp-10 {
    margin-bottom: 10px !important;
  }
}
@media screen and (min-width: 835px) {
  .mbpc-11 {
    margin-bottom: 11px !important;
  }
}
@media screen and (max-width: 834px) {
  .mbsp-11 {
    margin-bottom: 11px !important;
  }
}
@media screen and (min-width: 835px) {
  .mbpc-12 {
    margin-bottom: 12px !important;
  }
}
@media screen and (max-width: 834px) {
  .mbsp-12 {
    margin-bottom: 12px !important;
  }
}
@media screen and (min-width: 835px) {
  .mbpc-13 {
    margin-bottom: 13px !important;
  }
}
@media screen and (max-width: 834px) {
  .mbsp-13 {
    margin-bottom: 13px !important;
  }
}
@media screen and (min-width: 835px) {
  .mbpc-14 {
    margin-bottom: 14px !important;
  }
}
@media screen and (max-width: 834px) {
  .mbsp-14 {
    margin-bottom: 14px !important;
  }
}
@media screen and (min-width: 835px) {
  .mbpc-15 {
    margin-bottom: 15px !important;
  }
}
@media screen and (max-width: 834px) {
  .mbsp-15 {
    margin-bottom: 15px !important;
  }
}
@media screen and (min-width: 835px) {
  .mbpc-16 {
    margin-bottom: 16px !important;
  }
}
@media screen and (max-width: 834px) {
  .mbsp-16 {
    margin-bottom: 16px !important;
  }
}
@media screen and (min-width: 835px) {
  .mbpc-17 {
    margin-bottom: 17px !important;
  }
}
@media screen and (max-width: 834px) {
  .mbsp-17 {
    margin-bottom: 17px !important;
  }
}
@media screen and (min-width: 835px) {
  .mbpc-18 {
    margin-bottom: 18px !important;
  }
}
@media screen and (max-width: 834px) {
  .mbsp-18 {
    margin-bottom: 18px !important;
  }
}
@media screen and (min-width: 835px) {
  .mbpc-19 {
    margin-bottom: 19px !important;
  }
}
@media screen and (max-width: 834px) {
  .mbsp-19 {
    margin-bottom: 19px !important;
  }
}
@media screen and (min-width: 835px) {
  .mbpc-20 {
    margin-bottom: 20px !important;
  }
}
@media screen and (max-width: 834px) {
  .mbsp-20 {
    margin-bottom: 20px !important;
  }
}
@media screen and (min-width: 835px) {
  .mbpc-21 {
    margin-bottom: 21px !important;
  }
}
@media screen and (max-width: 834px) {
  .mbsp-21 {
    margin-bottom: 21px !important;
  }
}
@media screen and (min-width: 835px) {
  .mbpc-22 {
    margin-bottom: 22px !important;
  }
}
@media screen and (max-width: 834px) {
  .mbsp-22 {
    margin-bottom: 22px !important;
  }
}
@media screen and (min-width: 835px) {
  .mbpc-23 {
    margin-bottom: 23px !important;
  }
}
@media screen and (max-width: 834px) {
  .mbsp-23 {
    margin-bottom: 23px !important;
  }
}
@media screen and (min-width: 835px) {
  .mbpc-24 {
    margin-bottom: 24px !important;
  }
}
@media screen and (max-width: 834px) {
  .mbsp-24 {
    margin-bottom: 24px !important;
  }
}
@media screen and (min-width: 835px) {
  .mbpc-25 {
    margin-bottom: 25px !important;
  }
}
@media screen and (max-width: 834px) {
  .mbsp-25 {
    margin-bottom: 25px !important;
  }
}
@media screen and (min-width: 835px) {
  .mbpc-26 {
    margin-bottom: 26px !important;
  }
}
@media screen and (max-width: 834px) {
  .mbsp-26 {
    margin-bottom: 26px !important;
  }
}
@media screen and (min-width: 835px) {
  .mbpc-27 {
    margin-bottom: 27px !important;
  }
}
@media screen and (max-width: 834px) {
  .mbsp-27 {
    margin-bottom: 27px !important;
  }
}
@media screen and (min-width: 835px) {
  .mbpc-28 {
    margin-bottom: 28px !important;
  }
}
@media screen and (max-width: 834px) {
  .mbsp-28 {
    margin-bottom: 28px !important;
  }
}
@media screen and (min-width: 835px) {
  .mbpc-29 {
    margin-bottom: 29px !important;
  }
}
@media screen and (max-width: 834px) {
  .mbsp-29 {
    margin-bottom: 29px !important;
  }
}
@media screen and (min-width: 835px) {
  .mbpc-30 {
    margin-bottom: 30px !important;
  }
}
@media screen and (max-width: 834px) {
  .mbsp-30 {
    margin-bottom: 30px !important;
  }
}
@media screen and (min-width: 835px) {
  .mbpc-31 {
    margin-bottom: 31px !important;
  }
}
@media screen and (max-width: 834px) {
  .mbsp-31 {
    margin-bottom: 31px !important;
  }
}
@media screen and (min-width: 835px) {
  .mbpc-32 {
    margin-bottom: 32px !important;
  }
}
@media screen and (max-width: 834px) {
  .mbsp-32 {
    margin-bottom: 32px !important;
  }
}
@media screen and (min-width: 835px) {
  .mbpc-33 {
    margin-bottom: 33px !important;
  }
}
@media screen and (max-width: 834px) {
  .mbsp-33 {
    margin-bottom: 33px !important;
  }
}
@media screen and (min-width: 835px) {
  .mbpc-34 {
    margin-bottom: 34px !important;
  }
}
@media screen and (max-width: 834px) {
  .mbsp-34 {
    margin-bottom: 34px !important;
  }
}
@media screen and (min-width: 835px) {
  .mbpc-35 {
    margin-bottom: 35px !important;
  }
}
@media screen and (max-width: 834px) {
  .mbsp-35 {
    margin-bottom: 35px !important;
  }
}
@media screen and (min-width: 835px) {
  .mbpc-36 {
    margin-bottom: 36px !important;
  }
}
@media screen and (max-width: 834px) {
  .mbsp-36 {
    margin-bottom: 36px !important;
  }
}
@media screen and (min-width: 835px) {
  .mbpc-37 {
    margin-bottom: 37px !important;
  }
}
@media screen and (max-width: 834px) {
  .mbsp-37 {
    margin-bottom: 37px !important;
  }
}
@media screen and (min-width: 835px) {
  .mbpc-38 {
    margin-bottom: 38px !important;
  }
}
@media screen and (max-width: 834px) {
  .mbsp-38 {
    margin-bottom: 38px !important;
  }
}
@media screen and (min-width: 835px) {
  .mbpc-39 {
    margin-bottom: 39px !important;
  }
}
@media screen and (max-width: 834px) {
  .mbsp-39 {
    margin-bottom: 39px !important;
  }
}
@media screen and (min-width: 835px) {
  .mbpc-40 {
    margin-bottom: 40px !important;
  }
}
@media screen and (max-width: 834px) {
  .mbsp-40 {
    margin-bottom: 40px !important;
  }
}
@media screen and (min-width: 835px) {
  .mbpc-41 {
    margin-bottom: 41px !important;
  }
}
@media screen and (max-width: 834px) {
  .mbsp-41 {
    margin-bottom: 41px !important;
  }
}
@media screen and (min-width: 835px) {
  .mbpc-42 {
    margin-bottom: 42px !important;
  }
}
@media screen and (max-width: 834px) {
  .mbsp-42 {
    margin-bottom: 42px !important;
  }
}
@media screen and (min-width: 835px) {
  .mbpc-43 {
    margin-bottom: 43px !important;
  }
}
@media screen and (max-width: 834px) {
  .mbsp-43 {
    margin-bottom: 43px !important;
  }
}
@media screen and (min-width: 835px) {
  .mbpc-44 {
    margin-bottom: 44px !important;
  }
}
@media screen and (max-width: 834px) {
  .mbsp-44 {
    margin-bottom: 44px !important;
  }
}
@media screen and (min-width: 835px) {
  .mbpc-45 {
    margin-bottom: 45px !important;
  }
}
@media screen and (max-width: 834px) {
  .mbsp-45 {
    margin-bottom: 45px !important;
  }
}
@media screen and (min-width: 835px) {
  .mbpc-46 {
    margin-bottom: 46px !important;
  }
}
@media screen and (max-width: 834px) {
  .mbsp-46 {
    margin-bottom: 46px !important;
  }
}
@media screen and (min-width: 835px) {
  .mbpc-47 {
    margin-bottom: 47px !important;
  }
}
@media screen and (max-width: 834px) {
  .mbsp-47 {
    margin-bottom: 47px !important;
  }
}
@media screen and (min-width: 835px) {
  .mbpc-48 {
    margin-bottom: 48px !important;
  }
}
@media screen and (max-width: 834px) {
  .mbsp-48 {
    margin-bottom: 48px !important;
  }
}
@media screen and (min-width: 835px) {
  .mbpc-49 {
    margin-bottom: 49px !important;
  }
}
@media screen and (max-width: 834px) {
  .mbsp-49 {
    margin-bottom: 49px !important;
  }
}
@media screen and (min-width: 835px) {
  .mbpc-50 {
    margin-bottom: 50px !important;
  }
}
@media screen and (max-width: 834px) {
  .mbsp-50 {
    margin-bottom: 50px !important;
  }
}
@media screen and (min-width: 835px) {
  .mbpc-51 {
    margin-bottom: 51px !important;
  }
}
@media screen and (max-width: 834px) {
  .mbsp-51 {
    margin-bottom: 51px !important;
  }
}
@media screen and (min-width: 835px) {
  .mbpc-52 {
    margin-bottom: 52px !important;
  }
}
@media screen and (max-width: 834px) {
  .mbsp-52 {
    margin-bottom: 52px !important;
  }
}
@media screen and (min-width: 835px) {
  .mbpc-53 {
    margin-bottom: 53px !important;
  }
}
@media screen and (max-width: 834px) {
  .mbsp-53 {
    margin-bottom: 53px !important;
  }
}
@media screen and (min-width: 835px) {
  .mbpc-54 {
    margin-bottom: 54px !important;
  }
}
@media screen and (max-width: 834px) {
  .mbsp-54 {
    margin-bottom: 54px !important;
  }
}
@media screen and (min-width: 835px) {
  .mbpc-55 {
    margin-bottom: 55px !important;
  }
}
@media screen and (max-width: 834px) {
  .mbsp-55 {
    margin-bottom: 55px !important;
  }
}
@media screen and (min-width: 835px) {
  .mbpc-56 {
    margin-bottom: 56px !important;
  }
}
@media screen and (max-width: 834px) {
  .mbsp-56 {
    margin-bottom: 56px !important;
  }
}
@media screen and (min-width: 835px) {
  .mbpc-57 {
    margin-bottom: 57px !important;
  }
}
@media screen and (max-width: 834px) {
  .mbsp-57 {
    margin-bottom: 57px !important;
  }
}
@media screen and (min-width: 835px) {
  .mbpc-58 {
    margin-bottom: 58px !important;
  }
}
@media screen and (max-width: 834px) {
  .mbsp-58 {
    margin-bottom: 58px !important;
  }
}
@media screen and (min-width: 835px) {
  .mbpc-59 {
    margin-bottom: 59px !important;
  }
}
@media screen and (max-width: 834px) {
  .mbsp-59 {
    margin-bottom: 59px !important;
  }
}
@media screen and (min-width: 835px) {
  .mbpc-60 {
    margin-bottom: 60px !important;
  }
}
@media screen and (max-width: 834px) {
  .mbsp-60 {
    margin-bottom: 60px !important;
  }
}
@media screen and (min-width: 835px) {
  .mbpc-61 {
    margin-bottom: 61px !important;
  }
}
@media screen and (max-width: 834px) {
  .mbsp-61 {
    margin-bottom: 61px !important;
  }
}
@media screen and (min-width: 835px) {
  .mbpc-62 {
    margin-bottom: 62px !important;
  }
}
@media screen and (max-width: 834px) {
  .mbsp-62 {
    margin-bottom: 62px !important;
  }
}
@media screen and (min-width: 835px) {
  .mbpc-63 {
    margin-bottom: 63px !important;
  }
}
@media screen and (max-width: 834px) {
  .mbsp-63 {
    margin-bottom: 63px !important;
  }
}
@media screen and (min-width: 835px) {
  .mbpc-64 {
    margin-bottom: 64px !important;
  }
}
@media screen and (max-width: 834px) {
  .mbsp-64 {
    margin-bottom: 64px !important;
  }
}
@media screen and (min-width: 835px) {
  .mbpc-65 {
    margin-bottom: 65px !important;
  }
}
@media screen and (max-width: 834px) {
  .mbsp-65 {
    margin-bottom: 65px !important;
  }
}
@media screen and (min-width: 835px) {
  .mbpc-66 {
    margin-bottom: 66px !important;
  }
}
@media screen and (max-width: 834px) {
  .mbsp-66 {
    margin-bottom: 66px !important;
  }
}
@media screen and (min-width: 835px) {
  .mbpc-67 {
    margin-bottom: 67px !important;
  }
}
@media screen and (max-width: 834px) {
  .mbsp-67 {
    margin-bottom: 67px !important;
  }
}
@media screen and (min-width: 835px) {
  .mbpc-68 {
    margin-bottom: 68px !important;
  }
}
@media screen and (max-width: 834px) {
  .mbsp-68 {
    margin-bottom: 68px !important;
  }
}
@media screen and (min-width: 835px) {
  .mbpc-69 {
    margin-bottom: 69px !important;
  }
}
@media screen and (max-width: 834px) {
  .mbsp-69 {
    margin-bottom: 69px !important;
  }
}
@media screen and (min-width: 835px) {
  .mbpc-70 {
    margin-bottom: 70px !important;
  }
}
@media screen and (max-width: 834px) {
  .mbsp-70 {
    margin-bottom: 70px !important;
  }
}
@media screen and (min-width: 835px) {
  .mbpc-71 {
    margin-bottom: 71px !important;
  }
}
@media screen and (max-width: 834px) {
  .mbsp-71 {
    margin-bottom: 71px !important;
  }
}
@media screen and (min-width: 835px) {
  .mbpc-72 {
    margin-bottom: 72px !important;
  }
}
@media screen and (max-width: 834px) {
  .mbsp-72 {
    margin-bottom: 72px !important;
  }
}
@media screen and (min-width: 835px) {
  .mbpc-73 {
    margin-bottom: 73px !important;
  }
}
@media screen and (max-width: 834px) {
  .mbsp-73 {
    margin-bottom: 73px !important;
  }
}
@media screen and (min-width: 835px) {
  .mbpc-74 {
    margin-bottom: 74px !important;
  }
}
@media screen and (max-width: 834px) {
  .mbsp-74 {
    margin-bottom: 74px !important;
  }
}
@media screen and (min-width: 835px) {
  .mbpc-75 {
    margin-bottom: 75px !important;
  }
}
@media screen and (max-width: 834px) {
  .mbsp-75 {
    margin-bottom: 75px !important;
  }
}
@media screen and (min-width: 835px) {
  .mbpc-76 {
    margin-bottom: 76px !important;
  }
}
@media screen and (max-width: 834px) {
  .mbsp-76 {
    margin-bottom: 76px !important;
  }
}
@media screen and (min-width: 835px) {
  .mbpc-77 {
    margin-bottom: 77px !important;
  }
}
@media screen and (max-width: 834px) {
  .mbsp-77 {
    margin-bottom: 77px !important;
  }
}
@media screen and (min-width: 835px) {
  .mbpc-78 {
    margin-bottom: 78px !important;
  }
}
@media screen and (max-width: 834px) {
  .mbsp-78 {
    margin-bottom: 78px !important;
  }
}
@media screen and (min-width: 835px) {
  .mbpc-79 {
    margin-bottom: 79px !important;
  }
}
@media screen and (max-width: 834px) {
  .mbsp-79 {
    margin-bottom: 79px !important;
  }
}
@media screen and (min-width: 835px) {
  .mbpc-80 {
    margin-bottom: 80px !important;
  }
}
@media screen and (max-width: 834px) {
  .mbsp-80 {
    margin-bottom: 80px !important;
  }
}
@media screen and (min-width: 835px) {
  .mbpc-81 {
    margin-bottom: 81px !important;
  }
}
@media screen and (max-width: 834px) {
  .mbsp-81 {
    margin-bottom: 81px !important;
  }
}
@media screen and (min-width: 835px) {
  .mbpc-82 {
    margin-bottom: 82px !important;
  }
}
@media screen and (max-width: 834px) {
  .mbsp-82 {
    margin-bottom: 82px !important;
  }
}
@media screen and (min-width: 835px) {
  .mbpc-83 {
    margin-bottom: 83px !important;
  }
}
@media screen and (max-width: 834px) {
  .mbsp-83 {
    margin-bottom: 83px !important;
  }
}
@media screen and (min-width: 835px) {
  .mbpc-84 {
    margin-bottom: 84px !important;
  }
}
@media screen and (max-width: 834px) {
  .mbsp-84 {
    margin-bottom: 84px !important;
  }
}
@media screen and (min-width: 835px) {
  .mbpc-85 {
    margin-bottom: 85px !important;
  }
}
@media screen and (max-width: 834px) {
  .mbsp-85 {
    margin-bottom: 85px !important;
  }
}
@media screen and (min-width: 835px) {
  .mbpc-86 {
    margin-bottom: 86px !important;
  }
}
@media screen and (max-width: 834px) {
  .mbsp-86 {
    margin-bottom: 86px !important;
  }
}
@media screen and (min-width: 835px) {
  .mbpc-87 {
    margin-bottom: 87px !important;
  }
}
@media screen and (max-width: 834px) {
  .mbsp-87 {
    margin-bottom: 87px !important;
  }
}
@media screen and (min-width: 835px) {
  .mbpc-88 {
    margin-bottom: 88px !important;
  }
}
@media screen and (max-width: 834px) {
  .mbsp-88 {
    margin-bottom: 88px !important;
  }
}
@media screen and (min-width: 835px) {
  .mbpc-89 {
    margin-bottom: 89px !important;
  }
}
@media screen and (max-width: 834px) {
  .mbsp-89 {
    margin-bottom: 89px !important;
  }
}
@media screen and (min-width: 835px) {
  .mbpc-90 {
    margin-bottom: 90px !important;
  }
}
@media screen and (max-width: 834px) {
  .mbsp-90 {
    margin-bottom: 90px !important;
  }
}
@media screen and (min-width: 835px) {
  .mbpc-91 {
    margin-bottom: 91px !important;
  }
}
@media screen and (max-width: 834px) {
  .mbsp-91 {
    margin-bottom: 91px !important;
  }
}
@media screen and (min-width: 835px) {
  .mbpc-92 {
    margin-bottom: 92px !important;
  }
}
@media screen and (max-width: 834px) {
  .mbsp-92 {
    margin-bottom: 92px !important;
  }
}
@media screen and (min-width: 835px) {
  .mbpc-93 {
    margin-bottom: 93px !important;
  }
}
@media screen and (max-width: 834px) {
  .mbsp-93 {
    margin-bottom: 93px !important;
  }
}
@media screen and (min-width: 835px) {
  .mbpc-94 {
    margin-bottom: 94px !important;
  }
}
@media screen and (max-width: 834px) {
  .mbsp-94 {
    margin-bottom: 94px !important;
  }
}
@media screen and (min-width: 835px) {
  .mbpc-95 {
    margin-bottom: 95px !important;
  }
}
@media screen and (max-width: 834px) {
  .mbsp-95 {
    margin-bottom: 95px !important;
  }
}
@media screen and (min-width: 835px) {
  .mbpc-96 {
    margin-bottom: 96px !important;
  }
}
@media screen and (max-width: 834px) {
  .mbsp-96 {
    margin-bottom: 96px !important;
  }
}
@media screen and (min-width: 835px) {
  .mbpc-97 {
    margin-bottom: 97px !important;
  }
}
@media screen and (max-width: 834px) {
  .mbsp-97 {
    margin-bottom: 97px !important;
  }
}
@media screen and (min-width: 835px) {
  .mbpc-98 {
    margin-bottom: 98px !important;
  }
}
@media screen and (max-width: 834px) {
  .mbsp-98 {
    margin-bottom: 98px !important;
  }
}
@media screen and (min-width: 835px) {
  .mbpc-99 {
    margin-bottom: 99px !important;
  }
}
@media screen and (max-width: 834px) {
  .mbsp-99 {
    margin-bottom: 99px !important;
  }
}
@media screen and (min-width: 835px) {
  .mbpc-100 {
    margin-bottom: 100px !important;
  }
}
@media screen and (max-width: 834px) {
  .mbsp-100 {
    margin-bottom: 100px !important;
  }
}
@media screen and (min-width: 835px) {
  .mbpc-101 {
    margin-bottom: 101px !important;
  }
}
@media screen and (max-width: 834px) {
  .mbsp-101 {
    margin-bottom: 101px !important;
  }
}
@media screen and (min-width: 835px) {
  .mbpc-102 {
    margin-bottom: 102px !important;
  }
}
@media screen and (max-width: 834px) {
  .mbsp-102 {
    margin-bottom: 102px !important;
  }
}
@media screen and (min-width: 835px) {
  .mbpc-103 {
    margin-bottom: 103px !important;
  }
}
@media screen and (max-width: 834px) {
  .mbsp-103 {
    margin-bottom: 103px !important;
  }
}
@media screen and (min-width: 835px) {
  .mbpc-104 {
    margin-bottom: 104px !important;
  }
}
@media screen and (max-width: 834px) {
  .mbsp-104 {
    margin-bottom: 104px !important;
  }
}
@media screen and (min-width: 835px) {
  .mbpc-105 {
    margin-bottom: 105px !important;
  }
}
@media screen and (max-width: 834px) {
  .mbsp-105 {
    margin-bottom: 105px !important;
  }
}
@media screen and (min-width: 835px) {
  .mbpc-106 {
    margin-bottom: 106px !important;
  }
}
@media screen and (max-width: 834px) {
  .mbsp-106 {
    margin-bottom: 106px !important;
  }
}
@media screen and (min-width: 835px) {
  .mbpc-107 {
    margin-bottom: 107px !important;
  }
}
@media screen and (max-width: 834px) {
  .mbsp-107 {
    margin-bottom: 107px !important;
  }
}
@media screen and (min-width: 835px) {
  .mbpc-108 {
    margin-bottom: 108px !important;
  }
}
@media screen and (max-width: 834px) {
  .mbsp-108 {
    margin-bottom: 108px !important;
  }
}
@media screen and (min-width: 835px) {
  .mbpc-109 {
    margin-bottom: 109px !important;
  }
}
@media screen and (max-width: 834px) {
  .mbsp-109 {
    margin-bottom: 109px !important;
  }
}
@media screen and (min-width: 835px) {
  .mbpc-110 {
    margin-bottom: 110px !important;
  }
}
@media screen and (max-width: 834px) {
  .mbsp-110 {
    margin-bottom: 110px !important;
  }
}
@media screen and (min-width: 835px) {
  .mbpc-111 {
    margin-bottom: 111px !important;
  }
}
@media screen and (max-width: 834px) {
  .mbsp-111 {
    margin-bottom: 111px !important;
  }
}
@media screen and (min-width: 835px) {
  .mbpc-112 {
    margin-bottom: 112px !important;
  }
}
@media screen and (max-width: 834px) {
  .mbsp-112 {
    margin-bottom: 112px !important;
  }
}
@media screen and (min-width: 835px) {
  .mbpc-113 {
    margin-bottom: 113px !important;
  }
}
@media screen and (max-width: 834px) {
  .mbsp-113 {
    margin-bottom: 113px !important;
  }
}
@media screen and (min-width: 835px) {
  .mbpc-114 {
    margin-bottom: 114px !important;
  }
}
@media screen and (max-width: 834px) {
  .mbsp-114 {
    margin-bottom: 114px !important;
  }
}
@media screen and (min-width: 835px) {
  .mbpc-115 {
    margin-bottom: 115px !important;
  }
}
@media screen and (max-width: 834px) {
  .mbsp-115 {
    margin-bottom: 115px !important;
  }
}
@media screen and (min-width: 835px) {
  .mbpc-116 {
    margin-bottom: 116px !important;
  }
}
@media screen and (max-width: 834px) {
  .mbsp-116 {
    margin-bottom: 116px !important;
  }
}
@media screen and (min-width: 835px) {
  .mbpc-117 {
    margin-bottom: 117px !important;
  }
}
@media screen and (max-width: 834px) {
  .mbsp-117 {
    margin-bottom: 117px !important;
  }
}
@media screen and (min-width: 835px) {
  .mbpc-118 {
    margin-bottom: 118px !important;
  }
}
@media screen and (max-width: 834px) {
  .mbsp-118 {
    margin-bottom: 118px !important;
  }
}
@media screen and (min-width: 835px) {
  .mbpc-119 {
    margin-bottom: 119px !important;
  }
}
@media screen and (max-width: 834px) {
  .mbsp-119 {
    margin-bottom: 119px !important;
  }
}
@media screen and (min-width: 835px) {
  .mbpc-120 {
    margin-bottom: 120px !important;
  }
}
@media screen and (max-width: 834px) {
  .mbsp-120 {
    margin-bottom: 120px !important;
  }
}
@media screen and (min-width: 835px) {
  .mbpc-121 {
    margin-bottom: 121px !important;
  }
}
@media screen and (max-width: 834px) {
  .mbsp-121 {
    margin-bottom: 121px !important;
  }
}
@media screen and (min-width: 835px) {
  .mbpc-122 {
    margin-bottom: 122px !important;
  }
}
@media screen and (max-width: 834px) {
  .mbsp-122 {
    margin-bottom: 122px !important;
  }
}
@media screen and (min-width: 835px) {
  .mbpc-123 {
    margin-bottom: 123px !important;
  }
}
@media screen and (max-width: 834px) {
  .mbsp-123 {
    margin-bottom: 123px !important;
  }
}
@media screen and (min-width: 835px) {
  .mbpc-124 {
    margin-bottom: 124px !important;
  }
}
@media screen and (max-width: 834px) {
  .mbsp-124 {
    margin-bottom: 124px !important;
  }
}
@media screen and (min-width: 835px) {
  .mbpc-125 {
    margin-bottom: 125px !important;
  }
}
@media screen and (max-width: 834px) {
  .mbsp-125 {
    margin-bottom: 125px !important;
  }
}
@media screen and (min-width: 835px) {
  .mbpc-126 {
    margin-bottom: 126px !important;
  }
}
@media screen and (max-width: 834px) {
  .mbsp-126 {
    margin-bottom: 126px !important;
  }
}
@media screen and (min-width: 835px) {
  .mbpc-127 {
    margin-bottom: 127px !important;
  }
}
@media screen and (max-width: 834px) {
  .mbsp-127 {
    margin-bottom: 127px !important;
  }
}
@media screen and (min-width: 835px) {
  .mbpc-128 {
    margin-bottom: 128px !important;
  }
}
@media screen and (max-width: 834px) {
  .mbsp-128 {
    margin-bottom: 128px !important;
  }
}
@media screen and (min-width: 835px) {
  .mbpc-129 {
    margin-bottom: 129px !important;
  }
}
@media screen and (max-width: 834px) {
  .mbsp-129 {
    margin-bottom: 129px !important;
  }
}
@media screen and (min-width: 835px) {
  .mbpc-130 {
    margin-bottom: 130px !important;
  }
}
@media screen and (max-width: 834px) {
  .mbsp-130 {
    margin-bottom: 130px !important;
  }
}
@media screen and (min-width: 835px) {
  .mbpc-131 {
    margin-bottom: 131px !important;
  }
}
@media screen and (max-width: 834px) {
  .mbsp-131 {
    margin-bottom: 131px !important;
  }
}
@media screen and (min-width: 835px) {
  .mbpc-132 {
    margin-bottom: 132px !important;
  }
}
@media screen and (max-width: 834px) {
  .mbsp-132 {
    margin-bottom: 132px !important;
  }
}
@media screen and (min-width: 835px) {
  .mbpc-133 {
    margin-bottom: 133px !important;
  }
}
@media screen and (max-width: 834px) {
  .mbsp-133 {
    margin-bottom: 133px !important;
  }
}
@media screen and (min-width: 835px) {
  .mbpc-134 {
    margin-bottom: 134px !important;
  }
}
@media screen and (max-width: 834px) {
  .mbsp-134 {
    margin-bottom: 134px !important;
  }
}
@media screen and (min-width: 835px) {
  .mbpc-135 {
    margin-bottom: 135px !important;
  }
}
@media screen and (max-width: 834px) {
  .mbsp-135 {
    margin-bottom: 135px !important;
  }
}
@media screen and (min-width: 835px) {
  .mbpc-136 {
    margin-bottom: 136px !important;
  }
}
@media screen and (max-width: 834px) {
  .mbsp-136 {
    margin-bottom: 136px !important;
  }
}
@media screen and (min-width: 835px) {
  .mbpc-137 {
    margin-bottom: 137px !important;
  }
}
@media screen and (max-width: 834px) {
  .mbsp-137 {
    margin-bottom: 137px !important;
  }
}
@media screen and (min-width: 835px) {
  .mbpc-138 {
    margin-bottom: 138px !important;
  }
}
@media screen and (max-width: 834px) {
  .mbsp-138 {
    margin-bottom: 138px !important;
  }
}
@media screen and (min-width: 835px) {
  .mbpc-139 {
    margin-bottom: 139px !important;
  }
}
@media screen and (max-width: 834px) {
  .mbsp-139 {
    margin-bottom: 139px !important;
  }
}
@media screen and (min-width: 835px) {
  .mbpc-140 {
    margin-bottom: 140px !important;
  }
}
@media screen and (max-width: 834px) {
  .mbsp-140 {
    margin-bottom: 140px !important;
  }
}
.mb0 {
  margin-bottom: 0 !important;
}

/* ====================================================
reset style
==================================================== */
html {
  overflow-y: scroll;
  overflow-x: hidden;
  font-size: 10px;
  /* Base font size, change to 14px or 16px if needed */
  -ms-text-size-adjust: 100%;
  -webkit-text-size-adjust: 100%;
}

body, div, dl, dt, dd, ul, ol, li, h1, h2, h3, h4, h5, h6, pre, code, form, fieldset, legend, input, textarea, p, blockquote, th, td {
  margin: 0;
  padding: 0;
}

* {
  box-sizing: border-box;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

fieldset, img {
  border: 0;
}

img {
  vertical-align: middle;
  max-width: 100%;
}

address, caption, cite, code, dfn, var {
  font-style: normal;
  font-weight: normal;
}

li {
  list-style: none;
}

caption, th {
  text-align: left;
}

h1, h2, h3, h4, h5, h6 {
  font-size: 100%;
  font-weight: normal;
}

q:before, q:after {
  content: "";
}

abbr, acronym {
  border: 0;
  font-variant: normal;
}

sup {
  vertical-align: text-top;
}

sub {
  vertical-align: text-bottom;
}

input, textarea, select, button {
  font-family: inherit;
  font-size: inherit;
  font-weight: inherit;
  outline: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}

/*to enable resizing for IE*/
input, textarea, select {
  *font-size: 100%;
}

/*because legend doesn't inherit in IE */
legend {
  color: #000;
}

del, ins {
  text-decoration: none;
}

main {
  display: block;
}

section {
  position: relative;
}

/* ====================================================
HTML
==================================================== */
body {
  color: #1D3E62;
  font-family: "fot-tsukuardgothic-std", sans-serif;
  font-size: 100%;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  line-height: 1.4;
  overflow-x: hidden;
}

a {
  text-decoration: none;
  transition: all 0.3s ease;
}
a:hover {
  opacity: 0.7;
}
@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
  a {
    display: inline-block;
  }
}
@media screen and (min-width: 835px) {
  a[href^=tel] {
    pointer-events: none;
  }
}

.clearfix {
  *zoom: 1;
}
.clearfix:before {
  content: "";
  display: table;
}
.clearfix:after {
  clear: both;
  content: "";
  display: table;
}

/* ====================================================
Layout
==================================================== */
.wrapper {
  padding-top: 136px;
}
@media screen and (max-width: 1024px) {
  .wrapper {
    padding-top: 60px;
  }
}

.inner {
  display: block;
  max-width: 1240px;
  padding: 0 20px;
  margin: 0 auto;
}
.inner--1076 {
  max-width: 1076px;
}
.inner--1000 {
  max-width: 1040px;
}

.mv-page {
  overflow: hidden;
  z-index: 2;
}
.mv-page__ttl {
  text-align: center;
  position: relative;
  margin-bottom: 44px;
  padding-bottom: 34px;
}
@media screen and (max-width: 834px) {
  .mv-page__ttl {
    padding-bottom: 27px;
    margin-bottom: 42px;
  }
}
.mv-page__ttl:after {
  content: "";
  display: inline-block;
  position: absolute;
  width: 150px;
  height: 10px;
  background: linear-gradient(105deg, #FF82AC, #FBC900);
  border-radius: 5px;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
}
@media screen and (max-width: 834px) {
  .mv-page__ttl:after {
    height: 8px;
    width: 111px;
  }
}
.mv-page__img img {
  border-radius: 20px;
  width: 100%;
  object-fit: cover;
}
.mv-page__img--character {
  margin: 0 auto;
  width: 480px;
}
@media screen and (max-width: 834px) {
  .mv-page__img--character {
    width: 282px;
  }
}
.mv-page .c-txt__basic {
  position: relative;
  text-align: center;
  font-weight: 700;
}
@media screen and (max-width: 834px) {
  .mv-page .c-txt__basic {
    line-height: 2.083;
  }
}
.mv-page .c-txt__basic a {
  color: #1D3E62;
  text-decoration: underline;
}
.mv-page .mx-w860 {
  max-width: 860px;
  margin: 0 auto;
}
.mv-page__info {
  text-align: center;
  position: relative;
  font-weight: 700;
  margin-top: 50px;
  padding-bottom: 184px;
}
@media screen and (max-width: 834px) {
  .mv-page__info {
    margin-top: 41px;
    padding-bottom: 78px;
  }
}
.mv-page__info:before {
  content: "";
  display: inline-block;
  position: absolute;
  width: 232px;
  height: 257px;
  background: url(../img/about_eiseishi/field/bg-before.png) no-repeat center/contain;
  bottom: 33%;
  left: -9.8%;
  z-index: -1;
}
@media screen and (max-width: 834px) {
  .mv-page__info:before {
    bottom: 74%;
    left: -34px;
    width: 89px;
    height: 99px;
  }
}
.mv-page__info:after {
  content: "";
  display: inline-block;
  position: absolute;
  width: 212px;
  height: 277px;
  background: url(../img/about_eiseishi/field/bg-after.png) no-repeat center bottom/contain;
  bottom: -16px;
  right: -10.8%;
  z-index: -1;
}
@media screen and (max-width: 834px) {
  .mv-page__info:after {
    height: 107px;
    width: 82px;
    bottom: -6px;
    right: -31px;
  }
}
.mv-page__info-ttl {
  margin-bottom: 27px;
}
@media screen and (max-width: 834px) {
  .mv-page__info-ttl {
    margin-bottom: 13px;
  }
}
@media screen and (max-width: 834px) {
  .mv-page__info--ct {
    margin-top: 8px;
  }
}
.mv-page__info--ct::after, .mv-page__info--ct::before {
  content: none;
}
.mv-page__info--ct .c-txt__basic {
  position: relative;
  display: inline-block;
  padding: 0 151px 0 151px;
}
@media screen and (max-width: 1200px) {
  .mv-page__info--ct .c-txt__basic {
    padding: 0 50px;
  }
}
@media screen and (max-width: 834px) {
  .mv-page__info--ct .c-txt__basic {
    padding: 0 0;
    line-height: 2.083;
  }
}
.mv-page__info--ct .c-txt__basic:before {
  content: "";
  display: inline-block;
  position: absolute;
  width: 92px;
  height: 288px;
  background: url(../img/gakko_annai/img-l.png) no-repeat center/contain;
  bottom: -21px;
  left: 19px;
}
@media screen and (max-width: 1200px) {
  .mv-page__info--ct .c-txt__basic:before {
    left: -8%;
  }
}
@media screen and (max-width: 834px) {
  .mv-page__info--ct .c-txt__basic:before {
    content: none;
  }
}
.mv-page__info--ct .c-txt__basic:after {
  content: "";
  display: inline-block;
  position: absolute;
  width: 119px;
  height: 284px;
  background: url(../img/gakko_annai/img-r.png) no-repeat center bottom/contain;
  bottom: -19px;
  right: -1px;
}
@media screen and (max-width: 1200px) {
  .mv-page__info--ct .c-txt__basic:after {
    right: -10%;
  }
}
@media screen and (max-width: 834px) {
  .mv-page__info--ct .c-txt__basic:after {
    content: none;
  }
}
.mv-page__info--danshi {
  padding-bottom: 54px;
}
@media screen and (max-width: 834px) {
  .mv-page__info--danshi {
    padding-bottom: 39px;
  }
  .mv-page__info--danshi .mv-page__info-ttl {
    margin-bottom: 8px;
  }
}
.mv-page__info--danshi::before {
  bottom: auto;
  top: -49px;
}
@media screen and (max-width: 834px) {
  .mv-page__info--danshi::before {
    top: -41px;
    width: 100px;
    height: 104px;
  }
}
.mv-page__info--danshi::after {
  background: url(../img/nyushi/danshi/bg-after.png) no-repeat center/contain;
  width: 122px;
  height: 122px;
  bottom: 25px;
  right: -3.4%;
}
@media screen and (max-width: 834px) {
  .mv-page__info--danshi::after {
    width: 62px;
    height: 62px;
    right: -4.4%;
    bottom: 9px;
  }
}
.mv-page__info--nopen {
  margin-top: 39px;
  padding-bottom: 221px;
}
@media screen and (max-width: 834px) {
  .mv-page__info--nopen {
    margin-top: 33px;
    padding-bottom: 0;
    z-index: 1;
  }
  .mv-page__info--nopen:before {
    bottom: 61%;
    left: -32px;
    width: 96px;
    height: 106px;
    z-index: -1;
  }
}
.mv-page__info--nopen:after {
  display: none;
}
.mv-page__info--noimg:before, .mv-page__info--noimg:after {
  display: none;
}
.mv-page__info--shushoku {
  padding-bottom: 68px;
  margin-bottom: 41px;
}
@media screen and (max-width: 834px) {
  .mv-page__info--shushoku {
    padding-bottom: 106px;
    margin-bottom: 0;
  }
}
.mv-page__info--shushoku::before {
  width: 337px;
  height: 238px;
  background: url(../img/gakko_annai/shushoku/bg-after.png) no-repeat center/contain;
  bottom: -11px;
  left: -6.8%;
}
@media screen and (max-width: 834px) {
  .mv-page__info--shushoku::before {
    width: 138px;
    height: 98px;
    bottom: -5px;
    left: -69PX;
  }
}
.mv-page__info--shushoku::after {
  width: 263px;
  height: 196px;
  background: url(../img/gakko_annai/shushoku/bg-before.png) no-repeat center/contain;
  bottom: auto;
  top: -9px;
  right: -4.6%;
}
@media screen and (max-width: 834px) {
  .mv-page__info--shushoku::after {
    width: 98px;
    height: 74px;
    right: -32px;
    top: -36px;
  }
}
.mv-page__campus-01 {
  text-align: left;
  max-width: 1036px;
  margin: 0 auto;
  font-weight: 700;
  padding-bottom: 30px;
}
.mv-page__campus-01-ttl {
  text-align: left;
  font-size: 3rem;
  font-weight: 700;
}
@media screen and (max-width: 834px) {
  .mv-page__campus-01-ttl {
    font-size: 1.6rem;
    margin-bottom: 11px;
  }
}
.mv-page__campus-01 p {
  text-align: left;
}
@media screen and (max-width: 834px) {
  .mv-page__campus-01 p {
    line-height: 1.6666666667;
  }
}
.mv-page__reason {
  padding: 42px 0 48px;
}
@media screen and (max-width: 834px) {
  .mv-page__reason {
    padding: 37px 0 96px;
  }
}
.mv-page__setsubi {
  padding: 50px 0 55px;
}
.mv-page__setsubi > * {
  position: relative;
  z-index: 2;
}
@media screen and (max-width: 834px) {
  .mv-page__setsubi {
    padding: 46px 0 34px;
  }
}
@media screen and (max-width: 374px) {
  .mv-page__setsubi {
    padding-bottom: 55px;
  }
}
.mv-page__setsubi:before, .mv-page__setsubi:after {
  content: "";
  position: absolute;
}
.mv-page__setsubi:before {
  width: 192px;
  height: 219px;
  background: url(../img/campus_life/setsubi/item-01.png) no-repeat;
  background-size: 100%;
  top: 45px;
  left: -22px;
}
@media screen and (max-width: 834px) {
  .mv-page__setsubi:before {
    width: 87px;
    height: 99px;
    top: 13px;
    left: -30px;
  }
}
.mv-page__setsubi:after {
  width: 197px;
  height: 131px;
  background: url("../img/campus_life/setsubi/item-02.png") no-repeat;
  background-size: 100%;
  right: 0;
  bottom: 0;
}
@media screen and (max-width: 834px) {
  .mv-page__setsubi:after {
    width: 88px;
    height: 63px;
    right: -32px;
  }
}

.sec-mvhd {
  margin-top: -22px;
}
@media screen and (max-width: 834px) {
  .sec-mvhd {
    margin-top: -19px;
  }
}

.wave-pink-top {
  position: relative;
  background: linear-gradient(90deg, #FFDBE7, #FFEB9A);
}
@media screen and (max-width: 640px) {
  .wave-pink-top {
    background: linear-gradient(135deg, #FFDBE7, #FFEB9A);
  }
}
.wave-pink-top:before {
  content: "";
  position: absolute;
  background: url("../img/common/wave-pink.png") top center no-repeat;
  background-size: 100% 100%;
  width: 100%;
  height: 77px;
  top: -76px;
  left: 50%;
  transform: translateX(-50%);
  pointer-events: none;
}
@media screen and (max-width: 640px) {
  .wave-pink-top:before {
    background: url("../img/common/wave-pink-sp.png") top center no-repeat;
    background-size: 100% 100%;
    height: 36px;
    top: -35px;
  }
}

.wave-white-bottom {
  position: relative;
}
.wave-white-bottom:after {
  content: "";
  position: absolute;
  background: url("../img/common/wave-white.png") top center no-repeat;
  background-size: 100% 100%;
  width: 100%;
  height: 78px;
  bottom: -1px;
  left: 50%;
  transform: translateX(-50%);
  pointer-events: none;
}
@media screen and (max-width: 640px) {
  .wave-white-bottom:after {
    background: url("../img/common/wave-white-sp.png") top center no-repeat;
    background-size: 100% 100%;
    height: 36px;
  }
}

.breadcrumb {
  background: linear-gradient(105deg, #FFDBE7, #FFEB9A);
  font-family: "游ゴシック体", "Yu Gothic", YuGothic, "ヒラギノ角ゴPro W3", "Hiragino Kaku Gothic Pro", Osaka, "Meiryo", "メイリオ", "MS Pゴシック", Arial, Helvetica, Verdana, sans-serif;
  font-weight: 700;
  font-size: 16px;
  font-size: 1.6rem;
  line-height: 1.625;
  letter-spacing: 0.07rem;
  margin-bottom: 52px;
  padding: 3px 0 1px;
}
@media screen and (max-width: 834px) {
  .breadcrumb {
    font-size: 9px;
    font-size: 0.9rem;
    line-height: 1.6666666667;
    padding: 3px 0 2px;
    margin-bottom: 39px;
  }
}
.breadcrumb .inner {
  max-width: 1210px;
}
.breadcrumb a {
  color: inherit;
  display: inline-block;
  position: relative;
  padding-right: 22px;
}
@media screen and (max-width: 834px) {
  .breadcrumb a {
    padding-right: 11px;
  }
}
.breadcrumb a::after {
  content: ">";
  position: absolute;
  right: 4px;
  top: 0;
}
@media screen and (max-width: 834px) {
  .breadcrumb a::after {
    right: 2px;
  }
}
.breadcrumb--blue {
  background: linear-gradient(105deg, #9FCAFF, #E3FFBE);
}

.cm-list {
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  margin: 0 -9px;
  grid-gap: 49px 0;
}
@media screen and (max-width: 834px) {
  .cm-list {
    line-height: 2.083;
    grid-gap: 33px 0;
  }
}
.cm-list__item {
  width: 33.33%;
  padding: 0 9px;
}
@media screen and (max-width: 834px) {
  .cm-list__item {
    width: 50%;
  }
}
@media screen and (max-width: 640px) {
  .cm-list__item {
    width: 100%;
  }
}
.cm-list__wrap {
  display: block;
  border-radius: 20px;
  overflow: hidden;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16);
  background: white;
}
.cm-list__wrap p {
  color: #1D3E62;
}
.cm-list__img {
  position: relative;
}
.cm-list__img::before {
  content: "";
  padding-top: 68.8144329897%;
  display: block;
}
@media screen and (max-width: 834px) {
  .cm-list__img::before {
    padding-top: 40.7932011331%;
  }
}
.cm-list__img-thumb {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
}
.cm-list__img-thumb img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.cm-list__ttl {
  color: #E0346E;
  margin-bottom: 1px;
}
@media screen and (max-width: 834px) {
  .cm-list__ttl {
    font-size: 20px;
    font-size: 2rem;
    line-height: 1.5;
  }
}
.cm-list__ct {
  padding: 13px 21px 21px;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
}
@media screen and (min-width: 835px) {
  .cm-list__ct {
    min-height: 335px;
  }
}
@media screen and (max-width: 834px) {
  .cm-list__ct {
    padding: 14px 19px 24px;
  }
}
.cm-list__ct p {
  font-weight: 700;
}
.cm-list__ct p:not(:last-child) {
  margin-bottom: 5px;
}
@media screen and (max-width: 834px) {
  .cm-list__ct p:not(:last-child) {
    margin-bottom: 2px;
  }
}
.cm-list__ct .c-btn__02 {
  margin-top: auto;
}

.form-control {
  display: block;
  width: 100%;
  height: 45px;
  background: white;
  border: 1px solid #ccc;
  font-weight: 500;
  padding: 5px 10px;
  font-size: 1.5rem;
}
.form-control:focus {
  border-color: #66afe9;
  outline: 0;
  -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 8px rgba(102, 175, 233, 0.6);
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 8px rgba(102, 175, 233, 0.6);
}

.pure-list__input {
  position: relative;
}
.pure-list__input input {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 1;
  opacity: 0;
  cursor: pointer;
}
.pure-list__input input[type=checkbox] + span:after {
  background-color: transparent;
  top: 50%;
  left: 4px;
  width: 8px;
  height: 3px;
  margin-top: -4px;
  border-style: solid;
  border-color: #ffffff;
  border-width: 0 0 3px 3px;
  border-image: none;
  transform: rotate(-45deg) scale(0);
}
.pure-list__input input[type=checkbox]:checked + span:after {
  content: "";
  transform: rotate(-45deg) scale(1);
  transition: transform 200ms ease-out;
}
.pure-list__input input[type=checkbox]:checked + span:before {
  animation: borderscale 200ms ease-in;
  background: #4F8196;
}
.pure-list__input input[type=radio] + span:after, .pure-list__input input[type=radio] + span:before {
  border-radius: 50%;
}
.pure-list__input input[type=radio]:checked + span:before {
  animation: borderscale 300ms ease-in;
  background-color: white;
}
.pure-list__input input[type=radio]:checked + span:after {
  transform: scale(1);
}
.pure-list__input span {
  display: block;
  position: relative;
  padding: 10px 0 10px 40px;
  vertical-align: middle;
  user-select: none;
  cursor: pointer;
}
.pure-list__input span:before {
  box-sizing: content-box;
  content: "";
  color: #4F8196;
  position: absolute;
  top: 50%;
  left: 0;
  width: 14px;
  height: 14px;
  margin-top: -9px;
  border: 2px solid #4F8196;
  text-align: center;
  transition: all 0.4s ease;
}
.pure-list__input span:after {
  box-sizing: content-box;
  content: "";
  background-color: #4F8196;
  position: absolute;
  top: 50%;
  left: 4px;
  width: 10px;
  height: 10px;
  margin-top: -5px;
  transform: scale(0);
  transform-origin: 50%;
  transition: transform 200ms ease-out;
}

.table-cm {
  padding: 100px 48px 13px;
  border-radius: 20px;
  max-width: 1036px;
  margin: 0 auto;
  background: #fff;
  position: relative;
  z-index: 10;
}
@media screen and (max-width: 834px) {
  .table-cm {
    padding: 65px 20px 17px;
  }
}
.table-cm__list-item--th {
  font-size: 20px;
  font-size: 2rem;
  line-height: 1.75;
  font-weight: 700;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  padding-bottom: 15px;
  border-bottom: 2px solid #1D3E62;
}
@media screen and (max-width: 834px) {
  .table-cm__list-item--th {
    font-size: 16px;
    font-size: 1.6rem;
    line-height: 2.1875;
    padding-bottom: 13px;
    justify-content: space-between;
  }
}
.table-cm__list-itemth01 {
  width: 25.8510638298%;
}
@media screen and (max-width: 834px) {
  .table-cm__list-itemth01 {
    width: auto;
  }
}
.table-cm__list-itemth02 {
  width: 48.085106383%;
}
@media screen and (max-width: 834px) {
  .table-cm__list-itemth02 {
    display: none;
  }
}
.table-cm__list-itemth03 {
  width: 13.6170212766%;
  text-align: center;
}
@media screen and (max-width: 834px) {
  .table-cm__list-itemth03 {
    width: auto;
  }
}
.table-cm__list-itemth04 {
  width: 12.2340425532%;
  text-align: center;
  padding-left: 14px;
}
@media screen and (max-width: 834px) {
  .table-cm__list-itemth04 {
    display: none;
  }
}
.table-cm__list-item--td {
  font-family: "游ゴシック体", "Yu Gothic", YuGothic, "ヒラギノ角ゴPro W3", "Hiragino Kaku Gothic Pro", Osaka, "Meiryo", "メイリオ", "MS Pゴシック", Arial, Helvetica, Verdana, sans-serif;
  font-weight: 700;
  padding: 21px 0;
  border-bottom: 2px solid #1D3E62;
}
@media screen and (max-width: 834px) {
  .table-cm__list-item--td {
    padding: 19px 0 28px;
  }
}
.table-cm__list-item--bt .table-cm__listsub {
  color: #277AE2;
  font-weight: 700;
}
.table-cm__list-item--bt .table-cm__listsub--pink {
  color: #E0346E;
}
.table-cm__list-item--bt .table-cm__listsub-item {
  padding: 19px 0;
}
@media screen and (max-width: 834px) {
  .table-cm__list-item--bt .table-cm__listsub-item {
    padding: 19px 0;
    align-items: flex-end;
  }
  .table-cm__list-item--bt .table-cm__listsub-item .table-cm__listsub-item01 {
    font-size: 16px;
    font-size: 1.6rem;
    line-height: 1.875;
  }
}
.table-cm__list-hd {
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
}
@media screen and (max-width: 834px) {
  .table-cm__list-hd {
    font-size: 16px;
    font-size: 1.6rem;
    line-height: 1.875;
    align-items: center;
  }
}
.table-cm__list-hdl {
  width: 87.7659574468%;
}
@media screen and (max-width: 834px) {
  .table-cm__list-hdl {
    width: calc(100% - 79px);
  }
}
.table-cm__list-hdr {
  width: 12.2340425532%;
  text-align: center;
  padding-right: 24px;
  position: relative;
}
@media screen and (max-width: 834px) {
  .table-cm__list-hdr {
    width: 79px;
  }
}
.table-cm__list-ct {
  padding-top: 40px;
  line-height: 1.875;
  margin-bottom: 3px;
}
@media screen and (max-width: 834px) {
  .table-cm__list-ct {
    padding-top: 19px;
    line-height: 2.5;
    margin-bottom: -6px;
  }
}
.table-cm__listsub {
  color: #1D3E62;
  font-family: "游ゴシック体", "Yu Gothic", YuGothic, "ヒラギノ角ゴPro W3", "Hiragino Kaku Gothic Pro", Osaka, "Meiryo", "メイリオ", "MS Pゴシック", Arial, Helvetica, Verdana, sans-serif;
}
.table-cm__listsub-item {
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-justify-content: flex-end;
  -ms-flex-pack: end;
  justify-content: flex-end;
}
@media screen and (max-width: 834px) {
  .table-cm__listsub-item {
    gap: 0 3px;
  }
}
.table-cm__listsub-item01 {
  width: 48.085106383%;
}
@media screen and (max-width: 834px) {
  .table-cm__listsub-item01 {
    width: 69.0095846645%;
  }
}
.table-cm__listsub-item02 {
  width: 13.6170212766%;
  text-align: center;
}
@media screen and (max-width: 834px) {
  .table-cm__listsub-item02 {
    width: 19.1693290735%;
    text-align: right;
  }
}
.table-cm__listsub-item03 {
  width: 12.2340425532%;
  text-align: center;
  padding-left: 10px;
}
@media screen and (max-width: 834px) {
  .table-cm__listsub-item03 {
    width: 9.5846645367%;
    padding-left: 0;
    text-align: right;
  }
}

.btn-accordion {
  padding: 1px 5px;
  border-radius: 19px;
  background: #1D3E62;
  color: #fff;
  font-size: 14px;
  font-size: 1.4rem;
  line-height: 1.7857142857;
  font-weight: 700;
  position: relative;
  min-width: 79px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  transition: all 0.3s ease;
  font-family: "fot-tsukuardgothic-std", sans-serif;
}
.btn-accordion:hover {
  opacity: 0.7;
  transition: all 0.3s ease;
}
.btn-accordion:after {
  content: "";
  width: 18px;
  height: 18px;
  background: url("../img/common/img-add.png") no-repeat center center;
  background-size: 100%;
  margin-left: 5px;
  display: inline-block;
}
@media screen and (max-width: 834px) {
  .btn-accordion:after {
    width: 15px;
    height: 15px;
  }
}
.btn-accordion.on:after {
  background: url("../img/common/img-minus.png") no-repeat center center;
  background-size: 100%;
}

/* ====================================================
Control
==================================================== */
.c-fc--ff {
  color: #fff;
}
.c-fc--base {
  color: #1D3E62;
}
.c-fc--pink {
  color: #E0346E;
}
.c-fc--blue {
  color: #277AE2 !important;
}
.c-fc--purple {
  color: #B838C1;
}
.c-fc--green {
  color: #5BBE7F;
}

.c-ft--jp {
  font-family: "fot-tsukuardgothic-std", sans-serif;
}
.c-ft--base {
  font-family: "游ゴシック体", "Yu Gothic", YuGothic, "ヒラギノ角ゴPro W3", "Hiragino Kaku Gothic Pro", Osaka, "Meiryo", "メイリオ", "MS Pゴシック", Arial, Helvetica, Verdana, sans-serif;
}

.c-fw--lt {
  font-weight: 300 !important;
}
.c-fw--rg {
  font-weight: 400 !important;
}
.c-fw--md {
  font-weight: 500 !important;
}
.c-fw--sbd {
  font-weight: 600 !important;
}
.c-fw--bd {
  font-weight: 700 !important;
}
.c-fw--bk {
  font-weight: 900 !important;
}

.c-tx--v {
  -ms-writing-mode: tb-rl;
  writing-mode: vertical-rl;
}
@media screen and (min-width: 835px) {
  .c-tx--v-pc {
    -ms-writing-mode: tb-rl;
    writing-mode: vertical-rl;
  }
}

.c-al--c {
  text-align: center !important;
}
.c-al--r {
  text-align: right !important;
}
.c-al--l {
  text-align: left !important;
}

.c-val--md {
  vertical-align: middle;
}
.c-val--top {
  vertical-align: top;
}

/* ====================================================
Component
==================================================== */
.c-txt__basic {
  font-size: 16px;
  font-size: 1.6rem;
  line-height: 2.1875;
}
@media screen and (max-width: 834px) {
  .c-txt__basic {
    font-size: 12px;
    font-size: 1.2rem;
    line-height: 1.6666666667;
  }
}
.c-txt__basic--lh30 {
  line-height: 1.875;
}
.c-txt__under {
  text-decoration: underline;
}
.c-txt__hightline {
  background-image: linear-gradient(to right, #FFF8A1 50%, transparent 50%);
  background-size: 200% 23px;
  background-repeat: no-repeat;
  background-position: 0 33px;
}
@supports (-webkit-touch-callout: none) {
  .c-txt__hightline {
    background-position: 0 17px;
  }
}
@media screen and (max-width: 834px) {
  .c-txt__hightline {
    background-size: 200% 16px;
    background-position: 0 22px;
  }
  @supports (-webkit-touch-callout: none) {
    .c-txt__hightline {
      background-position: 0 12px;
    }
  }
}

.c-ttl__02 {
  font-size: 40px;
  font-size: 4rem;
  line-height: 1.75;
  font-weight: 700;
  text-align: center;
}
@media screen and (max-width: 834px) {
  .c-ttl__02 {
    font-size: 20px;
    font-size: 2rem;
    line-height: 1.5;
  }
}
@media screen and (max-width: 375px) {
  .c-ttl__02 {
    font-size: 1.8rem;
  }
}
@media screen and (max-width: 374px) {
  .c-ttl__02 {
    font-size: 1.6rem;
  }
}
.c-ttl__03 {
  font-size: 30px;
  font-size: 3rem;
  line-height: 1.6666666667;
  font-weight: 700;
  position: relative;
}
@media screen and (max-width: 834px) {
  .c-ttl__03 {
    font-size: 16px;
    font-size: 1.6rem;
    line-height: 1.25;
  }
}
.c-ttl__03--pink {
  color: #E0346E;
  position: relative;
  padding-left: 34px;
  margin-bottom: 7px;
}
@media screen and (max-width: 834px) {
  .c-ttl__03--pink {
    padding-left: 18px;
    margin-bottom: 15px;
  }
}
.c-ttl__03--pink:before {
  content: "";
  display: inline-block;
  position: absolute;
  width: 15px;
  height: 32px;
  background: #E0346E;
  left: 0;
  top: 12px;
}
@media screen and (max-width: 834px) {
  .c-ttl__03--pink:before {
    width: 8px;
    height: 17px;
    top: 3px;
  }
}
.c-ttl__03--line {
  color: #E0346E;
  padding-bottom: 26px;
  margin-bottom: 44px;
}
@media screen and (max-width: 834px) {
  .c-ttl__03--line {
    padding-bottom: 15px;
    margin-bottom: 19px;
  }
}
.c-ttl__03--line small {
  font-size: 2rem;
}
@media screen and (max-width: 834px) {
  .c-ttl__03--line small {
    font-size: 1.2rem;
  }
}
.c-ttl__03--line:after {
  content: "";
  display: inline-block;
  position: absolute;
  width: 100%;
  height: 10px;
  background: linear-gradient(105deg, #FFDBE7, #FFEB9A);
  border-radius: 5px;
  bottom: 0;
  left: 0;
}
@media screen and (max-width: 834px) {
  .c-ttl__03--line:after {
    height: 5px;
  }
}
@media screen and (max-width: 834px) {
  .c-ttl__03--sp {
    font-size: 2rem;
  }
}
.c-ttl__04 {
  font-size: 2rem;
  line-height: 1.4;
  font-weight: 700;
  color: #E0346E;
}
@media screen and (max-width: 834px) {
  .c-ttl__04 {
    font-size: 1.6rem;
  }
}
.c-ttl__04--lhb {
  line-height: 1.75;
}
@media screen and (max-width: 834px) {
  .c-ttl__04--lhb {
    line-height: 1.5625;
  }
}
.c-ttl__04 a {
  color: #E0346E;
}
.c-ttl__04 small {
  font-size: 1.6rem;
}
.c-ttl__gradient {
  text-align: center;
  font-size: 19px;
  font-size: 1.9rem;
  line-height: 1.5263157895;
  font-weight: 700;
}
@media screen and (max-width: 834px) {
  .c-ttl__gradient {
    font-size: 16px;
    font-size: 1.6rem;
    line-height: 1.8125;
  }
}
@media screen and (max-width: 834px) {
  .c-ttl__gradient {
    font-size: 1.5rem;
  }
}
.c-ttl__gradient span {
  background: linear-gradient(121deg, #ff82ac, #fbc900);
  display: inline-block;
  border-radius: 35px;
  color: white;
  padding: 8px 20px;
  position: relative;
}
@media screen and (max-width: 834px) {
  .c-ttl__gradient span {
    max-width: 313px;
    width: 100%;
  }
}
.c-ttl__gradient span:after {
  content: "";
  display: inline-block;
  position: absolute;
  width: 13px;
  height: 10px;
  background: linear-gradient(153deg, #fda558, #fcac45);
  clip-path: polygon(0 0, 50% 100%, 100% 0);
  top: 99%;
  left: 50%;
  transform: translateX(-50%);
}
.c-ttl__gradient--blue span {
  background: linear-gradient(139deg, #277ae2 0%, #fbc900 100%);
}
.c-ttl__gradient--blue span:after {
  background: linear-gradient(153deg, #a0a761, #adac53);
}
.c-ttl__gradient--large span {
  font-size: 3rem;
  padding: 14px 44px;
  letter-spacing: 3px;
}
@media screen and (max-width: 834px) {
  .c-ttl__gradient--large span {
    font-size: 2rem;
    letter-spacing: 0;
    padding: 10px 20px;
    max-width: none;
    width: auto;
  }
}
.c-ttl__gradient--large span:after {
  width: 22px;
  height: 18px;
}
@media screen and (max-width: 834px) {
  .c-ttl__gradient--large span:after {
    width: 16px;
    height: 11px;
  }
}
.c-ttl__gradient--green span {
  background: linear-gradient(139deg, #277AE2 0%, #93D142 100%);
}
.c-ttl__gradient--green span:after {
  background: linear-gradient(153deg, #62aa89, #66ad84);
}
@media screen and (max-width: 640px) {
  .c-ttl__gradient--green span {
    background: linear-gradient(139deg, #277AE2 0%, #FBC900 100%);
  }
  .c-ttl__gradient--green span:after {
    background: linear-gradient(153deg, #94A36D, #9CA665);
  }
}

.c-btn__01 {
  width: 100%;
  max-width: 251px;
  height: 50px;
  border-radius: 40px;
  background: white;
  position: relative;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  color: #E0346E;
  line-height: 1;
  font-size: 1.3rem;
  font-weight: bold;
  margin: 0 auto;
  border-bottom: 5px solid #DEDEDE;
}
@media screen and (max-width: 834px) {
  .c-btn__01 {
    border-radius: 30px;
  }
}
.c-btn__01:active {
  transform: translate(0px, 5px);
  -webkit-transform: translate(0px, 5px);
  border-bottom: 1px solid;
}
.c-btn__01 span {
  position: relative;
  padding: 0 14px 0 0;
}
.c-btn__01 span:after {
  content: "";
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 4px 0 4px 8px;
  border-color: transparent transparent transparent #E0346E;
  position: absolute;
  top: 4px;
  right: 0;
}
.c-btn__01--pink {
  max-width: 383px;
  height: 76px;
  background: #E0346E;
  color: white;
  font-size: 2rem;
  border-bottom: 7px solid #9C0F3F;
}
@media screen and (max-width: 834px) {
  .c-btn__01--pink {
    font-size: 1.6rem;
    max-width: 314px;
    height: 63px;
  }
}
.c-btn__01--pink:active {
  transform: translate(0px, 7px);
  -webkit-transform: translate(0px, 7px);
  border-bottom: 1px solid;
}
.c-btn__01--pink span {
  padding: 0 19px 0 0;
}
.c-btn__01--pink span:after {
  border-width: 7px 0 7px 12px;
  border-color: transparent transparent transparent white;
}
@media screen and (max-width: 834px) {
  .c-btn__01--pink span:after {
    border-width: 5px 0 5px 10px;
  }
}
.c-btn__01--blue {
  max-width: 383px;
  height: 76px;
  background: #277AE2;
  color: white;
  font-size: 2rem;
  border-bottom: 7px solid #0F478D;
}
@media screen and (max-width: 834px) {
  .c-btn__01--blue {
    font-size: 1.6rem;
    max-width: 314px;
    height: 63px;
  }
}
.c-btn__01--blue:active {
  transform: translate(0px, 7px);
  -webkit-transform: translate(0px, 7px);
  border-bottom: 1px solid;
}
.c-btn__01--blue span {
  padding: 0 19px 0 0;
}
.c-btn__01--blue span:after {
  border-width: 7px 0 7px 12px;
  border-color: transparent transparent transparent white;
}
@media screen and (max-width: 834px) {
  .c-btn__01--blue span:after {
    border-width: 5px 0 5px 10px;
  }
}
.c-btn__01--pastel {
  background: #FFF2F6;
  border-bottom: 5px solid #FFC5D9;
}
.c-btn__01--yellow {
  max-width: 143px;
  margin: 0;
  background: #FF9100;
  border-color: #B16500;
  font-weight: bold;
  color: #fff;
  height: 39px;
  font-size: 1.6rem;
}
@media screen and (max-width: 834px) {
  .c-btn__01--yellow {
    margin: 0 auto;
  }
}
.c-btn__01--yellow span::after {
  border-color: transparent transparent transparent #fff;
}
.c-btn__01--blue {
  background: #277AE2;
  border-bottom-color: #0F478D;
}
.c-btn__02 {
  text-align: right;
  font-size: 16px;
  font-size: 1.6rem;
  line-height: 2.1875;
  font-weight: 700;
  color: #E0346E;
  padding-right: 31px;
  position: relative;
}
@media screen and (max-width: 834px) {
  .c-btn__02 {
    font-size: 1.3rem;
    padding-right: 23px;
  }
}
.c-btn__02::after {
  content: "";
  position: absolute;
  right: 11px;
  top: 50%;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  width: 0;
  height: 0;
  border-top: 7px solid transparent;
  border-left: 11px solid #E0346E;
  border-bottom: 7px solid transparent;
}
@media screen and (max-width: 834px) {
  .c-btn__02::after {
    top: calc(50% - 1px);
    font-size: 1.4rem;
    right: 8px;
    border-width: 6px 0 6px 11px;
  }
}
.c-btn__02--normal {
  font-size: 2rem;
  padding-right: 20px;
}
@media screen and (max-width: 834px) {
  .c-btn__02--normal {
    font-size: 1.5rem;
  }
}
.c-btn__02--normal a {
  color: #1D3E62;
}
.c-btn__02--normal:after {
  font-size: 1.5rem;
  border-top: 7px solid transparent;
  border-left: 12px solid #1D3E62;
  border-bottom: 7px solid transparent;
  right: 3px;
  top: calc(50% + 1px);
}
.c-btn__02--blue {
  color: #277AE2;
  padding-left: 18px;
  padding-right: 0;
}
@media screen and (max-width: 834px) {
  .c-btn__02--blue {
    padding-left: 13px;
  }
}
.c-btn__02--blue::after {
  border-left-color: currentColor;
  right: auto;
  left: 1px;
  top: 50%;
  border-width: 7px 0 7px 10px;
}
@media screen and (max-width: 834px) {
  .c-btn__02--blue::after {
    border-width: 5px 0 5px 9px;
  }
}

.js-scrollin {
  opacity: 0;
  transform: translate(0, 70px);
}
.js-scrollin.active {
  opacity: 1;
  transform: translate(0, 0);
  transition: 0.7s cubic-bezier(0.25, 0.46, 0.45, 0.94);
}

/* ====================================================
Utility
==================================================== */
@media screen and (max-width: 834px) {
  .pc {
    display: none !important;
  }
}
@media screen and (max-width: 1024px) {
  .pc-tb {
    display: none !important;
  }
}
@media screen and (max-width: 990px) {
  .pc-lg {
    display: none !important;
  }
}
@media screen and (max-width: 640px) {
  .pc-sm {
    display: none !important;
  }
}
@media screen and (max-width: 392px) {
  .pc-xs {
    display: none !important;
  }
}

@media screen and (min-width: 835px) {
  .sp {
    display: none !important;
  }
}
@media screen and (min-width: 1025px) {
  .sp-tb {
    display: none !important;
  }
}
@media screen and (min-width: 991px) {
  .sp-lg {
    display: none !important;
  }
}
@media screen and (min-width: 641px) {
  .sp-sm {
    display: none !important;
  }
}
@media screen and (min-width: 375px) {
  .sp-xs {
    display: none !important;
  }
}

.u-fn-hover {
  display: inline-block;
}
@media (any-hover: hover) {
  .u-fn-hover {
    transition: all 0.2s;
  }
  .u-fn-hover:hover {
    opacity: 0.7;
  }
}

.u-pull--left {
  margin-left: calc((100vw - 100%) / 2 * -1);
}
.u-pull--right {
  margin-right: calc((100vw - 100%) / 2 * -1);
}

.u-img-of {
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center;
  font-family: "object-fit: cover; object-position: center", sans-serif;
}

.c-scroll-box {
  padding-bottom: 20px;
  overflow-x: scroll;
  overflow-y: hidden;
}
.c-scroll-box-y {
  padding-bottom: 20px;
  overflow-x: hidden;
  overflow-y: scroll;
}
@media screen and (max-width: 834px) {
  .c-scroll-box-sp {
    padding-bottom: 20px;
    overflow-x: scroll;
    overflow-y: hidden;
  }
}

/* ====================================================
Header
==================================================== */
#header {
  position: fixed;
  width: 100%;
  background: white;
  padding: 32px 0 24px;
  top: 0;
  left: 0;
  z-index: 999;
}
@media screen and (max-width: 1024px) {
  #header {
    height: 60px;
    padding: 22px 4px 20px;
  }
}
#header .header-logo {
  display: block;
  width: 100%;
  max-width: 412px;
  margin: 0 auto 23px;
}
@media screen and (max-width: 1024px) {
  #header .header-logo {
    max-width: 250px;
    margin: 0;
  }
}
@media screen and (max-width: 374px) {
  #header .header-logo {
    max-width: 200px;
  }
}
@media screen and (max-width: 1024px) {
  #header .header-nav {
    display: none;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    background: linear-gradient(rgba(255, 88, 144, 0.9), rgba(251, 184, 0, 0.9));
    padding: 141px 20px;
    overflow-y: scroll;
  }
  #header .header-nav.active {
    display: block;
  }
}
#header .header-nav__menu {
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
}
@media screen and (max-width: 1024px) {
  #header .header-nav__menu {
    display: block;
  }
}
#header .header-nav__menu-item {
  margin: 0 22px;
}
@media screen and (max-width: 1024px) {
  #header .header-nav__menu-item {
    margin: 0 0 43px;
  }
  #header .header-nav__menu-item:last-child {
    margin-bottom: 0;
  }
}
#header .header-nav__menu-item > a {
  display: block;
  color: black;
  font-weight: bold;
  font-size: 2rem;
  word-break: keep-all;
}
@media screen and (max-width: 1024px) {
  #header .header-nav__menu-item > a {
    text-align: center;
    color: white;
  }
}
@media screen and (min-width: 1025px) {
  #header .header-nav__menu-item--red > a {
    color: #E0346E;
  }
  #header .header-nav__menu-item--blue > a {
    color: #277AE2;
  }
}
#header .js-mobile {
  position: absolute;
  width: 40px;
  height: 40px;
  background: linear-gradient(#FF5890, #FBB800);
  border-radius: 50%;
  top: 11px;
  right: 20px;
  cursor: pointer;
  z-index: 12;
  transition: 0.3s ease;
  display: none;
}
@media screen and (max-width: 1024px) {
  #header .js-mobile {
    display: block;
  }
}
#header .js-mobile span {
  position: absolute;
  display: block;
  width: 22px;
  height: 2px;
  background: white;
  border-radius: 3px;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}
#header .js-mobile span:before, #header .js-mobile span:after {
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  background: white;
  border-radius: 3px;
  left: 0;
  transition: transform 0.3s;
}
#header .js-mobile span:before {
  top: -7px;
}
#header .js-mobile span:after {
  bottom: -7px;
}
#header .js-mobile--close {
  background: white;
}
#header .js-mobile--close span {
  background-color: transparent;
}
#header .js-mobile--close span:before, #header .js-mobile--close span:after {
  background: #E0346E;
}
#header .js-mobile--close span:before {
  transform: translateY(0) rotate(45deg);
  top: 0;
}
#header .js-mobile--close span:after {
  transform: translateY(0) rotate(-45deg);
  bottom: 0;
}

.js-locked {
  overflow: hidden;
  -ms-touch-action: none;
  touch-action: none;
}
.js-locked body {
  -webkit-overflow-scrolling: auto;
}

/* ====================================================
Footer
==================================================== */
#footer {
  border-top: 1px solid #1D3E62;
  padding: 86px 0 20px;
  background: white;
}
@media screen and (max-width: 834px) {
  #footer {
    border-top: 0;
    padding: 0 0 20px;
  }
}
#footer.nobd {
  border-top: 0;
}
@media screen and (min-width: 835px) {
  #footer .footer-menu {
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    border-bottom: 1px solid #1D3E62;
    padding-bottom: 52px;
    margin-bottom: 30px;
  }
}
#footer .footer-menu__item {
  font-family: "游ゴシック体", "Yu Gothic", YuGothic, "ヒラギノ角ゴPro W3", "Hiragino Kaku Gothic Pro", Osaka, "Meiryo", "メイリオ", "MS Pゴシック", Arial, Helvetica, Verdana, sans-serif;
  font-weight: 500;
  font-size: 1.6rem;
  letter-spacing: -0.6px;
  margin: 0 20px;
}
@media screen and (max-width: 990px) {
  #footer .footer-menu__item {
    margin: 0 11px;
  }
}
@media screen and (max-width: 834px) {
  #footer .footer-menu__item {
    font-size: 1.3rem;
    line-height: 1.41;
    letter-spacing: -0.3px;
    margin: 0 -20px;
  }
}
#footer .footer-menu__item a {
  color: #1D3E62;
}
#footer .footer-menu__sub {
  margin-bottom: 50px;
}
@media screen and (max-width: 834px) {
  #footer .footer-menu__sub {
    border-top: 1px solid #1D3E62;
    padding: 7px 10px;
    margin: 0;
  }
  #footer .footer-menu__sub.js-accorfooter .footer-menu__list {
    display: none;
    padding: 12px 10px 7px;
  }
  #footer .footer-menu__sub.js-accorfooter .footer-menu__list-item {
    border-bottom: 0;
  }
}
#footer .footer-menu__sub:last-child {
  margin-bottom: 0;
}
@media screen and (min-width: 835px) {
  #footer .footer-menu__ttl {
    position: relative;
    padding-left: 24px;
  }
  #footer .footer-menu__ttl:before {
    content: "●";
    position: absolute;
    font-size: 1rem;
    top: 5px;
    left: -2px;
  }
}
@media screen and (min-width: 835px) {
  #footer .footer-menu__list {
    position: relative;
    margin-top: 3px;
    padding: 4px 0 4px 25px;
  }
  #footer .footer-menu__list:before {
    content: "";
    position: absolute;
    background: #1D3E62;
    width: 1px;
    height: 100%;
    top: 0;
    left: 3px;
  }
  #footer .footer-menu__list:first-child {
    margin-top: -4px;
  }
}
@media screen and (max-width: 834px) {
  #footer .footer-menu__list {
    margin: 0 -10px;
  }
}
#footer .footer-menu__list-item {
  margin-bottom: 8px;
}
@media screen and (max-width: 834px) {
  #footer .footer-menu__list-item {
    border-bottom: 1px solid #1D3E62;
    padding: 0 10px 7px;
  }
}
#footer .footer-menu__list-item:last-child {
  margin-bottom: 0;
  border-bottom: 0;
}
@media screen and (max-width: 834px) {
  #footer .footer-menu__list-item:last-child {
    margin-bottom: -8px;
  }
}
#footer .footer-visitor {
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  max-width: 829px;
  margin: 0 auto 74px;
}
@media screen and (max-width: 834px) {
  #footer .footer-visitor {
    display: block;
    background: #213260;
    color: white;
    margin: 0 -20px;
    padding: 33px 25px 36px;
  }
}
#footer .footer-visitor__ttl {
  font-weight: bold;
  font-size: 1.6rem;
}
@media screen and (min-width: 835px) {
  #footer .footer-visitor__ttl {
    position: relative;
    width: 173px;
    padding-left: 15px;
  }
  #footer .footer-visitor__ttl:before {
    content: "●";
    position: absolute;
    font-size: 1rem;
    top: 5px;
    left: 0;
  }
}
@media screen and (max-width: 834px) {
  #footer .footer-visitor__ttl {
    font-size: 1.7rem;
  }
}
#footer .footer-visitor__ct {
  width: calc(100% - 173px);
}
@media screen and (max-width: 834px) {
  #footer .footer-visitor__ct {
    width: 100%;
    margin-top: 13px;
  }
}
#footer .footer-visitor__ct a {
  position: relative;
  display: inline-block;
  color: #1D3E62;
  font-family: "游ゴシック体", "Yu Gothic", YuGothic, "ヒラギノ角ゴPro W3", "Hiragino Kaku Gothic Pro", Osaka, "Meiryo", "メイリオ", "MS Pゴシック", Arial, Helvetica, Verdana, sans-serif;
  font-weight: 500;
  font-size: 1.6rem;
  line-height: 1.6;
  margin-right: 41px;
}
@media screen and (min-width: 835px) {
  #footer .footer-visitor__ct a:after {
    content: "";
    position: absolute;
    background: #1D3E62;
    width: 1px;
    height: 12px;
    top: 6px;
    right: -23px;
  }
}
@media screen and (max-width: 834px) {
  #footer .footer-visitor__ct a {
    display: block;
    color: white;
    font-size: 1.35rem;
    margin-bottom: 6px;
  }
  #footer .footer-visitor__ct a:last-child {
    margin-bottom: 0;
  }
}
#footer .footer-contact {
  position: relative;
  text-align: center;
  padding: 55px 0 62px;
  margin-bottom: 14px;
  z-index: 1;
}
@media screen and (max-width: 834px) {
  #footer .footer-contact {
    padding: 38px 0 43px;
    margin-bottom: 12px;
  }
}
#footer .footer-contact:before {
  content: "";
  position: absolute;
  background: linear-gradient(90deg, #FFDBE7, #FFEB9A);
  width: 100vw;
  height: 100%;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  z-index: -1;
  pointer-events: none;
}
@media screen and (max-width: 834px) {
  #footer .footer-contact:before {
    background: linear-gradient(135deg, #FFDBE7, #FFEB9A);
  }
}
#footer .footer-contact__txt {
  font-weight: bold;
  font-size: 2rem;
  margin-bottom: 45px;
}
@media screen and (max-width: 834px) {
  #footer .footer-contact__txt {
    margin-bottom: 34px;
  }
  #footer .footer-contact__txt span {
    font-size: 1.2rem;
  }
}
#footer .footer-contact__tel {
  position: relative;
  display: block;
  max-width: 440px;
  color: #1D3E62;
  font-weight: bold;
  font-size: 5rem;
  padding-left: 58px;
  margin: -36px auto 25px;
}
@media screen and (max-width: 834px) {
  #footer .footer-contact__tel {
    max-width: 324px;
    font-size: 3.6rem;
    padding-left: 38px;
    margin: -27px auto 5px;
  }
}
@media screen and (max-width: 374px) {
  #footer .footer-contact__tel {
    max-width: 275px;
    font-size: 3rem;
  }
}
#footer .footer-contact__tel:before {
  content: "";
  position: absolute;
  background: url("../img/common/icon-tel.svg") center no-repeat;
  background-size: contain;
  width: 43px;
  height: 72px;
  top: -1px;
  left: 6px;
}
@media screen and (max-width: 834px) {
  #footer .footer-contact__tel:before {
    width: 31px;
    height: 52px;
  }
}
@media screen and (max-width: 374px) {
  #footer .footer-contact__tel:before {
    top: -6px;
  }
}
#footer .footer-txt {
  text-align: center;
  font-weight: bold;
  font-size: 10px;
  font-size: 1rem;
  line-height: 2;
}
@media screen and (max-width: 834px) {
  #footer .footer-txt {
    font-size: 9px;
    font-size: 0.9rem;
    line-height: 2.2222222222;
    margin: 0 -10px;
  }
}
#footer .footer-txt a {
  color: #1D3E62;
}

/* ====================================================
PAGE INDEX
==================================================== */
.sec-mv {
  position: relative;
  z-index: 2;
}
.sec-mv__img {
  border-radius: 25px;
  box-shadow: rgba(0, 0, 0, 0.2) 0 6px 6px;
  overflow: hidden;
}
@media screen and (max-width: 640px) {
  .sec-mv__img {
    border-radius: 10px;
  }
}

.sec-intro {
  padding: 188px 0;
  margin-top: -154px;
}
@media screen and (max-width: 834px) {
  .sec-intro {
    padding: 217px 0 174px;
    margin-top: -195px;
  }
}

.intro-list {
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  margin-bottom: 70px;
}
@media screen and (max-width: 834px) {
  .intro-list {
    margin-bottom: 41px;
  }
}
.intro-list__item {
  width: 48.3%;
}
@media screen and (max-width: 640px) {
  .intro-list__item {
    width: 100%;
    margin-bottom: 15px;
  }
  .intro-list__item:last-child {
    margin-bottom: 0;
  }
}
.intro-list__item a {
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-align-items: flex-end;
  -ms-flex-align: end;
  align-items: flex-end;
  width: 100%;
  height: 100%;
}
.intro-list__item a img {
  width: 100%;
}

.intro-main {
  position: relative;
  display: block;
}
.intro-main:after {
  content: "";
  position: absolute;
  background: url("../img/index/intro-shape.svg") center no-repeat;
  background-size: contain;
  width: 128px;
  height: 119px;
  top: -123px;
  left: calc(50% + 343px);
  transform: translateX(-50%);
  pointer-events: none;
}
@media screen and (max-width: 834px) {
  .intro-main:after {
    width: 75px;
    height: 71px;
    top: -30px;
    right: -3px;
    left: auto;
    transform: unset;
  }
}
.intro-main img {
  width: 100%;
}

.sec-news {
  background: url("../img/index/bg-news-pc.png") center top no-repeat;
  background-size: cover;
  padding: 151px 0;
  margin-top: -116px;
}
@media screen and (max-width: 640px) {
  .sec-news {
    background: url("../img/index/bg-news-sp.png") center top no-repeat;
    background-size: cover;
    padding: 82px 0;
  }
}

.news-ttl {
  text-align: center;
  margin-bottom: 49px;
}
@media screen and (max-width: 834px) {
  .news-ttl {
    margin-bottom: 30px;
  }
}
.news-ttl small {
  display: block;
  margin-bottom: 20px;
}
@media screen and (max-width: 834px) {
  .news-ttl small {
    margin-bottom: 12px;
  }
}

.news-list {
  margin-bottom: 11px;
}
@media screen and (max-width: 834px) {
  .news-list {
    margin-bottom: 44px;
  }
}
.news-list__item {
  margin-bottom: 19px;
}
@media screen and (max-width: 834px) {
  .news-list__item {
    margin-bottom: 12px;
  }
}
.news-list__item:last-child {
  margin-bottom: 0;
}
.news-list__item a {
  display: block;
  background: white;
  color: #1D3E62;
  border-radius: 20px;
  font-weight: bold;
  font-size: 12px;
  font-size: 1.2rem;
  line-height: 1.6666666667;
  padding: 11px 31px 17px 18px;
}
@media screen and (max-width: 640px) {
  .news-list__item a {
    padding: 14px 20px 18px;
  }
}
.news-list__item p {
  display: -webkit-box;
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  max-height: 20px;
}
@media screen and (max-width: 640px) {
  .news-list__item p {
    -webkit-line-clamp: 3;
    max-height: 60px;
  }
}
.news-list__info {
  position: relative;
  color: #E0346E;
  border-bottom: 1px solid #E0346E;
  font-weight: bold;
  font-size: 2rem;
  padding: 0 95px 9px 114px;
  margin-bottom: 18px;
}
@media screen and (max-width: 640px) {
  .news-list__info {
    font-size: 1.6rem;
    padding: 31px 0 6px;
    margin-bottom: 4px;
  }
}
.news-list__info-tag {
  position: absolute;
  text-align: center;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  width: 94px;
  height: 26px;
  background: #E0346E;
  color: white;
  border-radius: 13px;
  font-weight: bold;
  font-size: 1.5rem;
  line-height: 1;
  padding-bottom: 4px;
  top: 5px;
  left: 0;
}
@media screen and (max-width: 640px) {
  .news-list__info-tag {
    width: 74px;
    height: 20px;
    font-size: 1.1rem;
    padding-bottom: 1px;
    top: 7px;
  }
}
.news-list__info-date {
  position: absolute;
  width: max-content;
  color: #1D3E62;
  font-weight: bold;
  font-size: 1.5rem;
  line-height: 1;
  top: 11px;
  right: 0;
}
@media screen and (max-width: 640px) {
  .news-list__info-date {
    font-size: 1.2rem;
  }
}

.event-list {
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  margin: 0 -10px 11px;
}
@media screen and (max-width: 1024px) {
  .event-list {
    margin: 0 -8px -10px;
  }
}
.event-list__item {
  width: calc(25% - 20px);
  background: white;
  border-radius: 20px;
  overflow: hidden;
  margin: 0 10px;
}
@media screen and (max-width: 1024px) {
  .event-list__item {
    width: calc(50% - 16px);
    border-radius: 15px;
    margin: 0 8px 12px;
  }
}
.event-list__ttl {
  position: relative;
  background: linear-gradient(90deg, #FF82AC, #FFDA43);
  font-weight: bold;
  font-size: 1.8rem;
  padding: 16px 10px 15px 125px;
}
@media screen and (max-width: 640px) {
  .event-list__ttl {
    font-size: 1.2rem;
    padding: 11px 10px 11px 87px;
  }
}
@media screen and (max-width: 374px) {
  .event-list__ttl {
    padding: 11px 6px 8px 64px;
  }
}
.event-list__ttl span {
  position: absolute;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  width: 89px;
  height: 32px;
  background: white;
  border-radius: 18px;
  font-weight: bold;
  top: 50%;
  left: 26px;
  transform: translateY(-50%);
}
@media screen and (max-width: 640px) {
  .event-list__ttl span {
    width: 62px;
    height: 22px;
    left: 18px;
  }
}
@media screen and (max-width: 374px) {
  .event-list__ttl span {
    width: 53px;
    height: 19px;
    left: 7px;
  }
}
.event-list__ct {
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  font-weight: bold;
  font-size: 13px;
  font-size: 1.3rem;
  line-height: 1.6923076923;
  padding: 10px 8px 17px;
}
@media screen and (max-width: 640px) {
  .event-list__ct {
    font-size: 12px;
    font-size: 1.2rem;
    line-height: 1.25;
    padding: 8px 8px 14px;
  }
}
.event-list__ct p span {
  display: inline-block;
}
@media screen and (max-width: 834px) {
  .event-list__ct p span {
    display: block;
  }
}
.event-list__date {
  position: relative;
  display: inline-block;
  height: 86px;
  font-weight: bold;
  font-size: 4.4rem;
  margin-right: 70px;
  line-height: 1;
}
@media screen and (max-width: 640px) {
  .event-list__date {
    height: 57px;
    font-size: 3rem;
    margin-right: 53px;
  }
}
@media screen and (max-width: 374px) {
  .event-list__date {
    margin-right: 42px;
  }
}
.event-list__date:before {
  content: "";
  position: absolute;
  width: 1px;
  height: 74px;
  background: #1D3E62;
  top: 7px;
  left: calc(100% + 1px);
  transform: rotate(43deg);
}
@media screen and (max-width: 640px) {
  .event-list__date:before {
    height: 50px;
    top: 4px;
  }
}
.event-list__date span {
  position: absolute;
  top: 37px;
  left: calc(100% + 5px);
}
@media screen and (max-width: 640px) {
  .event-list__date span {
    top: 25px;
  }
}

.sec-pickup {
  position: relative;
  background: url("../img/index/bg-pickup-pc.png") center no-repeat;
  background-size: 100% 100%;
  padding: 129px 0 179px;
  margin: -114px 0 -78px;
  z-index: 1;
}
@media screen and (max-width: 1400px) {
  .sec-pickup {
    background-size: cover;
  }
}
@media screen and (max-width: 640px) {
  .sec-pickup {
    background: url("../img/index/bg-pickup-sp.png") center no-repeat;
    background-size: 100% 100%;
    padding: 74px 0 100px;
    margin: -58px 0 -78px;
  }
}
.sec-pickup .inner {
  position: relative;
}
.sec-pickup .inner:before {
  content: "";
  position: absolute;
  background: url("../img/index/pickup-shape1.png") center no-repeat;
  background-size: contain;
  width: 145px;
  height: 134px;
  top: -79px;
  left: -74px;
  z-index: 1;
}
@media screen and (max-width: 1200px) {
  .sec-pickup .inner:before {
    left: 0;
  }
}
@media screen and (max-width: 640px) {
  .sec-pickup .inner:before {
    width: 84px;
    height: 77px;
    top: -58px;
    left: 20px;
  }
}
.sec-pickup .inner:after {
  content: "";
  position: absolute;
  background: url("../img/index/pickup-shape2.png") center no-repeat;
  background-size: contain;
  width: 109px;
  height: 200px;
  bottom: -260px;
  right: -62px;
  z-index: 1;
}
@media screen and (max-width: 1200px) {
  .sec-pickup .inner:after {
    right: 0;
  }
}
@media screen and (max-width: 640px) {
  .sec-pickup .inner:after {
    width: 51px;
    height: 94px;
    bottom: -134px;
    right: 20px;
  }
}

.pickup-list {
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  margin: 0 -11px;
}
.pickup-list__item {
  width: 33.333%;
  padding: 0 11px;
}
@media screen and (max-width: 640px) {
  .pickup-list__item {
    width: 100%;
    margin-bottom: 24px;
  }
  .pickup-list__item:last-child {
    margin-bottom: 0;
  }
}
.pickup-list__item a {
  display: block;
}

.sec-chosen {
  background: #FFF2F6 url("../img/index/bg-chosen.png") -77px -32px repeat;
  background-size: 188px 290px;
  padding: 149px 0 150px;
}
@media screen and (max-width: 834px) {
  .sec-chosen {
    background-size: 125px 176px;
    padding: 142px 0 86px;
  }
}
.sec-chosen .inner {
  position: relative;
}
.sec-chosen .inner:after {
  content: "";
  position: absolute;
  background: url("../img/index/chosen-shape1.png") center no-repeat;
  background-size: contain;
  width: 215px;
  height: 216px;
  bottom: -262px;
  right: -33px;
  z-index: 1;
}
@media screen and (max-width: 1306px) {
  .sec-chosen .inner:after {
    right: 0;
  }
}
@media screen and (max-width: 834px) {
  .sec-chosen .inner:after {
    width: 92px;
    height: 100px;
    bottom: -128px;
    right: 20px;
  }
}

.chosen-list {
  position: relative;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  max-width: 885px;
  margin: 0 auto -7px;
}
@media screen and (max-width: 834px) {
  .chosen-list {
    margin-bottom: 55px;
  }
}
.chosen-list:after {
  content: "";
  position: absolute;
  background: url("../img/index/chosen-shape3.png") center no-repeat;
  background-size: contain;
  width: 137px;
  height: 359px;
  bottom: -36px;
  left: -78px;
  z-index: 1;
}
@media screen and (max-width: 1024px) {
  .chosen-list:after {
    width: 100px;
    left: -35px;
  }
}
@media screen and (max-width: 640px) {
  .chosen-list:after {
    width: 54px;
    height: 140px;
    bottom: -151px;
    left: 19px;
  }
}
@media screen and (max-width: 374px) {
  .chosen-list:after {
    left: 0;
  }
}
.chosen-list__item {
  width: calc(50% - 32px);
  background: white;
  border-radius: 20px;
  font-size: 15px;
  font-size: 1.5rem;
  line-height: 1.6666666667;
  padding: 45px 25px 43px 38px;
  margin-bottom: 57px;
}
@media screen and (max-width: 1024px) {
  .chosen-list__item {
    width: calc(50% - 10px);
    padding: 25px 20px;
    margin-bottom: 20px;
  }
}
@media screen and (max-width: 834px) {
  .chosen-list__item {
    font-size: 12px;
    font-size: 1.2rem;
    line-height: 2.0833333333;
  }
  .chosen-list__item:first-child .chosen-list__ttl {
    font-size: 3.8rem;
  }
  .chosen-list__item:first-child .chosen-list__ttl > span small {
    font-size: 2.9rem;
  }
  .chosen-list__item:first-child .chosen-list__ttl > span strong {
    font-size: 4.8rem;
  }
  .chosen-list__item:nth-child(3) .chosen-list__ttl {
    font-size: 2.8rem;
  }
  .chosen-list__item:nth-child(3) .chosen-list__ttl > span {
    background-size: 200% 20px;
    padding-bottom: 1px;
  }
  .chosen-list__item:nth-child(3) .chosen-list__ttl > span strong {
    font-size: 3.5rem;
  }
  .chosen-list__item:nth-child(4) .chosen-list__ttl {
    font-size: 3.2rem;
  }
}
@media screen and (max-width: 834px) and (max-width: 640px) {
  .chosen-list__item:nth-child(4) .chosen-list__ttl {
    padding: 0 31px 0 8px;
    text-align: left;
  }
}
@media screen and (max-width: 834px) and (max-width: 640px) and (max-width: 374px) {
  .chosen-list__item:nth-child(4) .chosen-list__ttl {
    font-size: 2.8rem;
    padding-left: 0;
  }
}
@media screen and (max-width: 834px) and (max-width: 640px) {
  .chosen-list__item:nth-child(4) .chosen-list__ttl:after {
    content: "";
    position: absolute;
    background: url("../img/index/chosen-shape2.png") center no-repeat;
    background-size: contain;
    width: 32px;
    height: 46px;
    top: 22px;
    left: 286px;
  }
}
@media screen and (max-width: 834px) and (max-width: 640px) and (max-width: 374px) {
  .chosen-list__item:nth-child(4) .chosen-list__ttl:after {
    top: 17px;
    left: 222px;
  }
}
@media screen and (max-width: 834px) {
  .chosen-list__item:nth-child(4) .chosen-list__ttl > span {
    background-size: 200% 20px;
    letter-spacing: -1px;
  }
  .chosen-list__item:nth-child(4) .chosen-list__ttl > span small {
    font-size: 2.4rem;
  }
}
@media screen and (max-width: 834px) and (max-width: 374px) {
  .chosen-list__item:nth-child(4) .chosen-list__ttl > span small {
    font-size: 1.8rem;
  }
}
@media screen and (max-width: 834px) and (max-width: 640px) {
  .chosen-list__item:nth-child(5) .chosen-list__ttl > small {
    margin-bottom: 1px;
  }
}
@media screen and (max-width: 834px) {
  .chosen-list__item:nth-child(5) .chosen-list__ttl > span {
    padding-bottom: 0;
    background-size: 200% 15px;
  }
  .chosen-list__item:nth-child(5) .chosen-list__ttl > span strong {
    font-size: 3rem;
  }
}
@media screen and (max-width: 834px) {
  .chosen-list__item:nth-child(6) .chosen-list__ttl {
    font-size: 2.6rem;
  }
  .chosen-list__item:nth-child(6) .chosen-list__ttl > span strong {
    font-size: 3.3rem;
  }
}
@media screen and (max-width: 640px) {
  .chosen-list__item {
    width: 100%;
    padding: 28px 22px 25px;
    margin-bottom: 22px;
  }
  .chosen-list__item:last-child {
    margin-bottom: 0;
  }
}
@media screen and (min-width: 641px) {
  .chosen-list__item--center {
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    min-height: 304px;
  }
}
@media screen and (min-width: 641px) and (max-width: 834px) {
  .chosen-list__item--center {
    min-height: 225px;
  }
}
@media screen and (min-width: 641px) {
  .chosen-list__item--center .chosen-list__ttl > small {
    margin-bottom: 17px;
  }
  .chosen-list__item--center .chosen-list__ttl > span {
    letter-spacing: -1.6px;
  }
  .chosen-list__item--center .chosen-list__ttl > span strong {
    letter-spacing: 0;
  }
}
@media screen and (max-width: 640px) {
  .chosen-list__item--center {
    padding: 17px 22px 21px;
  }
}
.chosen-list__item--center .chosen-list__ttl {
  margin-bottom: 0;
}
.chosen-list__item p {
  font-family: "游ゴシック体", "Yu Gothic", YuGothic, "ヒラギノ角ゴPro W3", "Hiragino Kaku Gothic Pro", Osaka, "Meiryo", "メイリオ", "MS Pゴシック", Arial, Helvetica, Verdana, sans-serif;
  font-weight: 500;
}
.chosen-list__ttl {
  position: relative;
  color: #FF9100;
  font-weight: bold;
  font-size: 4rem;
  line-height: 1.22;
  margin-bottom: 22px;
}
@media screen and (max-width: 640px) {
  .chosen-list__ttl {
    text-align: center;
    margin: 0 -10px 22px;
  }
}
.chosen-list__ttl > small {
  display: block;
  color: #1D3E62;
  font-weight: bold;
  font-size: 2rem;
  margin-bottom: 9px;
}
@media screen and (max-width: 834px) {
  .chosen-list__ttl > small {
    font-size: 1.6rem;
    margin-bottom: 2px;
  }
}
@media screen and (max-width: 374px) {
  .chosen-list__ttl > small {
    font-size: 1.4rem;
  }
}
.chosen-list__ttl > span {
  background-image: linear-gradient(to right, #FFF8A1 50%, transparent 50%);
  background-size: 200% 23px;
  background-repeat: no-repeat;
  background-position: 0 bottom;
  padding-bottom: 2px;
}
@media screen and (max-width: 834px) {
  .chosen-list__ttl > span {
    padding-bottom: 5px;
  }
}
.chosen-list__ttl > span small {
  font-size: 3rem;
}
.chosen-list__ttl > span strong {
  font-size: 5rem;
}
@media screen and (max-width: 640px) {
  .chosen-list__ttl--light > small {
    margin-bottom: 9px;
  }
}
.chosen-list__ttl--light:after {
  content: "";
  position: absolute;
  background: url("../img/index/chosen-shape4.png") center no-repeat;
  background-size: contain;
  width: 70px;
  height: 57px;
  top: 1px;
  left: calc(50% + 132px);
  transform: translateX(-50%);
}
@media screen and (max-width: 834px) {
  .chosen-list__ttl--light:after {
    width: 43px;
    height: 35px;
    top: auto;
    bottom: 10px;
    left: calc(50% + 124px);
  }
}
@media screen and (max-width: 374px) {
  .chosen-list__ttl--light:after {
    right: -6px;
  }
}
.chosen-list__img {
  text-align: center;
  padding: 10px 12px 0 0;
}

.sec-catalogue {
  padding: 89px 0 168px;
}
@media screen and (max-width: 834px) {
  .sec-catalogue {
    margin-bottom: 56px;
  }
}
@media screen and (max-width: 640px) {
  .sec-catalogue {
    padding: 57px 0 69px;
  }
}

.catalogue-list {
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  margin: 0 -11px 69px;
}
@media screen and (max-width: 640px) {
  .catalogue-list {
    margin: 0 -8px 28px;
  }
}
.catalogue-list__item {
  width: 25%;
  padding: 0 11px;
}
@media screen and (max-width: 640px) {
  .catalogue-list__item {
    width: 50%;
    padding: 0 8px;
    margin-bottom: 13px;
  }
}
.catalogue-list__item a {
  text-align: center;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  background: white;
  color: #F9A6C2;
  width: 100%;
  height: 169px;
  border-radius: 20px;
  font-weight: bold;
  font-size: 1.6rem;
  letter-spacing: 1.5px;
}
@media screen and (max-width: 834px) {
  .catalogue-list__item a {
    height: 117px;
    border-radius: 15px;
    font-size: 1.2rem;
  }
}
.catalogue-list__item a p {
  width: 100%;
}
.catalogue-list__item a span {
  display: block;
  color: #E0346E;
  font-size: 3rem;
  letter-spacing: 3px;
}
@media screen and (max-width: 834px) {
  .catalogue-list__item a span {
    font-size: 2rem;
    letter-spacing: 2px;
    margin-bottom: 1px;
  }
}

.catalogue-logo {
  display: block;
  max-width: 225px;
  margin: 0 auto 58px;
}
@media screen and (max-width: 834px) {
  .catalogue-logo {
    margin-bottom: 42px;
  }
}

.catalogue-store {
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  margin: 0 -10px;
}
@media screen and (max-width: 640px) {
  .catalogue-store {
    margin: 0 -8px;
  }
}
.catalogue-store__item {
  width: 25%;
  padding: 0 11px;
}
@media screen and (max-width: 640px) {
  .catalogue-store__item {
    width: 50%;
    padding: 0 8px;
    margin-bottom: 17px;
  }
}

@media screen and (min-width: 835px) {
  #page-campus_life_qa .mv-page__ttl {
    padding-bottom: 27px;
  }
}

.sec-int {
  padding-bottom: 66px;
}
@media screen and (max-width: 834px) {
  .sec-int {
    padding-bottom: 39px;
  }
}

.int-block__item {
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  margin-bottom: 31px;
}
@media screen and (max-width: 834px) {
  .int-block__item {
    margin-bottom: 20px;
  }
}
.int-block__item--02 {
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
}
.int-block__item--02 .int-block__ct {
  width: calc(61.5% - 47px);
}
@media screen and (min-width: 1201px) {
  .int-block__item--02 .int-block__ct .c-ttl__03 {
    margin-right: -15px;
  }
}
@media screen and (max-width: 640px) {
  .int-block__item--02 .int-block__ct {
    width: 100%;
  }
  .int-block__item--02 .int-block__ct img {
    padding-top: 6px;
    margin-bottom: 17px;
  }
}
.int-block__item--02 .int-block__img {
  width: 38.5%;
  padding-top: 11px;
}
@media screen and (max-width: 640px) {
  .int-block__item--02 .int-block__img {
    width: 100%;
  }
}
.int-block__item--03 {
  display: block;
  margin-bottom: 55px;
}
@media screen and (max-width: 834px) {
  .int-block__item--03 {
    margin-bottom: 43px;
  }
}
@media screen and (max-width: 640px) {
  .int-block__item--03 .c-ttl__03 {
    margin-bottom: 20px;
  }
}
.int-block__item--03 .int-block__img {
  width: 38.5%;
  padding-top: 11px;
  display: inline-block;
  float: right;
  margin-left: 30px;
}
@media screen and (max-width: 640px) {
  .int-block__item--03 .int-block__img {
    width: 100%;
  }
}
.int-block__item--03 .int-block__ct {
  width: auto;
}
.int-block__item--03 .int-block__ct p {
  margin-bottom: 18px;
}
@media screen and (max-width: 640px) {
  .int-block__item--03 .int-block__ct img {
    margin-bottom: 17px;
  }
}
.int-block__item--04 {
  display: block;
}
.int-block__item--04 .int-block__img {
  width: 38.5%;
  display: inline-block;
  float: left;
  margin-right: 30px;
  margin-bottom: 10px;
}
@media screen and (max-width: 640px) {
  .int-block__item--04 .int-block__img {
    width: 100%;
  }
}
.int-block__item--04 .int-block__ct {
  width: auto;
}
@media screen and (min-width: 1201px) {
  .int-block__item--04 .int-block__ct {
    margin-right: -8%;
  }
}
.int-block__item--04 .int-block__ct .c-ttl__03 {
  display: grid;
  margin-bottom: 40px;
}
@media screen and (max-width: 640px) {
  .int-block__item--04 .int-block__ct img {
    margin-bottom: 17px;
  }
}
.int-block__item--05 .int-block__ct-img {
  width: 38.5%;
  float: right;
  margin-left: 30px;
}
@media screen and (max-width: 640px) {
  .int-block__item--05 .int-block__ct-img {
    width: 100%;
    margin-bottom: 17px;
  }
}
.int-block p {
  font-weight: 700;
  font-family: "游ゴシック体", "Yu Gothic", YuGothic, "ヒラギノ角ゴPro W3", "Hiragino Kaku Gothic Pro", Osaka, "Meiryo", "メイリオ", "MS Pゴシック", Arial, Helvetica, Verdana, sans-serif;
  margin-bottom: 30px;
}
.int-block p:last-child {
  margin-bottom: 12px;
}
.int-block--02 p {
  margin-bottom: 13px;
}
@media screen and (max-width: 834px) {
  .int-block--02 p {
    margin-bottom: 18px;
  }
}

.sec-setsubi {
  padding: 50px 0 0;
}
@media screen and (max-width: 834px) {
  .sec-setsubi {
    padding: 12px 0 0;
  }
}

.setsubi-note {
  position: relative;
  font-weight: bold;
  margin-bottom: 40px;
}
@media screen and (max-width: 834px) {
  .setsubi-note {
    line-height: 2.0833333333;
    margin-bottom: 18px;
  }
}
.setsubi-note p {
  margin-bottom: 21px;
}
.setsubi-note p:last-child {
  margin-bottom: 0;
}
@media screen and (max-width: 834px) {
  .setsubi-note .c-ttl__03 {
    line-height: 1.5;
  }
}
.setsubi-note__list {
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  margin: 0 -8px;
  text-align: center;
  position: relative;
}
@media screen and (max-width: 834px) {
  .setsubi-note__list {
    margin: 0 -4px;
  }
  .setsubi-note__list:after {
    content: "";
    width: 80px;
    height: 100px;
    background: url(../img/campus_life/setsubi/item-04.png) no-repeat;
    background-size: 100%;
    background-position: bottom;
    position: absolute;
    bottom: -7px;
    right: -17px;
  }
}
.setsubi-note__list-item {
  width: 33.333%;
  padding: 0 8px;
  position: relative;
  z-index: 2;
}
@media screen and (max-width: 834px) {
  .setsubi-note__list-item {
    padding: 0 4px;
    width: 50%;
    margin-bottom: 6px;
  }
}
.setsubi-note__list img {
  width: 100%;
  border-radius: 20px;
  margin-bottom: 8px;
}
@media screen and (max-width: 834px) {
  .setsubi-note__list img {
    margin-bottom: 0;
  }
}
.setsubi-note__list span {
  display: block;
  font-size: 2rem;
  font-weight: bold;
}
@media screen and (max-width: 834px) {
  .setsubi-note__list span {
    font-size: 1.2rem;
  }
}
.setsubi-note--item {
  padding: 0 0 84px;
  margin-bottom: 22px;
}
@media screen and (max-width: 834px) {
  .setsubi-note--item {
    padding: 0 0 96px;
    margin-bottom: 0;
  }
}
.setsubi-note--item > * {
  position: relative;
  z-index: 2;
}
.setsubi-note--item:after {
  content: "";
  width: 130px;
  height: 152px;
  background: url(../img/campus_life/setsubi/item-05.png) no-repeat;
  background-size: 100%;
  background-position: bottom;
  position: absolute;
  bottom: 0;
  left: -131px;
}
@media screen and (max-width: 834px) {
  .setsubi-note--item:after {
    width: 82px;
    height: 103px;
    left: -36px;
  }
}

.setsubi-list {
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  margin: 0 -16px 37px;
}
@media screen and (max-width: 834px) {
  .setsubi-list {
    margin: 0 -4px 33px;
  }
}
.setsubi-list__item {
  width: 50%;
  padding: 0 16px;
  position: relative;
  margin-bottom: 56px;
}
.setsubi-list__item:nth-child(1) {
  width: 100%;
}
.setsubi-list__item:nth-child(1):before {
  content: "";
  width: 385px;
  height: 443px;
  background: url(../img/campus_life/setsubi/item-03.png) no-repeat;
  background-size: 100%;
  position: absolute;
  top: 160px;
  left: -186px;
}
@media screen and (max-width: 834px) {
  .setsubi-list__item:nth-child(1):before {
    width: 246px;
    height: 283px;
    top: 116px;
    left: -39px;
  }
}
.setsubi-list__item:nth-child(5):after {
  content: "";
  width: 190px;
  height: 237px;
  background: url(../img/campus_life/setsubi/item-04.png) no-repeat;
  background-size: 100%;
  background-position: bottom;
  position: absolute;
  bottom: -119px;
  right: -125px;
}
@media screen and (max-width: 834px) {
  .setsubi-list__item:nth-child(5):after {
    display: none;
  }
}
@media screen and (max-width: 834px) {
  .setsubi-list__item {
    padding: 0 4px;
    margin-bottom: 18px;
  }
  .setsubi-list__item:nth-child(2), .setsubi-list__item:nth-child(3) {
    width: 100%;
  }
}
.setsubi-list__ttl {
  position: relative;
  z-index: 1;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  text-align: center;
  font-size: 2rem;
  font-weight: bold;
  color: white;
  margin-bottom: 8px;
}
@media screen and (max-width: 834px) {
  .setsubi-list__ttl {
    font-size: 1.6rem;
  }
}
@media screen and (max-width: 374px) {
  .setsubi-list__ttl {
    font-size: 1.4rem;
  }
}
.setsubi-list__ttl span {
  display: block;
  width: 100%;
  max-width: 255px;
  background: url(../img/campus_life/setsubi/item-ttl.png) no-repeat;
  background-size: 100%;
  padding: 9px 5px 20px;
}
@media screen and (max-width: 834px) {
  .setsubi-list__ttl span {
    max-width: 180px;
    padding: 3px 5px 15px;
  }
}
@media screen and (max-width: 374px) {
  .setsubi-list__ttl span {
    max-width: 140px;
  }
}
.setsubi-list__img {
  position: relative;
  z-index: 1;
  overflow: hidden;
  border-radius: 20px;
}
.setsubi-list__img img {
  width: 100%;
}

/* ====================================================
css gakko_annai
==================================================== */
.mvhd-block {
  margin-bottom: 68px;
}
@media screen and (max-width: 834px) {
  .mvhd-block {
    margin-bottom: 0;
  }
}
.mvhd-block__img img {
  width: 100%;
  border-radius: 20px;
  overflow: hidden;
}
.mvhd-block__ttl {
  margin-top: -71px;
  margin-left: -23px;
}
@media screen and (max-width: 834px) {
  .mvhd-block__ttl {
    margin-top: -27px;
    margin-left: -9px;
  }
}
.mvhd-block__ttl img {
  height: 143px;
  object-fit: contain;
}
@media screen and (max-width: 834px) {
  .mvhd-block__ttl img {
    height: 58px;
  }
}

.sec-message {
  position: relative;
  margin-bottom: 96px;
}
@media screen and (max-width: 834px) {
  .sec-message {
    padding-bottom: 64px;
    overflow: hidden;
    padding-top: 57px;
    margin-bottom: 0;
  }
}
.sec-message:before {
  content: "";
  width: 100%;
  height: 141px;
  position: absolute;
  top: 7px;
  right: 0;
  left: 0;
  z-index: 5;
  background: url("../img/gakko_annai/img-top01.png") no-repeat top center;
  background-size: cover;
}
@media screen and (max-width: 834px) {
  .sec-message:before {
    height: 38px;
    background: url("../img/gakko_annai/img-top01sp.png") no-repeat top center;
    background-size: cover;
    top: 64px;
  }
}
.sec-message:after {
  content: "";
  width: 100%;
  height: 82px;
  position: absolute;
  bottom: 0;
  right: 0;
  left: 0;
  background: url("../img/gakko_annai/img-top02.png") no-repeat top center;
  background-size: cover;
}
@media screen and (max-width: 834px) {
  .sec-message:after {
    height: 39px;
    background: url("../img/gakko_annai/img-top02sp.png") no-repeat top center;
    background-size: cover;
    bottom: 60px;
  }
}
.sec-message .message-block {
  max-width: 1085px;
  margin: 0 auto;
  position: relative;
  z-index: 5;
  padding-bottom: 156px;
}
@media screen and (max-width: 834px) {
  .sec-message .message-block {
    padding-bottom: 84px;
  }
}
.sec-message .message-block-t {
  position: absolute;
  top: -49px;
  right: 27px;
  z-index: 5;
}
@media screen and (max-width: 1024px) {
  .sec-message .message-block-t {
    width: 200px;
    top: -25px;
    right: 0;
  }
}
@media screen and (max-width: 834px) {
  .sec-message .message-block-t {
    width: 122px;
    position: absolute;
    right: -12px;
    z-index: 5;
  }
}
.sec-message .message-block-bt {
  position: absolute;
  bottom: -105px;
  z-index: 7;
}
@media screen and (max-width: 1024px) {
  .sec-message .message-block-bt {
    width: 200px;
    bottom: -31px;
    left: -22px;
    z-index: 7;
  }
}
@media screen and (max-width: 834px) {
  .sec-message .message-block-bt {
    width: 156px;
  }
}
.sec-message .message-ttl {
  text-align: center;
  margin-bottom: 77px;
}
@media screen and (max-width: 834px) {
  .sec-message .message-ttl {
    margin-bottom: 25px;
  }
}
.sec-message .message-ttl__ico {
  margin-bottom: 23px;
  display: inline-block;
}
@media screen and (max-width: 834px) {
  .sec-message .message-ttl__ico {
    width: 40px;
    margin-bottom: 11px;
  }
}
.sec-message .message-ttl__main {
  display: block;
}
@media screen and (max-width: 834px) {
  .sec-message .message-ttl__main {
    width: 128px;
    margin: 0 auto;
  }
}
.sec-message__w {
  position: relative;
}
.sec-message__w:after {
  content: "";
  width: 100%;
  height: calc(100% - 140px);
  background-color: #FFFCDB;
  position: absolute;
  top: 140px;
  right: 0;
  left: 0;
}
@media screen and (max-width: 834px) {
  .sec-message__w:after {
    height: calc(100% - 38px);
    top: 38px;
  }
}
.sec-message__w > * {
  position: relative;
  z-index: 3;
}
.sec-message__w .inner {
  z-index: 5;
}

.message-wrap {
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
}
@media screen and (max-width: 834px) {
  .message-wrap {
    flex-wrap: wrap;
  }
}
.message-wrap__l {
  width: 58%;
  padding-right: 27px;
}
.message-wrap__l .c-txt__basic {
  font-weight: 700;
}
@media screen and (max-width: 834px) {
  .message-wrap__l {
    width: 100%;
    padding-right: 0;
    order: 2;
  }
  .message-wrap__l .c-txt__basic {
    line-height: 2.0833333333;
  }
}
.message-wrap__l .c-ttl__03 {
  margin-bottom: 29px;
}
@media screen and (max-width: 1200px) and (min-width: 835px) {
  .message-wrap__l .c-ttl__03 {
    font-size: 27px;
  }
}
@media screen and (max-width: 1024px) and (min-width: 835px) {
  .message-wrap__l .c-ttl__03 {
    font-size: 21px;
  }
}
@media screen and (max-width: 834px) {
  .message-wrap__l .c-ttl__03 {
    text-align: center;
    font-size: 20px;
    line-height: 1.5;
    margin-bottom: 17px;
  }
}
.message-wrap__r {
  width: 42%;
  margin-top: -56px;
}
@media screen and (max-width: 834px) {
  .message-wrap__r {
    width: 100%;
    margin-top: 0;
    margin-bottom: 33px;
  }
}
.message-wrap__r-img {
  margin-bottom: 22.5px;
}
@media screen and (max-width: 834px) {
  .message-wrap__r-img {
    width: 250px;
    margin: 0 auto 12px;
  }
}
.message-wrap__r-author {
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  margin-right: 23px;
}
@media screen and (max-width: 834px) {
  .message-wrap__r-author {
    margin-right: 5px;
  }
}
.message-wrap__r-func {
  font-size: 20px;
  font-size: 2rem;
  line-height: 1.45;
  color: #fff;
  font-weight: 700;
  background: #1D3E62;
  margin-right: 12.5px;
  padding: 2px 13.5px;
}
@media screen and (max-width: 834px) {
  .message-wrap__r-func {
    font-size: 12px;
    font-size: 1.2rem;
    line-height: 1.3333333333;
    margin-right: 8.5px;
    padding: 2px 7.5px;
  }
}
.message-wrap__r-name {
  font-size: 30px;
  font-size: 3rem;
  line-height: 0.9666666667;
  font-weight: 700;
  margin-top: -1px;
}
@media screen and (max-width: 834px) {
  .message-wrap__r-name {
    font-size: 16px;
    font-size: 1.6rem;
    line-height: 1;
  }
}

.history-ttl {
  text-align: center;
}
.history-ttl span {
  font-size: 30px;
  font-size: 3rem;
  line-height: 1.5;
  font-weight: 700;
  color: #fff;
  position: relative;
  min-width: 473px;
  height: 88px;
  position: relative;
  display: inline-block;
  padding: 11px 10px;
  margin-bottom: 33px;
  max-width: 100%;
}
@media screen and (max-width: 834px) {
  .history-ttl span {
    width: 320px;
    max-width: 100%;
    min-width: auto;
    font-size: 20px;
    font-size: 2rem;
    line-height: 1.45;
    margin-bottom: 26px;
    height: 60px;
    padding: 8px 5px;
  }
}
.history-ttl span:after {
  content: "";
  min-width: 473px;
  height: 88px;
  display: inline-block;
  position: absolute;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
  z-index: -1;
  background: url("../img/gakko_annai/img-btn.png") no-repeat center center;
  background-size: 100%;
  max-width: 100%;
}
@media screen and (max-width: 834px) {
  .history-ttl span:after {
    width: 320px;
    max-width: 100%;
    min-width: auto;
    height: 60px;
  }
}

.history-mail {
  max-width: 1036px;
  margin: 0 auto;
  border: 2px solid #E0346E;
  border-radius: 20px;
  padding: 41px 25px 47px;
  background: #fff;
  z-index: 3;
  position: relative;
}
@media screen and (max-width: 834px) {
  .history-mail {
    padding: 13px 15px 22px 18px;
  }
}
.history-mail__w {
  max-width: 548px;
  margin: 0 auto;
}
.history-mail__dl {
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
}
.history-mail__dt {
  width: 71px;
  color: #E0346E;
  padding-right: 15px;
}
@media screen and (max-width: 834px) {
  .history-mail__dt {
    width: 54px;
    padding-right: 5px;
  }
}
.history-mail__dd {
  width: calc(100% - 71px);
}
@media screen and (max-width: 834px) {
  .history-mail__dd {
    width: calc(100% - 54px);
  }
}
@media screen and (max-width: 834px) {
  .history-mail.c-txt__basic {
    line-height: 2.5 !important;
  }
}

.sec-history {
  padding-bottom: 142px;
  position: relative;
  overflow: hidden;
}
@media screen and (max-width: 834px) {
  .sec-history {
    padding-bottom: 121px;
  }
}
.sec-history:after {
  content: "";
  width: 386px;
  height: 442px;
  background: url(../img/gakko_annai/img-bg.png) no-repeat center center;
  background-size: 100%;
  position: absolute;
  bottom: 21px;
  right: -175px;
  display: inline-block;
  z-index: 2;
}
@media screen and (max-width: 834px) {
  .sec-history:after {
    width: 217px;
    height: 248px;
    bottom: 9px;
    right: -60px;
  }
}
.sec-history .c-txt__basic {
  color: #1D3E62;
  font-weight: 700;
}
@media screen and (max-width: 834px) {
  .sec-history .c-txt__basic {
    line-height: 2.0833333333;
    font-feature-settings: "palt";
    letter-spacing: 0.03em;
  }
}

.sec-education {
  margin-bottom: 125px;
}
@media screen and (max-width: 834px) {
  .sec-education {
    margin-bottom: 0;
  }
}
.sec-education .mv-page__info--ct {
  padding-bottom: 0;
}

/* ====================================================
PAGE about_eiseishi_field
==================================================== */
.sec-field {
  margin-top: -55px;
  margin-bottom: 160px;
}
@media screen and (max-width: 834px) {
  .sec-field {
    margin-top: -15px;
    margin-bottom: 74px;
  }
}
.sec-field__inner {
  position: relative;
}
.sec-field__inner:after {
  content: "";
  display: inline-block;
  position: absolute;
  width: 36%;
  height: 35%;
  background: url(../img/about_eiseishi/field/bg-01.png) no-repeat top left/contain;
  bottom: -21%;
  left: -16.5%;
  z-index: -1;
}
@media screen and (max-width: 834px) {
  .sec-field__inner:after {
    bottom: -21.8%;
    left: -4.5%;
    width: 39%;
    height: 32%;
  }
}

.article-list {
  font-weight: 700;
}
.article-list__item {
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
}
@media screen and (max-width: 834px) {
  .article-list__item {
    align-items: start;
  }
}
.article-list__item:not(:last-child) {
  margin-bottom: 50px;
}
@media screen and (max-width: 834px) {
  .article-list__item:not(:last-child) {
    margin-bottom: 20px;
  }
}
.article-list__img {
  width: 250px;
  align-self: baseline;
  border-radius: 20px;
  overflow: hidden;
  position: relative;
}
@media screen and (max-width: 834px) {
  .article-list__img {
    width: 160px;
  }
}
@media screen and (max-width: 374px) {
  .article-list__img {
    width: 110px;
  }
}
.article-list__img::before {
  content: "";
  display: block;
  padding-top: 100%;
}
.article-list__img img {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.article-list__ct {
  width: calc(100% - 289px);
}
@media screen and (max-width: 834px) {
  .article-list__ct {
    width: calc(100% - 177px);
  }
}
@media screen and (max-width: 374px) {
  .article-list__ct {
    width: calc(100% - 125px);
  }
}
.article-list__ct-ttl {
  color: #E0346E;
  margin-bottom: 5px;
}
@media screen and (max-width: 834px) {
  .article-list__ct-ttl {
    margin-left: -5px;
  }
}
.article-list__ct-ttl span {
  display: inline-block;
  background: #FFFCDB;
  border-radius: 31px;
  padding: 6px 28px;
}
@media screen and (max-width: 834px) {
  .article-list__ct-ttl span {
    padding: 6px 16px;
  }
}

.sec-look {
  padding-bottom: 139px;
  position: relative;
}
@media screen and (max-width: 834px) {
  .sec-look {
    padding-bottom: 95px;
  }
}
@media screen and (max-width: 834px) {
  .sec-look--field {
    overflow: hidden;
  }
}
.sec-look--field:after {
  content: "";
  display: inline-block;
  position: absolute;
  width: 21%;
  height: 31%;
  background: url(../img/about_eiseishi/field/bg-02.png) no-repeat top left/contain;
  bottom: 1%;
  right: 0;
  z-index: -1;
  max-width: 280px;
  max-height: 256px;
}
@media screen and (max-width: 640px) {
  .sec-look--field:after {
    bottom: 0%;
    right: -22px;
    width: 33%;
    height: 16%;
  }
}
@media screen and (min-width: 394px) and (max-width: 834px) {
  .sec-look--field:after {
    bottom: 3px;
    width: 99px;
    height: 100px;
    right: 0;
  }
}
@media screen and (max-width: 834px) {
  .sec-look--haspen {
    overflow: hidden;
  }
}
.sec-look--haspen:after {
  content: "";
  display: inline-block;
  position: absolute;
  width: 21%;
  height: 31%;
  background: url(../img/gakko_annai/img-pen.png) no-repeat top left/contain;
  bottom: 2.2%;
  left: 2.9%;
  z-index: -1;
  max-width: 172px;
  max-height: 172px;
}
@media screen and (max-width: 834px) {
  .sec-look--haspen:after {
    bottom: 0%;
    left: 0px;
    width: 28%;
    height: 4.8%;
  }
}
.sec-look--icobefore:before {
  content: "";
  display: inline-block;
  position: absolute;
  width: 385px;
  height: 443px;
  background: url(../img/gakko_annai/img-bg.png) no-repeat center/contain;
  top: 15%;
  right: -175px;
  z-index: -1;
}
@media screen and (max-width: 834px) {
  .sec-look--icobefore:before {
    width: 193px;
    height: 222px;
    right: -32px;
    top: 13.7%;
  }
}

.cm-list {
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  margin: 0 -9px;
  grid-gap: 49px 0;
}
@media screen and (max-width: 834px) {
  .cm-list {
    line-height: 2.083;
    grid-gap: 33px 0;
  }
}
.cm-list__item {
  width: 33.33%;
  padding: 0 9px;
}
@media screen and (max-width: 834px) {
  .cm-list__item {
    width: 50%;
  }
}
@media screen and (max-width: 640px) {
  .cm-list__item {
    width: 100%;
  }
}
.cm-list__wrap {
  display: block;
  border-radius: 20px;
  overflow: hidden;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16);
  background: white;
}
.cm-list__img {
  position: relative;
}
.cm-list__img::before {
  content: "";
  padding-top: 68.8144329897%;
  display: block;
}
@media screen and (max-width: 834px) {
  .cm-list__img::before {
    padding-top: 40.7932011331%;
  }
}
.cm-list__img-thumb {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
}
.cm-list__img-thumb img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.cm-list__ttl {
  color: #E0346E;
  margin-bottom: 1px;
}
@media screen and (max-width: 834px) {
  .cm-list__ttl {
    font-size: 20px;
    font-size: 2rem;
    line-height: 1.5;
  }
}
.cm-list__ct {
  padding: 13px 21px 21px;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
}
@media screen and (min-width: 835px) {
  .cm-list__ct {
    min-height: 335px;
  }
}
@media screen and (max-width: 834px) {
  .cm-list__ct {
    padding: 14px 19px 24px;
  }
}
.cm-list__ct p {
  font-weight: 700;
}
.cm-list__ct p:not(:last-child) {
  margin-bottom: 5px;
}
@media screen and (max-width: 834px) {
  .cm-list__ct p:not(:last-child) {
    margin-bottom: 2px;
  }
}
.cm-list__ct .c-btn__02 {
  margin-top: auto;
}

.look-ttl {
  text-align: center;
  margin-bottom: 48px;
}
@media screen and (max-width: 834px) {
  .look-ttl {
    margin-bottom: 21px;
  }
}

/* ====================================================
PAGE page-kitai
==================================================== */
@media screen and (max-width: 834px) {
  #page-about_eiseishi_kitai .sec-look--field::after {
    bottom: -2%;
    right: -20px;
  }
}

.mv-page__info--kitai {
  padding-bottom: 140px;
}
@media screen and (max-width: 834px) {
  .mv-page__info--kitai {
    padding-bottom: 69px;
  }
}
.mv-page__info--kitai::before {
  bottom: 54%;
}
@media screen and (max-width: 834px) {
  .mv-page__info--kitai::before {
    bottom: 78.2%;
  }
}
.mv-page__info--kitai::after {
  bottom: -13px;
  right: -10.8%;
}
@media screen and (max-width: 834px) {
  .mv-page__info--kitai::after {
    bottom: 9px;
    right: -8.8%;
  }
}

.sec-kitai {
  margin-top: -62px;
  margin-bottom: 179px;
}
@media screen and (max-width: 834px) {
  .sec-kitai {
    margin-top: -26px;
    margin-bottom: 74px;
  }
}
.sec-kitai__inner {
  position: relative;
}
.sec-kitai__inner:after {
  content: "";
  display: inline-block;
  position: absolute;
  width: 385px;
  height: 436px;
  background: url(../img/about_eiseishi/field/bg-01.png) no-repeat top left/contain;
  bottom: -301px;
  left: -16.5%;
  z-index: -1;
}
@media screen and (max-width: 834px) {
  .sec-kitai__inner:after {
    bottom: -151px;
    left: -5.5%;
    width: 161px;
    height: 185px;
  }
}

.kitai-box {
  background: white;
  line-height: 1.875;
}
@media screen and (max-width: 834px) {
  .kitai-box {
    line-height: 2.083;
  }
}
.kitai-box .c-ttl__03 {
  margin-bottom: 33px;
}
@media screen and (max-width: 834px) {
  .kitai-box .c-ttl__03 {
    margin-bottom: 22px;
  }
}
.kitai-box__dl:not(:last-child) {
  margin-bottom: 32px;
}
@media screen and (max-width: 834px) {
  .kitai-box__dl:not(:last-child) {
    margin-bottom: 38px;
  }
}
.kitai-box__dl-dt {
  margin-bottom: 13px;
}
@media screen and (max-width: 834px) {
  .kitai-box__dl-dt {
    margin-bottom: 3px;
  }
}
.kitai-box__dl-dd {
  font-weight: 700;
  font-family: "游ゴシック体", "Yu Gothic", YuGothic, "ヒラギノ角ゴPro W3", "Hiragino Kaku Gothic Pro", Osaka, "Meiryo", "メイリオ", "MS Pゴシック", Arial, Helvetica, Verdana, sans-serif;
}
iiy .kitai-box__note {
  padding-top: 46px;
  font-family: "游ゴシック体", "Yu Gothic", YuGothic, "ヒラギノ角ゴPro W3", "Hiragino Kaku Gothic Pro", Osaka, "Meiryo", "メイリオ", "MS Pゴシック", Arial, Helvetica, Verdana, sans-serif;
  font-weight: 700;
}
@media screen and (max-width: 834px) {
  iiy .kitai-box__note {
    padding-top: 14px;
  }
}
.kitai-box--style2 {
  padding-top: 44px;
  position: relative;
}
.kitai-box--style2:before {
  content: "";
  display: inline-block;
  position: absolute;
  width: 232px;
  height: 257px;
  background: url(../img/about_eiseishi/field/bg-before.png) no-repeat center/contain;
  top: -159px;
  right: 96.7%;
  z-index: -1;
}
@media screen and (max-width: 834px) {
  .kitai-box--style2:before {
    top: -62px;
    right: 84%;
    width: 89px;
    height: 99px;
  }
}
.kitai-box--style2:after {
  content: "";
  display: inline-block;
  position: absolute;
  width: 212px;
  height: 277px;
  background: url(../img/about_eiseishi/field/bg-after.png) no-repeat center bottom/contain;
  bottom: -79px;
  left: 100%;
  z-index: -1;
}
@media screen and (max-width: 834px) {
  .kitai-box--style2:after {
    height: 107px;
    width: 82px;
    bottom: 50%;
    left: 86%;
  }
}
@media screen and (max-width: 834px) {
  .kitai-box--style2 {
    padding-top: 23px;
    padding-bottom: 12px;
  }
}
.kitai-box--style2 .kitai-box__dl:not(:last-child) {
  margin-bottom: 53px;
}
@media screen and (max-width: 834px) {
  .kitai-box--style2 .kitai-box__dl:not(:last-child) {
    margin-bottom: 38px;
  }
}
.kitai-box--number {
  counter-reset: index;
  margin-bottom: 84px;
}
@media screen and (max-width: 834px) {
  .kitai-box--number {
    margin-bottom: 55px;
  }
}
.kitai-box--number .kitai-box__dl:not(:last-child) {
  margin-bottom: 47px;
}
@media screen and (max-width: 834px) {
  .kitai-box--number .kitai-box__dl:not(:last-child) {
    margin-bottom: 30px;
  }
}
.kitai-box--number .kitai-box__dl-dt {
  position: relative;
  padding-left: 40px;
}
@media screen and (max-width: 834px) {
  .kitai-box--number .kitai-box__dl-dt {
    padding-left: 32px;
  }
}
.kitai-box--number .kitai-box__dl-dt::before {
  counter-increment: index;
  content: counter(index) " . ";
  position: absolute;
  top: 0;
  left: 4px;
}
@media screen and (max-width: 834px) {
  .kitai-box--number .kitai-box__dl-dt::before {
    left: 3px;
  }
}

/* ====================================================
PAGE page-shigoto
==================================================== */
.sec-shigoto {
  padding-top: 83px;
  margin-bottom: 141px;
}
@media screen and (max-width: 834px) {
  .sec-shigoto {
    padding-top: 43px;
    margin-bottom: 68px;
  }
}
@media screen and (min-width: 835px) {
  .sec-shigoto .inner::after {
    bottom: -548px;
  }
}
@media screen and (max-width: 834px) {
  .sec-shigoto .inner::after {
    bottom: -126px;
  }
}

.sec-reason {
  padding: 0 0 100px 0;
}
@media screen and (max-width: 834px) {
  .sec-reason {
    padding: 0 0 82px 0;
  }
}
.sec-reason .inner {
  max-width: 1076px;
}

.reason-list {
  font-weight: bold;
}
.reason-list__item {
  border-radius: 20px;
  position: relative;
  background: #FFF2F6;
  text-align: center;
  padding: 45px 70px;
  margin-bottom: 52px;
}
@media screen and (max-width: 834px) {
  .reason-list__item {
    padding: 30px 21px 22px;
    margin-bottom: 22px;
    text-align: left;
    line-height: 2.0833333333;
  }
  .reason-list__item:nth-child(4) .reason-list__ttl {
    text-align: left;
  }
  .reason-list__item:nth-child(4) .reason-list__ttl .reason-list__ttl--small {
    font-size: 2.4rem;
  }
}
@media screen and (max-width: 834px) and (max-width: 374px) {
  .reason-list__item:nth-child(4) .reason-list__ttl .reason-list__ttl--small {
    font-size: 2.2rem;
  }
}
@media screen and (max-width: 834px) {
  .reason-list__item:nth-child(5) .reason-list__ttl .reason-list__ttl--big {
    font-size: 3rem;
  }
}
@media screen and (max-width: 834px) {
  .reason-list__item:nth-child(6) .reason-list__ttl {
    font-size: 2.6rem;
  }
  .reason-list__item:nth-child(6) .reason-list__ttl .reason-list__ttl--big {
    font-size: 3.3rem;
  }
  .reason-list__item:nth-child(6) .reason-list__ttl > span {
    background-size: 200% 27px;
    background-repeat: no-repeat;
    background-position: 0 20px;
  }
}
@media screen and (max-width: 374px) {
  .reason-list__item {
    padding-left: 15px;
    padding-right: 15px;
  }
}
.reason-list__item:nth-child(even) {
  background: #FFFCDB;
}
.reason-list__item > * {
  position: relative;
  z-index: 2;
}
.reason-list__ttl {
  display: block;
  line-height: 1.3;
  font-weight: bold;
  font-size: 4rem;
  color: #E0346E;
  margin-bottom: 27px;
}
@media screen and (max-width: 834px) {
  .reason-list__ttl {
    font-size: 3.2rem;
    text-align: center;
    margin-bottom: 19px;
  }
}
@media screen and (max-width: 374px) {
  .reason-list__ttl {
    font-size: 2.8rem;
  }
}
.reason-list__ttl small {
  display: block;
  font-size: 2rem;
  font-weight: bold;
  margin-bottom: 5px;
}
@media screen and (max-width: 834px) {
  .reason-list__ttl small {
    font-size: 1.6rem;
    margin-bottom: -2px;
  }
}
.reason-list__ttl--small {
  font-size: 3rem;
}
@media screen and (max-width: 834px) {
  .reason-list__ttl--small {
    font-size: 2.9rem;
  }
}
@media screen and (max-width: 374px) {
  .reason-list__ttl--small {
    font-size: 2.6rem;
  }
}
.reason-list__ttl--big {
  font-size: 5rem;
}
@media screen and (max-width: 834px) {
  .reason-list__ttl--big {
    font-size: 4.8rem;
  }
}
@media screen and (max-width: 374px) {
  .reason-list__ttl--big {
    font-size: 4.2rem;
  }
}
@media screen and (max-width: 834px) {
  .reason-list__ttl--big-other {
    font-size: 3.5rem;
  }
}
@media screen and (max-width: 374px) {
  .reason-list__ttl--big-other {
    font-size: 3rem;
  }
}
.reason-list__ttl > span {
  background-image: -o-linear-gradient(left, #FFF8A1 50%, transparent 50%);
  background-image: -webkit-gradient(linear, left top, right top, color-stop(50%, #FFF8A1), color-stop(50%, transparent));
  background-image: linear-gradient(to right, #FFF8A1 50%, transparent 50%);
  background-size: 200% 23px;
  background-repeat: no-repeat;
  background-position: 0 33px;
}
@supports (-webkit-touch-callout: none) {
  .reason-list__ttl > span {
    background-position: 0 17px;
  }
}
@media screen and (max-width: 834px) {
  .reason-list__ttl > span {
    background-size: 200% 26px;
    background-repeat: no-repeat;
    background-position: 0 26px;
  }
  @supports (-webkit-touch-callout: none) {
    .reason-list__ttl > span {
      background-position: 0 12px;
    }
  }
}
.reason-list__img {
  position: absolute;
  z-index: 1;
}
.reason-list__img--01 {
  top: -19px;
  right: -83px;
  width: 263px;
}
@media screen and (max-width: 834px) {
  .reason-list__img--01 {
    top: -59px;
    right: -32px;
    width: 120px;
  }
}
.reason-list__img--03 {
  left: -19px;
  top: -43px;
  width: 158px;
}
@media screen and (max-width: 834px) {
  .reason-list__img--03 {
    top: -57px;
    width: 86px;
    left: -8px;
  }
}
@media screen and (max-width: 374px) {
  .reason-list__img--03 {
    top: -44px;
    width: 65px;
  }
}
.reason-list__img--04 {
  top: -87px;
  right: -82px;
  width: 255px;
}
@media screen and (max-width: 834px) {
  .reason-list__img--04 {
    top: -27px;
    right: -61px;
    width: 140px;
  }
}
.reason-list__img--06 {
  left: -81px;
  bottom: -78px;
  width: 137px;
}
@media screen and (max-width: 834px) {
  .reason-list__img--06 {
    left: -21px;
    bottom: -102px;
    width: 70px;
  }
}
@media screen and (max-width: 374px) {
  .reason-list__img--06 {
    width: 55px;
  }
}
.reason-list__sub {
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
}
.reason-list__sub .c-btn__01 {
  margin: 0 10px;
}
@media screen and (max-width: 834px) {
  .reason-list__sub .c-btn__01 {
    margin-bottom: 10px;
  }
  .reason-list__sub .c-btn__01:last-child {
    margin-bottom: 0;
  }
}
.reason-list p {
  margin-bottom: 34px;
}
@media screen and (max-width: 834px) {
  .reason-list p {
    margin-bottom: 20px;
  }
}

.sec-detail {
  padding-bottom: 61px;
}
@media screen and (max-width: 834px) {
  .sec-detail {
    padding-bottom: 30px;
  }
}

.detail-block__wrap {
  background: #FFF2F6;
  border-radius: 20px;
  padding: 13px 25px;
  margin-bottom: 13px;
}
@media screen and (max-width: 834px) {
  .detail-block__wrap {
    padding: 18px 19px 25px;
    margin-bottom: 16px;
  }
}
.detail-block__date {
  font-weight: 700;
}
@media screen and (max-width: 834px) {
  .detail-block__date {
    display: block;
    margin-bottom: 9px;
  }
}
.detail-block__ttl {
  line-height: 1.35;
}
.detail-block__area {
  font-weight: 700;
}
.detail-block__area h2 {
  font-size: 30px;
  font-size: 3rem;
  line-height: 1.6666666667;
  font-weight: 700;
  color: #FF9100;
  position: relative;
  padding-left: 34px;
  margin-bottom: 15px;
  margin-top: 72px;
}
@media screen and (max-width: 834px) {
  .detail-block__area h2 {
    font-size: 16px;
    font-size: 1.6rem;
    line-height: 1.25;
    padding-left: 18px;
    margin-bottom: 17px;
    margin-top: 56px;
  }
}
.detail-block__area h2:before {
  content: "";
  display: inline-block;
  position: absolute;
  width: 15px;
  height: 32px;
  background: #FF9100;
  left: 0;
  top: 12px;
}
@media screen and (max-width: 834px) {
  .detail-block__area h2:before {
    width: 8px;
    height: 95%;
    top: 3px;
  }
}
.detail-block__area h3 {
  font-size: 20px;
  font-size: 2rem;
  line-height: 2.5;
  font-weight: 700;
  color: #E0346E;
  border-bottom: 2px #FF9100 solid;
  padding-bottom: 3px;
  margin-bottom: 13px;
  margin-top: 67px;
}
@media screen and (max-width: 834px) {
  .detail-block__area h3 {
    font-size: 16px;
    font-size: 1.6rem;
    line-height: 1.25;
    padding-bottom: 9px;
    margin-bottom: 15px;
    margin-top: 58px;
  }
}
.detail-block__area p {
  font-family: "游ゴシック体", "Yu Gothic", YuGothic, "ヒラギノ角ゴPro W3", "Hiragino Kaku Gothic Pro", Osaka, "Meiryo", "メイリオ", "MS Pゴシック", Arial, Helvetica, Verdana, sans-serif;
  margin-bottom: 34px;
}
@media screen and (max-width: 834px) {
  .detail-block__area p {
    margin-bottom: 20px;
  }
}

.sec-column {
  position: relative;
  padding-bottom: 33px;
  z-index: 1;
}
@media screen and (max-width: 834px) {
  .sec-column {
    padding: 25px 0 14px;
  }
}
@media screen and (max-width: 640px) {
  .sec-column .newspage-list__item p {
    -webkit-line-clamp: 4;
    max-height: 80px;
  }
}
.sec-column:before {
  content: "";
  position: absolute;
  background: url("../img/column/bg-column.png") top center no-repeat;
  background-size: 100%;
  width: 100vw;
  height: 102%;
  top: -37px;
  left: 50%;
  transform: translateX(-50%);
  z-index: -1;
  pointer-events: none;
}
@media screen and (max-width: 640px) {
  .sec-column:before {
    background: url("../img/column/bg-column-sp.png") top center no-repeat;
    background-size: 100%;
    top: -17px;
  }
}

/* ====================================================
PAGE about_eiseishi
==================================================== */
#page-about_eiseishi .mvhd-block__ttl {
  margin-top: -77px;
  margin-left: -29px;
}
@media screen and (max-width: 834px) {
  #page-about_eiseishi .mvhd-block__ttl {
    margin-top: -42px;
    margin-left: -29px;
  }
}
#page-about_eiseishi .mvhd-block__ttl img {
  height: auto;
}

.sec-heading {
  text-align: center;
  margin-bottom: 121px;
  font-weight: 700;
}
@media screen and (max-width: 834px) {
  .sec-heading {
    padding-top: 180px;
    margin-bottom: 48px;
  }
  .sec-heading .c-txt__basic {
    line-height: 2.083;
  }
}
.sec-heading__ttl {
  margin-bottom: 22px;
}
@media screen and (max-width: 834px) {
  .sec-heading__ttl {
    margin-bottom: 13px;
  }
}
.sec-heading:before {
  content: "";
  display: inline-block;
  position: absolute;
  width: 238px;
  height: 257px;
  background: url(../img/about_eiseishi/bg-before.png) no-repeat center/contain;
  top: 41.7%;
  left: 11.7%;
  z-index: -1;
}
@media screen and (max-width: 834px) {
  .sec-heading:before {
    left: 26.2%;
    width: 149px;
    top: -2px;
    height: 159px;
  }
}
.sec-heading:after {
  content: "";
  display: inline-block;
  position: absolute;
  width: 108px;
  height: 141px;
  background: url(../img/about_eiseishi/bg-after.png) no-repeat center bottom/contain;
  top: -2%;
  right: 17.6%;
  z-index: -1;
}
@media screen and (max-width: 834px) {
  .sec-heading:after {
    height: 88px;
    width: 69px;
    right: 26.1%;
    top: 2px;
  }
}

.sec-about {
  background: url(../img/about_eiseishi/bg-gray.png) no-repeat bottom center/100% 93%;
  padding-bottom: 15%;
}
@media screen and (max-width: 834px) {
  .sec-about {
    background: url(../img/about_eiseishi/bg-graysp.png) no-repeat bottom center/100% 80%;
    padding-bottom: 30%;
  }
}

.about-photo {
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  margin: 0 -14px 81px;
  grid-gap: 11px 0;
}
@media screen and (max-width: 834px) {
  .about-photo {
    margin: 0 -6px 30px;
  }
}
.about-photo__item {
  width: 33.33%;
  padding: 0 14px;
}
@media screen and (max-width: 834px) {
  .about-photo__item {
    width: 50%;
    padding: 0 6px;
  }
  .about-photo__item:nth-child(3) {
    width: 48.6%;
  }
}
.about-photo__item img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 20px;
}

.about-heading {
  text-align: center;
  margin-bottom: 122px;
  font-weight: 700;
}
@media screen and (max-width: 834px) {
  .about-heading {
    line-height: 2.083;
    margin-bottom: 79px;
  }
}
.about-heading__ttl {
  margin-bottom: 28px;
}
@media screen and (max-width: 834px) {
  .about-heading__ttl {
    margin-bottom: 23px;
  }
}

.about-question {
  background: #FFFFFF;
  border-radius: 20px;
  position: relative;
  padding: 0 35px 51px;
  margin: 0 auto;
  max-width: 1036px;
  font-weight: 700;
}
@media screen and (min-width: 835px) {
  .about-question {
    text-align: center;
  }
}
@media screen and (max-width: 834px) {
  .about-question {
    padding: 0 19px 38px;
    line-height: 2.083;
  }
}
.about-question:before {
  content: "";
  display: inline-block;
  position: absolute;
  width: 98px;
  height: 88px;
  background: url(../img/about_eiseishi/ico-05.png) no-repeat center/contain;
  right: 35px;
  top: -44px;
}
@media screen and (max-width: 834px) {
  .about-question:before {
    width: 64px;
    height: 57px;
    right: 20px;
    top: calc(100% - 28px);
  }
}
.about-question__ttl {
  position: relative;
  transform: translateY(-58%);
}
@media screen and (max-width: 834px) {
  .about-question__ttl {
    transform: translateY(-66%);
  }
}
.about-question p {
  margin-top: -2px;
}

.sec-info {
  background: url(../img/about_eiseishi/bg-02.png) no-repeat center/100% 100%;
  margin-top: -8.9%;
  padding: 280px 0 216px;
}
@media screen and (max-width: 834px) {
  .sec-info {
    background: url(../img/about_eiseishi/bg-02sp.png) no-repeat center/100% 100%;
    margin-top: -9.9%;
    padding: 161px 0 25%;
  }
}
.sec-info:before {
  content: "";
  display: inline-block;
  position: absolute;
  width: 136px;
  height: 182px;
  background: url(../img/about_eiseishi/ico-04.png) no-repeat top center/contain;
  right: 3.5%;
  top: 0;
}
@media screen and (max-width: 834px) {
  .sec-info:before {
    right: 1.5%;
    width: 90px;
    height: 112px;
  }
}
.sec-info:after {
  content: "";
  display: inline-block;
  position: absolute;
  width: 154px;
  height: 177px;
  background: url(../img/about_eiseishi/ico-01.png) no-repeat top center/contain;
  left: 2.1%;
  top: 14.8%;
}
@media screen and (max-width: 834px) {
  .sec-info:after {
    width: 92px;
    height: 120px;
    left: -25px;
    top: 11.2%;
  }
}
.sec-info__ttl {
  text-align: center;
  position: relative;
  margin-bottom: 109px;
  z-index: 10;
}
@media screen and (max-width: 834px) {
  .sec-info__ttl {
    margin-bottom: 126px;
  }
}
.sec-info__ttl:before {
  content: "";
  display: inline-block;
  position: absolute;
  width: 64px;
  height: 98px;
  background: url(../img/about_eiseishi/ico-03.png) no-repeat center top/contain;
  top: -127px;
  left: 50%;
  transform: translateX(-50%);
}
@media screen and (max-width: 834px) {
  .sec-info__ttl:before {
    top: -93px;
    width: 56px;
    height: 71px;
  }
}

.info-list {
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  grid-gap: 55px 18px;
  font-weight: 700;
}
.info-list__item {
  width: calc(33.33% - 12px);
  background: white;
  border-radius: 20px;
  padding: 0 29px 58px 37px;
  position: relative;
}
@media screen and (max-width: 834px) {
  .info-list__item {
    width: 100%;
    padding: 0 20px 34px;
  }
}
.info-list__number {
  position: relative;
  text-align: center;
  transform: translateY(-50%);
  display: block;
  margin-bottom: 3px;
}
@media screen and (max-width: 834px) {
  .info-list__number {
    margin-bottom: 1px;
  }
}
.info-list__ttl {
  text-align: center;
  margin-bottom: 34px;
}
@media screen and (max-width: 834px) {
  .info-list__ttl {
    margin-top: -5px;
    margin-bottom: 20px;
  }
}
.info-list__img {
  text-align: center;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  margin-bottom: 25px;
}
@media screen and (max-width: 834px) {
  .info-list__img {
    margin-bottom: 16px;
  }
}

.sec-look--about {
  padding-top: 112px;
}
@media screen and (max-width: 834px) {
  .sec-look--about {
    background: url(../img/about_eiseishi/bg-03.png) no-repeat top center/100% 100%;
    margin-top: -9%;
    padding-top: 109px;
  }
}
.sec-look--about:before {
  content: "";
  display: inline-block;
  position: absolute;
  width: 150px;
  height: 176px;
  background: url(../img/about_eiseishi/ico-02.png) no-repeat top center/contain;
  right: 3.4%;
  top: -163px;
  margin-top: -35px;
}
@media screen and (max-width: 834px) {
  .sec-look--about:before {
    right: 0.4%;
    top: -26px;
    width: 102px;
    height: 115px;
  }
}

@media screen and (max-width: 834px) {
  #page-gakko_annai_kokka975 {
    overflow: hidden;
  }
}

.mv-page__kokka {
  position: relative;
  padding-top: 23px;
  padding-bottom: 94px;
}
@media screen and (max-width: 834px) {
  .mv-page__kokka {
    padding-top: 24px;
    padding-bottom: 36px;
  }
}
.mv-page__kokka:before {
  content: "";
  display: inline-block;
  position: absolute;
  width: 337px;
  height: 238px;
  background: url(../img/gakko_annai/img-books.png) no-repeat center/contain;
  bottom: 12px;
  left: -6.8%;
  z-index: -1;
}
@media screen and (max-width: 990px) {
  .mv-page__kokka:before {
    width: 280px;
    height: 160px;
  }
}
@media screen and (max-width: 834px) {
  .mv-page__kokka:before {
    display: none;
  }
}
.mv-page__kokka:after {
  content: "";
  display: inline-block;
  position: absolute;
  width: 263px;
  height: 196px;
  background: url(../img/gakko_annai/img-built.png) no-repeat center bottom/contain;
  top: -9px;
  right: -4.8%;
}
@media screen and (max-width: 990px) {
  .mv-page__kokka:after {
    width: 200px;
    height: 150px;
  }
}
@media screen and (max-width: 834px) {
  .mv-page__kokka:after {
    height: 115px;
    width: 95px;
    top: 7px;
    right: -28px;
  }
}
.mv-page__kokka-ttl {
  margin-bottom: 40px;
}
@media screen and (max-width: 834px) {
  .mv-page__kokka-ttl {
    margin-bottom: 21px;
  }
}
.mv-page__kokka-wrap {
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  text-align: center;
  max-width: 580px;
  margin: 0 auto;
}
@media screen and (max-width: 834px) {
  .mv-page__kokka-wrap {
    max-width: 350px;
  }
}
.mv-page__kokka-wrap-item {
  width: 50%;
}
.mv-page__kokka-wrap-item:nth-child(2) .mv-page__kokka-wrap-ttl .c-ttl__03 {
  margin-left: -25px;
}
.mv-page__kokka-wrap-item:nth-child(2) .mv-page__kokka-wrap-ttl span::before {
  left: auto;
  right: -90px;
  transform: rotate(90deg);
}
@media screen and (max-width: 834px) {
  .mv-page__kokka-wrap-item:nth-child(2) .mv-page__kokka-wrap-ttl span::before {
    right: -45px;
  }
}
@media screen and (max-width: 640px) {
  .mv-page__kokka-wrap-item:nth-child(2) {
    text-align: left;
    padding-left: 8px;
  }
  .mv-page__kokka-wrap-item:nth-child(2) .mv-page__kokka-wrap-ttl .c-ttl__03 {
    margin-left: 0;
    padding-left: 27px;
  }
}
.mv-page__kokka-wrap-ttl {
  font-weight: 700;
}
.mv-page__kokka-wrap-ttl > small {
  display: block;
}
.mv-page__kokka-wrap-ttl span {
  font-size: 106px;
  font-size: 10.6rem;
  line-height: 0.7264150943;
  position: relative;
}
@media screen and (max-width: 990px) {
  .mv-page__kokka-wrap-ttl span {
    font-size: 7rem;
  }
}
@media screen and (max-width: 834px) {
  .mv-page__kokka-wrap-ttl span {
    font-size: 5.5rem;
    line-height: 0.5;
  }
}
.mv-page__kokka-wrap-ttl span:before {
  content: "";
  display: inline-block;
  position: absolute;
  width: 70px;
  height: 69px;
  background: url(../img/gakko_annai/kokka975/icon-star.png) no-repeat center/cover;
  left: -85px;
  bottom: 30px;
}
@media screen and (max-width: 834px) {
  .mv-page__kokka-wrap-ttl span:before {
    width: 36px;
    height: 35px;
    left: -43px;
    bottom: 12px;
  }
}
.mv-page__kokka-wrap-ttl span small {
  font-size: 6.4rem;
}
@media screen and (max-width: 834px) {
  .mv-page__kokka-wrap-ttl span small {
    font-size: 3.3rem;
  }
}

.sec-kokka {
  position: relative;
  overflow: hidden;
  padding-bottom: 73px;
}
@media screen and (max-width: 834px) {
  .sec-kokka {
    padding-bottom: 34px;
    overflow: initial;
  }
}
.sec-kokka:after {
  content: "";
  width: 386px;
  height: 442px;
  background: url(../img/gakko_annai/img-bg.png) no-repeat center center;
  background-size: 100%;
  position: absolute;
  bottom: 34%;
  right: -175px;
  display: inline-block;
  z-index: -1;
}
@media screen and (max-width: 834px) {
  .sec-kokka:after {
    width: 205px;
    height: 245px;
    bottom: -99px;
    right: -41px;
  }
}

.kokka-block__item {
  margin-bottom: 77px;
}
@media screen and (max-width: 834px) {
  .kokka-block__item {
    margin-bottom: 39px;
  }
}
.kokka-block__item-ttl {
  margin-bottom: 16px;
}
@media screen and (max-width: 834px) {
  .kokka-block__item-ttl {
    line-height: 1.4;
    padding-bottom: 17px;
    margin-bottom: 3px;
  }
}
.kokka-block__item-txt {
  font-weight: 700;
}
@media screen and (max-width: 834px) {
  .kokka-block__item-txt {
    line-height: 2.1;
  }
}
.kokka-block__item-txt img {
  margin-top: 24px;
}
@media screen and (max-width: 834px) {
  .kokka-block__item-txt img {
    margin-top: 18px;
    border-radius: 20px;
  }
}
@media screen and (max-width: 834px) {
  .kokka-block__item-img {
    position: relative;
    display: block;
  }
  .kokka-block__item-img:before {
    content: "";
    display: inline-block;
    position: absolute;
    width: 142px;
    height: 96px;
    background: url(../img/gakko_annai/img-books.png) no-repeat center/contain;
    background-size: 100%;
    position: absolute;
    bottom: -39px;
    left: -64px;
  }
}

@media screen and (min-width: 1025px) {
  #page-nyushi .mvhd-block__ttl img {
    height: 158px;
    margin-left: -9px;
    margin-top: -10px;
    margin-bottom: -4px;
  }
}
@media screen and (max-width: 834px) {
  .sec-education--02 {
    padding-top: 180px;
    margin-bottom: 16px;
  }
}
.sec-education--02 .mv-page__info::before {
  content: "";
  bottom: -27%;
  left: 15%;
  width: 125px;
  height: 150px;
  background: url("../img/nyushi/icon-before.png") center no-repeat;
  background-size: contain;
  position: absolute;
}
@media screen and (max-width: 1200px) {
  .sec-education--02 .mv-page__info::before {
    left: 0;
  }
}
@media screen and (max-width: 834px) {
  .sec-education--02 .mv-page__info::before {
    background: url("../img/nyushi/icon-before-sp.png") center no-repeat;
    background-size: contain;
    width: 218px;
    height: 161px;
    left: 50%;
    transform: translateX(-50%);
    top: -181px;
  }
}
.sec-education--02 .mv-page__info::after {
  content: "";
  top: -11px;
  width: 216px;
  height: 162px;
  background: url("../img/nyushi/icon-after.png") center no-repeat;
  background-size: contain;
  position: absolute;
  bottom: auto;
  right: 11%;
}
@media screen and (max-width: 1200px) {
  .sec-education--02 .mv-page__info::after {
    right: 0;
    width: 180px;
    height: 150px;
  }
}
@media screen and (max-width: 834px) {
  .sec-education--02 .mv-page__info::after {
    display: none;
  }
}
.sec-education--02 .c-txt__basic::before, .sec-education--02 .c-txt__basic::after {
  display: none;
}
@media screen and (max-width: 1200px) {
  .sec-education--02 .c-txt__basic {
    padding: 0;
  }
}
@media screen and (max-width: 834px) {
  .sec-education--02 .c-txt__basic {
    line-height: 2.08;
  }
}

@media screen and (min-width: 835px) {
  .sec-education--03 {
    margin-bottom: 141px;
  }
}
@media screen and (max-width: 834px) {
  .sec-education--03 {
    padding-top: 180px;
    margin-bottom: 16px;
  }
}
.sec-education--03 .mv-page__info::before {
  content: "";
  bottom: 3px;
  left: 9%;
  width: 150px;
  height: 244px;
  background: url("../img/campus_life/icon-before.png") center no-repeat;
  background-size: contain;
  position: absolute;
}
@media screen and (max-width: 1024px) {
  .sec-education--03 .mv-page__info::before {
    left: -20px;
    width: 110px;
    height: 180px;
  }
}
@media screen and (max-width: 834px) {
  .sec-education--03 .mv-page__info::before {
    background: url("../img/campus_life/icon-before-sp.png") center no-repeat;
    background-size: contain;
    width: 182px;
    height: 140px;
    left: 50%;
    transform: translateX(-50%);
    top: -173px;
  }
}
.sec-education--03 .mv-page__info::after {
  content: "";
  top: -5px;
  width: 177px;
  height: 176px;
  background: url("../img/campus_life/icon-after.png") center no-repeat;
  background-size: contain;
  position: absolute;
  bottom: auto;
  right: 12.2%;
}
@media screen and (max-width: 1024px) {
  .sec-education--03 .mv-page__info::after {
    right: 0;
    width: 140px;
    height: 130px;
  }
}
@media screen and (max-width: 834px) {
  .sec-education--03 .mv-page__info::after {
    display: none;
  }
}
.sec-education--03 .c-txt__basic::before, .sec-education--03 .c-txt__basic::after {
  display: none;
}
@media screen and (max-width: 1200px) {
  .sec-education--03 .c-txt__basic {
    padding: 0;
  }
}
@media screen and (max-width: 834px) {
  .sec-education--03 .c-txt__basic {
    line-height: 2.08;
  }
}

.sec-nyushi {
  padding: 26px 0 50px;
}
@media screen and (max-width: 834px) {
  .sec-nyushi {
    padding: 26px 0 63px;
  }
}
.sec-nyushi .inner {
  position: relative;
  z-index: 2;
}
.sec-nyushi .inner::before, .sec-nyushi .inner::after {
  content: "";
  width: 232px;
  height: 257px;
  background: url("../img/nyushi/icon-top.png") center no-repeat;
  background-size: contain;
  position: absolute;
  z-index: -1;
  top: 7.8%;
  left: -16.5%;
}
@media screen and (max-width: 640px) {
  .sec-nyushi .inner::before, .sec-nyushi .inner::after {
    width: 125px;
    height: 138px;
    top: 11.1%;
    left: -27px;
  }
}
.sec-nyushi .inner::after {
  bottom: -2.7%;
  top: auto;
  background: url("../img/nyushi/icon-bottom.png") center no-repeat;
  background-size: contain;
  width: 246px;
  height: 209px;
  left: -15%;
}
@media screen and (max-width: 640px) {
  .sec-nyushi .inner::after {
    width: 119px;
    height: 140px;
    top: 47.2%;
    right: -8%;
  }
}

.nyushi-list {
  margin: 0 -18px;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  position: relative;
  z-index: 2;
}
@media screen and (max-width: 640px) {
  .nyushi-list {
    margin: 0;
  }
}
.nyushi-list::after {
  content: "";
  width: 122px;
  height: 122px;
  background: url("../img/nyushi/icon-center.png") center no-repeat;
  background-size: contain;
  position: absolute;
  z-index: -1;
  top: 62%;
  right: -9.8%;
  transform: translateY(-50%);
}
@media screen and (max-width: 640px) {
  .nyushi-list::after {
    width: 65px;
    height: 65px;
    top: 46.6%;
    right: -9%;
  }
}
.nyushi-list__item {
  padding: 0 18px;
  width: 50%;
  margin-bottom: 68px;
}
@media screen and (max-width: 640px) {
  .nyushi-list__item {
    width: 100%;
    padding: 0;
    margin-bottom: 31px;
  }
}
.nyushi-list__item a {
  background: #fff;
  display: block;
  border-radius: 20px;
  overflow: hidden;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16);
}
.nyushi-list__img {
  position: relative;
  overflow: hidden;
}
.nyushi-list__img:before {
  content: "";
  display: block;
  padding-bottom: 53.4%;
}
@media screen and (max-width: 834px) {
  .nyushi-list__img:before {
    padding-bottom: 41.1%;
  }
}
.nyushi-list__img-thumb {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
}
.nyushi-list__img-thumb img {
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
  object-fit: cover;
}
.nyushi-list__ct {
  padding: 20px 20px;
}
@media screen and (max-width: 834px) {
  .nyushi-list__ct {
    padding: 14px 20px 21px;
  }
}
.nyushi-list__ct p {
  color: #1D3E62;
  min-height: 62px;
  margin-bottom: 10px;
  font-weight: bold;
}
@media screen and (max-width: 834px) {
  .nyushi-list__ct p {
    min-height: fit-content;
    margin-bottom: 7px;
  }
}
.nyushi-list__ttl {
  color: #E0346E;
  font-weight: bold;
  line-height: 1.3;
  margin-bottom: 6px;
  font-size: 3rem;
}
@media screen and (max-width: 834px) {
  .nyushi-list__ttl {
    font-size: 2rem;
  }
}

.sec-dental-department {
  padding-top: 17.5vw;
  overflow: hidden;
}
@media screen and (max-width: 834px) {
  .sec-dental-department {
    padding-top: 26.5vw;
    padding-bottom: min(70px, 16.667vw);
  }
}
@media screen and (max-width: 375px) {
  .sec-dental-department {
    padding-top: 30.5vw;
  }
}

.dental-department {
  background: rgb(255, 219, 231);
  background: linear-gradient(145deg, rgb(255, 219, 231) 0%, rgb(255, 235, 154) 100%);
  position: relative;
  margin-bottom: -30px;
}
@media screen and (max-width: 834px) {
  .dental-department {
    background: linear-gradient(170deg, rgb(255, 219, 231) 0%, rgb(255, 235, 154) 100%);
  }
}
.dental-department:before {
  content: "";
  display: inline-block;
  position: absolute;
  width: 100%;
  height: 11vw;
  background: url(../img/nyushi/boshu/bg-day-01.png) no-repeat top center/100%;
  top: -10.5vw;
}
@media screen and (max-width: 834px) {
  .dental-department:before {
    height: 17vw;
    top: -17vw;
    background: url(../img/nyushi/boshu/bg-day-01-sp.png) no-repeat bottom center/cover;
  }
}
.dental-department:after {
  content: "";
  display: inline-block;
  position: absolute;
  width: 100%;
  height: 11vw;
  background: url(../img/nyushi/boshu/bg-day-02.png) no-repeat bottom center/100%;
  bottom: -1.4vw;
  z-index: 2;
}
@media screen and (max-width: 834px) {
  .dental-department:after {
    display: none;
  }
}
.dental-department__ttl {
  text-align: center;
  margin-bottom: 70px;
}
@media screen and (max-width: 834px) {
  .dental-department__ttl {
    margin-bottom: 30px;
  }
}
.dental-department__deco > * {
  position: absolute;
}
.dental-department__deco-01 {
  width: 17%;
  top: -16.5vw;
  left: 0;
}
@media screen and (max-width: 834px) {
  .dental-department__deco-01 {
    width: min(26vw, 130px);
    top: -26.5vw;
    left: -21px;
  }
}
.dental-department__deco-02 {
  width: 28%;
  right: 0;
  top: 50%;
  transform: translate(50%, -50%);
}
@media screen and (max-width: 834px) {
  .dental-department__deco-02 {
    width: 177px;
    right: -44px;
    top: 175px;
    transform: none;
  }
}
.dental-department__deco-03 {
  width: 11.5vw;
  bottom: -5.2vw;
  right: 83px;
  z-index: 3;
}
@media screen and (max-width: 834px) {
  .dental-department__deco-03 {
    width: 18.5vw;
    bottom: 1.8vw;
    right: 16px;
  }
}
.dental-department__content {
  font-family: "游ゴシック体", "Yu Gothic", YuGothic, "ヒラギノ角ゴPro W3", "Hiragino Kaku Gothic Pro", Osaka, "Meiryo", "メイリオ", "MS Pゴシック", Arial, Helvetica, Verdana, sans-serif;
  position: relative;
  z-index: 10;
}
@media screen and (max-width: 834px) {
  .dental-department__content {
    font-size: 1.6rem;
  }
}
@media screen and (max-width: 375px) {
  .dental-department__content {
    font-size: 1.4rem;
  }
}
.dental-department__dl {
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-justify-content: flex-start;
  -ms-flex-pack: start;
  justify-content: flex-start;
  -webkit-align-items: flex-start;
  -ms-flex-align: start;
  align-items: flex-start;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  margin-bottom: 13px;
  font-weight: 700;
}
@media screen and (max-width: 834px) {
  .dental-department__dl {
    margin-bottom: 14px;
  }
}
.dental-department__dl:last-child {
  margin-bottom: 0;
}
@media screen and (max-width: 834px) {
  .dental-department__dl:last-child {
    margin-bottom: 37px;
  }
}
.dental-department__dt {
  width: 140px;
  display: inline-block;
  background: #fff;
  border-radius: 15px;
  padding: 5px 0;
  color: #E0346E;
  line-height: 1.25;
  text-align: center;
}
@media screen and (max-width: 640px) {
  .dental-department__dt {
    width: auto;
    min-width: 110px;
    padding: 8px 20px;
    margin-bottom: 2px;
  }
}
@media screen and (min-width: 835px) {
  .dental-department__dt--02 {
    padding: 9px 0;
  }
}
.dental-department__dd {
  width: calc(100% - 140px);
  padding-left: 38px;
  color: #1D3E62;
  line-height: 1.875;
}
@media screen and (max-width: 834px) {
  .dental-department__dd {
    padding-left: 20px;
  }
}
@media screen and (max-width: 640px) {
  .dental-department__dd {
    width: 100%;
    padding-left: 0;
    line-height: 2.1875;
    letter-spacing: -0.5px;
  }
}
.dental-department__dd--full {
  width: 100%;
  padding-left: 0;
  padding-top: 20px;
}
.dental-department__dd a {
  color: #1D3E62;
}
.dental-department__table {
  width: 100%;
  border-radius: 20px;
  overflow: hidden;
}
@media screen and (max-width: 834px) {
  .dental-department__table {
    font-size: 1.2rem;
  }
}
.dental-department__table tr th, .dental-department__table tr td {
  padding: 7px 7px;
  text-align: center;
}
@media screen and (max-width: 640px) {
  .dental-department__table tr th, .dental-department__table tr td {
    padding: 5px 5px;
  }
}
.dental-department__table tr th:last-of-type, .dental-department__table tr td:last-of-type {
  border-right: none;
}
.dental-department__table tr th {
  background: #FFC5D9;
  border-bottom: 2px solid #fff;
  border-right: 2px solid #fff;
  line-height: 1.4;
  width: 20.25%;
}
.dental-department__table tr th:first-child {
  width: 19%;
}
.dental-department__table tr th small {
  font-size: 1.3rem;
  display: block;
}
@media screen and (max-width: 834px) {
  .dental-department__table tr th small {
    font-size: 1.2rem;
    display: inline;
  }
}
.dental-department__table tr td {
  background: #fff;
  border-bottom: 2px solid #FFC5D9;
  border-right: 2px solid #FFC5D9;
}
@media screen and (max-width: 834px) {
  .dental-department__table tr td {
    font-size: 1.6rem;
  }
}
.dental-department__table tr:first-child th, .dental-department__table tr:first-child td {
  padding: 17px 17px;
}
@media screen and (max-width: 834px) {
  .dental-department__table tr:first-child th, .dental-department__table tr:first-child td {
    padding: 13px 10px;
  }
}
.dental-department__table tr:last-of-type th, .dental-department__table tr:last-of-type td {
  border-bottom: none;
}
.dental-department__note {
  font-size: 1.3rem;
}
@media screen and (max-width: 834px) {
  .dental-department__note {
    font-size: 1rem;
  }
}
.dental-department .inner {
  position: relative;
  top: -9vw;
  max-width: 1076px;
  margin-bottom: -5vw;
}
@media screen and (max-width: 834px) {
  .dental-department .inner {
    top: -17vw;
    padding-bottom: 14vw;
  }
}
.dental-department--night {
  background: rgb(159, 202, 255);
  background: linear-gradient(145deg, rgb(159, 202, 255) 0%, rgb(255, 235, 154) 100%);
  padding-top: 88px;
}
@media screen and (max-width: 834px) {
  .dental-department--night {
    background: linear-gradient(170deg, rgb(159, 202, 255) 0%, rgb(255, 235, 154) 100%);
    padding-top: 0;
    margin-top: -37px;
    margin-bottom: 0;
  }
}
.dental-department--night::before {
  display: none;
}
@media screen and (max-width: 834px) {
  .dental-department--night::before {
    display: block;
    height: 16.667vw;
    top: -16.667vw;
    background: url(../img/nyushi/boshu/bg-night-01-sp.png) no-repeat top center/cover;
  }
}
.dental-department--night::after {
  background: url(../img/nyushi/boshu/bg-night-02.png) no-repeat bottom center/100%;
  bottom: -8.5vw;
}
@media screen and (max-width: 834px) {
  .dental-department--night::after {
    height: 16.667vw;
    display: block;
    background: url(../img/nyushi/boshu/Union-15@2x.png) no-repeat top center/100%;
    bottom: -4px;
    z-index: 3;
  }
}
.dental-department--night .inner {
  z-index: 3;
  top: -57px;
  margin-bottom: 20vw;
}
@media screen and (max-width: 834px) {
  .dental-department--night .inner {
    margin-bottom: 0;
    padding-bottom: 16vw;
  }
}
@media screen and (max-width: 834px) {
  .dental-department--night .dental-department__dl:last-child {
    margin-bottom: 0;
  }
}
.dental-department--night .dental-department__dt {
  color: #277AE2;
}
.dental-department--night .dental-department__table tr th, .dental-department--night .dental-department__table tr td {
  width: 16.2%;
}
.dental-department--night .dental-department__table tr th:first-child, .dental-department--night .dental-department__table tr td:first-child {
  width: 19%;
}
.dental-department--night .dental-department__table tr th {
  background: #9FCAFF;
}
.dental-department--night .dental-department__table tr td {
  border-color: #9FCAFF;
}
.dental-department--night .dental-department__deco > * {
  position: absolute;
}
.dental-department--night .dental-department__deco-01 {
  width: 28%;
  top: -6vw;
  left: -90px;
  z-index: 3;
}
@media screen and (max-width: 834px) {
  .dental-department--night .dental-department__deco-01 {
    width: 177px;
    left: -40px;
    top: -42vw;
  }
}
.dental-department--night .dental-department__deco-02 {
  width: 28%;
  right: 23px;
  top: 50%;
  transform: translate(50%, -50%);
}
@media screen and (max-width: 834px) {
  .dental-department--night .dental-department__deco-02 {
    width: 177px;
    transform: none;
    top: 55vw;
    right: -40px;
  }
}
.dental-department--night .dental-department__deco-03 {
  width: 20.5vw;
  bottom: 3vw;
  left: 0;
  transform: translateY(100%);
  z-index: 4;
}
@media screen and (max-width: 834px) {
  .dental-department--night .dental-department__deco-03 {
    width: min(200px, 47.5vw);
    bottom: 24vw;
    left: 4vw;
  }
}
@media screen and (max-width: 640px) {
  .dental-department--night .dental-department__deco-03 {
    bottom: 31vw;
  }
}

#page-nyushi_nyushi .sec-support-system {
  padding-top: 32px;
  padding-bottom: 32px;
}
@media screen and (max-width: 834px) {
  #page-nyushi_nyushi .sec-support-system {
    padding-bottom: 50px;
    padding-top: 0;
  }
}
#page-nyushi_nyushi .mv-page {
  overflow: visible;
}

.mv-page__info--nyushi {
  padding-bottom: 0;
}
@media screen and (max-width: 834px) {
  .mv-page__info--nyushi {
    margin-top: 60px;
    padding-bottom: 33px;
  }
}
.mv-page__info--nyushi::before {
  bottom: auto;
  top: -50px;
  z-index: 10;
}
@media screen and (max-width: 1024px) {
  .mv-page__info--nyushi::before {
    left: 0;
    transform: translateX(-70%);
  }
}
@media screen and (max-width: 834px) {
  .mv-page__info--nyushi::before {
    bottom: auto;
    top: -105px;
    z-index: 10;
    left: 35px;
  }
}
.mv-page__info--nyushi:after {
  width: 122px;
  height: 122px;
  background: url(../img/common/icon-pen.png) no-repeat center/cover;
  right: -40px;
  bottom: -28px;
  z-index: 10;
}
@media screen and (max-width: 834px) {
  .mv-page__info--nyushi:after {
    width: 62px;
    height: 62px;
    right: -18px;
  }
}
@media screen and (max-width: 640px) {
  .mv-page__info--nyushi .c-ttl__02 {
    margin: 0 -22px;
    position: relative;
    left: 4px;
    margin-bottom: 8px;
  }
}

#page-yakan .mvhd-block__ttl {
  margin-top: -53px;
  margin-left: 17px;
}
@media screen and (max-width: 834px) {
  #page-yakan .mvhd-block__ttl {
    margin-left: 0px;
    margin-top: -25px;
  }
}
#page-yakan .mvhd-block__ttl img {
  height: 103px;
}
@media screen and (max-width: 834px) {
  #page-yakan .mvhd-block__ttl img {
    height: 49px;
  }
}
#page-yakan .sec-heading {
  margin-bottom: 62px;
}
@media screen and (max-width: 834px) {
  #page-yakan .sec-heading {
    padding-top: 175px;
    margin-bottom: 35px;
  }
}
#page-yakan .sec-heading::before {
  width: 213px;
  height: 244px;
  background: url("../img/yakan/icon-before.png") no-repeat center/cover;
  top: 17%;
  left: 11.5%;
}
@media screen and (max-width: 1200px) {
  #page-yakan .sec-heading::before {
    width: 140px;
    height: 170px;
    top: 40%;
    left: 0%;
  }
}
@media screen and (max-width: 834px) {
  #page-yakan .sec-heading::before {
    width: 98px;
    height: 134px;
    top: 5.8%;
    left: 27%;
  }
}
#page-yakan .sec-heading::after {
  width: 137px;
  height: 135px;
  background: url("../img/yakan/icon-after.png") no-repeat center/contain;
  top: -12.5%;
  right: 19.5%;
}
@media screen and (max-width: 1200px) {
  #page-yakan .sec-heading::after {
    width: 110px;
    height: 100px;
    top: -12.5%;
    right: 4%;
  }
}
@media screen and (max-width: 834px) {
  #page-yakan .sec-heading::after {
    width: 79px;
    height: 83px;
    right: 24.1%;
    top: 43px;
  }
}
@media screen and (min-width: 835px) {
  #page-yakan .sec-introduction::before {
    top: 13%;
  }
  #page-yakan .sec-introduction::after {
    bottom: -10%;
  }
}
@media screen and (max-width: 834px) {
  #page-yakan .sec-introduction {
    padding-bottom: 116px;
    margin-bottom: -40px;
  }
}
@media screen and (max-width: 834px) {
  #page-yakan .bg-introduction {
    background: url("../img/yakan/bg-02-sp.png") center no-repeat;
    background-size: 100% 100%;
  }
  #page-yakan .bg-introduction::after {
    bottom: -10px;
  }
}

@media screen and (max-width: 834px) {
  .bg-introduction {
    background: url("../img/yakan/bg-01-sp.png") center no-repeat;
    background-size: 100% 111%;
    position: relative;
  }
  .bg-introduction::after {
    content: "";
    width: 51px;
    height: 107px;
    bottom: 0;
    right: 5%;
    background: url("../img/yakan/icon-04.png") center no-repeat;
    background-size: contain;
    position: absolute;
    z-index: 2;
  }
}

.sec-introduction {
  padding: 2px 0 172px;
  margin-bottom: -80px;
  background: url("../img/yakan/bg-01.png") center no-repeat;
  background-size: 100% 100%;
  z-index: 2;
  position: relative;
}
@media screen and (max-width: 834px) {
  .sec-introduction {
    padding-bottom: 116px;
    background: none;
    margin-bottom: -40px;
  }
}
.sec-introduction::before {
  content: "";
  width: 144px;
  height: 134px;
  background: url("../img/yakan/icon-03.png") center no-repeat;
  background-size: contain;
  position: absolute;
  top: 7%;
  left: 5.2%;
}
@media screen and (max-width: 834px) {
  .sec-introduction::before {
    width: 84px;
    height: 75px;
    top: 0.5%;
    left: 5%;
  }
}
.sec-introduction::after {
  content: "";
  width: 110px;
  height: 270px;
  background: url("../img/yakan/icon-04.png") center no-repeat;
  background-size: contain;
  position: absolute;
  bottom: -4%;
  right: 6%;
}
@media screen and (max-width: 1200px) {
  .sec-introduction::after {
    width: 90px;
    height: 230px;
    bottom: -7%;
  }
}
@media screen and (max-width: 834px) {
  .sec-introduction::after {
    display: none;
  }
}
.sec-introduction .curriculum-page__intro::before, .sec-introduction .curriculum-page__intro::after {
  display: none;
}
.sec-introduction .curriculum-page__ttl {
  top: 0;
  margin-bottom: 63px;
}
@media screen and (max-width: 834px) {
  .sec-introduction .curriculum-page__ttl {
    top: -13px;
    margin-bottom: 15px;
  }
}

.introduction-list {
  margin: 0 auto;
  max-width: 92%;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
}
@media screen and (max-width: 1200px) {
  .introduction-list {
    max-width: initial;
    padding: 0;
    margin: 0 -20px;
  }
}
@media screen and (max-width: 834px) {
  .introduction-list {
    margin: 0;
    flex-wrap: wrap;
  }
}
.introduction-list--night {
  max-width: initial;
  margin: 0 -40px;
}
@media screen and (max-width: 1200px) {
  .introduction-list--night {
    margin: 0 -20px;
  }
}
@media screen and (max-width: 834px) {
  .introduction-list--night {
    margin: 0;
    flex-wrap: wrap;
  }
}
.introduction-list .c-ttl__gradient--large span {
  padding: 12px 44px;
  min-width: 383px;
}
@media screen and (max-width: 834px) {
  .introduction-list .c-ttl__gradient--large span {
    padding: 6px 20px;
  }
}
@media screen and (max-width: 834px) {
  .introduction-list .c-ttl__gradient--large span {
    max-width: 320px;
    width: 100%;
    min-width: max-content;
  }
}
.introduction-list__item {
  width: 50%;
  padding: 0 40px;
  font-weight: bold;
}
@media screen and (max-width: 1200px) {
  .introduction-list__item {
    padding: 0 20px;
  }
}
@media screen and (max-width: 834px) {
  .introduction-list__item {
    width: 100%;
    padding: 0;
    margin-bottom: 42px;
  }
  .introduction-list__item:last-child {
    margin-bottom: 0;
  }
  .introduction-list__item p {
    line-height: 2.08;
  }
}
.introduction-list__item .c-ttl__gradient {
  margin-bottom: 27px;
}
@media screen and (max-width: 834px) {
  .introduction-list__item .c-ttl__gradient {
    margin-bottom: 21px;
  }
}
.introduction-list__img {
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  margin-bottom: 17px;
}
@media screen and (max-width: 834px) {
  .introduction-list__img {
    margin-bottom: 19px;
  }
}
.introduction-list__img img {
  max-width: 100%;
}
.introduction-list__ttl {
  font-feature-settings: "palt";
  color: #1D3E62;
  font-weight: bold;
  line-height: 1.5;
  text-align: center;
  margin-bottom: 10px;
  letter-spacing: 2px;
  font-size: 3rem;
}
@media screen and (max-width: 1200px) {
  .introduction-list__ttl {
    letter-spacing: 0;
    font-size: 2.4rem;
  }
}
@media screen and (max-width: 834px) {
  .introduction-list__ttl {
    margin-bottom: 14px;
    font-size: 2rem;
  }
}

.sec-session {
  padding: 165px 0 166px;
  background: #F9F9F9;
  margin: 0 0 -82px;
}
@media screen and (max-width: 834px) {
  .sec-session {
    padding: 47px 0 99px;
    background: none;
    margin: 0 0 -30px;
  }
}

.session-flex {
  max-width: 839px;
  width: 100%;
  margin: 0 auto;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}
@media screen and (max-width: 834px) {
  .session-flex {
    flex-wrap: wrap;
  }
}
.session-flex__ct {
  width: 47.5%;
  padding-right: 10px;
  font-weight: bold;
  padding-bottom: 18px;
}
@media screen and (max-width: 1200px) {
  .session-flex__ct {
    padding-right: 10px;
    width: 50%;
  }
}
@media screen and (max-width: 834px) {
  .session-flex__ct {
    width: 100%;
    padding-right: 0;
    text-align: center;
    padding-bottom: 15px;
  }
  .session-flex__ct p {
    line-height: 2.08;
  }
}
.session-flex__ttl {
  color: #277AE2;
  margin-bottom: 9px;
  font-weight: bold;
  font-size: 3rem;
}
@media screen and (max-width: 834px) {
  .session-flex__ttl {
    margin-bottom: 12px;
    font-size: 2rem;
  }
}
.session-flex__ttl span {
  display: inline-block;
  padding: 4px 6px;
  background: #FFF471;
}
@media screen and (max-width: 834px) {
  .session-flex__ttl span {
    padding: 2px 5px;
  }
}
.session-flex__img {
  border-radius: 20px;
  overflow: hidden;
  width: 52.5%;
  transform: translateX(10px);
}
@media screen and (max-width: 1200px) {
  .session-flex__img {
    width: 50%;
    transform: translateX(0);
  }
}
@media screen and (max-width: 834px) {
  .session-flex__img {
    width: calc(100% + 14px);
    margin: 0 -7px;
  }
}
.session-flex__img img {
  width: 100%;
}

.sec-tuition {
  padding: 163px 0 176px;
  background: url("../img/yakan/bg-02.png") center no-repeat;
  background-size: 100% 100%;
}
@media screen and (max-width: 834px) {
  .sec-tuition {
    padding: 100px 0 80px;
    background: url("../img/yakan/bg-03-sp.png") center no-repeat;
    background-size: 100% 100%;
  }
}

.tuition-box {
  background: #fff;
  border-radius: 20px;
  padding: 30px 58px 55px;
  text-align: center;
  max-width: 1036px;
  margin: 0 auto 71px;
}
@media screen and (max-width: 1024px) {
  .tuition-box {
    padding: 30px 30px 55px;
  }
}
@media screen and (max-width: 834px) {
  .tuition-box {
    padding: 20px 12px 35px;
  }
}
.tuition-box:last-child {
  margin-bottom: 0;
  padding-bottom: 73px;
}
@media screen and (max-width: 834px) {
  .tuition-box:last-child {
    padding-bottom: 39px;
  }
}
@media screen and (max-width: 834px) {
  .tuition-box:last-child .c-txt__basic {
    padding: 0 6px;
    letter-spacing: 1.5px;
  }
}
.tuition-box__ttl {
  margin-bottom: 24px;
  text-align: center;
  margin-top: -53px;
  position: relative;
  z-index: 2;
}
@media screen and (max-width: 834px) {
  .tuition-box__ttl {
    margin-top: -33px;
    margin-bottom: 14px;
  }
}
.tuition-box__ttl img {
  max-width: 100%;
  max-height: 43.5px;
  height: 100%;
}
@media screen and (max-width: 834px) {
  .tuition-box__ttl img {
    height: auto;
    max-height: max-content;
  }
}
@media screen and (max-width: 834px) {
  .tuition-box__ttl--02 {
    margin-top: -32px;
    margin-bottom: 34px;
  }
}
.tuition-box__desc {
  margin-bottom: 14px;
  font-weight: bold;
  line-height: 2;
  color: #1D3E62;
  font-size: 2rem;
}
@media screen and (max-width: 834px) {
  .tuition-box__desc {
    margin-bottom: 17px;
    line-height: 1.5625;
    text-align: center;
    font-size: 1.6rem;
    margin-left: -10px;
    margin-right: -10px;
  }
}
@media screen and (max-width: 374px) {
  .tuition-box__desc {
    font-size: 1.45rem;
  }
}
.tuition-box__desc span {
  background-image: -webkit-gradient(linear, left top, right top, color-stop(50%, #FFF8A1), color-stop(50%, transparent));
  background-image: -o-linear-gradient(left, #FFF8A1 50%, transparent 50%);
  background-image: linear-gradient(to right, #FFF8A1 50%, transparent 50%);
  background-size: 200% 20px;
  background-repeat: no-repeat;
  background-position: 0% 15px;
  padding-bottom: 10px;
}
@media screen and (max-width: 834px) {
  .tuition-box__desc span {
    background-position: 0% 6px;
    padding-bottom: 0px;
  }
}
.tuition-box__tags {
  margin-bottom: 45px;
}
@media screen and (max-width: 834px) {
  .tuition-box__tags {
    margin-bottom: 40px;
  }
}
.tuition-box__tags-item {
  color: #1D3E62;
  font-weight: bold;
  margin-bottom: 7px;
  font-size: 2rem;
}
@media screen and (max-width: 834px) {
  .tuition-box__tags-item {
    margin-bottom: 0;
    font-size: 1.6rem;
  }
}
.tuition-box__tags-item:last-child {
  margin-bottom: 0;
}
.tuition-box__tags-item span {
  font-size: 1.3rem;
}
@media screen and (max-width: 834px) {
  .tuition-box__tags-item span {
    font-size: 1.2rem;
  }
}
.tuition-box .c-txt__basic {
  margin-bottom: 32px;
  font-weight: bold;
}
@media screen and (max-width: 834px) {
  .tuition-box .c-txt__basic {
    margin-bottom: 25px;
    line-height: 2.13;
    font-feature-settings: "palt";
  }
}

.bg-wave-blue {
  background: #DFEEFF;
  position: relative;
}
.bg-wave-blue:before {
  content: "";
  display: inline-block;
  position: absolute;
  width: 100%;
  height: 6.369vw;
  background: url("../img/yakan/wave-before.png") no-repeat top center/cover;
  left: 0;
}
@media screen and (max-width: 834px) {
  .bg-wave-blue:before {
    height: 41px;
    background: url("../img/yakan/wave-before-sp.png") no-repeat top center/cover;
  }
}

/* ====================================================
PAGE nyushi danshi
==================================================== */
.sec-danshi {
  padding-bottom: 177px;
  overflow: hidden;
}
@media screen and (max-width: 834px) {
  .sec-danshi {
    padding-bottom: 198px;
  }
}

.danshi-heading {
  line-height: 1.875;
  margin-bottom: 63px;
}
@media screen and (max-width: 834px) {
  .danshi-heading {
    line-height: 2.083;
    margin-bottom: 55px;
  }
}
.danshi-heading__img {
  margin-bottom: 53px;
}
@media screen and (max-width: 834px) {
  .danshi-heading__img {
    margin-bottom: 33px;
  }
}
.danshi-heading__img img {
  border-radius: 20px;
}
.danshi-heading__dl:not(:last-child) {
  margin-bottom: 58px;
}
@media screen and (max-width: 834px) {
  .danshi-heading__dl:not(:last-child) {
    margin-bottom: 32px;
  }
}
.danshi-heading__dl-dt {
  margin-bottom: 10px;
}
@media screen and (max-width: 834px) {
  .danshi-heading__dl-dt {
    margin-bottom: 5px;
  }
}

.danshi-app {
  position: relative;
}
.danshi-app:before {
  content: "";
  display: inline-block;
  position: absolute;
  width: 274px;
  height: 265px;
  background: url(../img/nyushi/danshi/bg-before.png) no-repeat center/contain;
  left: -23.5%;
  bottom: -175px;
  z-index: -1;
}
@media screen and (max-width: 834px) {
  .danshi-app:before {
    width: 187px;
    height: 151px;
    left: -47px;
    bottom: -173px;
  }
}
.danshi-app .c-ttl__03 {
  margin-bottom: 15px;
}
@media screen and (max-width: 834px) {
  .danshi-app .c-ttl__03 {
    margin-bottom: 4px;
  }
}
.danshi-app__advise {
  font-size: 20px;
  font-size: 2rem;
  line-height: 1.75;
  font-weight: 700;
  color: #E0346E;
}
@media screen and (max-width: 834px) {
  .danshi-app__advise {
    font-size: 16px;
    font-size: 1.6rem;
    line-height: 1.5625;
  }
}
.danshi-app__advise:not(:last-child) {
  margin-bottom: 22px;
}
@media screen and (max-width: 834px) {
  .danshi-app__advise:not(:last-child) {
    margin-bottom: 11px;
  }
}
.danshi-app__advise a {
  color: inherit;
}
.danshi-app__advise small {
  font-size: 1.6rem;
}
@media screen and (max-width: 834px) {
  .danshi-app p {
    line-height: 2.083;
  }
}
.danshi-app p:not(:last-child) {
  margin-bottom: 33px;
}
@media screen and (max-width: 834px) {
  .danshi-app p:not(:last-child) {
    margin-bottom: 23px;
  }
}
.danshi-app .c-btn__01 {
  margin-left: 0;
}

#page-nyushi_shogakukin {
  overflow: hidden;
}

#page-nyushi_price .sec-support-system {
  padding-top: 90px;
  padding-bottom: 173px;
}
@media screen and (max-width: 834px) {
  #page-nyushi_price .sec-support-system {
    padding-bottom: 134px;
  }
}
#page-nyushi_price .sec-support-system .support-system__box-deco--01 {
  transform: translate(-85%, -35%);
}
@media screen and (max-width: 834px) {
  #page-nyushi_price .sec-support-system .support-system__box-deco--01 {
    transform: translate(0px, -89%);
  }
}
#page-nyushi_price .sec-support-system .support-system__box-deco--02 {
  top: auto;
  bottom: 0;
  transform: translate(100%, 21%);
  width: 122px;
}
@media screen and (max-width: 1024px) {
  #page-nyushi_price .sec-support-system .support-system__box-deco--02 {
    transform: translate(0, 45%);
  }
}
@media screen and (max-width: 834px) {
  #page-nyushi_price .sec-support-system .support-system__box-deco--02 {
    transform: translate(0, 106%);
    width: 60px;
    right: -19px;
  }
}
@media screen and (max-width: 374px) {
  #page-nyushi_price .sec-support-system .support-system__box-deco--02 {
    transform: translate(0, 60%);
  }
}
#page-nyushi_price .sec-support-system .support-system__box-deco--04 {
  top: auto;
  right: auto;
  bottom: 0;
  transform: translate(-88%, 89%);
  width: 280px;
}
@media screen and (max-width: 1024px) {
  #page-nyushi_price .sec-support-system .support-system__box-deco--04 {
    transform: translate(-50%, 98%);
  }
}
@media screen and (max-width: 834px) {
  #page-nyushi_price .sec-support-system .support-system__box-deco--04 {
    transform: translate(0, 100%);
    width: 160px;
    left: -53px;
  }
}

.sec-support-system {
  padding-top: 80px;
  padding-bottom: 48px;
}
@media screen and (max-width: 834px) {
  .sec-support-system {
    padding-top: 43px;
    padding-bottom: 153px;
  }
}

.support-system__box {
  margin-bottom: 77px;
  position: relative;
}
@media screen and (max-width: 834px) {
  .support-system__box {
    margin-bottom: 67px;
  }
}
.support-system__box-ttl {
  margin-bottom: 15px;
}
@media screen and (max-width: 834px) {
  .support-system__box-ttl {
    margin-bottom: 10px;
  }
}
.support-system__box-ttl small {
  font-size: 1.6rem;
}
.support-system__box-ct {
  font-weight: 700;
}
@media screen and (max-width: 834px) {
  .support-system__box-ct {
    line-height: 2.1;
  }
}
.support-system__box-ct p {
  margin-bottom: 23px;
}
@media screen and (max-width: 834px) {
  .support-system__box-ct p {
    margin-bottom: 13px;
  }
}
.support-system__box-ct p:last-child {
  margin-bottom: 0;
}
.support-system__box-guide {
  font-size: 2rem;
  color: #E0346E;
  margin-bottom: 32px;
  line-height: 1.4;
}
@media screen and (max-width: 834px) {
  .support-system__box-guide {
    font-size: 1.6rem;
  }
}
.support-system__box-guide small {
  font-size: 1.3rem;
  color: #1D3E62;
  margin-left: 10px;
}
@media screen and (max-width: 834px) {
  .support-system__box-guide small {
    display: block;
    margin-left: 0;
    font-size: 1.2rem;
  }
}
.support-system__box-btn {
  margin-bottom: 15px;
}
@media screen and (max-width: 834px) {
  .support-system__box-btn {
    margin-bottom: 10px;
  }
}
.support-system__box-btn a {
  margin-left: 0;
  margin-right: auto;
}
.support-system__box-txt-large {
  font-size: 2rem;
  color: #E0346E;
  line-height: 1.7;
  margin-bottom: 20px !important;
}
@media screen and (max-width: 834px) {
  .support-system__box-txt-large {
    font-size: 1.6rem;
    margin-bottom: 5px !important;
  }
}
.support-system__box-txt-large a {
  color: #E0346E;
  margin-left: 5px;
}
.support-system__box-txt-large span {
  font-size: 1.6rem;
}
.support-system__box-deco {
  position: absolute;
  z-index: 2;
}
.support-system__box-deco--01 {
  width: min(16.984vw, 232px);
  top: 0;
  left: 0;
  transform: translate(-71%, -86%);
}
@media screen and (max-width: 1024px) {
  .support-system__box-deco--01 {
    transform: translate(-30%, -100%);
  }
}
@media screen and (max-width: 834px) {
  .support-system__box-deco--01 {
    width: 90px;
    transform: translate(0, -100%);
    left: -28px;
  }
}
.support-system__box-deco--02 {
  width: min(11.713vw, 160px);
  top: 0;
  right: 0;
  transform: translate(75%, -70%);
}
@media screen and (max-width: 1024px) {
  .support-system__box-deco--02 {
    transform: translate(30%, -70%);
  }
}
@media screen and (max-width: 834px) {
  .support-system__box-deco--02 {
    width: 74px;
    transform: translate(0, -100%);
    top: 0;
    right: -22px;
  }
}
.support-system__box-deco--03 {
  width: min(20.498vw, 280px);
  top: 0;
  left: 0;
  transform: translate(-88%, -75%);
}
@media screen and (max-width: 1024px) {
  .support-system__box-deco--03 {
    transform: translate(-82%, -75%);
  }
}
@media screen and (max-width: 834px) {
  .support-system__box-deco--03 {
    display: none;
  }
}
.support-system__box-deco--04 {
  width: min(28.258vw, 386px);
  top: 0;
  right: 0;
  transform: translate(64%, -12%);
}
@media screen and (max-width: 834px) {
  .support-system__box-deco--04 {
    width: 180px;
    top: auto;
    right: auto;
    bottom: 0;
    left: -45px;
    transform: translate(0, 100%);
  }
}
.support-system__box-dl {
  margin-bottom: 23px;
}
@media screen and (max-width: 834px) {
  .support-system__box-dl {
    margin-bottom: 10px;
  }
}
.support-system__box-dl:last-of-type {
  margin-bottom: 0;
}
.support-system__box-dt {
  font-size: 2rem;
  color: #E0346E;
  font-weight: 700;
  margin-bottom: 14px;
  line-height: 1.4;
}
@media screen and (max-width: 834px) {
  .support-system__box-dt {
    font-size: 1.6rem;
    margin-bottom: 5px;
  }
}
.support-system__box-dd {
  font-size: 16px;
  font-size: 1.6rem;
  line-height: 1.875;
  font-family: "游ゴシック体", "Yu Gothic", YuGothic, "ヒラギノ角ゴPro W3", "Hiragino Kaku Gothic Pro", Osaka, "Meiryo", "メイリオ", "MS Pゴシック", Arial, Helvetica, Verdana, sans-serif;
}
@media screen and (max-width: 834px) {
  .support-system__box-dd {
    font-size: 1.2rem;
    line-height: 2;
  }
}
.support-system__box--star .support-system__box-deco--04 {
  top: auto;
  bottom: 0;
  transform: translate(58%, 46%);
}
@media screen and (max-width: 834px) {
  .support-system__box--star .support-system__box-deco--04 {
    transform: translate(0, 11%);
    width: 160px;
    right: -60px;
    left: auto;
  }
}
@media screen and (max-width: 374px) {
  .support-system__box--star .support-system__box-deco--04 {
    right: -125px;
  }
}
.support-system__box--note .support-system__box-deco--04 {
  width: min(21.258vw, 289px);
  bottom: 0;
  transform: translate(50%, 34%);
}
@media screen and (max-width: 834px) {
  .support-system__box--note .support-system__box-deco--04 {
    width: 140px;
    right: 40px;
    left: auto;
    bottom: -40px;
  }
}
@media screen and (max-width: 374px) {
  .support-system__box--note .support-system__box-deco--04 {
    right: 0px;
    bottom: -77px;
  }
}

.c-table {
  font-family: "游ゴシック体", "Yu Gothic", YuGothic, "ヒラギノ角ゴPro W3", "Hiragino Kaku Gothic Pro", Osaka, "Meiryo", "メイリオ", "MS Pゴシック", Arial, Helvetica, Verdana, sans-serif;
  border-radius: 20px;
  overflow: hidden;
  border: 2px solid #FFC5D9;
  border-top: none;
  margin-bottom: 25px;
}
@media screen and (max-width: 834px) {
  .c-table {
    margin-bottom: 10px;
  }
}
.c-table:last-child {
  margin-bottom: 0;
}
.c-table table {
  width: 100%;
}
.c-table table tr th, .c-table table tr td {
  width: 33.33%;
  padding: 15px 15px;
  text-align: center;
  line-height: 1.25;
}
@media screen and (max-width: 834px) {
  .c-table table tr th, .c-table table tr td {
    padding: 0 8px;
    line-height: 1.7;
  }
}
@media screen and (max-width: 375px) {
  .c-table table tr th, .c-table table tr td {
    padding: 0px 4px;
  }
}
.c-table table tr th:last-of-type, .c-table table tr td:last-of-type {
  border-right: none;
}
@media screen and (max-width: 374px) {
  .c-table table tr th, .c-table table tr td {
    font-size: 1rem;
  }
}
.c-table table tr th {
  background: #FFC5D9;
  border-right: 2px solid #fff;
  padding: 17px 15px;
}
@media screen and (max-width: 1024px) {
  .c-table table tr th {
    padding: 18px 8px;
  }
}
@media screen and (max-width: 834px) {
  .c-table table tr th {
    padding: 6px 4px;
  }
}
.c-table table tr td {
  border-right: 2px solid #FFC5D9;
  border-bottom: 2px solid #FFC5D9;
  height: 71px;
  padding: 0 15px;
}
@media screen and (max-width: 834px) {
  .c-table table tr td {
    height: 82px;
    padding: 0 4px;
  }
}
.c-table table tr:last-child td {
  border-bottom: none;
}
.c-table--large table tr td {
  padding: 24px 15px;
}
@media screen and (max-width: 834px) {
  .c-table--large table tr td {
    padding: 4px 8px;
  }
}
@media screen and (max-width: 375px) {
  .c-table--large table tr td {
    padding: 4px 4px;
  }
}
.c-table--styles02 {
  margin-bottom: 32px;
}
@media screen and (max-width: 834px) {
  .c-table--styles02 {
    margin-bottom: 10px;
  }
}
.c-table--styles02 table tr th, .c-table--styles02 table tr td {
  width: 50%;
  padding: 25px 15px;
}
@media screen and (max-width: 834px) {
  .c-table--styles02 table tr th, .c-table--styles02 table tr td {
    padding: 14px 8px;
  }
}
@media screen and (max-width: 834px) {
  .c-table--styles03 {
    margin-bottom: 20px;
  }
}
.c-table--styles03 table tr th, .c-table--styles03 table tr td {
  width: 14.18%;
}
@media screen and (max-width: 834px) {
  .c-table--styles03 table tr th, .c-table--styles03 table tr td {
    width: 25%;
  }
}
@media screen and (max-width: 834px) {
  .c-table--styles03 table tr td {
    padding: 6px 4px;
  }
}
.c-table--styles03 table tr th {
  border-bottom: 2px solid #fff;
}
@media screen and (max-width: 834px) {
  .c-table--styles03 table tr th {
    padding: 6px 4px;
  }
}
.c-table--styles03 table tr:last-child th {
  border-bottom: none;
}
.c-table--schedule {
  position: relative;
  z-index: 10;
}
.c-table--schedule table {
  background: #fff;
}
.c-table--schedule table tr th, .c-table--schedule table tr td {
  width: 16%;
}
@media screen and (max-width: 834px) {
  .c-table--schedule table tr th, .c-table--schedule table tr td {
    width: 16.57%;
  }
}
.c-table--schedule table tr th:first-child, .c-table--schedule table tr td:first-child {
  width: 18.92%;
}
@media screen and (max-width: 834px) {
  .c-table--schedule table tr th:first-child, .c-table--schedule table tr td:first-child {
    width: 16.57%;
  }
}
@media screen and (max-width: 834px) {
  .c-table--schedule table tr th, .c-table--schedule table tr td {
    line-height: 1.2;
  }
}
@media screen and (max-width: 834px) {
  .c-table--schedule table tr th {
    padding: 23px 4px;
  }
}
.c-table--schedule table tr td {
  height: 76px;
}
@media screen and (max-width: 834px) {
  .c-table--schedule table tr td {
    padding: 15px 2px;
    height: 70px;
  }
}
.c-table--blue {
  border-color: #9FCAFF;
}
.c-table--blue table tr th {
  background: #9FCAFF;
  color: #277AE2 !important;
}
.c-table--blue table tr td {
  border-color: #9FCAFF;
}
@media screen and (max-width: 834px) {
  .c-table--auto-sp table tr td {
    height: auto;
  }
}

.c-list-dots li {
  position: relative;
  padding-left: 15px;
}
@media screen and (max-width: 834px) {
  .c-list-dots li {
    padding-left: 10px;
  }
}
.c-list-dots li:before {
  content: "";
  display: inline-block;
  position: absolute;
  width: auto;
  height: auto;
  content: "・";
  top: 0;
  left: 0;
}

@media screen and (max-width: 834px) {
  .c-table-td-07 table {
    table-layout: fixed;
    font-feature-settings: "palt";
  }
}
.c-table-td-07 table tr th, .c-table-td-07 table tr td {
  width: 14.2857142857% !important;
}
.c-table-td-07 table tr td {
  padding-left: 5px;
  padding-right: 5px;
}
@media screen and (max-width: 834px) {
  .c-table-td-07 table tr td {
    padding: 7px 2px;
    word-break: break-word;
  }
}

.sec-scholarship {
  margin-top: -178px;
  padding-bottom: 89px;
  overflow: hidden;
  z-index: 3;
}
@media screen and (max-width: 834px) {
  .sec-scholarship {
    margin-top: 0;
    padding: 41px 0 80px;
  }
}

.scholarship-wrap {
  position: relative;
  margin-bottom: 71px;
}
.scholarship-wrap:last-child {
  margin-bottom: 0;
}
@media screen and (max-width: 834px) {
  .scholarship-wrap {
    margin-bottom: 45px;
  }
  .scholarship-wrap .c-txt__basic {
    line-height: 2.08;
  }
}
.scholarship-wrap--shape1:after {
  content: "";
  position: absolute;
  background: url("../img/nyushi/icon-center.png") center no-repeat;
  background-size: contain;
  width: 122px;
  height: 122px;
  top: 76px;
  right: -122px;
  pointer-events: none;
}
@media screen and (max-width: 834px) {
  .scholarship-wrap--shape1:after {
    width: 63px;
    height: 63px;
    top: -49px;
    right: -17px;
  }
}
.scholarship-wrap--shape2:after {
  content: "";
  position: absolute;
  background: url("../img/nyushi/icon-bottom.png") center no-repeat;
  background-size: contain;
  width: 281px;
  height: 275px;
  top: 87px;
  right: -200px;
  transform: rotate(53deg);
  pointer-events: none;
}
@media screen and (max-width: 834px) {
  .scholarship-wrap--shape2:after {
    width: 158px;
    height: 153px;
    top: 155px;
    right: -61px;
    transform: rotate(60deg);
  }
}
@media screen and (max-width: 374px) {
  .scholarship-wrap--shape2:after {
    right: -120px;
  }
}
.scholarship-wrap__ct {
  text-align: center;
  background: #FFF2F6;
  color: #E0346E;
  border-radius: 20px;
  padding: 41px 15px 52px;
}
@media screen and (max-width: 834px) {
  .scholarship-wrap__ct {
    padding: 25px 4px 34px;
  }
}
.scholarship-wrap__ct-price {
  font-weight: bold;
  font-size: 5rem;
  margin-top: -1px;
}
@media screen and (max-width: 834px) {
  .scholarship-wrap__ct-price {
    font-size: 2.9rem;
    margin-top: 8px;
    letter-spacing: -0.9px;
  }
}
@media screen and (max-width: 374px) {
  .scholarship-wrap__ct-price {
    font-size: 2.2rem;
  }
}
.scholarship-wrap__ct-price span {
  background-image: linear-gradient(to right, #FFF8A1 50%, transparent 50%);
  background-size: 200% 23px;
  background-repeat: no-repeat;
  background-position: 0 bottom;
}
@media screen and (max-width: 834px) {
  .scholarship-wrap__ct-price span {
    background-size: 200% 14px;
  }
}
.scholarship-wrap__ct small {
  display: block;
  font-weight: bold;
  font-size: 1.2rem;
  margin: 15px 0 -12px;
}
@media screen and (max-width: 834px) {
  .scholarship-wrap__ct small {
    margin-bottom: 2px;
  }
}
.scholarship-wrap .c-btn__01 {
  margin-left: 0;
}

#page-kyufu .mv-page__info {
  margin-top: 51px;
  padding-bottom: 137px;
}
@media screen and (max-width: 834px) {
  #page-kyufu .mv-page__info {
    margin-top: 62px;
    padding-bottom: 0;
  }
  #page-kyufu .mv-page__info:before {
    bottom: 95%;
  }
}

.sec-kyufu {
  padding-bottom: 81px;
  margin-top: -83px;
  overflow: hidden;
}
@media screen and (max-width: 834px) {
  .sec-kyufu {
    padding: 76px 0 90px;
    margin-top: -35px;
  }
}
.sec-kyufu .scholarship-wrap--shape1:after {
  top: 144px;
}
@media screen and (max-width: 834px) {
  .sec-kyufu .scholarship-wrap--shape1:after {
    top: -73px;
  }
}
.sec-kyufu .scholarship-wrap--shape2:after {
  top: 50px;
}
@media screen and (max-width: 834px) {
  .sec-kyufu .scholarship-wrap--shape2:after {
    top: 97px;
  }
}

.kyufu-list {
  counter-reset: my-sec-counter;
  margin-bottom: 57px;
}
@media screen and (max-width: 834px) {
  .kyufu-list {
    margin-bottom: 31px;
  }
}
.kyufu-list__item {
  position: relative;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
}
.kyufu-list__item:before {
  counter-increment: my-sec-counter;
  content: counter(my-sec-counter) ".";
  display: block;
  color: #E0346E;
  margin-right: 4px;
}
@media screen and (max-width: 834px) {
  .kyufu-list__item:before {
    margin-right: 3px;
  }
}

.sec-iryou {
  padding: 80px 0 86px;
}
@media screen and (max-width: 834px) {
  .sec-iryou {
    padding: 43px 0 71px;
  }
}

.iryou-txt {
  font-family: "游ゴシック体", "Yu Gothic", YuGothic, "ヒラギノ角ゴPro W3", "Hiragino Kaku Gothic Pro", Osaka, "Meiryo", "メイリオ", "MS Pゴシック", Arial, Helvetica, Verdana, sans-serif;
  font-weight: bold;
  font-size: 1.2rem;
  margin-bottom: 27px;
}
@media screen and (max-width: 834px) {
  .iryou-txt {
    font-size: 0.9rem;
    margin-bottom: 15px;
  }
}
.iryou-txt--border {
  border: 1px solid #1D3E62;
  padding: 6px 4px;
}
@media screen and (max-width: 834px) {
  .iryou-txt--border {
    padding: 8px 5px 6px;
  }
}

.iryou-wrap {
  background: #F9F9F9;
  padding: 19px 37px 23px;
}
@media screen and (max-width: 640px) {
  .iryou-wrap {
    padding: 10px 20px 18px;
  }
}
.iryou-wrap__item {
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  font-size: 16px;
  font-size: 1.6rem;
  line-height: 2.1875;
  font-family: "游ゴシック体", "Yu Gothic", YuGothic, "ヒラギノ角ゴPro W3", "Hiragino Kaku Gothic Pro", Osaka, "Meiryo", "メイリオ", "MS Pゴシック", Arial, Helvetica, Verdana, sans-serif;
}
@media screen and (max-width: 640px) {
  .iryou-wrap__item {
    font-size: 10px;
    font-size: 1rem;
    line-height: 2;
  }
}
.iryou-wrap__ttl {
  width: 143px;
  font-weight: bold;
}
@media screen and (max-width: 834px) {
  .iryou-wrap__ttl {
    width: 80px;
  }
}
.iryou-wrap__txt {
  width: calc(100% - 143px);
  font-weight: 500;
}
.iryou-wrap__txt a {
  color: #1D3E62;
}
@media screen and (max-width: 834px) {
  .iryou-wrap__txt {
    width: calc(100% - 80px);
  }
}

.sec-newspage {
  position: relative;
  padding-bottom: 91px;
  z-index: 1;
}
@media screen and (max-width: 834px) {
  .sec-newspage {
    padding: 25px 0 67px;
  }
}
.sec-newspage:before {
  content: "";
  position: absolute;
  background: url("../img/news/bg-news.png") top center no-repeat;
  background-size: 100%;
  width: 100vw;
  height: 102%;
  top: -37px;
  left: 50%;
  transform: translateX(-50%);
  z-index: -1;
  pointer-events: none;
}
@media screen and (max-width: 640px) {
  .sec-newspage:before {
    background: url("../img/news/bg-news-sp.png") top center no-repeat;
    background-size: 100%;
    top: -17px;
  }
}
@media screen and (max-width: 640px) {
  .sec-newspage:after {
    content: "";
    position: absolute;
    background: url("../img/news/icon-news.png") top center no-repeat;
    background-size: 100%;
    width: 88px;
    height: 102px;
    bottom: 25px;
    right: 1%;
    z-index: -1;
    pointer-events: none;
  }
}

.newspage-list {
  margin-bottom: 103px;
}
@media screen and (max-width: 834px) {
  .newspage-list {
    margin-bottom: 79px;
  }
}
.newspage-list__item {
  position: relative;
}
.newspage-list__item:before {
  content: "";
  position: absolute;
  background: linear-gradient(90deg, #FFDBE7, #FFEB9A);
  width: 100%;
  height: 10px;
  border-radius: 5px;
  top: 0;
  left: 0;
}
@media screen and (max-width: 834px) {
  .newspage-list__item:before {
    height: 5px;
  }
}
.newspage-list__item:last-child:after {
  content: "";
  position: absolute;
  background: linear-gradient(90deg, #FFDBE7, #FFEB9A);
  width: 100%;
  height: 10px;
  border-radius: 5px;
  bottom: -10px;
  left: 0;
}
@media screen and (max-width: 834px) {
  .newspage-list__item:last-child:after {
    height: 5px;
    bottom: -4px;
  }
}
.newspage-list__item a {
  display: block;
  background: white;
  color: #1D3E62;
  font-weight: bold;
  padding: 42px 0 29px;
}
@media screen and (max-width: 834px) {
  .newspage-list__item a {
    padding: 32px 0 25px;
  }
}
.newspage-list__item p {
  display: -webkit-box;
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  max-height: 70px;
  font-family: "游ゴシック体", "Yu Gothic", YuGothic, "ヒラギノ角ゴPro W3", "Hiragino Kaku Gothic Pro", Osaka, "Meiryo", "メイリオ", "MS Pゴシック", Arial, Helvetica, Verdana, sans-serif;
}
@media screen and (max-width: 640px) {
  .newspage-list__item p {
    -webkit-line-clamp: 3;
    max-height: 60px;
  }
}
.newspage-list__date {
  font-weight: bold;
  font-size: 1.6rem;
  line-height: 1;
  margin-bottom: 12px;
}
@media screen and (max-width: 834px) {
  .newspage-list__date {
    font-size: 1.2rem;
    margin-bottom: 10px;
  }
}
.newspage-list__ttl {
  display: block;
  color: #E0346E;
  font-weight: bold;
  font-size: 30px;
  font-size: 3rem;
  line-height: 1.6666666667;
  margin: 0 -4px 6px 0;
}
@media screen and (max-width: 834px) {
  .newspage-list__ttl {
    font-size: 16px;
    font-size: 1.6rem;
    line-height: 1.5625;
    margin-bottom: 10px;
  }
}
.newspage-list__ttl span {
  background-image: linear-gradient(to right, #E0346E 50%, transparent 50%);
  background-size: 200% 1px;
  background-repeat: no-repeat;
  background-position: 0 bottom;
  padding-bottom: 3px;
}

.newspage-btn {
  text-align: center;
}
.newspage-btn .page-numbers {
  display: none;
}
.newspage-btn .prev, .newspage-btn .next {
  display: inline-block;
}
.newspage-btn a {
  color: #1D3E62;
  font-family: "游ゴシック体", "Yu Gothic", YuGothic, "ヒラギノ角ゴPro W3", "Hiragino Kaku Gothic Pro", Osaka, "Meiryo", "メイリオ", "MS Pゴシック", Arial, Helvetica, Verdana, sans-serif;
  font-weight: bold;
  font-size: 1.6rem;
  text-decoration: underline;
  margin: 0 20px;
}
@media screen and (max-width: 834px) {
  .newspage-btn a {
    font-size: 1.2rem;
  }
}

#page-news_detail .sec-newsdetail:before {
  display: none;
}

.sec-newsdetail {
  padding: 58px 0 95px;
}
@media screen and (max-width: 834px) {
  .sec-newsdetail {
    position: relative;
    padding: 29px 0 67px;
  }
  .sec-newsdetail:before {
    content: "";
    position: absolute;
    background: url("../img/news/bg-detail.jpg") top center repeat-y;
    background-size: 100%;
    width: 100%;
    height: calc(100% + 87px);
    top: -87px;
    left: 0;
    z-index: -1;
  }
}

.newsdetail-info {
  background: #FFF2F6;
  border-radius: 20px;
  padding: 18px 25px 13px;
  margin-bottom: 12px;
}
@media screen and (max-width: 834px) {
  .newsdetail-info {
    padding: 22px 20px 21px;
    margin-bottom: 24px;
  }
}
.newsdetail-info__date {
  display: block;
  font-weight: bold;
  font-size: 1.6rem;
  line-height: 1;
  margin-bottom: 9px;
}
@media screen and (max-width: 834px) {
  .newsdetail-info__date {
    font-size: 1.2rem;
    margin-bottom: 10px;
  }
}

/* ====================================================
PAGE gakko_annai_shushoku
==================================================== */
@media screen and (max-width: 834px) {
  #page-gakko_annai_shushoku .sec-look {
    margin-top: -12px;
  }
}
@media screen and (min-width: 835px) {
  #page-gakko_annai_shushoku .sec-look::before {
    top: 35%;
  }
}

/* ====================================================
PAGE page-gakko_annai_access
==================================================== */
#page-gakko_annai_access .mv-page__info--shushoku {
  padding-bottom: 36px;
  margin-bottom: 49px;
}
@media screen and (max-width: 834px) {
  #page-gakko_annai_access .mv-page__info--shushoku {
    padding-bottom: 78px;
    margin-bottom: 0;
  }
  #page-gakko_annai_access .mv-page__info--shushoku::after {
    top: -64px;
    z-index: 1;
  }
}

.sec-access {
  padding-bottom: 155px;
  overflow: hidden;
}
@media screen and (max-width: 834px) {
  .sec-access {
    padding-bottom: 105px;
    margin-top: -10px;
  }
}
.sec-access__inner {
  position: relative;
}
.sec-access__inner:before {
  content: "";
  display: inline-block;
  position: absolute;
  width: 385px;
  height: 443px;
  background: url(../img/gakko_annai/img-bg.png) no-repeat center/contain;
  top: 34.5%;
  right: -32.7%;
  z-index: -1;
}
@media screen and (max-width: 834px) {
  .sec-access__inner:before {
    width: 193px;
    height: 222px;
    top: 41.2%;
    right: -29px;
  }
}
.sec-access__inner:after {
  content: "";
  display: inline-block;
  position: absolute;
  width: 172px;
  height: 172px;
  background: url(../img/gakko_annai/img-pen.png) no-repeat center/contain;
  bottom: -132px;
  left: -12%;
  z-index: -1;
}
@media screen and (max-width: 834px) {
  .sec-access__inner:after {
    width: 95px;
    height: 95px;
    bottom: -93px;
    left: -1px;
  }
}

.access-contact {
  font-weight: 700;
}
@media screen and (max-width: 834px) {
  .access-contact {
    line-height: 2.083;
  }
}
.access-contact__map {
  margin-bottom: 26px;
  position: relative;
}
@media screen and (max-width: 834px) {
  .access-contact__map {
    margin-bottom: 10px;
  }
}
.access-contact__map::before {
  content: "";
  padding-top: 56.4%;
  display: block;
}
@media screen and (max-width: 834px) {
  .access-contact__map::before {
    padding-top: 56.3739376771%;
  }
}
.access-contact__map iframe {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
}
.access-contact__info {
  margin-bottom: 82px;
}
@media screen and (max-width: 834px) {
  .access-contact__info {
    margin-bottom: 50px;
  }
}
.access-contact__info dl dt {
  width: 60px;
  display: inline-block;
}
@media screen and (max-width: 834px) {
  .access-contact__info dl dt {
    width: 44px;
  }
}
.access-contact__info dl dd {
  display: inline;
}
.access-contact__route {
  margin-bottom: 34px;
}
@media screen and (max-width: 834px) {
  .access-contact__route {
    margin-bottom: 16px;
  }
}

/* ====================================================
PAGE page-gakko_annai_syusyoku100
==================================================== */
#page-gakko_annai_syusyoku100 .mv-page__info--shushoku {
  padding-bottom: 104px;
  margin-bottom: 0;
}
@media screen and (max-width: 834px) {
  #page-gakko_annai_syusyoku100 .mv-page__info--shushoku {
    margin-bottom: 14px;
  }
  #page-gakko_annai_syusyoku100 .mv-page__info--shushoku::after {
    top: -64px;
    z-index: 1;
  }
}
#page-gakko_annai_syusyoku100 .mv-page__info--shushoku .c-txt__basic {
  max-width: 863px;
  margin: 0 auto;
}
#page-gakko_annai_syusyoku100 .mv-page__info--shushoku::before {
  bottom: -11px;
  left: -10.5%;
}
@media screen and (max-width: 834px) {
  #page-gakko_annai_syusyoku100 .mv-page__info--shushoku::before {
    bottom: -5px;
    left: -68px;
  }
}

.sec-syusyoku100 {
  margin-top: -29px;
  padding-bottom: 238px;
  z-index: 12;
}

.syusyoku100-heading {
  font-weight: 700;
}
.syusyoku100-heading__ttl {
  margin-bottom: 15px;
}

/* ====================================================
PAGE page-gakko_annai_shinro_soudan
==================================================== */
#page-gakko_annai_shinro_soudan .mv-page__info {
  padding-bottom: 101px;
  margin-bottom: 8px;
}
@media screen and (max-width: 834px) {
  #page-gakko_annai_shinro_soudan .mv-page__info {
    padding-bottom: 105px;
    margin-bottom: 12px;
  }
}
@media screen and (min-width: 835px) {
  #page-gakko_annai_shinro_soudan .mv-page__info::before {
    left: -10.5%;
  }
}
#page-gakko_annai_shinro_soudan .mv-page__info::after {
  top: -34px;
  right: -6.9%;
}
@media screen and (max-width: 834px) {
  #page-gakko_annai_shinro_soudan .mv-page__info::after {
    top: -64px;
    right: -31px;
    z-index: 1;
  }
}
@media screen and (min-width: 835px) {
  #page-gakko_annai_shinro_soudan .sec-look--icobefore:before {
    top: -13%;
  }
}

.sec-shinro_soudan {
  padding-bottom: 184px;
}
@media screen and (max-width: 834px) {
  .sec-shinro_soudan {
    padding-bottom: 95px;
  }
}

.soudan-list {
  font-weight: 700;
}
.soudan-list__item:not(:last-child) {
  margin-bottom: 36px;
}
@media screen and (max-width: 834px) {
  .soudan-list__item:not(:last-child) {
    margin-bottom: 25px;
  }
}
.soudan-list__dt {
  margin-bottom: 17px;
}
.soudan-list__dd span {
  font-size: 30px;
  font-size: 3rem;
  line-height: 1.6666666667;
  color: #E0346E;
}
@media screen and (max-width: 834px) {
  .soudan-list__dd span {
    font-size: 16px;
    font-size: 1.6rem;
    line-height: 1.25;
  }
}

.sec-campus {
  padding: 80px 0 250px;
  background: url("../img/campus_life/bg-01.png") bottom center no-repeat;
  background-size: 101% 96.8%;
}
@media screen and (max-width: 834px) {
  .sec-campus {
    background: url("../img/campus_life/bg-01-sp.png") bottom center no-repeat;
    background-size: 101% 100%;
    background-position: top 54.7vw center;
    padding: 31px 0 167px;
  }
}
.sec-campus::before {
  content: "";
  width: 190px;
  height: 213px;
  background: url("../img/campus_life/icon-01.png") center no-repeat;
  background-size: contain;
  position: absolute;
  bottom: -16px;
  left: 63px;
}
@media screen and (max-width: 834px) {
  .sec-campus::before {
    bottom: 47px;
    left: 8px;
    width: 92px;
    height: 98px;
    z-index: 3;
  }
}

.campus-img {
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  margin: -100px -14px 91px;
}
@media screen and (max-width: 834px) {
  .campus-img {
    justify-content: center;
    margin: 0 -6px 53px;
    flex-wrap: wrap;
  }
}
.campus-img__item {
  width: 33.33%;
  padding: 0 14px;
  overflow: hidden;
}
@media screen and (max-width: 834px) {
  .campus-img__item {
    padding: 0 6px;
    width: 50%;
    margin-bottom: 8px;
  }
}
.campus-img__img {
  position: relative;
  overflow: hidden;
  border-radius: 20px;
}
.campus-img__img:before {
  content: "";
  display: block;
  padding-bottom: 74.0157480315%;
}
.campus-img__img-thumb {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
}
.campus-img__img-thumb img {
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
  object-fit: cover;
}

.campus-list__item {
  margin-bottom: 70px;
}
@media screen and (max-width: 834px) {
  .campus-list__item {
    margin-bottom: 37px;
  }
}
.campus-list__item:last-child {
  margin-bottom: 0;
}
.campus-list__item:nth-child(2n) .campus-list__heading {
  text-align: left;
}
@media screen and (max-width: 834px) {
  .campus-list__item:nth-child(2n) .campus-list__heading {
    padding-left: 20px;
    padding-right: 0;
  }
}
.campus-list__heading {
  text-align: right;
  margin-bottom: -7px;
}
.campus-list__heading img {
  max-width: 100%;
  display: inline-block;
  height: 100%;
  max-height: 50px;
}
@media screen and (max-width: 834px) {
  .campus-list__heading img {
    max-height: 30px;
    padding-right: 19px;
    margin-bottom: 2px;
  }
}
.campus-list__flex {
  background: #fff;
  border-radius: 20px;
  padding: 47px 46px 55px;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}
@media screen and (max-width: 1200px) {
  .campus-list__flex {
    padding: 40px 25px;
    flex-wrap: wrap-reverse;
  }
}
@media screen and (max-width: 834px) {
  .campus-list__flex {
    padding: 18px 20px 21px;
  }
}
.campus-list__img {
  width: 45.75%;
  border-radius: 20px;
  overflow: hidden;
}
@media screen and (max-width: 834px) {
  .campus-list__img {
    width: 100%;
    margin-bottom: 15px;
  }
}
.campus-list__img img {
  width: 100%;
}
.campus-list__ct {
  width: 54.25%;
  padding-left: 47px;
  padding-bottom: 4px;
}
@media screen and (max-width: 1200px) {
  .campus-list__ct {
    padding-left: 25px;
  }
}
@media screen and (max-width: 834px) {
  .campus-list__ct {
    padding-left: 0;
    width: 100%;
  }
}
.campus-list__ct p {
  margin-bottom: 15px;
  letter-spacing: 0.1px;
  font-weight: bold;
}
@media screen and (max-width: 834px) {
  .campus-list__ct p {
    margin-bottom: 16px;
    letter-spacing: 0;
    line-height: 2.05;
  }
}
.campus-list__ttl {
  font-weight: bold;
  font-feature-settings: "palt" 1;
  color: #E0346E;
  line-height: 1.667;
  letter-spacing: 0;
  margin-bottom: 4px;
  font-size: 3rem;
}
@media screen and (max-width: 1200px) {
  .campus-list__ttl {
    font-size: 2.4rem;
  }
}
@media screen and (max-width: 834px) {
  .campus-list__ttl {
    margin-bottom: 19px;
    line-height: 1.5;
    letter-spacing: 1.25px;
    font-size: 2rem;
  }
}
.campus-list__ttl span {
  letter-spacing: 2px;
  display: inline-block;
  margin-bottom: 11px;
  border-radius: 32px;
  background: #E0346E;
  padding: 8px 15px;
  text-align: center;
  min-width: 128px;
  line-height: 1.2;
  color: #fff;
  font-weight: bold;
  font-size: 1.6rem;
}
@media screen and (max-width: 834px) {
  .campus-list__ttl span {
    padding: 6px 13px;
    min-width: 92px;
    margin-bottom: 20px;
    font-size: 1.2rem;
  }
}

.sec-look--02 {
  position: relative;
  padding: 151px 0 182px;
}
@media screen and (max-width: 834px) {
  .sec-look--02 {
    padding: 113px 0 87px;
    background: url("../img/campus_life/bg-02.png") top center no-repeat;
    background-size: 100%;
    margin-top: -76px;
  }
  .sec-look--02 .inner {
    z-index: 2;
    position: relative;
  }
}
.sec-look--02::before, .sec-look--02::after {
  content: "";
  width: 386px;
  height: 444px;
  background: url("../img/campus_life/icon-03.png") center no-repeat;
  background-size: contain;
  position: absolute;
  bottom: -9%;
  left: -2.5%;
  z-index: -1;
}
@media screen and (max-width: 834px) {
  .sec-look--02::before, .sec-look--02::after {
    width: 246px;
    height: 283px;
    bottom: 0.2%;
    left: -6.5%;
    z-index: 1;
  }
}
.sec-look--02::after {
  bottom: auto;
  top: 3.8%;
  right: 6%;
  left: auto;
  width: 197px;
  height: 131px;
  background: url("../img/campus_life/icon-02.png") center no-repeat;
  background-size: contain;
}
@media screen and (max-width: 834px) {
  .sec-look--02::after {
    width: 91px;
    height: 61px;
    top: 4.3%;
    right: 5%;
    z-index: 2;
  }
}

.sec-question {
  padding: 72px 0 98px;
}
@media screen and (max-width: 834px) {
  .sec-question {
    padding: 40px 0 68px;
  }
}

.question-note {
  font-weight: bold;
}
@media screen and (max-width: 834px) {
  .question-note {
    line-height: 2.0833333333;
  }
}
.question-note__item {
  margin-bottom: 69px;
}
@media screen and (max-width: 834px) {
  .question-note__item {
    margin-bottom: 31px;
  }
}
.question-note__item:last-child {
  margin-bottom: 0;
}
.question-note__item .c-ttl__03 {
  margin-bottom: 16px;
}
@media screen and (max-width: 834px) {
  .question-note__item .c-ttl__03 {
    line-height: 1.5;
    padding-bottom: 19px;
    margin-bottom: 6px;
  }
}

.sec-privacy {
  padding: 72px 0 91px;
}
@media screen and (max-width: 834px) {
  .sec-privacy {
    padding: 33px 0 53px;
  }
}

.privacy-note {
  font-weight: bold;
  margin-bottom: 51px;
}
@media screen and (max-width: 834px) {
  .privacy-note {
    line-height: 2.0833333333;
    margin-bottom: 37px;
  }
}
.privacy-note a {
  color: #1D3E62;
}
.privacy-note .c-ttl__03 {
  margin-bottom: 9px;
}
@media screen and (max-width: 834px) {
  .privacy-note .c-ttl__03 {
    padding-bottom: 24px;
    margin-bottom: 8px;
    font-size: 20px;
    font-size: 2rem;
    line-height: 1.25;
  }
}
.privacy-note .c-btn__01 {
  background: #FFF2F6;
  margin-left: 0;
  color: #E0346E;
}
.privacy-note .c-fc--pink {
  font-size: 20px;
  font-size: 2rem;
  line-height: 1.75;
}
@media screen and (max-width: 834px) {
  .privacy-note .c-fc--pink {
    font-size: 1.6rem;
  }
}
.privacy-note .c-fc--pink small {
  font-size: 1.6rem;
}
.privacy-note p {
  margin-bottom: 10px;
}
@media screen and (max-width: 834px) {
  .privacy-note p {
    margin-bottom: 23px;
  }
}
.privacy-note p:last-child {
  margin-bottom: 0;
}
.privacy-note .c-txt__basic {
  font-family: "游ゴシック体", "Yu Gothic", YuGothic, "ヒラギノ角ゴPro W3", "Hiragino Kaku Gothic Pro", Osaka, "Meiryo", "メイリオ", "MS Pゴシック", Arial, Helvetica, Verdana, sans-serif;
}

.sec-character {
  padding: 15px 0 78px;
}
@media screen and (max-width: 834px) {
  .sec-character {
    padding: 13px 0 54px;
  }
}

.character-note {
  font-weight: bold;
  margin-bottom: 51px;
}
@media screen and (max-width: 834px) {
  .character-note {
    line-height: 2.0833333333;
    margin-bottom: 36px;
  }
}
.character-note .c-fc--pink {
  display: block;
  font-size: 3rem;
  line-height: 1.2;
  margin-bottom: 11px;
}
@media screen and (max-width: 834px) {
  .character-note .c-fc--pink {
    font-size: 2rem;
    margin-bottom: 17px;
  }
}
.character-note .c-ttl__03 {
  margin-bottom: 9px;
}
@media screen and (max-width: 834px) {
  .character-note .c-ttl__03 {
    font-size: 2rem;
    padding-bottom: 26px;
  }
}
.character-note p {
  margin-bottom: 35px;
}
@media screen and (max-width: 834px) {
  .character-note p {
    margin-bottom: 24px;
  }
}
.character-note p:last-child {
  margin-bottom: 0;
}

@media screen and (max-width: 374px) {
  #page-event .mv-page .c-txt__basic {
    margin: 0 -10px;
  }
}

.sec-benefits {
  padding: 193px 0 120px;
}
@media screen and (min-width: 1681px) {
  .sec-benefits {
    margin-top: 50px;
  }
}
@media screen and (max-width: 640px) {
  .sec-benefits {
    padding: 26.1vw 0 92px;
  }
}
.sec-benefits .dental-department:after {
  bottom: -3.1vw;
}
.sec-benefits .dental-department .inner {
  max-width: 1240px;
}
@media screen and (min-width: 835px) {
  .sec-benefits .dental-department .inner {
    top: -123px;
  }
}
@media screen and (max-width: 834px) {
  .sec-benefits .dental-department .inner {
    padding-bottom: 146px;
  }
}
.sec-benefits .dental-department__deco-01 {
  top: -17.7vw;
}
@media screen and (max-width: 834px) {
  .sec-benefits .dental-department__deco-01 {
    top: -28.6vw;
    left: -6px;
  }
}
.sec-benefits .dental-department__deco-02 {
  right: -63px;
  top: 28.3%;
}
@media screen and (max-width: 834px) {
  .sec-benefits .dental-department__deco-02 {
    right: -88px;
    top: 27.6%;
  }
}
.sec-benefits .dental-department__deco-03 {
  bottom: -1.6vw;
  right: 43px;
}
@media screen and (max-width: 834px) {
  .sec-benefits .dental-department__deco-03 {
    bottom: -7.8vw;
    right: 20px;
    width: 96px;
  }
}
.sec-benefits .dental-department--night {
  background: linear-gradient(124deg, #9fcaff 0%, #ffeb9a 100%);
}
@media screen and (max-width: 640px) {
  .sec-benefits .dental-department--night {
    background: linear-gradient(166deg, #9fcaff 0%, #ffeb9a 100%);
  }
}
.sec-benefits .dental-department--night:after {
  bottom: -10vw;
}
@media screen and (max-width: 834px) {
  .sec-benefits .dental-department--night:after {
    display: none;
  }
}
.sec-benefits .dental-department--night .inner {
  top: -43px;
}
@media screen and (max-width: 834px) {
  .sec-benefits .dental-department--night .inner {
    top: -67px;
  }
}
.sec-benefits .dental-department--night .dental-department__deco-01 {
  top: -13.4vw;
  left: -38px;
}
@media screen and (max-width: 834px) {
  .sec-benefits .dental-department--night .dental-department__deco-01 {
    top: -45vw;
    left: -35px;
  }
}
@media screen and (max-width: 834px) {
  .sec-benefits .dental-department--night .dental-department__deco-02 {
    top: 31.8%;
  }
}
.sec-benefits .dental-department--night .dental-department__deco-03 {
  bottom: 1.4vw;
}
@media screen and (max-width: 834px) {
  .sec-benefits .dental-department--night .dental-department__deco-03 {
    bottom: 205px;
    width: 149px;
    left: 0;
  }
}
@media screen and (max-width: 640px) {
  .sec-benefits .dental-department--night .dental-department__deco-03 {
    bottom: 192px;
  }
}
.sec-benefits .dental-department--gray {
  background: #F5F5F5;
  margin-top: -17vw;
  padding: 22.8vw 0 124px;
}
@media screen and (max-width: 834px) {
  .sec-benefits .dental-department--gray {
    margin-top: -112px;
    padding: 160px 0 52px;
  }
  .sec-benefits .dental-department--gray .inner {
    padding-bottom: 0;
  }
}
@media screen and (max-width: 640px) {
  .sec-benefits .dental-department--gray {
    padding-top: 134px;
  }
}
.sec-benefits .dental-department--gray:before {
  display: none;
}
@media screen and (max-width: 834px) {
  .sec-benefits .dental-department--gray:before {
    content: "";
    position: absolute;
    display: block;
    background: url("../img/common/wave-gray-sp.png") top center no-repeat;
    background-size: 100% 100%;
    width: 100%;
    height: 36px;
    top: -35px;
    left: 50%;
    transform: translateX(-50%);
    pointer-events: none;
  }
}
.sec-benefits .dental-department--gray:after {
  content: "";
  position: absolute;
  background: url("../img/common/wave-white.png") top center no-repeat;
  background-size: 100% 100%;
  width: 100%;
  height: 78px;
  bottom: -1px;
  left: 50%;
  transform: translateX(-50%);
  pointer-events: none;
}
@media screen and (max-width: 834px) {
  .sec-benefits .dental-department--gray:after {
    display: block;
    background: url("../img/common/wave-white-sp.png") top center no-repeat;
    background-size: 100% 100%;
    height: 36px;
  }
}
.sec-benefits .c-ttl__gradient--large span {
  min-width: 382px;
}
@media screen and (max-width: 834px) {
  .sec-benefits .c-ttl__gradient--large span {
    min-width: 190px;
    padding: 7px 20px;
  }
}

.benefits-list {
  position: relative;
  z-index: 3;
}
.benefits-list__item {
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  background: white;
  border-radius: 20px;
  padding: 44px 45px 36px 48px;
  margin-bottom: 50px;
}
.benefits-list__item:last-child {
  margin-bottom: 0;
}
@media screen and (max-width: 834px) {
  .benefits-list__item {
    padding: 21px 20px 29px;
    margin-bottom: 28px;
  }
  .benefits-list__item .c-txt__basic {
    line-height: 2.08;
  }
}
.benefits-list__ct {
  width: 50%;
}
@media screen and (max-width: 834px) {
  .benefits-list__ct {
    width: 100%;
    margin-bottom: 13px;
  }
}
.benefits-list__ct-ttl {
  color: #E0346E;
  font-weight: bold;
  font-size: 3rem;
  margin-bottom: 12px;
}
@media screen and (max-width: 834px) {
  .benefits-list__ct-ttl {
    font-size: 2.1rem;
    line-height: 1.5;
    margin-bottom: 11px;
  }
}
@media screen and (min-width: 835px) {
  .benefits-list__ct-ttl img {
    margin-left: 18px;
  }
}
.benefits-list__timeline {
  font-weight: bold;
  margin-top: 28px;
}
@media screen and (max-width: 834px) {
  .benefits-list__timeline {
    line-height: 2.08;
    margin-top: 19px;
  }
}
.benefits-list__timeline-item {
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
  margin-bottom: 9px;
}
@media screen and (max-width: 834px) {
  .benefits-list__timeline-item {
    margin-bottom: 11px;
  }
}
.benefits-list__timeline-ttl {
  text-align: center;
  color: #E0346E;
  width: 110px;
  letter-spacing: 3px;
}
@media screen and (max-width: 834px) {
  .benefits-list__timeline-ttl {
    width: 95px;
    letter-spacing: 2px;
  }
}
.benefits-list__timeline-ttl span {
  display: block;
  width: 100%;
  background: #FFF2F6;
  border-radius: 20px;
  padding: 0 5px 1px;
  margin: 3px 0 2px;
  letter-spacing: 0;
}
@media screen and (max-width: 834px) {
  .benefits-list__timeline-ttl span {
    padding: 3px 5px 2px;
    margin: 0 0 3px;
  }
}
.benefits-list__timeline-ttl img {
  margin-left: 18px;
}
.benefits-list__timeline-txt {
  width: calc(100% - 124px);
}
@media screen and (max-width: 834px) {
  .benefits-list__timeline-txt {
    width: calc(100% - 110px);
    line-height: 2.08;
  }
}
.benefits-list__img {
  width: 45.4%;
}
@media screen and (max-width: 834px) {
  .benefits-list__img {
    width: 100%;
  }
}
.benefits-list__img-thumb {
  position: relative;
  border-radius: 20px;
  overflow: hidden;
  margin-bottom: 32px;
}
@media screen and (max-width: 834px) {
  .benefits-list__img-thumb {
    margin: 18px 0 0;
  }
}
.benefits-list__img-thumb:before {
  content: "";
  display: block;
  padding-bottom: 66.7330677291%;
}
.benefits-list__img-thumb img {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  object-fit: cover;
}
@media screen and (min-width: 835px) {
  .benefits-list__img .c-btn__01 {
    margin-right: 0;
  }
}
.benefits-list__img .c-btn__01 span {
  padding: 0 34px 0 14px;
}
@media screen and (max-width: 834px) {
  .benefits-list__img .c-btn__01 span {
    padding: 0 23px;
  }
}
.benefits-list--blue .benefits-list__ct-ttl {
  color: #277AE2;
}
.benefits-list--blue .benefits-list__timeline-ttl {
  color: #277AE2;
}
.benefits-list--blue .benefits-list__timeline-ttl span {
  background: #DFEEFF;
}
.benefits-list--blue .benefits-list__img .c-btn__01 {
  background: #277AE2;
  border-bottom-color: #0E3E7A;
}
.benefits-list--gradient .benefits-list__timeline-ttl {
  color: white;
}
.benefits-list--gradient .benefits-list__timeline-ttl span {
  background: linear-gradient(90deg, #FF5890, #FBB800);
}
.benefits-list--gradient .benefits-list__img .c-btn__01 {
  height: 69px;
  border-bottom: 0;
  z-index: 1;
}
@media screen and (max-width: 834px) {
  .benefits-list--gradient .benefits-list__img .c-btn__01 {
    height: 56px;
  }
}
.benefits-list--gradient .benefits-list__img .c-btn__01:before {
  content: "";
  position: absolute;
  background: linear-gradient(90deg, #FF5890, #FBB800);
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  border-radius: 40px;
  z-index: -1;
}
@media screen and (max-width: 834px) {
  .benefits-list--gradient .benefits-list__img .c-btn__01:before {
    border-radius: 30px;
  }
}
.benefits-list--gradient .benefits-list__img .c-btn__01:after {
  content: "";
  position: absolute;
  background: linear-gradient(90deg, #BB003F, #AF8100);
  width: 100%;
  height: 100%;
  bottom: -7px;
  left: 0;
  border-radius: 40px;
  transition: 0.3s ease;
  z-index: -2;
}
@media screen and (max-width: 834px) {
  .benefits-list--gradient .benefits-list__img .c-btn__01:after {
    border-radius: 30px;
  }
}
.benefits-list--gradient .benefits-list__img .c-btn__01:active {
  transform: translate(0px, 7px);
}
.benefits-list--gradient .benefits-list__img .c-btn__01:active:after {
  transform: translate(0px, -7px);
}

.benefits-btn {
  position: relative;
  display: block;
  width: 100%;
  max-width: 378px;
  height: 58px;
  background: #FFF2F6;
  color: #E0346E;
  border-radius: 20px;
  font-weight: bold;
  font-size: 2rem;
  padding: 8px 5px 5px 59px;
}
@media screen and (max-width: 834px) {
  .benefits-btn {
    max-width: 286px;
    height: 44px;
    font-size: 1.4rem;
    padding: 6px 5px 5px 45px;
  }
}
.benefits-btn:before {
  content: "";
  position: absolute;
  background: url("../img/event/icon-tel.svg") center no-repeat;
  background-size: contain;
  width: 29px;
  height: 38px;
  left: 23px;
  top: 50%;
  transform: translateY(-50%);
}
@media screen and (max-width: 834px) {
  .benefits-btn:before {
    width: 22px;
    height: 29px;
    left: 18px;
  }
}
.benefits-btn span {
  font-size: 3rem;
}
@media screen and (max-width: 834px) {
  .benefits-btn span {
    font-size: 2.1rem;
  }
}
.benefits-btn--mail {
  letter-spacing: -1px;
  padding: 15px 5px 5px 69px;
}
@media screen and (max-width: 834px) {
  .benefits-btn--mail {
    letter-spacing: -0.7px;
    padding: 11px 5px 5px 53px;
  }
}
.benefits-btn--mail:before {
  background: url("../img/event/icon-mail.svg") center no-repeat;
  background-size: contain;
  width: 37px;
  height: 29px;
}
@media screen and (max-width: 834px) {
  .benefits-btn--mail:before {
    width: 28px;
    height: 22px;
  }
}

.sec-interview {
  position: relative;
  margin-top: -82px;
  padding-bottom: 67px;
  z-index: 1;
}
@media screen and (max-width: 834px) {
  .sec-interview {
    margin-top: -39px;
    padding-bottom: 62px;
  }
}
.sec-interview:before {
  content: "";
  position: absolute;
  background: url("../img/campus_life/interview/bg-interview-pc.png") center top no-repeat;
  background-size: 100%;
  width: 100%;
  height: calc(100% + 498px);
  top: -498px;
  left: 0;
  pointer-events: none;
}
@media screen and (max-width: 640px) {
  .sec-interview:before {
    background: url("../img/campus_life/interview/bg-interview-sp.png") center top no-repeat;
    background-size: 100%;
    height: calc(100% + 369px);
    top: -369px;
  }
}

.interview-list__item {
  position: relative;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-align-items: flex-start;
  -ms-flex-align: start;
  align-items: flex-start;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  padding: 78px 0 75px;
}
@media screen and (max-width: 834px) {
  .interview-list__item {
    flex-direction: column-reverse;
    padding: 23px 0 28px;
  }
}
.interview-list__item:before {
  content: "";
  position: absolute;
  background: linear-gradient(90deg, #FFDBE7, #FFEB9A);
  width: 100%;
  height: 10px;
  border-radius: 5px;
  top: 0;
  left: 0;
}
@media screen and (max-width: 834px) {
  .interview-list__item:before {
    height: 5px;
  }
}
.interview-list__ct {
  width: 48%;
}
@media screen and (max-width: 834px) {
  .interview-list__ct {
    width: 100%;
  }
}
.interview-list__ct .c-txt__basic {
  display: block;
  color: #E0346E;
  font-weight: bold;
  margin-bottom: -3px;
}
@media screen and (max-width: 834px) {
  .interview-list__ct .c-txt__basic {
    margin-bottom: 5px;
  }
}
.interview-list__ct .c-ttl__03 {
  margin-bottom: 33px;
}
@media screen and (max-width: 834px) {
  .interview-list__ct .c-ttl__03 {
    line-height: 1.5625;
    margin-bottom: 18px;
  }
}
.interview-list__ct .c-btn__01 {
  max-width: 251px;
  height: 50px;
  font-size: 1.3rem;
  border-bottom-width: 5px;
}
@media screen and (min-width: 835px) {
  .interview-list__ct .c-btn__01 {
    margin-left: 0;
  }
}
@media screen and (max-width: 834px) {
  .interview-list__ct .c-btn__01 {
    max-width: 214px;
  }
}
.interview-list__ct .c-btn__01 span {
  padding: 0 40px;
}
@media screen and (max-width: 834px) {
  .interview-list__ct .c-btn__01 span {
    padding: 0 52px;
  }
}
.interview-list__ct .c-btn__01 span:after {
  border-width: 4px 0 4px 8px;
  top: 4px;
}
.interview-list__img {
  width: 48.5%;
  position: relative;
  border-radius: 20px;
  overflow: hidden;
  margin-top: 10px;
}
@media screen and (max-width: 834px) {
  .interview-list__img {
    width: 100%;
    margin-bottom: 20px;
  }
}
.interview-list__img:before {
  content: "";
  display: block;
  padding-bottom: 66.7330677291%;
}
.interview-list__img img {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  object-fit: cover;
}

#page-campus_life_movie .mv-page__setsubi {
  padding-bottom: 28px;
}
@media screen and (max-width: 834px) {
  #page-campus_life_movie .mv-page__setsubi {
    padding: 36px 0 55px;
  }
}
#page-campus_life_movie .mv-page__setsubi::before {
  top: -2px;
}

.sec-movie {
  padding: 26px 0 132px;
}
@media screen and (max-width: 834px) {
  .sec-movie {
    padding: 0 0 45px;
    margin-top: -3px;
  }
}

.movie-wrap {
  position: relative;
  margin-bottom: 42px;
}
@media screen and (max-width: 834px) {
  .movie-wrap {
    margin-bottom: 20px;
  }
}
.movie-wrap:before {
  content: "";
  display: block;
  padding-bottom: 55.5984555985%;
}
.movie-wrap--shape1:after {
  content: "";
  position: absolute;
  background: url("../img/common/icon-start-rbga.png") center no-repeat;
  background-size: contain;
  width: 385px;
  height: 443px;
  top: 31%;
  left: -202px;
  z-index: -1;
}
@media screen and (max-width: 834px) {
  .movie-wrap--shape1:after {
    top: -8%;
    left: -81px;
    width: 197px;
    height: 226px;
  }
}
.movie-wrap--shape2:after {
  content: "";
  position: absolute;
  background: url("../img/common/icon-cup.png") center no-repeat;
  background-size: contain;
  width: 194px;
  height: 258px;
  top: 44.2%;
  right: -143px;
  z-index: -1;
}
@media screen and (max-width: 834px) {
  .movie-wrap--shape2:after {
    top: 40.2%;
    right: -35px;
    width: 80px;
    height: 112px;
  }
}
.movie-wrap iframe {
  position: absolute;
  width: 100%;
  height: 100%;
  border: 0;
  top: 0;
  left: 0;
}

#page-hiruma .mvhd-block__ttl {
  margin-left: 0;
  margin-top: -76px;
}
@media screen and (max-width: 834px) {
  #page-hiruma .mvhd-block__ttl {
    margin-left: -14px;
    margin-top: -39px;
  }
}
#page-hiruma .mvhd-block__ttl img {
  height: 157px;
}
@media screen and (max-width: 1200px) {
  #page-hiruma .mvhd-block__ttl img {
    height: 120px;
  }
}
@media screen and (max-width: 834px) {
  #page-hiruma .mvhd-block__ttl img {
    height: 77px;
  }
}
#page-hiruma .sec-heading {
  margin-bottom: 90px;
}
@media screen and (max-width: 834px) {
  #page-hiruma .sec-heading {
    padding-top: 160px;
    margin-bottom: 60px;
  }
}
#page-hiruma .sec-heading::before {
  width: 213px;
  height: 244px;
  background: url(../img/hiruma/bg-before.png) no-repeat center/cover;
  top: 19.7%;
  left: 12%;
}
@media screen and (max-width: 1200px) {
  #page-hiruma .sec-heading::before {
    left: 3%;
  }
}
@media screen and (max-width: 1024px) {
  #page-hiruma .sec-heading::before {
    left: -10%;
  }
}
@media screen and (max-width: 834px) {
  #page-hiruma .sec-heading::before {
    width: 121px;
    height: 139px;
    top: 1.7%;
    left: 22%;
  }
}
#page-hiruma .sec-heading::after {
  width: 132px;
  height: 138px;
  background: url(../img/hiruma/bg-after.png) no-repeat center/cover;
  top: -12.5%;
  right: 20.3%;
}
@media screen and (max-width: 1200px) {
  #page-hiruma .sec-heading::after {
    right: 12%;
  }
}
@media screen and (max-width: 1024px) {
  #page-hiruma .sec-heading::after {
    right: 3%;
  }
}
@media screen and (max-width: 834px) {
  #page-hiruma .sec-heading::after {
    width: 79px;
    height: 83px;
    right: 26.1%;
    top: 43px;
  }
}
@media screen and (min-width: 835px) {
  #page-hiruma .sec-heading__ttl {
    margin-bottom: 30px;
  }
}
@media screen and (max-width: 834px) {
  #page-hiruma .sec-introduction {
    background: url("../img/yakan/bg-01-sp.png") center no-repeat;
    background-size: 100% 100%;
    padding-bottom: 116px;
    margin-bottom: -40px;
  }
}
@media screen and (max-width: 834px) {
  #page-hiruma .bg-introduction {
    background: none;
  }
}

.schedule-day__ttl {
  margin-bottom: 8px;
}
@media screen and (max-width: 834px) {
  .schedule-day__ttl {
    margin-bottom: 33px;
  }
}
.schedule-day__ttl span {
  padding: 12px 52px;
  letter-spacing: 0px;
}
@media screen and (max-width: 834px) {
  .schedule-day__ttl span {
    max-width: 320px;
    width: 100%;
    padding: 6px 20px;
  }
}
@media screen and (max-width: 834px) {
  .schedule-day__ttl span {
    padding: 6px 14px;
  }
}
.schedule-day__ttl--top {
  margin-bottom: -35px;
  position: relative;
  z-index: 11;
}
.schedule-day__box {
  margin-bottom: 21px;
}
@media screen and (max-width: 834px) {
  .schedule-day__box {
    margin-bottom: 25px;
  }
}
.schedule-day__box:last-child {
  margin-bottom: 0;
}
.schedule-day__box-dt {
  font-weight: 700;
  color: #E0346E;
  margin-bottom: 3px;
  font-family: "游ゴシック体", "Yu Gothic", YuGothic, "ヒラギノ角ゴPro W3", "Hiragino Kaku Gothic Pro", Osaka, "Meiryo", "メイリオ", "MS Pゴシック", Arial, Helvetica, Verdana, sans-serif;
}
@media screen and (max-width: 834px) {
  .schedule-day__box-dt {
    font-size: 1.6rem;
    margin-bottom: 7px;
  }
}
.schedule-day__box-dt--blue {
  color: #277AE2;
}
.schedule-day__box-dd .c-table {
  border: none;
}
.schedule-day__box-dd .c-table tr th {
  color: #E0346E;
}
.schedule-day__box-dd .c-table tr td {
  font-weight: 700;
}
.schedule-day__wp {
  position: relative;
}
@media screen and (max-width: 834px) {
  .schedule-day__wp {
    margin-bottom: 53px;
  }
  .schedule-day__wp:last-child {
    margin-bottom: 0;
  }
}
.schedule-day__deco span {
  width: 322px;
  position: absolute;
}
@media screen and (max-width: 834px) {
  .schedule-day__deco span {
    width: 205px;
  }
}
.schedule-day__deco-01 {
  top: 0;
  right: 0;
  transform: translate(87%, -46%);
}
@media screen and (max-width: 834px) {
  .schedule-day__deco-01 {
    top: -90px;
    right: -87px;
    transform: none;
  }
}
.schedule-day__deco-02 {
  top: 32%;
  left: 0;
  transform: translate(-64%, 0);
}
@media screen and (max-width: 834px) {
  .schedule-day__deco-02 {
    left: -50px;
    top: 37%;
    transform: none;
  }
}
.schedule-day__deco-03 {
  bottom: -17px;
  right: 0;
  transform: translate(72%, 0);
}
@media screen and (max-width: 834px) {
  .schedule-day__deco-03 {
    bottom: -113px;
    transform: translate(55%, 0);
  }
}
.schedule-day__deco--styles02 .schedule-day__deco-01 {
  top: 0;
  left: 0;
  transform: translate(-65%, 30%);
}
@media screen and (max-width: 834px) {
  .schedule-day__deco--styles02 .schedule-day__deco-01 {
    top: 127px;
    left: -52px;
    transform: none;
  }
}
.schedule-day__deco--styles02 .schedule-day__deco-02 {
  top: 29%;
  right: 0;
  left: auto;
  transform: translate(87%, 0);
}
@media screen and (max-width: 834px) {
  .schedule-day__deco--styles02 .schedule-day__deco-02 {
    top: 40%;
    right: 0;
    transform: translate(60%, 0);
  }
}
.schedule-day__deco--styles02 .schedule-day__deco-03 {
  bottom: -70px;
  left: 0;
  transform: translate(-64%, 0);
  right: auto;
}
@media screen and (max-width: 834px) {
  .schedule-day__deco--styles02 .schedule-day__deco-03 {
    bottom: -70px;
    transform: translate(-25%, 0);
  }
}

.bg-wave-pink {
  background: #FFF2F6;
  position: relative;
}
.bg-wave-pink:before {
  content: "";
  display: inline-block;
  position: absolute;
  width: 100%;
  height: 6.369vw;
  background: url(../img/hiruma/wave-before.png) no-repeat top center/cover;
  left: 0;
}
@media screen and (max-width: 834px) {
  .bg-wave-pink:before {
    height: 10.433vw;
    background: url(../img/hiruma/wave-before-sp.png) no-repeat top center/cover;
  }
}

.sec-curriculum {
  padding-bottom: 16.5vw;
  margin-bottom: -84px;
}
@media screen and (max-width: 834px) {
  .sec-curriculum {
    padding-bottom: 44.5vw;
  }
}

.curriculum-page__ttl {
  margin-bottom: 54px;
  position: relative;
  top: -9px;
}
@media screen and (max-width: 834px) {
  .curriculum-page__ttl {
    margin-bottom: 26px;
  }
}
.curriculum-page__intro {
  margin-bottom: 68px;
  position: relative;
}
@media screen and (max-width: 834px) {
  .curriculum-page__intro {
    margin-bottom: 50px;
  }
}
.curriculum-page__intro:before {
  content: "";
  display: inline-block;
  position: absolute;
  width: 161px;
  height: 179px;
  background: url(../img/common/icon-top02.png) no-repeat center/cover;
  left: -88px;
  bottom: -28px;
}
@media screen and (max-width: 834px) {
  .curriculum-page__intro:before {
    width: 16vw;
    height: 17.5vw;
    left: 0;
    bottom: auto;
    top: 53px;
  }
}
.curriculum-page__intro:after {
  content: "";
  display: inline-block;
  position: absolute;
  width: 174px;
  height: 186px;
  background: url(../img/common/icon-apple.png) no-repeat center/cover;
  right: -83px;
  bottom: 66px;
}
@media screen and (max-width: 834px) {
  .curriculum-page__intro:after {
    width: 21.628vw;
    height: 23.155vw;
    right: 0;
    bottom: auto;
    top: -47px;
  }
}
.curriculum-page__intro-ttl {
  margin-bottom: 24px;
  line-height: 1.5;
  position: relative;
  z-index: 2;
}
@media screen and (max-width: 834px) {
  .curriculum-page__intro-ttl {
    font-size: 20px;
    font-size: 2rem;
    line-height: 1.5;
    margin-bottom: 13px;
    margin-left: -10px;
    margin-right: -10px;
  }
}
@media screen and (max-width: 374px) {
  .curriculum-page__intro-ttl {
    font-size: 1.7rem;
  }
  .curriculum-page__intro-ttl .c-txt__hightline {
    background-position: 0 17px;
  }
}
.curriculum-page__intro-desc {
  font-weight: 700;
}
@media screen and (max-width: 834px) {
  .curriculum-page__intro-desc {
    line-height: 2.1;
  }
}
.curriculum-page__intro--yakan:before {
  content: "";
  display: inline-block;
  position: absolute;
  width: 161px;
  height: 179px;
  background: url(../img/yakan/icon-top.png) no-repeat center/cover;
  left: -88px;
  bottom: -28px;
}
@media screen and (max-width: 834px) {
  .curriculum-page__intro--yakan:before {
    width: 16vw;
    height: 17.5vw;
    left: 0;
    bottom: auto;
    top: 53px;
  }
}

.sec-tuition--day {
  background: url(../img/hiruma/bg-wave-rgba.png) no-repeat center/100% 100%;
}
@media screen and (max-width: 834px) {
  .sec-tuition--day {
    background: url(../img/hiruma/bg-wave-rgba-sp.png) no-repeat center/100% 100%;
    z-index: 2;
  }
}

.sec-introduction--day::before {
  background: url(../img/hiruma/icon-03.png) no-repeat center/100%;
}
.sec-introduction--day::after {
  background: url(../img/hiruma/icon-04.png) no-repeat center/100%;
}

@media screen and (max-width: 834px) {
  .bg-introduction--day::after {
    background: url(../img/hiruma/icon-04.png) no-repeat center/100%;
  }
}

/* ====================================================
Page himawari
==================================================== */
#page-himawari .mvhd-block__ttl {
  margin-top: -69px;
  margin-left: -29px;
}
@media screen and (max-width: 834px) {
  #page-himawari .mvhd-block__ttl {
    margin-top: -20px;
    margin-left: -8px;
  }
}
#page-himawari .mvhd-block__ttl img {
  height: auto;
}

.sec-himawari {
  padding-bottom: 114px;
}
@media screen and (max-width: 834px) {
  .sec-himawari {
    padding-bottom: 44px;
  }
}
.sec-himawari:after {
  content: "";
  display: inline-block;
  position: absolute;
  width: 100%;
  height: 100%;
  background: url(../img/himawari/img-bg.png) no-repeat center top/contain;
  top: -28px;
  right: -21px;
  z-index: -1;
}
@media screen and (max-width: 834px) {
  .sec-himawari:after {
    background: url(../img/himawari/img-bgsp.png) no-repeat center top/contain;
    top: -32px;
    right: -1px;
  }
}

.himawari-heading {
  margin-bottom: 41px;
  font-weight: 700;
}
@media screen and (max-width: 834px) {
  .himawari-heading {
    line-height: 2.083;
    margin-bottom: 53px;
  }
}
.himawari-heading p:not(:last-child) {
  margin-bottom: 20px;
}
@media screen and (max-width: 834px) {
  .himawari-heading p:not(:last-child) {
    margin-bottom: 15px;
  }
}
.himawari-heading__list {
  font-family: "游ゴシック体", "Yu Gothic", YuGothic, "ヒラギノ角ゴPro W3", "Hiragino Kaku Gothic Pro", Osaka, "Meiryo", "メイリオ", "MS Pゴシック", Arial, Helvetica, Verdana, sans-serif;
}
@media screen and (max-width: 834px) {
  .himawari-heading__list-item:not(:last-child) {
    margin-bottom: 2px;
  }
}

.himawari-info {
  background: #F9F9F9;
  border-radius: 20px;
  padding: 53px 50px 45px;
  font-weight: 700;
}
@media screen and (max-width: 834px) {
  .himawari-info {
    padding: 30px 19px 34px;
  }
}
.himawari-info__ttl {
  text-align: center;
  position: relative;
  padding-bottom: 23px;
  margin-bottom: 21px;
}
@media screen and (max-width: 834px) {
  .himawari-info__ttl {
    font-size: 20px;
    font-size: 2rem;
    line-height: 1.5;
    padding-bottom: 22px;
    margin-bottom: 33px;
  }
}
.himawari-info__ttl:after {
  content: "";
  display: inline-block;
  position: absolute;
  width: 150px;
  height: 10px;
  background: #1D3E62;
  border-radius: 5px;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
}
@media screen and (max-width: 834px) {
  .himawari-info__ttl:after {
    width: 111px;
    height: 8px;
  }
}
.himawari-info a {
  color: #E0346E;
  font-size: 3rem;
  letter-spacing: 0.1rem;
  margin-top: -10px;
  display: block;
}
@media screen and (max-width: 834px) {
  .himawari-info a {
    font-size: 16px;
    font-size: 1.6rem;
    line-height: 1.25;
    margin-top: 18px;
    margin-bottom: 22px;
    letter-spacing: 0;
  }
}
@media screen and (min-width: 835px) {
  .himawari-info p {
    line-height: 3.4375;
  }
}

#page-himawari_detail .sec-newsdetail::before {
  display: none;
}

/* ====================================================
Page taishousha_zaikou
==================================================== */
.sec-zaikou {
  padding: 51px 0 236px;
}
@media screen and (max-width: 834px) {
  .sec-zaikou {
    padding: 43px 0 242px;
  }
}
.sec-zaikou__ttl {
  margin-bottom: 10px;
}
@media screen and (max-width: 834px) {
  .sec-zaikou__ttl {
    margin-bottom: 14px;
  }
}

.zaikou-list__item:not(:last-child) {
  margin-bottom: 35px;
}
@media screen and (max-width: 834px) {
  .zaikou-list__item:not(:last-child) {
    margin-bottom: 30px;
  }
}
.zaikou-list__item a {
  color: inherit;
  font-weight: 700;
}

.sec-sitemap {
  padding: 49px 0 87px;
}
@media screen and (max-width: 834px) {
  .sec-sitemap {
    padding: 40px 0 44px;
  }
}

.sitemap {
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  line-height: 1.4;
  font-weight: bold;
}
.sitemap a {
  color: #1D3E62;
}
.sitemap-list {
  width: 50%;
  padding-right: 15px;
}
@media screen and (max-width: 834px) {
  .sitemap-list {
    width: 100%;
    padding-right: 0;
    margin-bottom: 43px;
  }
  .sitemap-list:last-child {
    margin-bottom: 0;
  }
}
.sitemap-list__item {
  margin-bottom: 42px;
}
.sitemap-list__item:last-child {
  margin-bottom: 0;
}
.sitemap-list__item .c-ttl__04 {
  margin-bottom: 16px;
}
@media screen and (max-width: 834px) {
  .sitemap-list__item .c-ttl__04 {
    margin-bottom: 10px;
  }
}
.sitemap-list ul {
  padding: 0 0 0 20px;
  margin-bottom: 36px;
}
.sitemap-list ul:last-child {
  margin-bottom: 0;
}
.sitemap-list ul li {
  margin-bottom: 8px;
}
.sitemap-list ul li:last-child {
  margin-bottom: 0;
}
.sitemap-list ul ul {
  padding: 6px 0 0 16px;
}
@media screen and (max-width: 834px) {
  .sitemap-list ul ul {
    padding: 8px 0 0 12px;
  }
}
.sitemap-list ul.first {
  padding-left: 0;
}

/* ====================================================
Module
==================================================== */
/* ====================================================
Pages
==================================================== */