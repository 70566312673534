@use "vars" as *;

/* ====================================================
PAGE gakko_annai_shushoku
==================================================== */
#page-gakko_annai_shushoku {
	.sec-look {
		@include mq {
			margin-top: -12px;
		}
		&::before{
			@include mq(md-min){
				top: 35%;
			}
		}
	}
}

/* ====================================================
PAGE page-gakko_annai_access
==================================================== */
#page-gakko_annai_access {
	.mv-page__info--shushoku {
		padding-bottom: 36px;
		margin-bottom: 49px;
		@include mq{
			padding-bottom: 78px;
			margin-bottom: 0;
			&::after{
				top: -64px;
				z-index: 1;
			}
		}
	}
}

.sec-access {
	padding-bottom: 155px;
	overflow: hidden;
	@include mq{
		padding-bottom: 105px;
		margin-top: -10px;
	}
	&__inner {
		position: relative;

		@include p-before(385px, 443px) {
			background: url(../img/gakko_annai/img-bg.png) no-repeat center/contain;
			top: 34.5%;
			right: -32.7%;
			z-index: -1;
			@include mq{
				width: 193px;
				height: 222px;
				top: 41.2%;
				right: -29px;
			}
		}

		@include p-after(172px, 172px) {
			background: url(../img/gakko_annai/img-pen.png) no-repeat center/contain;
			bottom: -132px;
			left: -12%;
			z-index: -1;
			@include mq{
				width: 95px;
				height: 95px;
				bottom: -93px;
				left: -1px;
			}
		}
	}


}

.access-contact {
	font-weight: 700;
	@include mq{
		
		line-height: 2.083;
	}
	&__map {
		margin-bottom: 26px;
		position: relative;
		@include mq{
			margin-bottom: 10px;
		}
		&::before{
			content: "";
			padding-top: calc((564 / 1000) * 100% );
			display: block;
			@include mq{
				padding-top: calc((199 / 353) * 100%);
			}
		}
		
		iframe {
			position: absolute;
			width: 100%;
			// height: 564px;
			height: 100%;
			top: 0;
			left: 0;
			// transform: scale(.9);
			// @include mq{
			// 	height: 199px;
			// }
		}
	}

	&__info {
		margin-bottom: 82px;
		@include mq{
			margin-bottom: 50px;
		}
		dl {
			// @include flex(0, 0, wrap);

			dt {
				width: 60px;
				display: inline-block;
				@include mq{
					width: 44px;
				}
			}

			dd {
				display: inline;
				// width: calc(100% - 65px);
				// @include mq{
				// 	width: auto;
				// }
			}
		}
	}

	&__route {
		margin-bottom: 34px;
		@include mq{
			margin-bottom: 16px;
		}
	}
}
/* ====================================================
PAGE page-gakko_annai_syusyoku100
==================================================== */
#page-gakko_annai_syusyoku100{
	.mv-page__info--shushoku {
		padding-bottom: 104px;
		margin-bottom: 0;
		@include mq{
			margin-bottom: 14px;
			&::after{
				top: -64px;
				z-index: 1;
			}
		}
		.c-txt__basic{
			max-width: 863px;
			margin: 0 auto;
		}
		&::before{
			bottom: -11px;
    		left: -10.5%;
			@include mq{
				bottom: -5px;
				left: -68px;
			}
		}
	}

}
.sec-syusyoku100{
	margin-top: -29px;
	padding-bottom: 238px;
	z-index: 12;
	
}
.syusyoku100-heading{
	font-weight: 700;
	&__ttl{
		margin-bottom: 15px;
	}
}
/* ====================================================
PAGE page-gakko_annai_shinro_soudan
==================================================== */
#page-gakko_annai_shinro_soudan{
	.mv-page__info{
		padding-bottom: 101px;
		margin-bottom: 8px;
		@include mq{
			padding-bottom: 105px;
			margin-bottom: 12px;
		}
		
		&::before{
			
			@include mq(md-min){
				left: -10.5%;
			}
		}
		&::after{
			top: -34px;
			right: -6.9%;
			@include mq{
				top: -64px;
				right: -31px;
				z-index: 1;
			}
		}
	}
	.sec-look--icobefore{
		&:before{
			@include mq(md-min){
				top: -13%;
			}
		}
	}
}
.sec-shinro_soudan{
	padding-bottom: 184px;
	@include mq{
		padding-bottom: 95px;
	}
}
.soudan-list{
	font-weight: 700;
	&__item{
		&:not(:last-child){
			margin-bottom: 36px;
			@include mq{
				margin-bottom: 25px;
			}
		}
	}
	&__dt{
		margin-bottom: 17px;
	}
	&__dd{
		span{
			@include fts(30, 50);
			color: #E0346E;
			@include mq() {
				@include fts(16, 20);
			}
		
		}
	}
}
