@use "vars" as *;

.sec-question{
	padding: 72px 0 98px;
	@include mq(){
		padding: 40px 0 68px;
	}
}

.question-note{
	font-weight: bold;
	@include mq(){
		line-height: 2.08333333333;
	}
	&__item{
		margin-bottom: 69px;
		@include mq(){
			margin-bottom: 31px;
		}
		&:last-child{
			margin-bottom: 0;
		}
		.c-ttl__03{
			margin-bottom: 16px;
			@include mq(){
				line-height: 1.5;
				padding-bottom: 19px;
				margin-bottom: 6px;
			}
		}
	}
}

.sec-privacy{
	padding: 72px 0 91px;
	@include mq(){
		padding: 33px 0 53px;
	}
}
.privacy-note{
	font-weight: bold;
	margin-bottom: 51px;
	@include mq(){
		line-height: 2.08333333333;
		margin-bottom: 37px;
	}
	a{
		color: #1D3E62;
	}
	.c-ttl__03{
		margin-bottom: 9px;
		@include mq(){
			padding-bottom: 24px;
			margin-bottom: 8px;
			@include fts(20,25);
		}
	}
	.c-btn__01{
		background: #FFF2F6;
		margin-left: 0;
		color: #E0346E;
	}
	.c-fc--pink{
		@include fts(20,35);
		@include mq(){
			@include font-size(16);
		}
		small{
			@include font-size(16);
		}
	}
	p{
		margin-bottom: 10px;
		@include mq(){
			margin-bottom: 23px;
		}
		&:last-child{
			margin-bottom: 0;
		}
	}
	.c-txt__basic{
		font-family: $font-Yugothic;
	}
}




.sec-character{
	padding: 15px 0 78px;
	@include mq(){
		padding: 13px 0 54px;
	}
}

.character-note{
	font-weight: bold;
	margin-bottom: 51px;
	@include mq(){
		line-height: 2.08333333333;
		margin-bottom: 36px;
	}
	.c-fc--pink{
		display: block;
		@include font-size(30);
		line-height: 1.2;
		margin-bottom: 11px;
		@include mq(){
			@include font-size(20);
			margin-bottom: 17px;
		}
	}
	.c-ttl__03{
		margin-bottom: 9px;
		@include mq(){
			@include font-size(20);
			padding-bottom: 26px;
		}
	}
	p{
		margin-bottom: 35px;
		@include mq(){
			margin-bottom: 24px;
		}
		&:last-child{
			margin-bottom: 0;
		}
	}
}