@use "vars" as *;

.sec-scholarship {
	margin-top: -178px;
	padding-bottom: 89px;
	overflow: hidden;
	z-index: 3;
	@include mq {
		margin-top: 0;
		padding: 41px 0 80px;
	}
}


.scholarship-wrap {
	position: relative;
	margin-bottom: 71px;

	&:last-child {
		margin-bottom: 0;
	}

	@include mq {
		margin-bottom: 45px;
		.c-txt__basic {
			line-height: 2.08;
		}
	}

	&--shape1 {
		&:after {
			content: "";
			position: absolute;
			background: url("../img/nyushi/icon-center.png") center no-repeat;
			background-size: contain;
			width: 122px;
			height: 122px;
			top: 76px;
			right: -122px;
			pointer-events: none;
			@include mq {
				width: 63px;
				height: 63px;
				top: -49px;
				right: -17px;
			}
		}
	}

	&--shape2 {
		&:after {
			content: "";
			position: absolute;
			background: url("../img/nyushi/icon-bottom.png") center no-repeat;
			background-size: contain;
			width: 281px;
			height: 275px;
			top: 87px;
			right: -200px;
			transform: rotate(53deg);
			pointer-events: none;
			@include mq {
				width: 158px;
				height: 153px;
				top: 155px;
				right: -61px;
				transform: rotate(60deg);
			}
			@include mq(xs) {
				right: -120px;
			}
		}
	}

	&__ct {
		text-align: center;
		background: #FFF2F6;
		color: #E0346E;
		border-radius: 20px;
		padding: 41px 15px 52px;
		@include mq {
			padding: 25px 4px 34px;
		}

		&-price {
			font-weight: bold;
			@include font-size(50);
			margin-top: -1px;
			@include mq {
				@include font-size(29);
				margin-top: 8px;
				letter-spacing: -0.9px;
			}
			@include mq(xs) {
				@include font-size(22);
			}

			span {
				background-image: linear-gradient(to right, #FFF8A1 50%, transparent 50%);
				background-size: 200% 23px;
				background-repeat: no-repeat;
				background-position: 0 bottom;
				@include mq {
					background-size: 200% 14px;
				}
			}
		}

		small {
			display: block;
			font-weight: bold;
			@include font-size(12);
			margin: 15px 0 -12px;
			@include mq {
				margin-bottom: 2px;
			}
		}
	}

	.c-btn__01 {
		margin-left: 0;
	}
}

#page-kyufu {
	.mv-page__info {
		margin-top: 51px;
		padding-bottom: 137px;
		@include mq {
			margin-top: 62px;
			padding-bottom: 0;
			&:before {
				bottom: 95%;
			}
		}
	}
}

.sec-kyufu {
	padding-bottom: 81px;
	margin-top: -83px;
	overflow: hidden;
	@include mq {
		padding: 76px 0 90px;
		margin-top: -35px;
	}

	.scholarship-wrap {
		&--shape1 {
			&:after {
				top: 144px;
				@include mq {
					top: -73px;
				}
			}
		}

		&--shape2 {
			&:after {
				top: 50px;
				@include mq {
					top: 97px;
				}
			}
		}
	}
}

.kyufu-list {
	counter-reset: my-sec-counter;
	margin-bottom: 57px;
	@include mq {
		margin-bottom: 31px;
	}

	&__item {
		position: relative;
		@include flex();

		&:before {
			counter-increment: my-sec-counter;
			content: counter(my-sec-counter) ".";
			display: block;
			color: #E0346E;
			margin-right: 4px;
			@include mq {
				margin-right: 3px;
			}
		}
	}
}

.sec-iryou {
	padding: 80px 0 86px;
	@include mq {
		padding: 43px 0 71px;
	}
}

.iryou-txt {
	font-family: $font-Yugothic;
	font-weight: bold;
	@include font-size(12);
	margin-bottom: 27px;
	@include mq {
		@include font-size(9);
		margin-bottom: 15px;
	}
	&--border {
		border: 1px solid #1D3E62;
		padding: 6px 4px;
		@include mq {
			padding: 8px 5px 6px;
		}
	}
}

.iryou-wrap {
	background: #F9F9F9;
	padding: 19px 37px 23px;
	@include mq(sm) {
		padding: 10px 20px 18px;
	}
	&__item {
		@include flex();
		@include fts(16, 35);
		font-family: $font-Yugothic;
		@include mq(sm) {
			@include fts(10, 20);
		}
	}
	&__ttl {
		width: 143px;
		font-weight: bold;
		@include mq {
			width: 80px;
		}
	}
	&__txt {
		width: calc(100% - 143px);
		font-weight: 500;
		a{
			color: #1D3E62;
		}
		@include mq {
			width: calc(100% - 80px);
		}
	}
}