@use "vars" as *;

/* ====================================================
Page himawari
==================================================== */
#page-himawari {
	.mvhd-block__ttl {
		margin-top: -69px;
		margin-left: -29px;

		@include mq {
			margin-top: -20px;
			margin-left: -8px;
		}

		img {
			height: auto;
		}
	}
}

.sec-himawari {
	padding-bottom: 114px;

	@include mq {
		padding-bottom: 44px;
	}

	@include p-after(100%, 100%) {
		background: url(../img/himawari/img-bg.png) no-repeat center top/contain;
		top: -28px;
		right: -21px;
		z-index: -1;

		@include mq {
			background: url(../img/himawari/img-bgsp.png) no-repeat center top/contain;
			top: -32px;
			right: -1px;
		}
	}
}

.himawari-heading {
	margin-bottom: 41px;
	font-weight: 700;
	@include mq {
		line-height: 2.083;
		margin-bottom: 53px;

	}

	p {
		&:not(:last-child) {
			margin-bottom: 20px;

			@include mq {
				margin-bottom: 15px;
			}
		}
	}

	&__list {
		font-family: $font-Yugothic;
		
		&-item {
			@include mq {
				&:not(:last-child) {
					margin-bottom: 2px
				}
			}
		}
	}
}

.himawari-info {
	background: #F9F9F9;
	border-radius: 20px;
	padding: 53px 50px 45px;
	font-weight: 700;

	@include mq {
		padding: 30px 19px 34px;
	}

	&__ttl {
		text-align: center;
		position: relative;
		padding-bottom: 23px;
		margin-bottom: 21px;

		@include mq {
			@include fts(20, 30);
			padding-bottom: 22px;
			margin-bottom: 33px;
		}

		@include p-after(150px, 10px) {
			background: #1D3E62;
			border-radius: 5px;
			bottom: 0;
			left: 50%;
			transform: translateX(-50%);

			@include mq {
				width: 111px;
				height: 8px;
			}
		}
	}

	a {
		color: #E0346E;
		@include font-size(30);
		letter-spacing: 0.1rem;
		margin-top: -10px;
		display: block;

		@include mq {
			@include fts(16, 20);
			margin-top: 18px;
			margin-bottom: 22px;
			letter-spacing: 0;
		}
	}

	p {

		@include mq(md-min) {
			line-height: 3.4375;
		}
	}
}
// page detail
#page-himawari_detail{
	.sec-newsdetail{
		&::before{
			display: none;
		}
	}
}
