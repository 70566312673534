@use "vars" as *;

/* ====================================================
Page taishousha_zaikou
==================================================== */
.sec-zaikou{
	padding: 51px 0 236px;
	@include mq{
		padding: 43px 0 242px;
	}
	&__ttl{
		margin-bottom: 10px;
		@include mq{
			margin-bottom: 14px;
		}
	}
}
.zaikou-list {
	&__item{
		&:not(:last-child){
			margin-bottom: 35px;
			@include mq{
				margin-bottom: 30px;
			}
		}
		a{
			color: inherit;
			font-weight: 700;
		}
	}
}
