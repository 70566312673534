@use "vars" as *;

/* ====================================================
PAGE INDEX
==================================================== */

.sec-mv {
	position: relative;
	z-index: 2;
	&__img {
		border-radius: 25px;
		box-shadow: rgba(0, 0, 0, 0.2) 0 6px 6px;
		overflow: hidden;
		@include mq(sm) {
			border-radius: 10px;
		}
	}
}

.sec-intro {
	padding: 188px 0;
	margin-top: -154px;
	@include mq {
		padding: 217px 0 174px;
		margin-top: -195px;
	}
}

.intro-list {
	@include flex(between, 0, wrap);
	margin-bottom: 70px;
	@include mq {
		margin-bottom: 41px;
	}
	&__item {
		width: 48.3%;
		@include mq(sm) {
			width: 100%;
			margin-bottom: 15px;
			&:last-child {
				margin-bottom: 0;
			}
		}
		a {
			@include flex(center, end);
			width: 100%;
			height: 100%;
			img {
				width: 100%;
			}
		}
	}
}

.intro-main {
	position: relative;
	display: block;
	&:after {
		content: "";
		position: absolute;
		background: url("../img/index/intro-shape.svg") center no-repeat;
		background-size: contain;
		width: 128px;
		height: 119px;
		top: -123px;
		left: calc(50% + 343px);
		transform: translateX(-50%);
		pointer-events: none;
		@include mq {
			width: 75px;
			height: 71px;
			top: -30px;
			right: -3px;
			left: auto;
			transform: unset;
		}
	}
	img {
		width: 100%;
	}
}

.sec-news {
	background: url("../img/index/bg-news-pc.png") center top no-repeat;
	background-size: cover;
	padding: 151px 0;
	margin-top: -116px;
	@include mq(sm) {
		background: url("../img/index/bg-news-sp.png") center top no-repeat;
		background-size: cover;
		padding: 82px 0;
	}
}

.news-ttl {
	text-align: center;
	margin-bottom: 49px;
	@include mq {
		margin-bottom: 30px;
	}
	small {
		display: block;
		margin-bottom: 20px;
		@include mq {
			margin-bottom: 12px;
		}
	}
}

.news-list {
	margin-bottom: 11px;
	@include mq {
		margin-bottom: 44px;
	}
	&__item {
		margin-bottom: 19px;
		@include mq {
			margin-bottom: 12px;
		}
		&:last-child {
			margin-bottom: 0;
		}
		a {
			display: block;
			background: white;
			color: #1D3E62;
			border-radius: 20px;
			font-weight: bold;
			@include fts(12, 20);
			padding: 11px 31px 17px 18px;
			@include mq(sm) {
				padding: 14px 20px 18px;
			}
		}
		p {
			display: -webkit-box;
			overflow: hidden;
			text-overflow: ellipsis;
			-webkit-line-clamp: 1;
			-webkit-box-orient: vertical;
			max-height: 20px;
			@include mq(sm) {
				-webkit-line-clamp: 3;
				max-height: 60px;
			}
		}
	}
	&__info {
		position: relative;
		color: #E0346E;
		border-bottom: 1px solid #E0346E;
		font-weight: bold;
		@include font-size(20);
		padding: 0 95px 9px 114px;
		margin-bottom: 18px;
		@include mq(sm) {
			@include font-size(16);
			padding: 31px 0 6px;
			margin-bottom: 4px;
		}
		&-tag {
			position: absolute;
			text-align: center;
			@include flex(center, center);
			width: 94px;
			height: 26px;
			background: #E0346E;
			color: white;
			border-radius: 13px;
			font-weight: bold;
			@include font-size(15);
			line-height: 1;
			padding-bottom: 4px;
			top: 5px;
			left: 0;
			@include mq(sm) {
				width: 74px;
				height: 20px;
				@include font-size(11);
				padding-bottom: 1px;
				top: 7px;
			}
		}
		&-date {
			position: absolute;
			width: max-content;
			color: #1D3E62;
			font-weight: bold;
			@include font-size(15);
			line-height: 1;
			top: 11px;
			right: 0;
			@include mq(sm) {
				@include font-size(12);
			}
		}
	}
}

.event-list {
	@include flex(0, 0, wrap);
	margin: 0 -10px 11px;
	@include mq(tb) {
		margin: 0 -8px -10px;
	}
	&__item {
		width: calc(25% - 20px);
		background: white;
		border-radius: 20px;
		overflow: hidden;
		margin: 0 10px;
		@include mq(tb) {
			width: calc(50% - 16px);
			border-radius: 15px;
			margin: 0 8px 12px;
		}
	}
	&__ttl {
		position: relative;
		background: linear-gradient(90deg, #FF82AC, #FFDA43);
		font-weight: bold;
		@include font-size(18);
		padding: 16px 10px 15px 125px;
		@include mq(sm) {
			@include font-size(12);
			padding: 11px 10px 11px 87px;
		}
		@include mq(xs) {
			padding: 11px 6px 8px 64px;
		}
		span {
			position: absolute;
			@include flex(center, center);
			width: 89px;
			height: 32px;
			background: white;
			border-radius: 18px;
			font-weight: bold;
			top: 50%;
			left: 26px;
			transform: translateY(-50%);
			@include mq(sm) {
				width: 62px;
				height: 22px;
				left: 18px;
			}
			@include mq(xs) {
				width: 53px;
				height: 19px;
				left: 7px;
			}
		}
	}
	&__ct {
		@include flex(center, center);
		font-weight: bold;
		@include fts(13, 22);
		padding: 10px 8px 17px;
		@include mq(sm) {
			@include fts(12, 15);
			padding: 8px 8px 14px;
		}
		p span {
			display: inline-block;
			@include mq {
				display: block;
			}
		}
	}
	&__date {
		position: relative;
		display: inline-block;
		height: 86px;
		font-weight: bold;
		@include font-size(44);
		margin-right: 70px;
		line-height: 1;
		@include mq(sm) {
			height: 57px;
			@include font-size(30);
			margin-right: 53px;
		}
		@include mq(xs) {
			margin-right: 42px;
		}
		&:before {
			content: "";
			position: absolute;
			width: 1px;
			height: 74px;
			background: #1D3E62;
			top: 7px;
			left: calc(100% + 1px);
			transform: rotate(43deg);
			@include mq(sm) {
				height: 50px;
				top: 4px;
			}
		}
		span {
			position: absolute;
			top: 37px;
			left: calc(100% + 5px);
			@include mq(sm) {
				top: 25px;
			}
		}
	}
}

.sec-pickup {
	position: relative;
	background: url("../img/index/bg-pickup-pc.png") center no-repeat;
	background-size: 100% 100%;
	padding: 129px 0 179px;
	margin: -114px 0 -78px;
	z-index: 1;
	@media screen and (max-width: 1400px) {
		background-size: cover;
	}
	@include mq(sm) {
		background: url("../img/index/bg-pickup-sp.png") center no-repeat;
		background-size: 100% 100%;
		padding: 74px 0 100px;
		margin: -58px 0 -78px;
	}
	.inner {
		position: relative;
		&:before {
			content: "";
			position: absolute;
			background: url("../img/index/pickup-shape1.png") center no-repeat;
			background-size: contain;
			width: 145px;
			height: 134px;
			top: -79px;
			left: -74px;
			z-index: 1;
			@include mq(xl) {
				left: 0;
			}
			@include mq(sm) {
				width: 84px;
				height: 77px;
				top: -58px;
				left: 20px;
			}
		}
		&:after {
			content: "";
			position: absolute;
			background: url("../img/index/pickup-shape2.png") center no-repeat;
			background-size: contain;
			width: 109px;
			height: 200px;
			bottom: -260px;
			right: -62px;
			z-index: 1;
			@include mq(xl) {
				right: 0;
			}
			@include mq(sm) {
				width: 51px;
				height: 94px;
				bottom: -134px;
				right: 20px;
			}
		}
	}
}

.pickup-list {
	@include flex(0, 0, wrap);
	margin: 0 -11px;
	&__item {
		width: 33.333%;
		padding: 0 11px;
		@include mq(sm) {
			width: 100%;
			margin-bottom: 24px;
			&:last-child {
				margin-bottom: 0;
			}
		}
		a {
			display: block;
		}
	}
}

.sec-chosen {
	background: #FFF2F6 url("../img/index/bg-chosen.png") -77px -32px repeat;
	background-size: 188px 290px;
	padding: 149px 0 150px;
	@include mq {
		background-size: 125px 176px;
		padding: 142px 0 86px;
	}
	.inner {
		position: relative;
		&:after {
			content: "";
			position: absolute;
			background: url("../img/index/chosen-shape1.png") center no-repeat;
			background-size: contain;
			width: 215px;
			height: 216px;
			bottom: -262px;
			right: -33px;
			z-index: 1;
			@media screen and (max-width: 1306px) {
				right: 0;
			}
			@include mq {
				width: 92px;
				height: 100px;
				bottom: -128px;
				right: 20px;
			}
		}
	}
}

.chosen-list {
	position: relative;
	@include flex(between, 0, wrap);
	max-width: 885px;
	margin: 0 auto -7px;
	@include mq {
		margin-bottom: 55px;
	}
	&:after {
		content: "";
		position: absolute;
		background: url("../img/index/chosen-shape3.png") center no-repeat;
		background-size: contain;
		width: 137px;
		height: 359px;
		bottom: -36px;
		left: -78px;
		z-index: 1;
		@include mq(tb) {
			width: 100px;
			left: -35px;
		}
		@include mq(sm) {
			width: 54px;
			height: 140px;
			bottom: -151px;
			left: 19px;
		}
		@include mq(xs) {
			left: 0;
		}
	}
	&__item {
		width: calc(50% - 32px);
		background: white;
		border-radius: 20px;
		@include fts(15, 25);
		padding: 45px 25px 43px 38px;
		margin-bottom: 57px;
		@include mq(tb) {
			width: calc(50% - 10px);
			padding: 25px 20px;
			margin-bottom: 20px;
		}
		@include mq {
			@include fts(12, 25);
			&:first-child {
				.chosen-list__ttl {
					@include font-size(38);
					> span {
						small {
							@include font-size(29);
						}
						strong {
							@include font-size(48);
						}
					}
				}
			}
			&:nth-child(3) {
				.chosen-list__ttl {
					@include font-size(28);
					> span {
						background-size: 200% 20px;
						padding-bottom: 1px;
						strong {
							@include font-size(35);
						}
					}
				}
			}
			&:nth-child(4) {
				.chosen-list__ttl {
					@include font-size(32);
					@include mq(sm) {
						padding: 0 31px 0 8px;
						text-align: left;
						@include mq(xs) {
							@include font-size(28);
							padding-left: 0;
						}
						&:after {
							content: "";
							position: absolute;
							background: url("../img/index/chosen-shape2.png") center no-repeat;
							background-size: contain;
							width: 32px;
							height: 46px;
							top: 22px;
							left: 286px;
							@include mq(xs) {
								top: 17px;
								left: 222px;
							}
						}
					}
					> span {
						background-size: 200% 20px;
						letter-spacing: -1px;
						small {
							@include font-size(24);
							@include mq(xs) {
								@include font-size(18);
							}
						}
					}
				}
			}
			&:nth-child(5) {
				.chosen-list__ttl {
					> small {
						@include mq(sm) {
							margin-bottom: 1px;
						}
					}
					> span {
						padding-bottom: 0;
						background-size: 200% 15px;
						strong {
							@include font-size(30);
						}
					}
				}
			}
			&:nth-child(6) {
				.chosen-list__ttl {
					@include font-size(26);
					> span {
						strong {
							@include font-size(33);
						}
					}
				}
			}
		}
		@include mq(sm) {
			width: 100%;
			padding: 28px 22px 25px;
			margin-bottom: 22px;
			&:last-child {
				margin-bottom: 0;
			}
		}
		&--center {
			@include mq(sm-min) {
				@include flex(center, center);
				min-height: 304px;
				@include mq {
					min-height: 225px;
				}
				.chosen-list__ttl {
					> small {
						margin-bottom: 17px;
					}
					> span {
						letter-spacing: -1.6px;
						strong {
							letter-spacing: 0;
						}
					}
				}
			}
			@include mq(sm) {
				padding: 17px 22px 21px;
			}
			.chosen-list__ttl {
				margin-bottom: 0;
			}
		}
		p {
			font-family: $font-Yugothic;
			font-weight: 500;
		}
	}
	&__ttl {
		position: relative;
		color: #FF9100;
		font-weight: bold;
		@include font-size(40);
		line-height: 1.22;
		margin-bottom: 22px;
		@include mq(sm) {
			text-align: center;
			margin: 0 -10px 22px;
		}
		> small {
			display: block;
			color: #1D3E62;
			font-weight: bold;
			@include font-size(20);
			margin-bottom: 9px;
			@include mq {
				@include font-size(16);
				margin-bottom: 2px;
			}
			@include mq(xs) {
				@include font-size(14);
			}
		}
		> span {
			background-image: linear-gradient(to right, #FFF8A1 50%, transparent 50%);
			background-size: 200% 23px;
			background-repeat: no-repeat;
			background-position: 0 bottom;
			padding-bottom: 2px;
			@include mq {
				padding-bottom: 5px;
			}
			small {
				@include font-size(30);
			}
			strong {
				@include font-size(50);
			}
		}
		&--light {
			@include mq(sm) {
				> small {
					margin-bottom: 9px;
				}
			}
			&:after {
				content: "";
				position: absolute;
				background: url("../img/index/chosen-shape4.png") center no-repeat;
				background-size: contain;
				width: 70px;
				height: 57px;
				top: 1px;
				left: calc(50% + 132px);
				transform: translateX(-50%);
				@include mq {
					width: 43px;
					height: 35px;
					top: auto;
					bottom: 10px;
					left: calc(50% + 124px);
				}
				@include mq(xs) {
					right: -6px;
				}
			}
		}
	}
	&__img {
		text-align: center;
		padding: 10px 12px 0 0;
	}
}

.sec-catalogue {
	padding: 89px 0 168px;
	@include mq {
		margin-bottom: 56px;
	}
	@include mq(sm) {
		padding: 57px 0 69px;
	}
}

.catalogue-list {
	@include flex(0, 0, wrap);
	margin: 0 -11px 69px;
	@include mq(sm) {
		margin: 0 -8px 28px;
	}
	&__item {
		width: 25%;
		padding: 0 11px;
		@include mq(sm) {
			width: 50%;
			padding: 0 8px;
			margin-bottom: 13px;
		}
		a {
			text-align: center;
			@include flex(center, center);
			background: white;
			color: #F9A6C2;
			width: 100%;
			height: 169px;
			border-radius: 20px;
			font-weight: bold;
			@include font-size(16);
			letter-spacing: 1.5px;
			@include mq {
				height: 117px;
				border-radius: 15px;
				@include font-size(12);
			}
			p {
				width: 100%;
			}
			span {
				display: block;
				color: #E0346E;
				@include font-size(30);
				letter-spacing: 3px;
				@include mq {
					@include font-size(20);
					letter-spacing: 2px;
					margin-bottom: 1px;
				}
			}
		}
	}
}

.catalogue-logo {
	display: block;
	max-width: 225px;
	margin: 0 auto 58px;
	@include mq {
		margin-bottom: 42px;
	}
}

.catalogue-store {
	@include flex(0, 0, wrap);
	margin: 0 -10px;
	@include mq(sm) {
		margin: 0 -8px;
	}
	&__item {
		width: 25%;
		padding: 0 11px;
		@include mq(sm) {
			width: 50%;
			padding: 0 8px;
			margin-bottom: 17px;
		}
	}
}