@use "vars" as *;

.sec-newspage {
	position: relative;
	padding-bottom: 91px;
	z-index: 1;
	@include mq {
		padding: 25px 0 67px;
	}

	&:before {
		content: "";
		position: absolute;
		background: url("../img/news/bg-news.png") top center no-repeat;
		background-size: 100%;
		width: 100vw;
		height: 102%;
		top: -37px;
		@include h-align;
		z-index: -1;
		pointer-events: none;
		@include mq(sm) {
			background: url("../img/news/bg-news-sp.png") top center no-repeat;
			background-size: 100%;
			top: -17px;
		}
	}
	&:after{
		@include mq(sm){
			content: "";
			position: absolute;
			background: url("../img/news/icon-news.png") top center no-repeat;
			background-size: 100%;
			width: 88px;
			height: 102px;
			bottom: 25px;
			right: 1%;		
			z-index: -1;
			pointer-events: none;
		}
	}
}

.newspage-list {
	margin-bottom: 103px;
	@include mq {
		margin-bottom: 79px;
	}

	&__item {
		position: relative;

		&:before {
			content: "";
			position: absolute;
			background: linear-gradient(90deg, #FFDBE7, #FFEB9A);
			width: 100%;
			height: 10px;
			border-radius: 5px;
			top: 0;
			left: 0;
			@include mq {
				height: 5px;
			}
		}

		&:last-child {
			&:after {
				content: "";
				position: absolute;
				background: linear-gradient(90deg, #FFDBE7, #FFEB9A);
				width: 100%;
				height: 10px;
				border-radius: 5px;
				bottom: -10px;
				left: 0;
				@include mq {
					height: 5px;
					bottom: -4px;
				}
			}
		}

		a {
			display: block;
			background: white;
			color: #1D3E62;
			font-weight: bold;
			padding: 42px 0 29px;
			@include mq {
				padding: 32px 0 25px;
			}
		}

		p {
			display: -webkit-box;
			overflow: hidden;
			text-overflow: ellipsis;
			-webkit-line-clamp: 2;
			-webkit-box-orient: vertical;
			max-height: 70px;
			font-family: $font-Yugothic;
			@include mq(sm) {
				-webkit-line-clamp: 3;
				max-height: 60px;
			}
		}
	}

	&__date {
		font-weight: bold;
		@include font-size(16);
		line-height: 1;
		margin-bottom: 12px;
		@include mq {
			@include font-size(12);
			margin-bottom: 10px;
		}
	}

	&__ttl {
		display: block;
		color: #E0346E;
		font-weight: bold;
		@include fts(30, 50);
		margin: 0 -4px 6px 0;
		@include mq {
			@include fts(16, 25);
			margin-bottom: 10px;
		}

		span {
			background-image: linear-gradient(to right, #E0346E 50%, transparent 50%);
			background-size: 200% 1px;
			background-repeat: no-repeat;
			background-position: 0 bottom;
			padding-bottom: 3px;
		}
	}
}

.newspage-btn {
	text-align: center;
	.page-numbers{
		display: none;
	}
	.prev,.next{
		display: inline-block;
	}
	a {
		color: #1D3E62;
		font-family: $font-Yugothic;
		font-weight: bold;
		@include font-size(16);
		text-decoration: underline;
		margin: 0 20px;
		@include mq {
			@include font-size(12);
		}
	}
}

#page-news_detail{
	.sec-newsdetail{
		&:before{
			display: none;
		}
	}
}

.sec-newsdetail {
	padding: 58px 0 95px;
	@include mq {
		position: relative;
		padding: 29px 0 67px;
		&:before {
			content: "";
			position: absolute;
			background: url("../img/news/bg-detail.jpg") top center repeat-y;
			background-size: 100%;
			width: 100%;
			height: calc(100% + 87px);
			top: -87px;
			left: 0;
			z-index: -1;
		}
	}
}

.newsdetail-info {
	background: #FFF2F6;
	border-radius: 20px;
	padding: 18px 25px 13px;
	margin-bottom: 12px;
	@include mq {
		padding: 22px 20px 21px;
		margin-bottom: 24px;
	}

	&__date {
		display: block;
		font-weight: bold;
		@include font-size(16);
		line-height: 1;
		margin-bottom: 9px;
		@include mq {
			@include font-size(12);
			margin-bottom: 10px;
		}
	}
}
