@use 'vars' as *;

#page-hiruma{
	.mvhd-block__ttl{
		margin-left: 0;
		margin-top: -76px;
		@include mq{
			margin-left: -14px;
			margin-top: -39px;
		}
		img{
			height: 157px;
			@include mq(xl){
				height: 120px;
			}
			@include mq{
				height: 77px;
			}
		}
	}
	.sec-heading{
		margin-bottom: 90px;
		@include mq{
			padding-top: 160px;
			margin-bottom: 60px;
		}
		&::before{
			width: 213px;
			height: 244px;
			background: url(../img/hiruma/bg-before.png) no-repeat center/cover;
			top: 19.7%;
			left: 12%;
			@include mq(xl){
				left: 3%;
			}
			@include mq(tb){
				left: -10%;
			}
			@include mq{
				width: 121px;
				height: 139px;
				top: 1.7%;
				left: 22%;
			}
		}
		&::after{
			width: 132px;
			height: 138px;
			background: url(../img/hiruma/bg-after.png) no-repeat center/cover;
			top: -12.5%;
			right: 20.3%;
			@include mq(xl){
				right: 12%;
			}
			@include mq(tb){
				right: 3%;
			}
			@include mq{
				width: 79px;
				height: 83px;
				right: 26.1%;
				top: 43px;
			}
		}
		&__ttl{
			@include mq(md-min){
				margin-bottom: 30px;
			}
		}
	}
	.sec-introduction{
		@include mq(){
			background: url('../img/yakan/bg-01-sp.png') center no-repeat;
			background-size: 100% 100%;
			padding-bottom: 116px;
			margin-bottom: -40px;
		}
	}
	.bg-introduction{
		@include mq(){
			background: none;
		}
	}
}

.schedule-day{
	&__ttl{
		margin-bottom: 8px;
		@include mq{
			margin-bottom: 33px;
		}
		span{
			padding: 12px 52px;
			letter-spacing: 0px;
			@include mq{
				max-width: 320px;
				width: 100%;
				padding: 6px 20px;
			}
			@include mq{
				padding: 6px 14px;
			}
		}
		&--top{
			margin-bottom: -35px;
			position: relative;
			z-index: 11;
		}
	}
	&__box{
		margin-bottom: 21px;
		@include mq{
			margin-bottom: 25px;
		}
		&:last-child{
			margin-bottom: 0;
		}
		&-dt{
			font-weight: 700;
			color: #E0346E;
			margin-bottom: 3px;
			font-family: $font-Yugothic;
			@include mq{
				@include font-size(16);
				margin-bottom: 7px;
			}
			&--blue{
				color: #277AE2;
			}
		}
		&-dd{
			.c-table{
				border: none;
				tr{
					th{
						color: #E0346E;
					}
					td{
						font-weight: 700;
					}
				}
			}
		}
	}
	&__wp{
		position: relative;
		@include mq{
			margin-bottom: 53px;
			&:last-child{
				margin-bottom: 0;
			}
		}
	}
	&__deco{
		span{
			width: 322px;
			position: absolute;
			@include mq{
				width: 205px;
			}
		}
		&-01{
			top: 0;
			right: 0;
			transform: translate(87%, -46%);
			@include mq{
				top: -90px;
				right: -87px;
				transform: none;
			}
		}
		&-02{
			top: 32%;
			left: 0;
			transform: translate(-64%, 0);
			@include mq{
				left: -50px;
				top: 37%;
				transform: none;
			}
		}
		&-03{
			bottom: -17px;
			right: 0;
			transform: translate(72%, 0);
			@include mq{
				bottom: -113px;
				transform: translate(55%, 0);
			}
		}
		&--styles02{
			.schedule-day__deco{
				&-01{
					top: 0;
					left: 0;
					transform: translate(-65%, 30%);
					@include mq{
						top: 127px;
						left: -52px;
						transform: none;
					}
				}
				&-02{
					top: 29%;
					right: 0;
					left: auto;
					transform: translate(87%, 0);
					@include mq{
						top: 40%;
						right: 0;
						transform: translate(60%, 0);
					}
				}
				&-03{
					bottom: -70px;
					left: 0;
					transform: translate(-64%, 0);
					right: auto;
					@include mq{
						bottom: -70px;
						transform: translate(-25%, 0);
					}
				}
			}
		}
	}
}

.bg-wave-pink{
	background: #FFF2F6;
	position: relative;
	@include p-before(100%, 6.369vw){
		background: url(../img/hiruma/wave-before.png) no-repeat top center/cover;
		left: 0;
		@include mq{
			height: 10.433vw;
			background: url(../img/hiruma/wave-before-sp.png) no-repeat top center/cover;
		}
	}
}

.sec-curriculum{
	padding-bottom: 16.5vw;
	margin-bottom: -84px;
	@include mq(){
		// padding-bottom: 38.5vw;
		padding-bottom: 44.5vw;
	}
}

.curriculum-page{
	&__ttl{
		margin-bottom: 54px;
		position: relative;
		top: -9px;
		@include mq{
			margin-bottom: 26px;
		}
	}
	&__intro{
		margin-bottom: 68px;
		position: relative;
		@include mq{
			margin-bottom: 50px;
		}
		@include p-before(161px, 179px){
			background: url(../img/common/icon-top02.png) no-repeat center/cover;
			left: -88px;
			bottom: -28px;
			@include mq{
				width: 16vw;
				height: 17.5vw;
				left: 0;
				bottom: auto;
				top: 53px;
			}
		}
		@include p-after(174px, 186px){
			background: url(../img/common/icon-apple.png) no-repeat center/cover;
			right: -83px;
			bottom: 66px;
			@include mq{
				width: 21.628vw;
				height: 23.155vw;
				right: 0;
				bottom: auto;
				top: -47px;
			}
		}
		&-ttl{
			margin-bottom: 24px;
			line-height: 1.5;
			position: relative;
			z-index: 2;
			@include mq{
				@include fts(20, 30);
				margin-bottom: 13px;
				margin-left: -10px;
				margin-right: -10px;
			}
			@include mq(xs){
				@include font-size(17);
				.c-txt__hightline{
					background-position: 0 17px;
				}
			}
		}
		&-desc{
			font-weight: 700;
			@include mq{
				line-height: 2.1;
			}
		}
		&--yakan{
			@include p-before(161px, 179px){
				background: url(../img/yakan/icon-top.png) no-repeat center/cover;
				left: -88px;
				bottom: -28px;
				@include mq{
					width: 16vw;
					height: 17.5vw;
					left: 0;
					bottom: auto;
					top: 53px;
				}
			}
		}
	}
}


.sec-tuition{
	&--day{
		background: url(../img/hiruma/bg-wave-rgba.png) no-repeat center/100% 100%;
		@include mq{
			background: url(../img/hiruma/bg-wave-rgba-sp.png) no-repeat center/100% 100%;
			z-index: 2;
		}
	}
}

.sec-introduction{
	&--day{
		&::before{
			background: url(../img/hiruma/icon-03.png) no-repeat center/100%;
		}
		&::after{
			background: url(../img/hiruma/icon-04.png) no-repeat center/100%;
		}
	}
}

.bg-introduction{
	&--day{
		&::after{
			@include mq{
				background: url(../img/hiruma/icon-04.png) no-repeat center/100%;
			}
		}
	}
}