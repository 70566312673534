@use "vars" as *;

#page-gakko_annai_kokka975{
    @include mq(){
        overflow: hidden;
    }
}
.mv-page{
    &__kokka{
        position: relative;
		padding-top: 23px;
		padding-bottom: 94px;
		@include mq {
			padding-top: 24px;
			padding-bottom: 36px;
		}
        @include p-before(337px, 238px) {
			background: url(../img/gakko_annai/img-books.png) no-repeat center/contain;
            bottom: 12px;
            left: -6.8%;
			z-index: -1;
            @include mq(lg){
                width: 280px;
                height: 160px;
            }
			@include mq {
				display: none;
			}
		}
		@include p-after(263px, 196px) {
			background: url(../img/gakko_annai/img-built.png) no-repeat center bottom/contain;
			top: -9px;
			right: -4.8%;
            @include mq(lg){
                width: 200px;
                height: 150px;
            }
			@include mq {
                height: 115px;
                width: 95px;
                top: 7px;
                right: -28px;
			}
		}
        &-ttl{
            margin-bottom: 40px;
            @include mq(){
                margin-bottom: 21px;
            }
        }
        &-wrap{
            @include flex(center,0,wrap);
            text-align: center;
            max-width: 580px;
            margin: 0 auto;
            @include mq(){
                max-width: 350px;
            }
            &-item{
                width: 50%;
                &:nth-child(2){
                    .mv-page__kokka-wrap-ttl{
                        .c-ttl__03{
                            margin-left: -25px;
                        }
                        span{
                            &::before{
                                left: auto;
                                right: -90px;
                                transform: rotate(90deg);
                                @include mq(){
                                    right: -45px;
                                }
                            }
                        }
                    }
                    @include mq(sm){
                        text-align: left;
                        padding-left: 8px;
                        .mv-page__kokka-wrap-ttl{
                            .c-ttl__03{
                                margin-left: 0;
                                padding-left: 27px;
                            }
                        }
                    }
                }
            }
            &-ttl{
                font-weight: 700;
                >small{
                    display: block;
                }
                span{
                    @include fts(106,77);
                    position: relative;
                    @include mq(lg){
                        @include font-size(70);
                    }
                    @include mq(){
                        @include font-size(55);
                        line-height: 0.5;
                    }
                    @include p-before(70px,69px){
                        background: url(../img/gakko_annai/kokka975/icon-star.png) no-repeat center/cover;
                        left: -85px;
                        bottom: 30px;
                        @include mq(){
                            width: 36px;
                            height: 35px;
                            left: -43px;
                            bottom: 12px;
                        }
                    }
                    small{
                       @include font-size(64); 
                       @include mq(){
                            @include font-size(33);
                        }
                    }
                }
            }
        }
    }
}
.sec-kokka{
    position: relative;
    overflow: hidden;
    padding-bottom: 73px;
    @include mq(){
        padding-bottom: 34px;
        overflow: initial;
    }
    &:after {
        content: "";
        width: 386px;
        height: 442px;
        background: url(../img/gakko_annai/img-bg.png) no-repeat center center;
        background-size: 100%;
        position: absolute;
        bottom: 34%;
        right: -175px;
        display: inline-block;
        z-index: -1;
        @include mq {
            width: 205px;
            height: 245px;
            bottom: -99px;
            right: -41px;
        }
    }
}
.kokka-block{
    &__item{
        margin-bottom: 77px;
        @include mq(){
            margin-bottom: 39px;
        }
        &-ttl{
            margin-bottom: 16px;
            @include mq(){
                line-height: 1.4;
                padding-bottom: 17px;
                margin-bottom: 3px;
            }
        }
        &-txt{
            font-weight: 700;
            @include mq(){
                line-height: 2.1;
            }
            img{
                margin-top: 24px;
                @include mq(){
                    margin-top: 18px;
                    border-radius: 20px;
                }
            }
        }
        &-img{
            @include mq(){
                position: relative;
                display: block;
                @include p-before(142px,96px){
                    background: url(../img/gakko_annai/img-books.png) no-repeat center/contain;
                    background-size: 100%;
                    position: absolute;
                    bottom: -39px;
                    left: -64px;
                }
            }
        }
    }
}