@use "vars" as *;

/* ====================================================
css gakko_annai
==================================================== */


.mvhd-block {
    margin-bottom: 68px;

    @include mq {
        margin-bottom: 0;
    }

    &__img {
        img {
            width: 100%;
            border-radius: 20px;
            overflow: hidden;
        }
    }

    &__ttl {
        margin-top: -71px;
        margin-left: -23px;

        @include mq {
            margin-top: -27px;
            margin-left: -9px;
        }

        img {
            height: 143px;
            object-fit: contain;

            @include mq {
                height: 58px;
            }
        }
    }
}

.sec-message {
    position: relative;
    margin-bottom: 96px;

    @include mq {
        padding-bottom: 64px;
        overflow: hidden;
        padding-top: 57px;
        margin-bottom: 0;
    }

    &:before {
        content: '';
        width: 100%;
        height: 141px;
        position: absolute;
        top: 7px;
        right: 0;
        left: 0;
        z-index: 5;
        background: url('../img/gakko_annai/img-top01.png') no-repeat top center;
        // background-size: 100%;
        background-size: cover;

        @include mq {
            height: 38px;
            background: url('../img/gakko_annai/img-top01sp.png') no-repeat top center;
            background-size: cover;
            top: 64px;
        }
    }

    &:after {
        content: '';
        width: 100%;
        height: 82px;
        position: absolute;
        bottom: 0;
        right: 0;
        left: 0;
        background: url('../img/gakko_annai/img-top02.png') no-repeat top center;
        background-size: cover;

        @include mq {
            height: 39px;
            background: url('../img/gakko_annai/img-top02sp.png') no-repeat top center;
            background-size: cover;
            bottom: 60px;
        }
    }

    .message-block {
        max-width: 1085px;
        margin: 0 auto;
        position: relative;
        z-index: 5;
        padding-bottom: 156px;

        @include mq {
            padding-bottom: 84px;
        }

        &-t {
            position: absolute;
            top: -49px;
            right: 27px;
            z-index: 5;

            @include mq(tb) {
                width: 200px;
                top: -25px;
                right: 0;
                
            }

            @include mq() {
                width: 122px;
                position: absolute;
                right: -12px;
                z-index: 5;
            }
        }

        &-bt {
            position: absolute;
            bottom: -105px;
            z-index: 7;

            @include mq(tb) {
                width: 200px;
                bottom: -31px;
                left: -22px;
                z-index: 7;
            }

            @include mq() {

                width: 156px;
            }
        }
    }

    .message-ttl {
        text-align: center;
        margin-bottom: 77px;

        @include mq {
            margin-bottom: 25px;
        }

        &__ico {
            margin-bottom: 23px;
            display: inline-block;

            @include mq {
                width: 40px;
                margin-bottom: 11px;
            }
        }

        &__main {
            display: block;

            @include mq {
                width: 128px;
                margin: 0 auto;
            }
        }
    }

    &__w {
        position: relative;

        &:after {
            content: '';
            width: 100%;
            height: calc(100% - 140px);
            background-color: #FFFCDB;
            position: absolute;
            top: 140px;
            right: 0;
            left: 0;

            @include mq {
                height: calc(100% - 38px);
                top: 38px;
            }
        }

        &>* {
            position: relative;
            z-index: 3;
        }

        .inner {
            z-index: 5;
        }
    }
}

.message-wrap {
    @include flex(0, 0);

    @include mq {
        flex-wrap: wrap;
    }

    &__l {
        width: 58%;
        padding-right: 27px;
        .c-txt__basic {
            font-weight: 700;
        }
        @include mq {
            width: 100%;
            padding-right: 0;
            order: 2;

            .c-txt__basic {
                line-height: calc(25/12);
            }
        }

        .c-ttl__03 {
            margin-bottom: 29px;

            @include mq(xl) {
                @include mq(md-min){
                    font-size: 27px;
                }
                
            }
            @include mq(tb) {
                @include mq(md-min){
                    font-size: 21px;
                }
                
            }
            @include mq {
                text-align: center;
                font-size: 20px;

                line-height: 1.5;
                margin-bottom: 17px;
            }
        }
    }

    &__r {
        width: 42%;
        margin-top: -56px;

        @include mq {
            width: 100%;
            margin-top: 0;
            margin-bottom: 33px;
        }

        &-img {
            margin-bottom: 22.5px;

            @include mq {
                width: 250px;
                margin: 0 auto 12px;
            }
        }

        &-author {
            @include flex(center, center);
            margin-right: 23px;

            @include mq {
                margin-right: 5px;
            }
        }

        &-func {
            @include fts(20, 29);
            color: #fff;
            font-weight: 700;
            background: #1D3E62;
            margin-right: 12.5px;
            padding: 2px 13.5px;

            @include mq {
                @include fts(12, 16);
                margin-right: 8.5px;
                padding: 2px 7.5px;
            }
        }

        &-name {
            @include fts(30, 29);
            font-weight: 700;
            margin-top: -1px;

            @include mq {
                @include fts(16, 16);
            }
        }
    }
}

.history-ttl {
    text-align: center;

    span {
        @include fts(30, 45);
        font-weight: 700;
        color: #fff;
        position: relative;
        min-width: 473px;
        height: 88px;
        position: relative;
        display: inline-block;
        padding: 11px 10px;
        margin-bottom: 33px;
        max-width: 100%;

        @include mq {
            width: 320px;
            max-width: 100%;
            min-width: auto;
            @include fts(20, 29);
            margin-bottom: 26px;
            height: 60px;
            padding: 8px 5px;
        }

        &:after {
            content: '';
            min-width: 473px;
            height: 88px;
            display: inline-block;
            position: absolute;
            top: 0;
            left: 50%;
            transform: translateX(-50%);
            z-index: -1;
            background: url('../img/gakko_annai/img-btn.png') no-repeat center center;
            background-size: 100%;
            max-width: 100%;

            @include mq {
                width: 320px;
                max-width: 100%;
                min-width: auto;
                height: 60px;
            }
        }
    }
}

.history-mail {
    max-width: 1036px;
    margin: 0 auto;
    border: 2px solid #E0346E;
    border-radius: 20px;
    padding: 41px 25px 47px;
    background: #fff;
    z-index: 3;
    position: relative;

    @include mq{
        padding: 13px 15px 22px 18px;
    }

    &__w {
        max-width: 548px;
        margin: 0 auto;
    }

    &__dl {
        @include flex(0, 0);
    }

    &__dt {
        width: 71px;
        color: #E0346E;
        padding-right: 15px;

        @include mq {
            width: 54px;
            padding-right: 5px;
        }
    }

    &__dd {
        width: calc(100% - 71px);

        @include mq {
            width: calc(100% - 54px);
        }
    }

    &.c-txt__basic {
       @include mq{
        line-height: calc(30/12) !important;
       }
       
        
    }
}

.sec-history {
    padding-bottom: 142px;
    position: relative;
    overflow: hidden;

    @include mq {
        padding-bottom: 121px;
    }

    &:after {
        content: "";
        width: 386px;
        height: 442px;
        background: url(../img/gakko_annai/img-bg.png) no-repeat center center;
        background-size: 100%;
        position: absolute;
        bottom: 21px;
        right: -175px;
        display: inline-block;
        z-index: 2;

        @include mq {
            width: 217px;
            height: 248px;
            bottom: 9px;
            right: -60px;
        }
    }

    .c-txt__basic {
        color: #1D3E62;
        font-weight: 700;

        @include mq {
            line-height: calc(25/12);
            font-feature-settings: "palt";
             letter-spacing: 0.03em;
        }
    }
}

.sec-education {
    margin-bottom: 125px;

    @include mq {
        margin-bottom: 0;
    }

    .mv-page__info--ct {
        padding-bottom: 0;
    }
}