@use 'vars' as *;

.sec-dental-department{
	padding-top: 17.5vw;
	overflow: hidden;
	@include mq{
		padding-top: 26.5vw;
		padding-bottom: min(70px, 16.667vw);
	}
	@media screen and (max-width: 375px){
		padding-top: 30.5vw;
	}
}

.dental-department{
	background: rgb(255,219,231);
	background: linear-gradient(145deg, rgba(255,219,231,1) 0%, rgba(255,235,154,1) 100%);
	position: relative;
	margin-bottom: -30px;
	@include mq{
		background: linear-gradient(170deg, rgba(255,219,231,1) 0%, rgba(255,235,154,1) 100%);
	}
	@include p-before(100%, 11vw){
		background: url(../img/nyushi/boshu/bg-day-01.png) no-repeat top center/100%;
		top: -10.5vw;
		@include mq{
			height: 17vw;
			top: -17vw;
			background: url(../img/nyushi/boshu/bg-day-01-sp.png) no-repeat bottom center/cover;
		}
	}
	@include p-after(100%, 11vw){
		background: url(../img/nyushi/boshu/bg-day-02.png) no-repeat bottom center/100%;
		bottom: -1.4vw;
		z-index: 2;
		@include mq{
			display: none;
		}
	}
	&__ttl{
		text-align: center;
		margin-bottom: 70px;
		@include mq{
			margin-bottom: 30px;
		}
		span{
			// display: inline-block;
			// text-align: center;
			// position: relative;
			// @include font-size(30);
			// font-weight: 700;
			// color: #fff;
			// border-radius: 35px;
			// background: rgb(255,130,172);
			// background: linear-gradient(139deg, rgba(255,130,172,1) 0%, rgba(251,201,0,1) 100%);
			// padding: 14px 44px;
			// letter-spacing: 3px;
			// @include mq{
			// 	@include font-size(20);
			// 	letter-spacing: 0;
			// 	padding: 10px 20px;
			// }
			// @include p-after(22px, 18px){
			// 	background: url(../img/nyushi/boshu/arrow-day.png) no-repeat center/100%;
			// 	bottom: 0;
			// 	left: 50%;
			// 	transform: translate(-50%, 100%);
			// 	@include mq{
			// 		width: 16px;
			// 		height: 11px;
			// 	}
			// }
		}
	}
	&__deco{
		& > *{
			position: absolute;
		}
		&-01{
			width: 17%;
			top: -16.5vw;
			left: 0;
			@include mq{
				width: min(26vw, 130px);
				top: -26.5vw;
				left: -21px;
			}
		}
		&-02{
			width: 28%;
			right: 0;
			top: 50%;
			transform: translate(50%, -50%);
			@include mq{
				width: 177px;
				right: -44px;
				top: 175px;
				transform: none;
			}
		}
		&-03{
			width: 11.5vw;
			bottom: -5.2vw;
			right: 83px;
			z-index: 3;
			@include mq{
				width: 18.5vw;
				bottom: 1.8vw;
				right: 16px;
			}
		}
	}
	&__content{
		font-family: $font-Yugothic;
		position: relative;
		z-index: 10;
		@include mq{
			@include font-size(16);
		}
		@media screen and (max-width: 375px){
			@include font-size(14);
		}
	}
	&__dl{
		@include flex(start, start, wrap);
		margin-bottom: 13px;
		font-weight: 700;
		@include mq{
			margin-bottom: 14px;
		}
		&:last-child{
			margin-bottom: 0;
			@include mq{
				margin-bottom: 37px; 
			}
		}
	}
	&__dt{
		width: 140px;
		display: inline-block;
		background: #fff;
		border-radius: 15px;
		padding: 5px 0;
		color: #E0346E;
		line-height: 1.25;
		text-align: center;
		@include mq(sm){
			width: auto;
			min-width: 110px;
			padding: 8px 20px;
			margin-bottom: 2px;
		}
		&--02{
			@include mq(md-min){
				padding: 9px 0;
			}
		}
	}
	&__dd{
		width: calc(100% - 140px);
		padding-left: 38px;
		color: #1D3E62;
		line-height: 1.875;
		@include mq{
			padding-left: 20px;
		}
		@include mq(sm){
			width: 100%;
			padding-left: 0;
			line-height: 2.1875;
			letter-spacing: -0.5px;
		}
		&--full{
			width: 100%;
			padding-left: 0;
			padding-top: 20px;
		}
		a{
			color: #1D3E62;
		}
	}

	&__table{
		width: 100%;
		border-radius: 20px;
		overflow: hidden;
		@include mq{
			@include font-size(12);
		}
		tr{
			th, td{
				padding: 7px 7px;
				text-align: center;
				@include mq(sm){
					padding: 5px 5px;
				}
				&:last-of-type{
					 border-right: none;
				}
			}
			th{
				background: #FFC5D9;
				border-bottom: 2px solid #fff;
				border-right: 2px solid #fff;
				line-height: 1.4;
				width: calc(81%/4);
				&:first-child{
					width: 19%;
				}
				small{
					@include font-size(13);
					display: block;
					@include mq{
						@include font-size(12);
						display: inline;
					}
				}
			}
			td{
				background: #fff;
				border-bottom: 2px solid #FFC5D9;
				border-right: 2px solid #FFC5D9;
				@include mq{
					@include font-size(16);
				}
			}
			&:first-child{
				th, td{
					padding: 17px 17px;
					@include mq{
						padding: 13px 10px;
					}
				}
			}
			&:last-of-type{
				th, td{
					border-bottom: none;
				}
			}
		}
	}
	&__note{
		@include font-size(13);
		@include mq{
			@include font-size(10);
		}
	}
	.inner{
		position: relative;
		top: -9vw;
		max-width: 1076px;
		margin-bottom: -5vw;
		@include mq{
			top: -17vw;
			padding-bottom: 14vw;
		}
	}
	&--night{
		background: rgb(159,202,255);
		background: linear-gradient(145deg, rgba(159,202,255,1) 0%, rgba(255,235,154,1) 100%);
		padding-top: 88px;
		@include mq{
			background: linear-gradient(170deg, rgba(159,202,255,1) 0%, rgba(255,235,154,1) 100%);
			padding-top: 0;
			margin-top: -37px;
			margin-bottom: 0;
		}
		&::before{
			display: none;
			@include mq{
				display: block;
				height: 16.667vw;
				top: -16.667vw;
				background: url(../img/nyushi/boshu/bg-night-01-sp.png) no-repeat top center/cover;
			}
		}
		&::after{
			background: url(../img/nyushi/boshu/bg-night-02.png) no-repeat bottom center/100%;
			bottom: -8.5vw;
			@include mq{
				height: 16.667vw;
				display: block;
				background: url(../img/nyushi/boshu/Union-15@2x.png) no-repeat top center/100%;
				bottom: -4px;
				z-index: 3;
			}
		}
		.inner{
			z-index: 3;
			top: -57px;
			margin-bottom: 20vw;
			@include mq{
				margin-bottom: 0;
				padding-bottom: 16vw;
			}
		}
		.dental-department{
			&__dl{
				&:last-child{
					@include mq{
						margin-bottom: 0;
					}
				}
			}
			&__dt{
				color: #277AE2;
			}
			&__table{
				tr{
					th, td{
						width: calc(81%/5);
						&:first-child{
							width: 19%;
						}
					}
					th{
						background: #9FCAFF;
					}
					td{
						border-color: #9FCAFF;
					}
				}
			}
			&__deco{
				& > *{
					position: absolute;
				}
				&-01{
					width: 28%;
					top: -6vw;
					left: -90px;
					z-index: 3;
					@include mq{
						width: 177px;
						left: -40px;
						top: -42vw;
					}
				}
				&-02{
					width: 28%;
					right: 23px;
					top: 50%;
					transform: translate(50%, -50%);
					@include mq{
						width: 177px;
						transform: none;
						top: 55vw;
						right: -40px;
					}
				}
				&-03{
					width: 20.5vw;
					bottom: 3vw;
					left: 0;
					transform: translateY(100%);
					z-index: 4;
					@include mq{
						width: min(200px, 47.5vw);
						bottom: 24vw;
						left: 4vw;
					}
					@include mq(sm){
						bottom: 31vw;
					}
				}
			}
		}
	}
}
