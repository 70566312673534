@use "vars" as *;

#page-nyushi_shogakukin{
	overflow: hidden;
}

#page-nyushi_price{
	.sec-support-system{
		padding-top: 90px;
		padding-bottom: 173px;
		@include mq{
			padding-bottom: 134px;
		}
		.support-system{
			&__box-deco{
				&--01{
					transform: translate(-85%, -35%);
					@include mq{
						transform: translate(0px, -89%);
					}
				}
				&--02{
					top: auto;
					bottom: 0;
					transform: translate(100%, 21%);
					width: 122px;
					@include mq(tb){
						transform: translate(0, 45%);
					}
					@include mq{
						transform: translate(0, 106%);
						width: 60px;
						right: -19px;
					}
					@include mq(xs){
						transform: translate(0, 60%);
					}
				}
				&--04{
					top: auto;
					right: auto;
					bottom: 0;
					transform: translate(-88%, 89%);
					width: 280px;
					@include mq(tb){
						transform: translate(-50%, 98%);
					}
					@include mq{
						transform: translate(0, 100%);
						width: 160px;
						left: -53px;
					}
				}
			}
		}
	}
}

.sec-support-system{
	padding-top: 80px;
	padding-bottom: 48px;
	@include mq{
		padding-top: 43px;
		padding-bottom: 153px;
	}
}

.support-system{
	&__box{
		margin-bottom: 77px;
		position: relative;
		@include mq{
			margin-bottom: 67px;
		}
		&-ttl{
			margin-bottom: 15px;
			@include mq{
				margin-bottom: 10px;
			}
			small{
				@include font-size(16);
			}
		}
		&-ct{
			font-weight: 700;
			@include mq{
				line-height: 2.1;
			}
			p{
				margin-bottom: 23px;
				@include mq{
					margin-bottom: 13px;
				}
				&:last-child{
					margin-bottom: 0;
				}
			}
		}

		&-guide{
			@include font-size(20);
			color: #E0346E;
			margin-bottom: 32px;
			line-height: 1.4;
			@include mq{
				@include font-size(16);
			}
			small{
				@include font-size(13);
				color: #1D3E62;
				margin-left: 10px;
				@include mq{
					display: block;
					margin-left: 0;
					@include font-size(12);
				}
			}
		}

		&-btn{
			margin-bottom: 15px;
			@include mq{
				margin-bottom: 10px;
			}
			a{
				margin-left: 0;
				margin-right: auto;
			}
		}
		&-txt-large{
			@include font-size(20);
			color: #E0346E;
			line-height: 1.7;
			margin-bottom: 20px !important;
			@include mq{
				@include font-size(16);
				margin-bottom: 5px !important;
			}
			a{
				color: #E0346E;
				margin-left: 5px;
			}
			span{
				@include font-size(16);
			}
		}

		&-deco{
			position: absolute;
			z-index: 2;
			&--01{
				width: min(16.984vw, 232px);
				top: 0;
				left: 0;
				transform: translate(-71%, -86%);
				@include mq(tb){
					transform: translate(-30%, -100%);
				}
				@include mq{
					width: 90px;
					transform: translate(0, -100%);
					left: -28px;
				}
			}
			&--02{
				width: min(11.713vw, 160px);
				top: 0;
				right: 0;
				transform: translate(75%, -70%);
				@include mq(tb){
					transform: translate(30%, -70%);
				}
				@include mq{
					width: 74px;
					transform: translate(0, -100%);
					top: 0;
					right: -22px;
				}
			}
			&--03{
				width: min(20.498vw, 280px);
				top: 0;
				left: 0;
				transform: translate(-88%, -75%);
				@include mq(tb){
					transform: translate(-82%, -75%);
				}
				@include mq{
					display: none;
				}
			}
			&--04{
				width: min(28.258vw, 386px);
				top: 0;
				right: 0;
				transform: translate(64%, -12%);
				@include mq{
					width: 180px;
					top: auto;
					right: auto;
					bottom: 0;
					left: -45px;
					transform: translate(0, 100%);
				}
			}
		}

		&-dl{
			margin-bottom: 23px;
			@include mq{
				margin-bottom: 10px;
			}
			&:last-of-type{
				margin-bottom: 0;
			}
		}
		&-dt{
			@include font-size(20);
			color: #E0346E;
			font-weight: 700;
			margin-bottom: 14px;
			line-height: 1.4;
			@include mq{
				@include font-size(16);
				margin-bottom: 5px;
			}
		}
		&-dd{
			@include fts(16, 30);
			font-family: $font-Yugothic;
			@include mq{
				@include font-size(12);
				line-height: 2;
			}
		}
		&--star{
			.support-system__box-deco--04{
				top: auto;
				bottom: 0;
				transform: translate(58%, 46%);
				@include mq{
					transform: translate(0, 11%);
					width: 160px;
					right: -60px;
					left: auto;
				}
				@include mq(xs){
					right: -125px;
				}
			}
		}
		&--note{
			.support-system__box-deco--04{
				width: min(21.258vw, 289px);
				bottom: 0;
				transform: translate(50%, 34%);
				@include mq{
					width: 140px;
					right: 40px;
					left: auto;
					bottom: -40px;
				}
				@include mq(xs){
					right: 0px;
					bottom: -77px;
				}
			}
		}
	}
}

.c-table{
	font-family: $font-Yugothic;
	border-radius: 20px;
	overflow: hidden;
	border: 2px solid #FFC5D9;
	border-top: none;
	margin-bottom: 25px;
	@include mq{
		margin-bottom: 10px;
	}
	&:last-child{
		margin-bottom: 0;
	}
	table{
		width: 100%;
		tr{
			th, td{
				width: 33.33%;
				padding: 15px 15px;
				text-align: center;
				line-height: 1.25;
				@include mq{
					padding: 0 8px;
					line-height: 1.7;
				}
				@media screen and (max-width: 375px) {
					padding: 0px 4px;
				}
				&:last-of-type{
					border-right: none;
				}
				@include mq(xs){
					@include font-size(10);
				}
			}
			th{
				background: #FFC5D9;
				border-right: 2px solid #fff;
				padding: 17px 15px;
				@include mq(tb){
					padding: 18px 8px;
				}
				@include mq{
					padding: 6px 4px;
				}
			}
			td{
				border-right: 2px solid #FFC5D9;
				border-bottom: 2px solid #FFC5D9;
				height: 71px;
				padding: 0 15px;
				@include mq{
					height: 82px;
					padding: 0 4px;
				}
			}
			&:last-child{
				td{
					border-bottom: none;
				}
			}
		}
	}
	&--large{
		table{
			tr{
				td{
					padding: 24px 15px;
					@include mq{
						padding: 4px 8px;
					}
					@media screen and (max-width: 375px) {
						padding: 4px 4px;
					}
				}
			}
		}
	}
	&--styles02{
		margin-bottom: 32px;
		@include mq{
			margin-bottom: 10px;
		}
		table{
			tr{
				th, td{
					width: 50%;
					padding: 25px 15px;
					@include mq{
						padding: 14px 8px;
					}
				}
			}
		}
	}
	&--styles03{
		@include mq{
			margin-bottom: 20px;
		}
		table{
			tr{
				th, td{
					width: 14.18%;
					@include mq{
						width: 25%;
					}
				}
				td{
					@include mq{
						padding: 6px 4px;
					}
				}
				th{
					border-bottom: 2px solid #fff;
					@include mq{
						padding: 6px 4px;
					}
				}
				&:last-child{
					th{
						border-bottom: none;
					}
				}
			}
		}
	}
	&--schedule{
		position: relative;
		z-index: 10;
		table{
			background: #fff;
			tr{
				th, td{
					width: 16%;
					@include mq{
						width: 16.57%;
					}
					&:first-child{
						width: 18.92%;
						@include mq{
							width: 16.57%;
						}
					}
					@include mq{
						line-height: 1.2;
					}
				}
				th{
					@include mq{
						padding: 23px 4px;
					}
				}
				td{
					height: 76px;
					@include mq{
						padding: 15px 2px;
						height: 70px;
					}
				}
			}
		}
	}
	&--blue{
		border-color: #9FCAFF;
		table{
			tr{
				th{
					background: #9FCAFF;
					color: #277AE2 !important;
				}
				td{
					border-color: #9FCAFF;
				}
			}
		}
	}
	&--auto-sp{
		table tr td{
			@include mq{
				height: auto;
			}
		}
	}
}


.c-list-dots{
	li{
		position: relative;
		padding-left: 15px;
		@include mq{
			padding-left: 10px;
		}
		@include p-before(auto, auto){
			content: "・";
			top: 0;
			left: 0;
		}
	}
}

.c-table-td-07{
	table {
		@include mq(){
			table-layout: fixed;
			font-feature-settings: "palt";
		}
		tr{
			th,td{
				width: calc(100%/7) !important;
			}
			td{
				padding-left: 5px;
				padding-right: 5px;
				@include mq(){
					padding: 7px 2px;
					word-break: break-word;
				}
			}
		}
	}
}